import { FC } from "react";
import "./styles.sass";
export const StudentSearchScreen: FC<any> = ({ search_materials, select_search_material }) => {
    const handleRefreshClick = () => {
        window.location.reload(); // Refresh the page
    };

    return (
        <div className="StudentSearchScreen">
            <h1 className="StudentSearchScreenMainTitle">
                {search_materials.length > 0 ? "результати пошуку" : "нічого не знайдено"}
            </h1>
            <div className="RefreshButtonContainer">
                <span onClick={handleRefreshClick}>назад</span>
            </div>
            <div className="StudentSearchScreenMainItemsContainer">
                {search_materials.length > 0 &&
                    search_materials.map((item: any, id: number) => {
                        return (
                            <div
                                className="StudentSearchScreenItem"
                                key={id}
                                onClick={() => select_search_material(item)}
                            >
                                <h1>
                                    {item.mg_name} {item.l_name}
                                </h1>
                                <h2>{item.theme}</h2>
                                <p>{"#" + item.hashtags.join("#")}</p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};