import React from "react";
import { string } from "yup/lib/locale";
import { NotificationVariant } from "./Components/atoms/Notification/types";
import { SUBJECT_MODE } from "./constants/admin";
import { MODULE_MODE } from "./constants/admin/view_mode";
import { CoursesModeType } from "./types";

type User = "admin" | "student";

export interface AdminContextType {
  notification_data: {
    title: string;
    type: NotificationVariant;
  };
  auth: boolean;
  user_type: User | null;
  user_id: number | null;
  current_mode?: CoursesModeType;
  search_data: undefined | any;
  student_search_data: undefined | any;
  menu_item_id?: number;
  add_subject_modal?: boolean;
  search_task_modal?: boolean;
  add_db_task_modal: boolean;
  add_task_modal?: boolean;
  add_student_modal?: boolean;
  show_additional_menu?: boolean;
  subject: { name: string; id: number } | null;
  module: any | null;
  lesson: any | null;
  tasks_header?: any | null;
  tasks: any[] | null;
  current_task_id: null | number;
  curator: undefined | any;
  teacher: undefined | any;
  new_year_modal?: boolean
}

export interface StudentContextType {
  notification_data: {
    title: string;
    type: NotificationVariant;
  };
  auth: boolean;
  user_type: User | null;
  user_id: number | null;
  user_full_name: string | undefined;
  current_mode?: CoursesModeType;
  menu_item_id?: number;
  subject: { name: string; id: number } | null;
  module: any | null;
  lesson: any | null;
  tasks: any[] | null;
  homework_answers?: string[];
  lesson_pre_save_modal?: boolean;
  leave_lesson?: null | boolean;
  stopped_action?: null | {
    name: "LESSON" | "TEST" | "SEND_LESSON" | "SEND_TEST" | "MODULE" | "MENU" | "SUBJECT" | "GENERAL";
    id?: number;
  };
  is_lesson_changed?: boolean;
  homework_id?: null | number;
  search_materials?: any;
  involved_mgs?: any[];
  mobile_menu?: boolean;
  user_logo?: string;
}

export interface CuratorContextType {
  auth: boolean;
  user_full_name: string;
}

export const GeneralContextItems:
  | AdminContextType
  | StudentContextType
  | CuratorContextType = {
  notification_data: {
    title: "",
    type: "info",
  },
  auth: false,
  user_type: null,
  user_id: null,
  user_full_name: undefined,
  menu_item_id: undefined,
  subject: null,
  module: null,
  lesson: null,
  tasks: null,
  is_lesson_changed: false
};

export const AdminCTX: AdminContextType = {
  ...GeneralContextItems,
  curator: undefined,
  teacher: undefined,
  search_data: undefined,
  student_search_data: undefined,
  add_db_task_modal: false,
  menu_item_id: undefined,
  current_mode: SUBJECT_MODE,
  add_subject_modal: false,
  search_task_modal: false,
  add_task_modal: false,
  add_student_modal: false,
  show_additional_menu: true,
  tasks_header: null,
  current_task_id: null,
  new_year_modal: false
};

export const StudentCTX: StudentContextType = {
  ...GeneralContextItems,
  menu_item_id: undefined,
  current_mode: MODULE_MODE,
  homework_answers: [],
  lesson_pre_save_modal: false,
  leave_lesson: null,
  stopped_action: null,
  homework_id: null,
  search_materials: [],
  involved_mgs: [],
  mobile_menu: false,
  user_logo: ""
};
export const CuratorCTX: CuratorContextType = {
  ...GeneralContextItems,
  user_full_name: "",
};

export const Context = React.createContext<any>(GeneralContextItems);

export const get_initial_context = (
  type: "admin" | "user" | "teacher" | "curator" | null
) => {
  if (type === "admin") return AdminCTX;
  if (type === "user") return StudentCTX;
  if (type === "teacher") return StudentCTX;
  if (type === "curator") return StudentCTX;
  return GeneralContextItems;
};
