import axios from 'axios';
import {
  GET_TASKS_PENDING,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
} from "./../../constants/admin";
import { requestConfig, serv } from "../../constants/config";
import { prepare_query_to_search } from '../../../tools/api';

interface LessonActionType
{
  lesson_id?: number
  exercise?: any
  exercise_id?: number
  search_data?: any
  type?: "lesson" | "test"
  mode?: "normal" | "db"
  callback?: (id: number) => void
}

// POST
export const post_lesson_task_to_db_action = ({lesson_id, exercise, search_data, callback}  : LessonActionType) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/exercises`;
  const body = { exercise };
  axios.post(url, body, requestConfig()).then((res: any) => 
    {
      lesson_id && dispatch(get_lesson_homework_action(lesson_id))
      search_data && dispatch(search_tasks_action(search_data))
      callback && callback(res.data.id)
    });
};

export const post_task_action = (lesson_id: number, exercise_info: any, type: "lesson" | "test") => (dispatch: any) =>
{
  const url = type === "lesson" ? `${serv}/adminpanel/homeworks/add_exercise?id=${lesson_id}` : `${serv}/adminpanel/master_group_exams/${lesson_id}/add_exercise` ;
  const body = { exercise_info };
  axios.post(url, body, requestConfig()).then(() => dispatch(type === "lesson" ? get_lesson_homework_action(lesson_id) : get_test_homework_action(lesson_id)));
};

export const post_test_task_action = (lesson_id: number, exercise_info: any) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/homeworks/add_exercise?id=${lesson_id}`;
  const body = { exercise_info };
  axios.post(url, body, requestConfig()).then(() => dispatch(get_test_homework_action(lesson_id)));
};

export const post_task_from_search_action = (lesson_id: number, exercise_info: any, type: "lesson" | "test") => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/${ type === "lesson" ? "homeworks" : "master_group_exams" }/${lesson_id}/add_exercise_by_id`;
  const body = { ...exercise_info };
  axios.post(url, body, requestConfig()).then(() => dispatch(type === "lesson" ? get_lesson_homework_action(lesson_id) : get_test_homework_action(lesson_id)));
};

// GET
export const search_tasks_action = (queries: any) => (dispatch: any) =>
{
  dispatch({ type: "SEARCH_TASKS_PENDING" });
  const url = `${serv}/adminpanel/exercises/search/?${prepare_query_to_search(queries)}`;
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: "SEARCH_TASKS_SUCCESS", payload: res.data }))
    .catch((err: any) => dispatch({ type: "SEARCH_TASKS_FAIL", payload: err }));
}

export const get_lesson_homework_action = (lesson_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/homeworks/${lesson_id}`;
  dispatch({ type: GET_TASKS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_TASKS_SUCCESS, payload: res.data, is_lesson: false }))
    .catch((err: any) => dispatch({ type: GET_TASKS_FAIL, payload: err }));
};

export const get_test_homework_action = (test_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_group_exams/${test_id}`;
  dispatch({ type: GET_TASKS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_TASKS_SUCCESS, payload: res.data, is_lesson: true }))
    .catch((err: any) => dispatch({ type: GET_TASKS_FAIL, payload: err }));
}

// PATCH

export const patch_lesson_task_action = (
  {lesson_id, exercise, exercise_id, search_data, type, mode} : LessonActionType,
  callback?: (is_ok?: boolean, status?: number) => void) => (dispatch: any) =>
{
  const is_lesson = type === "lesson"
  const url = mode === "normal" ? `${serv}/adminpanel/${is_lesson ? "homeworks" : "master_group_exams"}/${lesson_id}/update_exercise?exercise_id=${exercise_id}` : `${serv}/adminpanel/exercises/${exercise_id}`;
  if (mode === "normal")
  {
    delete exercise.exercise.id
    delete exercise.exercise.task_photos
    delete exercise.exercise.hint_photos
    delete exercise.exercise.right_solution_photos
    delete exercise.exercise.possible_answer_fr_photos
    delete exercise.exercise.possible_answer_sr_photos
  }
  delete exercise.task_photos
  delete exercise.hint_photos
  delete exercise.right_solution_photos
  delete exercise.possible_answer_fr_photos
  delete exercise.possible_answer_sr_photos
  delete exercise.id
  const body = { [mode === "normal" ? "exercise_info" : "exercise"]: exercise };
  axios
      .patch(url, body, requestConfig())
      .then((res) => 
      {
        callback && callback(true, res.status)
        lesson_id && dispatch(is_lesson ? get_lesson_homework_action(lesson_id) : get_test_homework_action(lesson_id));
        search_data && dispatch(search_tasks_action(search_data));
      })
      .catch((err) => callback && callback(false, err.response.status) );
};

// DELETE
export const delete_task_from_db_action = ({lesson_id, exercise_id, search_data}: LessonActionType) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/exercises/${exercise_id}`;
  axios.delete(url, requestConfig()).then(() => {
    lesson_id && dispatch(get_lesson_homework_action(lesson_id))
    // @ts-ignore
    search_data && dispatch<any>(search_tasks_action(search_data))
  });
};

export const delete_task_action = (lesson_id: number, exercise_id: number, position: number, type: "lesson" | "test") => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/${type === "lesson" ? "homeworks" : "master_group_exams"}/${lesson_id}/remove_exercise?exercise_id=${exercise_id}&position=${position}`;
  axios.delete(url, requestConfig()).then(() => dispatch(type === "lesson" ?  get_lesson_homework_action(lesson_id) : get_test_homework_action(lesson_id)));
}

// OTHER
export const clear_searched_task_action = () => (dispatch: any) => dispatch({type: "CLEAR_SEARCHED_TASK"})

export interface LoadPhotoType
{
  exercise_id: number
  field_type: "task_photos" | "hint_photos" | "right_solution_photos" | "possible_answer_fr_photos" | "possible_answer_sr_photos"
  exercise_photo: File
  initial_id: number
  is_correct: boolean
}

export const load_task_photo_action = ({ exercise_id, field_type, exercise_photo, initial_id, is_correct }: LoadPhotoType) =>
{
  const url = `${serv}/adminpanel/exercises/load_file`
  const body = { exercise_id, field_type, exercise_photo, initial_id, is_correct }
  console.warn("requestConfig('photo')", requestConfig("photo").headers["Content-Type"]);
  
  return axios.post(url, body, requestConfig("photo"))
}

export const delete_task_photo_action = (exercise_photo_id: number) =>
{
  const url = `${serv}/adminpanel/exercises/delete_file?exercise_photo_id=${exercise_photo_id}`
  return axios.delete(url, requestConfig())
}








