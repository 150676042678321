import {
  GET_STUDENT_TASKS_PENDING,
  GET_STUDENT_TASKS_SUCCESS,
  GET_STUDENT_TASKS_FAIL,
} from "./../../constants/student";

const initialState = {
  lesson_info: undefined,
  pending: false,
  error: "",
};

export const student_tasks = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STUDENT_TASKS_PENDING:
      return {
        ...state,
        lesson_info: undefined,
        pending: true,
      };
    case GET_STUDENT_TASKS_SUCCESS:
      return {
        ...state,
        lesson_info: action.payload,
        pending: false,
      };
    case GET_STUDENT_TASKS_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }