//@ts-nocheck
import { useContext } from "react";
import { telegram_icon } from "../../../assets/icons/studentLessons";
import { Context } from "../../../context";
import { prepareDate } from "../../../tools/admin/date";
import { link_check } from "../../../tools/common";
import "./styles.sass";

export const StudentModuleInfo = ({ module }) => {
  const [context, setContext] = useContext(Context)
  const click_bad_link = () =>
  {
    setContext({ ...context, notification_data: { title: "Посилання недоступне", type: "warning" } })
  }

  const prepare_link = (text: string, url: string | null) =>
  {
    let is_ok = link_check(url)
    //@ts-ignore
    return <a onClick={ is_ok ? () => {} : click_bad_link } href={ is_ok ? url : "#" } target={ is_ok ? "_blank" : "_self" }>{ text }</a>
  }
  return (
    <div className="StudentModuleInfo">
      <div className="StudentModuleInfoMain">
        <div className="StudentModuleInfoMainLeft">
          <h1>початок</h1>
          <h1>закінчення</h1>
          <h1>куратор</h1>
        </div>
        <div className="StudentModuleInfoMainRight">
          <h1>{prepareDate(module?.start_date ? new Date(module.start_date) : new Date(), false)}</h1>
          <h1>{prepareDate(module?.finish_date ? new Date(module.finish_date) : new Date(), false)}</h1>
          <h1>{ module.curator_name }</h1>
        </div>
      </div>
      <div className="StudentModuleInfoTelegramLink">
        {telegram_icon}
        { prepare_link("група в телеграмі", module.telegram_link) }
      </div>
    </div>
  );
};
