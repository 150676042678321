export const generate_module = (subject_id: number, modules_len: number) =>
({
  name: "НОВИЙ МОДУЛЬ",
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  description: "",
  subject_id,
  start_date: new Date().toISOString(),
  finish_date: new Date(
    new Date().setMonth(new Date().getMonth() + 1)
  ).toISOString(),
  sequence_number: modules_len,
  lessons_count: undefined,
  exams_count: undefined,
});

export const generate_lesson = (testMode: boolean, lessons_length: any, master_group_id: number) =>
({
  name: testMode ? "ТЕСТ _" : "УРОК _",
  lesson_url: "",
  lesson_number: lessons_length + 1,
  lesson_start: new Date().toISOString(),
  master_group_id,
  theme: "",
  description: "",
  aasm_state: "unpublished",
  additional_materials: "",
  hashtags: "",
})

export const generate_task = (task_len: number, subj_id: number) => ({
  "exercise_type": "ShortAnswer",
  "position": task_len + 1,
  "exercise": {
    "task": "",
    "max_shore": 1,
    "hint": "",
    "right_solution": "",
    "correct_answer": "", // відповідь
    "subject_id": subj_id,
    "hashtag": ""
  }
})

export const generate_db_task = (task_len: number) => ({
  "exercise_type": "ShortAnswer",
  "position": task_len + 1,
  "task": "",
  "id": -1,
  "max_shore": 1,
  "hint": "",
  "right_solution": "",
  "correct_answer": "", // відповідь
  "subject_id": 1,
  "hashtag": "theory"
})