import "./styles.sass"
import { FC, useContext, useEffect, useState } from "react"
import { TaskVariantType } from "./types"
import { AdminTasksHeader } from "../../molecules/AdminTasksHeader"
import { AdminTaskBody } from "../../molecules/AdminTaskBody"
import { useSelector } from "react-redux"
import { Context } from "../../../context"
import { clear_searched_task_action, delete_task_action, delete_task_from_db_action, patch_lesson_task_action, post_task_from_search_action, post_lesson_task_to_db_action, search_tasks_action, delete_task_photo_action, load_task_photo_action, LoadPhotoType } from "../../../redux/actions/admin"
import { useDispatch } from "react-redux"
import { alphabetic_list_ua } from "../../../constants/general/common"
import { is_lesson } from "../../../tools/admin/common"
import { is_json_string, prepare_match_variant_points } from "../../../tools/common"

export const AdminTasksInfo: FC<any> = ({ taskExternal, lock, taskSearched, taskExternalBody, delete_lesson }) => {
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const { tasks, theme, searched_tasks, is_it_lesson } = useSelector((st: any) => st.admin_tasks)
  const { lesson, search_data, current_task_id, subject } = context


  const [tasksLocal, setTasksLocal] = useState<any>([]);
  const [ballsLocal, setBallsLocal] = useState(0);
  const [changed_ids, set_changed_ids] = useState<number[]>([])

  // const [mf_tags_count, set_mf_tags_count] = useState(0)
  const mf_tags = () => Array.from( document.getElementsByTagName("math-field"))

  const tasks_to_return = () =>
  {
    if (taskSearched) return [taskSearched]
    if (taskExternal && taskExternalBody) return [taskExternalBody]
    if (taskExternal) return searched_tasks
    return tasks
  }

  const update_changed_ids = (id: number, mode: "add" | "delete") =>
  {
    if (changed_ids.indexOf(id) !== -1 && mode === "add") return
    if (changed_ids.indexOf(id) === -1 && mode === "delete") return
    set_changed_ids(mode === "add" ? [ ...changed_ids, id ] : [ ...changed_ids.filter((i: number) => i !== id) ])
  }

  const save_task_id = (id: number) =>
  {
    if (id === null || current_task_id === null) return
    if (current_task_id === null || current_task_id !== id) {
      setContext((prev: any) => ({ ...prev, current_task_id: id }))
    }
  }

  interface UniversalPhotoType { id: number, url: string | File }

  const detect_deleted_photo_by_id = (old_arr: UniversalPhotoType[], new_arr: UniversalPhotoType[]) =>
  {
    return old_arr.map(el => el.id).filter(el => new_arr.map(el => el.id).indexOf(el) === -1)
  }

  const check_task_photos_changes = (id: number, mode: "check" | "get" = "check") =>
  {
    if (lock) return true
    if (taskExternalBody) return true
    if (id === null || id === undefined) return false
    const prepared_old_task = taskExternal ? searched_tasks : tasks
    const old_task: any = prepared_old_task.filter((task: any) => task.id === id)[0]
    const current_task = tasksLocal.filter((task: any) => task.id === id)[0]
    if (!current_task) return false
    const check_photo_fields = ["task_photos", "hint_photos", "right_solution_photos", "possible_answer_fr_photos", "possible_answer_sr_photos"]
    const new_photos: LoadPhotoType[] = []
    const deleted_photos: number[] = []
    for (let i = 0; i < check_photo_fields.length; i++)
    {
      if (old_task[check_photo_fields[i]].length !== current_task[check_photo_fields[i]].length && mode === "check") { return true } 
      const count_new = current_task[check_photo_fields[i]].filter((el: { id: number, url: File | string }) => el.url instanceof File)
      
      if (count_new.length && mode === "check") { return true }  
      // const deleted = old_arr.map(el => el.id).filter(el => new_arr.map(el => el.id).indexOf(el) === -1)
      const count_deleted = detect_deleted_photo_by_id(old_task[check_photo_fields[i]], current_task[check_photo_fields[i]])
      if (count_deleted.length && mode === "check") { return true } 
      if (mode === "get")
      {
        new_photos.push(...count_new.map(
          (el: { id: number, url: File | string }) => ({
            exercise_id: id,
            field_type: check_photo_fields[i],
            exercise_photo: el.url,
            initial_id: el.id,
            is_correct: !isNaN(parseInt(current_task.correct_answer)) && parseInt(current_task.correct_answer) === el.id
          })
        ))
        deleted_photos.push(...count_deleted.map((el: number) => el))
      }
      
    }
    if (mode === "get") return { new_photos, deleted_photos }
    if (mode === "check") return false
  }

  const check_task_changes = (id: number) => {
    if (lock) return true
    if (taskExternalBody) return true
    if (id === null || id === undefined) return false
    const prepared_old_task = taskExternal ? searched_tasks : tasks
    const old_task: any = prepared_old_task.filter((task: any) => task.id === id)[0]
    const current_task = tasksLocal.filter((task: any) => task.id === id)[0]
    if (!old_task || !current_task) return false
    const current_type = current_task.exercise_type
    if (old_task.exercise_type !== current_type) return true
    if (old_task.max_shore !== current_task.max_shore) return true
    if (!taskExternal && (+old_task.position !== +current_task.position)) return true

    if (current_type === "ChooseAnswer" || current_type === "MatchPair")
    {
      if (!current_task?.possible_answer) return false
      if (current_task.possible_answer.length === 0) return false
      if (!old_task?.possible_answer) return false
      if (old_task.possible_answer.length === 0) return false
      const old_possible = old_task.possible_answer[0]
      const current_possible = current_task.possible_answer[0]
      if (current_type === "ChooseAnswer")
      {
        if (current_task.correct_answer !== old_task.correct_answer) return true
        const old_first_row = old_possible.first_row
        const current_first_row = current_possible.first_row
        if (old_first_row.length !== current_first_row.length) return true
        for (let i = 0; i < current_first_row.length; i++)
        {
          if (old_first_row[i].trim() !== current_first_row[i].trim()) return true
        }
      }
      if (current_type === "MatchPair")
      {
        const old_first_row = old_possible.first_row
        const current_first_row = current_possible.first_row
        const old_second_row = old_possible.second_row
        const current_second_row = current_possible.second_row
        if (old_first_row.length !== current_first_row.length) return true
        if (old_second_row.length !== current_second_row.length) return true
        for (let i = 0; i < current_first_row.length; i++)
        {
          if (old_first_row[i].trim() !== current_first_row[i].trim()) return true
        }
        for (let i = 0; i < current_second_row.length; i++)
        {
          if (old_second_row[i].trim() !== current_second_row[i].trim()) return true
        }
      }
    }
    const check_fields = ["task", "hint", "hashtag", "right_solution"]
    if (current_type !== "ChooseAnswer") check_fields.push("correct_answer")
    // logic for check items above ...
    for (let i = 0; i < check_fields.length; i++)
    {
      if (
        old_task[check_fields[i]] !== null && current_task[check_fields[i]] !== null &&
        old_task[check_fields[i]] !== undefined && current_task[check_fields[i]] !== undefined &&
        old_task[check_fields[i]].trim() !== current_task[check_fields[i]].trim()
        ) return true
    }
    if (check_task_photos_changes(id, "check")) return true
    return false
  }

  const change_task_info = ({ name, value }: any, id: number) =>
  {
    const task_id = id ?? current_task_id
    if (!task_id) return
    setTasksLocal((prev: any) => prev.map((el: any) => el.id === id ? { ...el, [name]: value } : { ...el } ));
    save_task_id(id)
  }

  const change_task_ball = (new_balls: number, id: number) =>
  {
    setTasksLocal(
      tasksLocal.map((el: any) =>
        el.id === id ? { ...el, max_shore: new_balls } : { ...el }
      )
    );
    save_task_id(id)
  }

  const change_task_variant = (new_variant: TaskVariantType, id: number) =>
  {
    setTasksLocal(tasksLocal.map((el: any) =>
      el.id === id ? { ...el, exercise_type: new_variant.name_en, correct_answer: new_variant.name_en === "MatchPair" || new_variant.name_en === "ChooseAnswer" ? "" : el.correct_answer } : { ...el }
    ));
    save_task_id(id)
  }

  const add_first_symbol = (arr: string[], mode: "numeric" | "alphabetic") =>
  {
    const empty_answer = mode === "numeric" ? ["1. @@@nobody"] : ["A. @@@nobody"]
    if (!arr) return empty_answer
    if (arr.length === 0) return empty_answer
    const new_strings_arr = [];
    for (let i = 0; i < arr.length; i++)
    {
      const prepared_item = arr[i].split("@@@").length > 1 ? arr[i].split("@@@")[1] : arr[i]
      new_strings_arr.push(mode === "numeric" ? i + 1 + ". @@@" + prepared_item : alphabetic_list_ua[i] + ". @@@" + prepared_item)
    }
    return new_strings_arr
  }

  const prepare_possible_answers = (task: any) =>
  {
    if (task.exercise_type === "ChooseAnswer")
    {
        if (!task.possible_answer) return [{first_row: ["1 @@@ nobody"]}]
        if (!task.possible_answer.length) return [{first_row: ["1 @@@ nobody"]}]
        if (!task.possible_answer[0].first_row) return [{first_row: ["1 @@@ nobody"]}]
        task.possible_answer[0].first_row = add_first_symbol(task.possible_answer[0].first_row, "numeric")
        return task.possible_answer
    }
    if (task.exercise_type === "MatchPair")
    {
        if (!task.possible_answer) return [{first_row: ["1 @@@ nobody"], second_row: ["A @@@ nobody"]}]
        if (!task.possible_answer.length) return [{first_row: ["1 @@@ nobody"], second_row: ["A @@@ nobody"]}]
        task.possible_answer[0].first_row = add_first_symbol(task.possible_answer[0].first_row, "numeric")
        task.possible_answer[0].second_row = add_first_symbol(task.possible_answer[0].second_row, "alphabetic")
        return task.possible_answer
    }
  }

  const correct_task = (task: any) =>
  {
    const task_copy: any = { ...task }
    const task_type = task_copy.exercise_type
    if (task_type === "ManualSolution") delete task_copy.possible_answer
    if (task_type === "ShortAnswer") delete task_copy.possible_answer
    if (task_type === "MatchPair" || task_type === "ChooseAnswer") task_copy.possible_answer = prepare_possible_answers(task)
    if (task_type === "ChooseAnswer")
    {
      if (task_copy.correct_answer.trim().length === 0 || task_copy.correct_answer === "1E 2А 3C") task_copy.correct_answer = task_copy.possible_answer[0].first_row[0].split("@@@")[1]
      const splitted_correct_answer = task_copy.correct_answer.split(" ")
      if (splitted_correct_answer.length > 2) task_copy.correct_answer = task_copy.correct_answer.replace(" ", "")
    }
    task_copy.position = +task.position
    return task_copy
  }

  const check_all_points = (task: any) =>
  {
    const { correct_answer, possible_answer, possible_answer_fr_photos } = task
    const is_photos = possible_answer_fr_photos.length > 0
    if (!is_photos)
    {
      if (!correct_answer || !possible_answer) return false
      if (!possible_answer[0]?.first_row) return false
    } else
    {
      if (!possible_answer_fr_photos.length) return false
    }
    const splitted = correct_answer.split(",")
    if (!is_photos)
    {
      if (splitted.length < possible_answer[0].first_row.length) return false
    } else
    {
      if (splitted.length < possible_answer_fr_photos.length) return false
    }
    for (let i = 0; i < (is_photos ? possible_answer_fr_photos : possible_answer[0].first_row).length; i++)
    {
      if (splitted[i].split("-")[1] === "") return false
    }
    return true
  }

  const set_new_correct_answer = (data: any, task: any) =>
  {
    const task_copy = { ...task }
    for (let i = 0; i < data.length; i++)
    {
      const fd = data[i].config.data
      if (fd)
      {
        const keys = [ ...fd.keys() ]
        const values = [ ...fd.values() ]
        let args: any = {}
        keys.forEach((el: string, id: number) => { args[el] = values[id] })
        args.new_id = data[i].data.id
        if (args.is_correct)
        {
          task_copy.correct_answer = args.new_id.toString()
          return task_copy
        }
      }
    }
    return task
  }

  const update_task_photos = (task: any, callback: (task: any) => void) =>
  {
    const task_id = task.id
    // @ts-ignore
    const { new_photos, deleted_photos } = check_task_photos_changes(task_id, "get")
    const AddPromises = new_photos.map((el: LoadPhotoType) => load_task_photo_action(el))
    const DeletePromises = deleted_photos.map((el: number) => delete_task_photo_action(el))
    Promise.all([ ...AddPromises, ...DeletePromises ]).then((data: any) =>
    {
      
      setContext((prev: any) => ({ ...prev, notification_data: { title: "Фото змінено", type: "success" } }))
      callback(task.exercise_type === "ChooseAnswer" ? set_new_correct_answer(data, task) : task)
    })
  }

  const update_task = (task: any) =>
  {
    const mode = (taskExternal && !taskExternalBody) ? "db" : "normal"
    set_changed_ids([])
    let changed_task = correct_task(task)
    if (mode === "normal")
    {
      changed_task = { exercise_type: changed_task.exercise_type, position: changed_task.position, exercise: changed_task }
      delete changed_task.exercise.position
    }
    const type = is_lesson(lesson ? lesson.name : "") ? "lesson" : "test"
    dispatch<any>(patch_lesson_task_action(
      {
        lesson_id: lesson?.id ? lesson.id : undefined,
        exercise: { ...changed_task },
        exercise_id: mode === "normal" ? changed_task.exercise.id : changed_task.id, 
        search_data,
        type,
        mode
      },
      (is_ok, status) => {
        const warn_msg = status === 422 ? "не обрана правильна відповідь" : "Щось пішло не так"
        setContext((prev: any) => ({
          ...prev,
          notification_data: {
            title: is_ok ? "Вправу оновлено успішно" : warn_msg,
            type: is_ok ? "success" : "warning"
          }
      }))}
    ));
  }

  const pre_pre_update_task = (task: any) =>
  {
    if (check_task_photos_changes(task.id, "check"))
    {
      update_task_photos(task, (updated_task) => { update_task(updated_task) })
    } else { update_task(task) }
  }

  const pre_update_task = (task: any) =>
  {
    if (task.exercise_type === "MatchPair") {
      if (check_all_points(task)) { pre_pre_update_task(task) }
      else { setContext((prev: any) => ({
          ...prev,
          notification_data:
          {
            title: "жоден або всі пункти не мають правильної відповіді",
            type: "warning"
          }
        }))
      }
    } else { pre_pre_update_task(task) }
  }

  const update_all = () =>
  {
    for (let a = 0; a < changed_ids.length; a++) {
      for (let b = 0; b < tasksLocal.length; b++) {
        if (changed_ids[a] === tasksLocal[b].id) {
          pre_update_task(tasksLocal[b])
        }
      }
    }
    set_changed_ids([])
  }

  const delete_task = (id: number, position: number) =>
  {
    dispatch<any>(delete_task_action(lesson.id, id, position, is_lesson(lesson.name) ? "lesson" : "test"))
  }

  const delete_task_from_db = (id: number) =>
  {
    dispatch<any>(delete_task_from_db_action({lesson_id: lesson?.id ? lesson.id : undefined, exercise_id: id, search_data}))
  }

  const generate_possible_answer = (name: "first_row" | "second_row", possible_answer: any) =>
  {
    if (!possible_answer) return [ { [name] : ["test"] } ]
    if (!Array.isArray(possible_answer)) return [ { [name] : ["test"] } ]
    if (!possible_answer.length) return [ { [name] : ["test"] } ]
    if (!possible_answer[0][name]) return [ { ...possible_answer[0], [name]: ["test"] } ]
    return [ { ...possible_answer[0], [name]: [ ...possible_answer[0][name], "test" ] } ]
  }

  const add_point = (name: "first_row" | "second_row", task: any) =>
  {
    const item_name = name === "first_row" ? "possible_answer_fr_photos" : "possible_answer_sr_photos"
    setTasksLocal(tasksLocal.map((t: any) => t.id === task.id
    ? { ...t, possible_answer: generate_possible_answer(name, task.possible_answer), [item_name]: [] }
    : { ...t } ));
  }

  const add_photo_point = (name: "first_row" | "second_row", photos: File[], task_id: number) =>
  {
    const task = tasksLocal.find((t: any) => t.id === task_id)
    if (!task) return
    const task_copy = { ...task }
    task_copy.possible_answer = task_copy?.possible_answer ? [ { ...task_copy.possible_answer[0], [name]: [] } ] : []
    const item_name = name === "first_row" ? "possible_answer_fr_photos" : "possible_answer_sr_photos"
    const current_max = get_max_photos_id(task_copy[item_name])
    task_copy[item_name] = [ ...task_copy[item_name], ...[ ...[...photos].map((file: File, index: number) => ({ id: current_max + index + 1, url: file })) ] ]
    setTasksLocal(tasksLocal.map((t: any) => t.id === task.id
    ? { ...task_copy }
    : { ...t } ));
  }

  const select_point = (value: string | number, task_id: number) =>
  {
    setTasksLocal(tasksLocal.map((el: any) => ({ ...el, correct_answer: el.id === task_id ? value.toString() : el.correct_answer })));
    save_task_id(task_id)
  }

  const change_point_text = (new_text: string, id: number, name: string, task_id: number) =>
  {
    let temp_task: any = tasksLocal.filter((el: any) => el.id === task_id)[0]
    temp_task.possible_answer[0][name][id] = new_text
    setTasksLocal(tasksLocal.map((el: any) => el.id === task_id ? { ...el, possible_answer: [...temp_task.possible_answer] } : { ...el }));
    save_task_id(task_id)
  }

  const change_point_variant = (new_point: { num: number, answ: string }, task_id: number) =>
  {
    if (new_point.answ.trim().length > 1) return
    if (alphabetic_list_ua.indexOf(new_point.answ.trim().toUpperCase()) === -1 && new_point.answ !== "") return
    const task = tasksLocal.find((t: any) => t.id === task_id)
    if (!task) return
    const prepared_old_correct = prepare_match_variant_points(task.correct_answer)
    let result;
    const old_len = prepared_old_correct.length
    
    if (old_len === 0) result = `${new_point.num}-${new_point.answ}`
    if (old_len > 0)
    {
      const is_answ_exist = prepared_old_correct.find((el: any) => el.num == new_point.num)
      if (is_answ_exist)
      {
        result = prepared_old_correct
                  .map((el: any) => el.num == new_point.num ? ({ ...el, answ: new_point.answ }) : ({ ...el }))
                  .sort((a: any,b: any) => parseInt(a.num) - parseInt(b.num)) 
                  .map((el: any) => `${el.num}-${el.answ}`)
                  .join(",")
      }
      if (!is_answ_exist) result = [ ...prepared_old_correct, new_point ].sort((a: any,b: any) => parseInt(a.num) - parseInt(b.num)) .map((el: any) => `${el.num}-${el.answ}`).join(",")
    }
    
    change_task_info({ name: "correct_answer", value: result }, task_id)
  }

  const depete_point_variant = (num: number, correct_answer: any) =>
  {
    const prepared = prepare_match_variant_points(correct_answer)
    
    if (!prepared.length) return ""
    return prepared
            .filter((el: any) => el.num != num)
            .map((el: any) => `${num > el.num ? el.num : el.num - 1}-${el.answ}`)
            .join(",")
  }

  const delete_point = (id: number, name: string, task_id: number) =>
  {
    const temp_task: any = tasksLocal.filter((el: any) => el.id === task_id)[0]

    let new_possible_answers: any = temp_task?.possible_answer;
    let new_items = new_possible_answers[0][name].filter((el: any, el_id: number) => el_id !== id)

    new_possible_answers[0][name] = new_items

    new_items.map((a: string) => a.split("@@@").length > 1 ? a.split("@@@")[1] : a).forEach((item: string, id: number) =>
    {
      const el = document.querySelectorAll(`[data-id="${id + temp_task.id}"]`)
      if (el.length)
      {
        //@ts-ignore
        el.forEach((_: any, i: number) => el[i].value = is_json_string(item) ? JSON.parse(item) : item )
      }
    })

    const new_tasks_local = tasksLocal.map((el: any) =>
      el.id === task_id
      ? {
          ...el,
          possible_answer: new_possible_answers,
          correct_answer: el.exercise_type === "MatchPair"
            ? depete_point_variant(id + 1, el.correct_answer)
            : el.correct_answer
        }
      : { ...el })
    setTasksLocal(new_tasks_local);
    save_task_id(task_id)
  }

  const delete_photo_point = (name: "possible_answer_fr_photos" | "possible_answer_sr_photos", photo_id: number, task_id: number) =>
  {
    const temp_task: any = tasksLocal.find((el: any) => el.id === task_id)
    if (!temp_task) return
    temp_task[name] = temp_task[name].filter((el: { id: number, url: File | string }) => el.id !== photo_id)
    const new_tasks_local = tasksLocal.map( (el: any) => el.id === task_id ? { ...temp_task } : { ...el } )
    setTasksLocal(new_tasks_local);
  }

  const get_max_photos_id = (arr: { id: number, url: File | string }[]) => arr.length ? Math.max(...arr.map((el: { id: number, url: File | string }) => el.id)) : 1

  const add_task_photos = ({ name, files }: { name: string, files: File[] }, id: number) =>
  {
    const task_id = id ?? current_task_id
    if (!task_id) return
    let max_id = get_max_photos_id(tasksLocal.find((el: any) => el.id === task_id)[name]);
    setTasksLocal((prev: any) => prev.map((el: any) =>
    {
      return el.id === id ? { ...el, [name]: [ ...el[name], ...[ ...files].map((el: File, index: number) => ({ id: max_id + index, url: el })) ] } : { ...el }
    }));
    save_task_id(id)
  }

  

  const delete_task_photos = (name: string, photo_id: number, id: number) =>
  {
    const task_id = id ?? current_task_id
    if (!task_id) return
    setTasksLocal((prev: any) => prev.map((el: any) => el.id === id ? { ...el, [name]: el[name].filter((el: { id: number, url: File | string }) => el.id !== photo_id) } : { ...el } ));
    save_task_id(id)
  }

  const add_from_db = (task: any) =>
  {
    const copy_task = correct_task(task)
    const new_exercise = { ...copy_task, ...copy_task.exercise }
    delete new_exercise.exercise
    new_exercise.subject_id = subject.id
    new_exercise.postition = tasksLocal ? tasksLocal.length + 1 : 0
    dispatch<any>(post_lesson_task_to_db_action({
      exercise: new_exercise,
      search_data,
      callback: (searched_task_id: number) => dispatch<any>(search_tasks_action({subject_id: subject.id, exercise_id: searched_task_id, sort_by: "id" }))
    }))
    setContext({ ...context, add_db_task_modal: false })
  }

  // temprary !!!
  const add_from_search = (task_data: { id: number, position: number }) =>
  {
    const type = (lesson.name.toLowerCase().includes("урок") || lesson.name.toLowerCase().includes("день")) ? "lesson" : "test"
    if (!task_data.position) task_data.position = tasks ? tasks.length + 1 : 1
    dispatch<any>(post_task_from_search_action(lesson.id, task_data, type))
    dispatch<any>(clear_searched_task_action())
    setContext({...context, search_task_modal: false})
    const tm = setTimeout(() =>
    {
      const container = document.getElementsByClassName("AdminTasksInfo")[0]
      container.scrollIntoView(false)
      clearTimeout(tm)
    }, 500)
  }

  const task_click = (id: number) => { console.log("click", id); id && setContext((prev: any) => ({ ...prev, current_task_id: id })) }

  useEffect(() =>
  {
    const prepared_arr = JSON.stringify(tasks_to_return())
    setTasksLocal(JSON.parse(prepared_arr))
  }, [tasks, searched_tasks])

  useEffect(() =>
  {
    let init_balls = 0
    if (tasksLocal.length === 0) return setBallsLocal(0)
    tasksLocal.forEach((t: any) => init_balls += t.max_shore)
    setBallsLocal(init_balls)
  }, [tasksLocal])

  const is_pasted = (value: string) => value.split("\\quad").length === 1

  const prepare_pasted = (value: string) =>
  {
    const init = value
      .replace(/$/g, "")
      .replace(/%/g, "\\% ")
      .replace(/ /g, "\\quad ")
    if (init.split("\\quad\\quad").length > 1) return init.replace(/\\quad\\quad/gi, "\\quad ")
    return init
  }

  const mf_change = (event: any, tag: any) =>
  {
    const { dataset, value } = event.target
    const { name, task_id, point_id, type } = dataset
    type === "field_item" && change_task_info({name, value: JSON.stringify(is_pasted(value) ? prepare_pasted(value) : value)}, +task_id)
    type === "point_item" && change_point_text(JSON.stringify(is_pasted(value) ? prepare_pasted(value) : value), +point_id, name, +task_id)
    if (is_pasted(value)) tag.value = prepare_pasted(value)
  }

  useEffect(() =>
  {
    const tags = mf_tags()
    if (!tags.length) return
    for (let i = 0; i < tags.length; i++)
    {
      const tag = tags[i]
      tag.addEventListener("input", (event) => mf_change(event, tag))
    }
    for (let i = 0; i < tags.length; i++)
    {
      const tag = tags[i]
      return tag.removeEventListener("input", (event) => mf_change(event, tag))
    }
  }, [tasksLocal])

  useEffect(() => { check_task_changes(current_task_id) }, [tasksLocal, current_task_id])

  const tasks_order_by_id = (arr: any) => arr.sort((a: any, b: any) => a.id - b.id)

  const task_header_props =
  {
    update_all,
    canSave: changed_ids.length > 0,
    lesson,
    homework_theme: theme,
    lesson_balls: ballsLocal.toString() ?? "0",
    is_it_lesson
  }

  const task_body_props =
  {
    lock,
    taskExternal,
    taskExternalBody,
    taskSearched,
    update_changed_ids,
    add_from_db,
    add_from_search,
    change_task_info,
    change_task_ball,
    change_task_variant,
    change_point_variant,
    update_task: pre_update_task,
    delete_task,
    delete_task_from_db,
    add_point,
    select_point,
    change_point_text,
    delete_point,
    check_task_changes,
    task_click,
    add_task_photos,
    delete_task_photos,
    add_photo_point,
    delete_photo_point
  }

  return (
    <div className={`AdminTasksInfo ${lock && "AdminTasksInfoExternal"}`}>
      {!(lock || taskExternal) && <AdminTasksHeader {...task_header_props} />}
      { is_it_lesson &&
        <div className="CoursesDeleteButton CoursesDeleteButton_sticky" onClick={delete_lesson}>
          видалити
        </div>
      }
      {((taskExternal && !taskExternalBody) ? tasksLocal : tasks_order_by_id(tasksLocal)).map((task: any) => <AdminTaskBody {...{ ...task_body_props, task }} />)}
    </div>
  )
}