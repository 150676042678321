import { useContext } from "react";
import { Context } from "../../../context";
import { CuratorInvolveCourses } from "../../molecules/CuratorInvolveCourses";
import { CuratorProfileRegistration } from "../../molecules/CuratorProfileRegistration";

import "./styles.sass";

export const CuratorProfile = () => {
  const [context] = useContext(Context);
  const { user_id } = context;

  return (
    <div className="CuratorProfile">
      <div className="CuratorProfileMain">
        <CuratorProfileRegistration user_id={user_id} />
      </div>
      <div className="CuratorProfileAdditional">
        <CuratorInvolveCourses />
      </div>
    </div>
  );
};
