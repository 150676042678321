// import {
// } from "../../constants/admin";

const initialState = {
  curators: [],
  teachers: [],
  pending: false,
  error: "",
};

export const admin_users = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_CURATORS_SUCCESS":
      return {
        ...state,
        curators: action.payload
      };
    case "GET_TEACHERS_SUCCESS":
      return {
        ...state,
        teachers: action.payload
      };
    default: {
      return state;
    }
    }
}