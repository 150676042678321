import { FC, useContext, useEffect } from "react";
import { CuratorCourses } from "../../Components/templates/CuratorCourses";
import { CuratorProfile } from "../../Components/templates/CuratorProfile";
import { STUDENT_COURSES_MODE, STUDENT_PROFILE_MODE } from "../../constants/admin";
import { Context } from "../../context";
import { CuratorType } from "./types";
import "./styles.sass";

export const Curator: FC<CuratorType> = () => {
  const [context, setContext] = useContext(Context);
  const { menu_item_id } = context;

  useEffect(() => { setContext({
    ...context,
    current_mode: menu_item_id === 0 ? STUDENT_COURSES_MODE : STUDENT_PROFILE_MODE,
  })}, [menu_item_id]);

  useEffect(() =>
  {
    const menu_local = localStorage.getItem("menu_item")
    setContext({
      ...context,
      menu_item_id: menu_local ? JSON.parse(menu_local) : 0
    })
  }, [])

  return (
    <div className="Curator">
      {menu_item_id === 0 && <CuratorCourses />}
      {menu_item_id === 1 && <CuratorProfile />}
    </div>
  );
};
