import "./styles.sass";
import { FC } from "react";

export const LessonInput: FC<any> = ({
  placeholder,
  value,
  onChange,
  width,
  height,
  margin,
  name,
  disabled = false,
  title,
  textAlign,
  estimate = "pending" // "pending" | "success" | "error"
}) => {
  return (
    <div
      className="LessonInputContainer"
      style={{ margin: margin ?? "0 10px 0 0" }}
    >
      {title && <h1>{title}</h1>}
      <div
        className={`LessonInputBlock LessonInputBlock_${ estimate } `}
        style={{ width: width ?? 50, height: height ?? 40 }}
      >
        <input
          style={{ textAlign: textAlign ?? "center" }}
          className={`LessonInputItem LessonInputItem_${ estimate }`}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
