import "./styles.sass"
import { FC, useContext, useEffect } from "react"
import { PhotoareaType, TextareaType } from "./types"
import { TaskTextarea } from "../../atoms/TaskTextarea";
import { SimpleInput } from "../../atoms/SimpleInput";
import { Button } from "../../atoms/Button";
import { Select } from "../../atoms/Select";
import { TaskVariantType } from "../../organisms/AdminTasksInfo/types";
import { TaskMarkPoints } from "../../atoms/TaskMarkPoints";
import { TaskVariantPoints } from "../TaskVariantPoints";
import { Context } from "../../../context";
import { prepare_match_variant_points } from "../../../tools/common";
import { TaskPhotosArea } from "../../atoms/TaskPhotosArea";

export const AdminTaskBody: FC<any> = ({
  lock,
  taskExternal,
  taskExternalBody,
  taskSearched,
  task,
  update_changed_ids,
  add_from_db,
  add_from_search,
  change_task_info,
  change_task_ball,
  change_task_variant,
  change_point_variant,
  update_task,
  delete_task,
  delete_task_from_db,
  add_point,
  select_point,
  change_point_text,
  delete_point,
  check_task_changes,
  task_click,
  add_task_photos,
  delete_task_photos,
  add_photo_point,
  delete_photo_point,
}) => {
  const [context] = useContext(Context)
  const { search_task_modal } = context
  const task_type = task.exercise_type

  const taskVariants: TaskVariantType[] = [
    { id: 0, name: "коротка відповідь", name_en: "ShortAnswer" },
    { id: 1, name: "тест", name_en: "ChooseAnswer" },
    { id: 2, name: "відповідність", name_en: "MatchPair" },
    { id: 3, name: "розгорнута відповідь", name_en: "ManualSolution" },
  ];

  const change_task = (event: any) =>
  {
    const { name, value } = event?.target ? event.target : event
     change_task_info({ name, value }, task.id)
  };

  const add_photos = (event: any) =>
  {
    const { name, files } = event.target
    add_task_photos({ name: name + "_photos", files }, task.id)
  }

  const delete_photos = (name: string, photo_id: number) =>
  {
    delete_task_photos(name, photo_id, task.id)
  }

  const PhotosElem = (type: PhotoareaType) =>
  {
    switch (type)
    {
      case "TASK_PHOTOS":
      {
        if (!task.task_photos) return <></>
        if (!task.task_photos.length) return <></>
        return <TaskPhotosArea
          photos={ task.task_photos }
          name="task_photos"
          delete_photos={delete_photos}
        />
      }
      case "HINT_PHOTOS":
      {
        // if (task_type !== "ShortAnswer") return <></>
        if (!task.hint_photos) return <></>
        if (!task.hint_photos.length) return <></>
        return <TaskPhotosArea
          photos={ task.hint_photos }
          name="hint_photos"
          delete_photos={delete_photos}
        />
      }
      case "RIGHT_SOLUTION_PHOTOS":
      {
        if (!task.right_solution_photos) return <></>
        if (!task.right_solution_photos.length) return <></>
        return <TaskPhotosArea
          photos={ task.right_solution_photos }
          name="right_solution_photos"
          delete_photos={delete_photos}
        />
      }
      case "POSSIBLE_ANSWER_FR_PHOTOS":
      {
        if (!task.possible_answer_fr_photos) return <></>
        if (!task.possible_answer_fr_photos.length) return <></>
        return <TaskPhotosArea
          photos={ task.possible_answer_fr_photos }
          name="possible_answer_fr_photos"
          delete_photos={delete_photos}
        />
      }
      case "POSSIBLE_ANSWER_SR_PHOTOS":
      {
        if (!task.possible_answer_sr_photos) return <></>
        if (!task.possible_answer_sr_photos.length) return <></>
        return <TaskPhotosArea
          photos={ task.possible_answer_sr_photos }
          name="possible_answer_sr_photos"
          delete_photos={delete_photos}
        />
      }
    }
  }

  const TextareaElem = (type: TextareaType) => {
    switch (type) {
      case "TASK": {
        return (
          <TaskTextarea
            task_id={ task.id }
            name_ua="Завдання"
            name="task"
            placeholder="Знайти НСК (28; 16; 10)"
            value={task.task}
            width={ search_task_modal ? 400 : 470 }
            small={lock}
            onChange={change_task}
            disabled={lock}
            mode="math"
            photo_action={add_photos}
          />
        );
      }
      case "HINT": {
        return (
          <TaskTextarea
            task_id={ task.id }
            name_ua="Підказка"
            name="hint"
            placeholder="НСК – найменше число, яке ділиться без остачі на кожне з даних чисел."
            value={task.hint}
            small={lock}
            onChange={change_task}
            disabled={lock}
            photo_action={ task_type === "ShortAnswer" && add_photos}
          />
        );
      }
      case "SOLUTION": {
        return (
          <TaskTextarea
            task_id={ task.id }
            name_ua="Рішення"
            name="right_solution"
            placeholder="1) 4,6-2,1=2,5 2) 2,5*3,2=8 3) -3,5+8=4,5"
            value={task.right_solution}
            width={ search_task_modal ? 400 : 470 }
            small={lock}
            onChange={change_task}
            disabled={lock}
            mode="math"
            photo_action={add_photos}
          />
        );
      }
      case "ANSWER": {
        return (
          <TaskTextarea
            task_id={ task.id }
            name_ua="Відповідь"
            name="correct_answer"
            placeholder="560"
            value={task.correct_answer}
            small={lock}
            onChange={change_task}
            disabled={lock}
          />
        );
      }
      case "HASHTAGS": {
        return (
          <TaskTextarea
            task_id={ task.id }
            name_ua="Хештеги"
            name="hashtag"
            placeholder="#тригонометрія #шось там #шосьтут"
            value={task.hashtag}
            small={lock}
            onChange={change_task}
            disabled={lock}
          />
        );
      }
    }
  };

  const TaskNum = <div className="LessonsTaskItemHeader">
    <div className="TaskInformationName">Завдання</div>
    <SimpleInput
      value={task.position}
      name={"position"}
      type="number"
      onChange={change_task}
      width={80}
      height={50}
      margin="0 30px 0 0"
    />
  </div>

  const TaskLine = <div className="LessonsTaskItemLineContainer"><div className="LessonsTaskItemLine" /></div>

  const TaskActions =
    <div className="LessonsTaskItemActions">
      <div className="TaskInformationTextBlock">
        {!lock && <div className="TaskInformationName">ID</div>}
        {!lock && <SimpleInput
          name="id"
          value={ task.id === -1 ? "" : task.id }
          width={150}
          height={50}
          type="number"
          margin="0"
          disabled
          onChange={change_task}
        />}
        <div className={`TaskInformationText ml10 ${lock && "TaskInformationTextLess"}`}>Тип</div>
        <Select
          setValue={(el: any) => change_task_variant(el, task.id)}
          value={
            taskVariants.filter(
              (el: any) => el.name_en === task.exercise_type
            )[0]
          }
          options={taskVariants}
          disabled={lock}
        />
      </div>
      <div className="TaskInformationTextBlock">
        <div className="TaskInformationText">Бали</div>
        <TaskMarkPoints
          taskMark={task.max_shore}
          setTaskMark={(ball: number) => change_task_ball(ball, task.id)}
          disabled={lock}
        />
      </div>
    </div>

  const possible_answers_splitted = (answers: string[]) => answers.map((a: string) => a.split("@@@").length > 1 ? a.split("@@@")[1] : a)

  const point_items_data = (type: "first_row" | "second_row") =>
  {
    if (!task.possible_answer) return []
    if (!Array.isArray(task.possible_answer)) return []
    if (!task.possible_answer.length) return []
    if (!task.possible_answer[0][type]) return []
    return possible_answers_splitted(task.possible_answer[0][type])
  }

  const TaskBodyItems =
    <div className={`LessonsTaskItemMainBody ${taskSearched ? "LessonsTaskItemMainBodyLimited" : ""}`}>
      <div className="TaskItemMainBodyArea">
        {task_type === "ShortAnswer" && (
          <>
            {TextareaElem("TASK")}
            {PhotosElem("TASK_PHOTOS")}
            {TextareaElem("HINT")}
            {PhotosElem("HINT_PHOTOS")}
          </>
        )}
        {task_type === "ChooseAnswer" && (
          <>
            {TextareaElem("TASK")}
            {PhotosElem("TASK_PHOTOS")}
            {TextareaElem("HINT")}
            {PhotosElem("HINT_PHOTOS")}
            {TextareaElem("SOLUTION")}
            {PhotosElem("RIGHT_SOLUTION_PHOTOS")}
            {/* {TextareaElem("ANSWER")} */}
          </>
        )}
        {task_type === "MatchPair" && (
          <>
            {TextareaElem("TASK")}
            {PhotosElem("TASK_PHOTOS")}
            {TextareaElem("HINT")}
            {PhotosElem("HINT_PHOTOS")}
            {TextareaElem("SOLUTION")}
            {PhotosElem("RIGHT_SOLUTION_PHOTOS")}
            {/* {TextareaElem("ANSWER")} */}
          </>
        )}
        {task_type === "ManualSolution" && (
          <>
            {TextareaElem("TASK")}
            {PhotosElem("TASK_PHOTOS")}
            {TextareaElem("HINT")}
            {PhotosElem("HINT_PHOTOS")}
          </>
        )}
        {TextareaElem("HASHTAGS")}
      </div>
      <div className="TaskItemMainBodyArea">
        {task_type === "ShortAnswer" &&
          <>
            {TextareaElem("SOLUTION")}
            {PhotosElem("RIGHT_SOLUTION_PHOTOS")}
            {TextareaElem("ANSWER")}
          </>
        }
        {task_type === "ChooseAnswer" && (
          <TaskVariantPoints
            point_items_data={point_items_data("first_row")}
            photo_items_data={ task.possible_answer_fr_photos }
            add_point={() => add_point("first_row", task)}
            select_point={select_point}
            change_point_text={change_point_text}
            delete_point={delete_point}
            delete_photo_point={delete_photo_point}
            name={"first_row"}
            mode={"math"}
            task_id={task.id}
            correct_answer={task.correct_answer}
            add_photo_point={ add_photo_point }
          />
        )}
        {task_type === "MatchPair" && (
          <>
            <TaskVariantPoints
              point_items_data={point_items_data("first_row")}
              photo_items_data={ task.possible_answer_fr_photos }
              add_point={() => add_point("first_row", task)}
              change_point_text={change_point_text}
              delete_point={delete_point}
              delete_photo_point={delete_photo_point}
              name={"first_row"}
              mode={"math"}
              task_id={task.id}
              variant="plural"
              variant_items={prepare_match_variant_points(task.correct_answer)}
              change_point_variant={change_point_variant}
              add_photo_point={ add_photo_point }
              />
            <TaskVariantPoints
              point_items_data={point_items_data("second_row")}
              photo_items_data={ task.possible_answer_sr_photos }
              add_point={() => add_point("second_row", task)}
              change_point_text={change_point_text}
              delete_point={delete_point}
              delete_photo_point={delete_photo_point}
              name={"second_row"}
              mode={"math"}
              task_id={task.id}
              add_photo_point={ add_photo_point }
            />
          </>
        )}
        {task_type === "ManualSolution" && (
          <>
            {TextareaElem("SOLUTION")}
            {/* {TextareaElem("ANSWER")} */}
            {/* {TextareaElem("LINK")} */}
          </>
        )}
      </div>
    </div>

  const button_title = () =>
  {
    if (lock) return "Завантажити"
    if (taskExternalBody) return "Додати"
    return "зберегти"
  }

  const button_action = () =>
  {
    if (lock) return add_from_search({ exercise_id: task.id, position: task.position })
    if (taskExternalBody) return add_from_db(task)
    return update_task(task)
  }

  const TaskGeneralActions = <div className="LessontaskMainActions">
    <Button
      title={button_title()}
      mode={check_task_changes(task.id) ? "btn_black" : "btn_gray"}
      disabled={!check_task_changes(task.id)}
      uppercase
      width={18}
      onClick={() => button_action()}
    />
    {!(lock || taskExternalBody) && <div
      className="TaskDeleteButton"
      onClick={() => taskExternal ? delete_task_from_db(task.id) : delete_task(task.id, task.position)}
    >
      видалити
    </div>}
  </div>

  useEffect(() => { update_changed_ids(task.id, check_task_changes(task.id) ? "add" : "delete") }, [task])

  return (
    <div className="AdminTaskBody" key={task.id} >
      {!(lock || taskExternal) && TaskLine}
      {!(lock || taskExternal) && TaskNum}
      {TaskActions}
      {TaskBodyItems}
      {TaskGeneralActions}
    </div>
  )
}