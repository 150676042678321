import "./styles.sass";
import { FC, useRef, useState } from "react";
import { DigitalSelectTypes } from "./types";
import { arrowIcon } from "../../../assets/icons/header";
import { ClickAwayListener } from "../../../tools/ClickAway";
import { getMaxItemLength } from "../../../tools/max_length";

export const DigitalSelect: FC<DigitalSelectTypes> = ({
  value,
  options = [value],
  margin = 0,
  width,
  size,
  borderRadius = 0,
  setValue,
  disabled = false
}) => {
  const [active, setActive] = useState(false);
  const selectRef = useRef(null);

  const current: any = selectRef?.current !== null ? selectRef.current : {};
  const maxLength = getMaxItemLength(options);

  const close = () => { setActive(false) };

  const select = (element: any) =>
  {
    setValue && setValue(element);
    close();
  };

  const sizeStyle: string = size === "small" ? "DigitalSelect DigitalSelectSmall" : "DigitalSelect"
  const title = value?.name ?? value

  return (
    <ClickAwayListener onClickAway={ close }>
      <div
        ref={ selectRef }
        className={ active ? `${sizeStyle} DigitalSelectActive` : sizeStyle }
        onClick={ () => !disabled && setActive(!active) }
        style={{
          height: size === "small" ? 25 : size === "middle" ? 35 : size === "big" ? 50 : 35,
          margin,
          borderRadius
        }}
      >
        <div className="DigitalSelectMain">{ title.toUpperCase() }</div>
        <div className={ active ? "DigitalSelectIconReverse" : "DigitalSelectIcon" }>
          { arrowIcon }
        </div>
        {active && options.length > 0 && (
          <div
            className="DigitalSelectOptions"
            style={{
              top: current["offsetTop"] + current["offsetHeight"] - 2,
              left: current["offsetLeft"],
              borderRadius
            }}
          >
            {options.map((el: any, id: number) =>
              <div
                key={ el?.id ? el.id : id }
                className="DigitalSelectOption"
                onClick={ () => select(el) }
              >
                { el?.name ? el.name.toUpperCase() : `no name ${el.id}` }
              </div>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
