// import {
//   GET_LESSONS_PENDING,
//   GET_LESSONS_SUCCESS,
//   GET_LESSONS_FAIL,
// } from "./../../constants/admin";
import axios from 'axios';
import { prepareDate } from '../../../tools/admin/date';
import { GET_STUDENTS_FAIL, GET_STUDENTS_SUCCESS } from '../../constants/admin';
import { requestConfig, serv } from "../../constants/config";
import { get_students_action } from './students';

export const get_curators_action = (subject_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/curators?subject_id=${subject_id}`;
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: "GET_CURATORS_SUCCESS", payload: res.data }))
};

export const create_student_action = (student_data: {email: string, password: string}, callback: (id: number) => void) => (dispatch: any) =>
{
  const url = `${serv}/users`;
  const body = { user: student_data }
  axios
    .post(url, body, requestConfig())
    .then((res: any) =>
      {
        dispatch(get_students_action())
        callback(res.data.id)
      })
};

export const get_teachers_action = (subject_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/teachers?subject_id=${subject_id}`;
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: "GET_TEACHERS_SUCCESS", payload: res.data }))
};

type LinkUserVariant = "user" | "teacher" | "curator"

export const send_link_action = (email: string, type: LinkUserVariant, subject_id?: number, callback?: (msg: string, user?: any) => void) => (dispatch: any) =>
{
  const url = `${serv}/${type}s`;
  const body = { [type]: { email, password: "password", subject_id} }
  type === "user" && delete body[type].subject_id
  axios
    .post(url, body, requestConfig())
    .then((res: any) =>
    {
      callback && callback("Запрошення надіслано", res.data)
    })
};

export const resend_user_link_action = (email: string, callback: (is_ok: boolean) => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/users/send_confirmation_email?email=${email}`;
  axios
    .get(url, requestConfig())
    .then(() => callback && callback(true))
    .catch(() => callback && callback(false))
}

interface UserQueriesType
{
  reset_password_token: string
  user_type: string
  email: string
}

interface UserDataType
{
  name: string
  surname: string
  password: string
  phone: string
}

export const update_user_data_action = (query: UserQueriesType, user_data: UserDataType) => (dispatch: any) =>
{
  const { reset_password_token, user_type } = query
  const updated_user_data = { ...user_data }
  if (updated_user_data.phone) updated_user_data.phone = updated_user_data.phone.replace(" ", "")
  const url = `${serv}/members/finish_registration?reset_password_token=${reset_password_token}&user_type=${user_type}`;
  const body = { member: updated_user_data}
  axios
    .patch(url, body, requestConfig())
    .then((res: any) => console.log(`UPDATE -- ${user_type} -- USER response`, res.data))
};



export const delete_user_action = (user_id: number, type: LinkUserVariant, callback?: (status: number) => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/${type}s/${user_id}`
  axios
    .delete(url, requestConfig())
    .then((res: any) =>
    {
      if (type === "user") dispatch(get_students_action())
      if (callback) callback(res.status)
    })
    .catch((err) =>
    {
      if (callback) callback(err.response.status)
    })
}

type SignInUserVariant = "user" | "admin" | "teacher" | "curator"
interface SignInDataType
{
  email: string
  password: string
}
export const sign_in_action = (data: SignInDataType, user_type: SignInUserVariant, callback: (status: number) => void) => (dispatch: any) =>
{
  const url = `${serv}/${user_type}s/sign_in`
  const body = {[user_type]: data}
  axios
    .post(url, body, {headers: { "Content-Type":  "application/json" }})
    .then((res: any) => {
      callback(res.status)
      return res.headers.get("Authorization")
    })
    .then((token: string) => localStorage.setItem("token", token))
    .catch((err: any) => {
      callback(err.response?.status ?? 401)
    })
}

const prepare_query_to_search = (data : any) =>
{
    const result: string[] = [];
    //@ts-ignore
    for (let d in data) data[d] && result.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return result.join('&');
}

export const search_student_action = (query: any) => (dispatch: any) =>
{
  const reg = (repl: string) => new RegExp(repl, 'g')
  //@ts-ignore
  const url = `${serv}/adminpanel/users/search?${prepare_query_to_search({ ...query, created_at: query?.created_at ? prepareDate(query.created_at, false, "search").replace(reg("/"), "-") : undefined })}`
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_STUDENTS_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_STUDENTS_FAIL, payload: err }));
}

export const password_recovery_request_action = (email: string, callback: (is_ok: boolean) => void) =>
{
  const url = `${serv}/users/password`
  const body = { user: { email } }
  axios
    .post(url, body, requestConfig())
    .then(() => callback(true))
    .catch(() => callback(false))
}

export const password_recovery_confirm = (password: string, token: string, user_type: string, callback: (is_ok: boolean) => void) =>
{
  const url = `${serv}/members/password_recovery?reset_password_token=${token}&user_type=${user_type}`
  const body =  { member: { "password": password, "password_confirmation": password } }
  axios
    .patch(url, body, requestConfig())
    .then(() => callback(true))
    .catch(() => callback(false))
}
