import { FC } from "react"
import "./styles.sass"
import { UserPhotosAreaType, UserPhotoType } from "./types"

export const UserPhotosArea: FC<UserPhotosAreaType> = ({ photos, nomargin, noborder }) =>
  <div
    className="UserPhotosAreaContainer"
    style={{ margin: nomargin ? "" : "0 0 30px 0" }}
  >
    <div className="UserPhotosArea">
      {photos.map(( el: UserPhotoType, index: number ) =>
        <div
          className={`UserPhotosItemContainer ${ noborder ? "UserPhotosItemContainer_noborder" : ""  }`}
          style={{ margin: nomargin ? "" : "0 10px 10px 0" }}
        >
          <img className="UserPhotosItem" key={ index } src={ el.url } />
        </div>
      )}
    </div>
  </div>