import "./styles.sass";
import { FC, useContext } from "react";
import { BeeTypes } from "./types";
import bzz_1 from "../../../assets/images/logos/bzz_1.png"
import bzz_2 from "../../../assets/images/logos/bzz_2.png"
import bzz_3 from "../../../assets/images/logos/bzz_3.png"
import bzz_4 from "../../../assets/images/logos/bzz_4.png"
import bzz_5 from "../../../assets/images/logos/bzz_5.png"
import bzz_6 from "../../../assets/images/logos/bzz_6.png"
import { Context } from "../../../context";

export const ProfileBee: FC<BeeTypes> = () =>
{
  const [context, setContext] = useContext(Context)
  const circlesStaff = [
    { hashtag: "#всезможу", img: bzz_1 },
    { hashtag: "#душніла", img: bzz_2 },
    { hashtag: "#кайфово", img: bzz_3 },
    { hashtag: "#спатихочу", img: bzz_4 },
    { hashtag: "#ботаю", img: bzz_5 },
    { hashtag: "#упахалась", img: bzz_6 },
  ];

  const chose_logo = (name: string) =>
  {
    setContext({ ...context, user_logo: name })
    localStorage.setItem("user_logo", name)
  }

  return (
    <div className="ProfileBeeContainer">
      {circlesStaff.map((bee) => {
        return (
          <div className="ProfileBee" onClick={() => chose_logo(bee.hashtag)}>
            <img src={bee.img} />
            <div className="ProfileBeeHashtag">{bee.hashtag}</div>
          </div>
        );
      })}
    </div>
  );
};
