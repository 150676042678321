import "./styles.sass";
import { FC, useContext, useEffect, useState, useRef } from "react";
import { StudentHeaderType } from "./types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { get_student_subjects_action } from "../../../redux/actions/student/subjects";
import { Context } from "../../../context";
import { STUDENT_TASK_MODE } from "../../../constants/admin";
import { prepareDate } from "../../../tools/admin/date";
import { burger_icon } from "../../../assets/icons/header";
import { clear_student_lessons_action } from "../../../redux/actions/student/lessons";

interface SubjectType {
  id: number;
  name: string;
  description: string | undefined;
  bought: boolean;
}

export const StudentHeader: FC<StudentHeaderType> = ({logo_click}) => {
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { subject, current_mode, user_type, is_lesson_changed, mobile_menu } = context

  const user_id = useSelector((st: any) => st.user_info.id)
  const { subjects } = useSelector((st: any) => st.student_subjects)
  const { subject_name, next_lesson_start } = useSelector((st: any) => st.teacher_profile)

  const is_user: boolean = user_type === "user"
  const is_curator: boolean = user_type === "curator"
  const is_teacher: boolean = user_type === "teacher"

  const find_first_bought = () => subjects.find((s: any) => s.bought)

  useEffect(() => { is_user && subjects.length > 0 && find_first_bought() && setContext({ ...context, subject: find_first_bought() }) }, [subjects])
  useEffect(() =>
  {
    is_user && dispatch<any>(get_student_subjects_action(user_id))
  }, [])
  const item_styles = (subj: SubjectType) =>
  {
    if (subj.name === "TEST" && !subj.bought) return "dnone"
    if (!subj.bought) return "StudentHeaderNotBoughtItem"
    if (subj.id !== (subject ? subject.id : subjects[0].id) ) return "bb cw"
    return ""
  }

  const select_subject = (subj: SubjectType) =>
  {
    if (!subj.bought) return
    if (current_mode === STUDENT_TASK_MODE && is_lesson_changed) return setContext({ ...context, lesson_pre_save_modal: true, stopped_action: { name: "SUBJECT", id: subj.id } })
    dispatch<any>(clear_student_lessons_action())
    setContext({ ...context, subject: subj, lesson: null, module: null })
    logo_click && logo_click()
  }

  const lesson_date: any = next_lesson_start ? prepareDate(new Date(next_lesson_start), true, "split") : { date: "дату уроку не задано", time: "" }
  const [selectedSubjectId, setSelectedSubjectId] = useState<number | null>(null);
  useEffect(() => {
    if (subjects.length > 0 && !selectedSubjectId) {
      setSelectedSubjectId(subjects[0].id); // Select the first subject if no subject is already selected
    }
  }, [subjects, selectedSubjectId]);
  useEffect(() => {
    function handleClickOutside(event:any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    // Attach the event listener to the document body
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <>
    <div className="StudentHeader StudentHeader_PC">
      <div className="StudentHeaderTopBlock">
        <div className="StudentHeaderLeftItem">
          <div className="StudentHeaderLeftItemBlack" />
        </div>
        {is_user && <div className="StudentHeaderNavigationContainer">
          {subjects.length > 0 && subjects.map((subj: SubjectType) =>
              <div
                key={subj.id}
                onClick={() => select_subject(subj) }
                className={`StudentHeaderNavigationItem ${item_styles(subj)}`}
              >
                {subj.name}
              </div>
          )}
        </div>}
        {is_teacher && <div className="TeacherHeaderContainer">
          <div className="TeacherHeaderSubject">{subject_name ?? "предмет не знайдено"}</div>
          <div className="TeacherHeaderDate">{lesson_date.date}</div>
          {next_lesson_start && <>
            <div className="TeacherHeaderDate">о</div>
            <div className="TeacherHeaderDate">{lesson_date.time}</div>
          </>}
        </div>}
        <div className="StudentHeaderRightItem">
          <div className="StudentHeaderRightItemBlack" />
        </div>
      </div>
      <div className="StudentHeaderBottomBlock">
        <div className="StudentHeaderBottomBlockBlack" />
      </div>
    </div>
      {is_user && (
          <div className="StudentSubjectMobile">
            <div className={`StudentSelectSubjectSelect ${isDropdownOpen ? 'open' : ''}`}
                 onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                 ref={dropdownRef}
            >
              <div className="StudentSelectSubjectOption">
                {selectedSubjectId &&
                    subjects.find((subj: SubjectType) => subj.id === selectedSubjectId)?.name}
              </div>
              {isDropdownOpen && (
                  <div className="options-list">
                    {subjects.length > 0 &&
                        subjects.map((subj: SubjectType) => (
                            <div
                                key={subj.id}
                                className={`StudentHeaderNavigationItemMobile ${item_styles(subj)}`}
                                onClick={() => {
                                  setSelectedSubjectId(subj.id);
                                  setIsDropdownOpen(false);
                                  const selectedSubject = subjects.find((subject: SubjectType) => subject.id === subj.id);
                                  select_subject(selectedSubject);
                                }}
                            >
                              {subj.name}
                            </div>
                        ))}
                  </div>
              )}
            </div>
          </div>
      )}
    <div className={`StudentHeader StudentHeader_MOBILE ${ mobile_menu ? "MENU_OPEN" : "MENU_CLOSE"}`}>
      <div className="StudentHeaderTopBlock">
      <div className="StudentHeaderTopBlockIcon" onClick={() => {setContext({ ...context, mobile_menu: !context.mobile_menu})}}>{ burger_icon }</div>

        {is_user && <div className="StudentHeaderNavigationContainer">
          {subjects.length > 0 && subjects.map((subj: SubjectType) =>
              <div
                key={subj.id}
                onClick={() => select_subject(subj) }
                className={`StudentHeaderNavigationItem ${item_styles(subj)}`}
              >
                {subj.name}
              </div>
          )}
        </div>}
      </div>
    </div>
    </>

  );
};
