import "./styles.sass";
import { FC, useContext, useEffect } from "react";
import { Context } from "../../../context";
import { STUDENT_SEARCH_MODE, TEACHER_LESSON_MODE, TEACHER_MODULE_MODE, TEACHER_TASK_MODE, TEACHER_TEST_MODE } from "../../../constants/admin";
import { TeacherCoursesType } from "./types";
import { StudentSearch } from "../../molecules/StudentSearch";
import { TeacherModuleInfo } from "../../organisms/TeacherModuleInfo";
import { StudentAdditionalMenu } from "../../molecules/StudentAdditionalMenu";
import { useDispatch } from "react-redux";
import { get_teacher_courses_action } from "../../../redux/actions/teacher";
import { TeacherLessonInfo } from "../../organisms/TeacherLessonInfo";
import { TeacherLesson } from "../../organisms/TeacherLesson";
import { useSelector } from "react-redux";
import { StudentSearchScreen } from "../../molecules/StudentSearchScreen";


export const TeacherCourses: FC<TeacherCoursesType> = ( ) =>
{
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const { current_mode, module, lesson, homework_id, search_materials } = context
  const { courses, pending } = useSelector((st: any) => st.teacher_courses)

  useEffect(() => { dispatch<any>(get_teacher_courses_action()) }, [])

  const scroll_to_module = (name: string) =>
  {
    const menu_element = document.getElementsByClassName("StAddMenu")[0]
    // if (menu_element.scrollTop === 0) return

    const elements = document.getElementsByClassName("StAddMenuFirstItem")
    const need_element = Array.from(elements).find(el => el.innerHTML === name)
    if (!need_element) return

    const need_element_id = Array.from(elements).indexOf(need_element)
    const menu_y = menu_element.getBoundingClientRect().top
    const element_y = elements[need_element_id].getBoundingClientRect().top
    const tm = setTimeout(() =>
    {
      menu_element.scrollTo(0, element_y - menu_y)
      clearTimeout(tm)
    }, 100)
  }

  const prepare_module_name = (name: string) => name.includes("МГ_") ? name.replace("МГ_", "МАЙСТЕР ГРУПА ") : name

  useEffect(() =>
  {
    if (!courses.length || pending) return
    const storage_mg = localStorage.getItem("mg")
    if (!storage_mg && !module) return
    if (!storage_mg && module) return localStorage.setItem("mg", JSON.stringify(module))
     
    if (storage_mg)
    {
    const parsed_storage_mg = JSON.parse(storage_mg)
    const storage_mg_in_courses = courses.find((course: any) => course.id === parsed_storage_mg.id)
    if (!storage_mg_in_courses) return localStorage.removeItem("mg")
    
    if (module) return localStorage.setItem("mg", JSON.stringify(module))
    localStorage.setItem("mg", JSON.stringify(storage_mg_in_courses))
    setContext({ ...context, module: storage_mg_in_courses })
    scroll_to_module(prepare_module_name(storage_mg_in_courses.name))
    }
  }, [courses, pending, module])

  // for search
  const select_search_material = (item: any) =>
  {
    const find_module = courses.find((el: any) => el.id === item.mg_id)
    const find_lesson = find_module.lessons.find((el: any) => el.id === item.id)
    setContext((prev: any) => ({
      ...prev,
      current_mode: TEACHER_TASK_MODE,
      module: find_module,
      lesson: find_lesson,
      homework_id: find_lesson.homework_id
    }))
  }

  const student_s_screen_props = { search_materials, select_search_material }


  return (
    <>
      <StudentSearch is_teacher />
      <div className="StudentContent">
        <StudentAdditionalMenu />
        { current_mode === TEACHER_MODULE_MODE && module && <TeacherModuleInfo /> }
        { current_mode === TEACHER_LESSON_MODE && lesson && <TeacherLessonInfo />}
        { ((current_mode === TEACHER_TASK_MODE && homework_id) || current_mode === TEACHER_TEST_MODE) && <TeacherLesson />}
        { current_mode === STUDENT_SEARCH_MODE &&  <StudentSearchScreen { ...student_s_screen_props } /> }
      </div>
    </>
  );
};
