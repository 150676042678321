import axios from 'axios';
import {
  GET_TEACHER_COURSES_PENDING,
  GET_TEACHER_COURSES_SUCCESS,
  GET_TEACHER_COURSES_FAIL,
} from "./../../constants/teacher";
import { requestConfig, serv } from "../../constants/config";

export const get_teacher_courses_action = () => (dispatch: any) =>
{
  const url = `${serv}/teacherpanel/master_groups`;
  dispatch({ type: GET_TEACHER_COURSES_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_TEACHER_COURSES_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_TEACHER_COURSES_FAIL, payload: err }));
};
