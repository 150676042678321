import "./styles.sass";
import {
  download_icon,
  paperclip_icon,
} from "../../../assets/icons/studentModuleInfo";
import { Button } from "../../atoms/Button";
import { prepareDate } from "../../../tools/admin/date";
import { FC, useContext } from "react";
import { TeacherLessonItem } from "../../molecules/TeacherLessonItem";
import { convert_url_to_embed, convert_url_to_embed_custom } from "../../../tools/youtube";
import { link_check } from "../../../tools/common";
import { Context } from "../../../context";

export const StudentLessonInfo: FC<any> = ({ lesson, set_task_mode, estimation_data }) => {
  const [context, setContext] = useContext(Context)
  const lesson_date: any = prepareDate(new Date(lesson?.lesson_start ? lesson.lesson_start : new Date()), true, "split")
  const status = estimation_data.status

  const action_title = () =>
  {
    if (status === "checked") return `ЗА ДЗ тобі нараховано ${estimation_data.current} зі ${estimation_data.max}`
    if (status === "completed") return "ДЗ відправлено на перевірку"
    return "Нужбо виконувати дЗ?"
  }

  const action_btn_title = (status === "completed" || status === "checked") ? "переглянути" : "вперед"

  const click_bad_link = () =>
  {
    setContext({ ...context, notification_data: { title: "Посилання недоступне", type: "warning" } })
  }

  const prepare_link = (text: string, url: string | null) =>
  {
    let is_ok = link_check(url)
    //@ts-ignore
    return <a onClick={ is_ok ? () => {} : click_bad_link } href={ is_ok ? url : "#" } target={ is_ok ? "_blank" : "_self" }>{ text }</a>
  }

  return (
    <div className="StudentLessonInfo">
      <div className="StudentLessonInfoMain">
        <div className="StudentLessonInfoMainTop">
          <div className="StudentLessonInfoMainTopGeneralTitle">
          <h1>{ lesson.theme !== null ? lesson.theme : "тему не задано" }</h1>
          <p>{ lesson.description !== null && lesson.description }</p>
          </div>
          <div className="StudentLessonInfoMainHashtags">
            { lesson.hashtags !== null && lesson.hashtags }
          </div>
        </div>
        <div className="StudentLessonInfoMainMiddle">
          <h2 className="StudentLessonInfoMainTime">
            <span className="StudentLessonInfoMainTimeBegin">Початок</span>
            <span className="StudentLessonInfoMainTimeTime">
              { lesson_date.date }
            </span>
            <span className="StudentLessonInfoMainTimeBetween">о</span>
            <span className="StudentLessonInfoMainTimeTime">
              { lesson_date.time }
            </span>
          </h2>
          <div className="StudentLessonInfoMainLinks">
            <div className="MainLinksDownload">
              <div className="MainLinksDownloadIcon">{download_icon}</div>
              <div className="MainLinksDownloadLink">
                { prepare_link("посилання на online трансляцію", lesson.lesson_url) }
              </div>
            </div>
            { lesson?.extra_materials && lesson.extra_materials.length > 0 &&
              lesson.extra_materials.map((m: any) =>
              <div className="MainLinksTranslation">
                <div className="MainLinksTranslationIcon">{paperclip_icon}</div>
                <div className="MainLinksTranslationLink">
                  { prepare_link(m.title, m.url) }
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="StudentLessonInfoMainBottom">
          <h1 className="MainLetsDoIt">{ action_title() }</h1>
          <Button
            title={ action_btn_title }
            uppercase
            onClick={set_task_mode}
            mode={"btn_black"}
            size="btn_normal"
            rounded
          />
        </div>
        {estimation_data.comment.trim().length > 0 && status === "checked" &&
          <TeacherLessonItem
            text={estimation_data.comment}
            height={100}
            width={388}
        />}
      </div>
      {lesson.lesson_url && <div className="StudentLessonInfoPlayer">
        <iframe
          className="ModuleInfoPlayer"
          width="505"
          height="308"
          src={ convert_url_to_embed_custom(lesson.lesson_url) }
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>}
      {lesson.lesson_url && <div className="StudentLessonInfoPlayerMobile">
        <iframe
            className="ModuleInfoPlayer"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            src={ convert_url_to_embed_custom(lesson.lesson_url) }
            frameBorder="0"
        ></iframe>
      </div>}
    </div>
  );
};
