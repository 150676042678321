import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { get_student_modules_action } from "../../../redux/actions/student";
import "./styles.sass";

export const AvailableCourses = () => {
  const user_id = useSelector((st: any) => st.user_info.id)
  const { subjects } = useSelector((st: any) => st.student_subjects)

  const [ student_packages, set_student_packages] = useState<any[]>([])

  const dispatch = useDispatch()

  const prepare_packages_info = () =>
  {
    const avil_subjects = subjects.length ? subjects.filter((s: any) => s.bought) : []
    if (!avil_subjects.length) return []
    const pkgs: any[] = []
    for (let i = 0; i < avil_subjects.length; i++)
    {
      const pkg: { [key: string]: string } = { subject: avil_subjects[i].name, module: "" }
      dispatch<any>(get_student_modules_action(user_id, avil_subjects[i].id, (m: any) =>
      {
        m.forEach((el: any) => pkgs.push({ ...pkg, module: el.name }))
      }))
    }
    set_student_packages(pkgs)
  }


  useEffect(() => { prepare_packages_info() }, [subjects])
  return (
    <div className="AvailableCourses">
      <h1 className="AvailableCoursesTitle">Доступні курси</h1>
      {student_packages.map((cours, id) => {
        return (
          <div className="AvailableCoursesItem" key={id}>
            <h1>{cours.subject} |</h1>
            <h1>{cours.module}</h1>
          </div>
        );
      })}
    </div>
  );
};
