import "./styles.sass";
import React, { FC } from "react";
import { TextTypes } from "./types";

export const Text: FC<TextTypes> = ({ title, font, underline, uppercase, margin }) => {
  return (
    <div className={`Text`}
      style={{
        margin: margin && margin,
        fontFamily: font?.family,
        color: font?.color,
        fontStyle: font?.style,
        fontSize: font?.size,
        fontWeight: font?.weigth,
        textDecoration: underline ? "underline" : "none"
       }}
    >{uppercase ? title.toUpperCase() : title}</div>
  );
};
