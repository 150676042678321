import "./styles.sass";
import { FC } from "react";
import { InputTypes } from "./types";
import React from "react";

export const Input: FC<InputTypes> = ({
  label,
  labelWidth,
  name,
  value,
  placeholder,
  onChange,
  onClick,
  width,
  margin,
  errorText,
  labelLeft,
  borderWidth,
  icon,
  height,
  disabled = false,
  type = "text",
}) => {
  const isError = errorText?.length;
  return (
    <div
      className={`${labelLeft ? "Input InputRow" : "Input"}`}
      style={{
        width: `${width ?? 100}%`,
        height: height ? height + 6 : "85px",
        minHeight: height ? height + 6 : "85px",
        margin,
      }}
    >
      {label && (
        <label
          htmlFor={label}
          style={{minWidth: labelWidth ?? "auto"}}
          className={`${labelLeft ? "InputLabel InputLabelRow" : "InputLabel"}`}
        >
          {label}
        </label>
      )}
      <div
        className="InputElementContainer"
        onClick={onClick}
        style={{
          borderRadius: borderWidth ? 8 : 0,
          height: height ?? "30px",
          overflowY: "hidden",
        }}
      >
        <input
          className={`${isError ? "InputElement InputError" : "InputElement"}`}
          style={{height: height ?? "calc(100% - 6px)"}}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          type={type}
        />
        {icon && <div className="InputElementIcon">{icon}</div>}
      </div>
      {isError && <div className="InputErrorText">{errorText}</div>}
    </div>
  );
};
