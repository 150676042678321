import {
  GET_SUBJECTS_PENDING,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  CREATE_SUBJECT_PENDING,
  CREATE_SUBJECT_SUCCESS,
  CREATE_SUBJECT_FAIL,
  GET_BOUGHT_SUBJECTS_PENDING,
  GET_BOUGHT_SUBJECTS_SUCCESS,
  GET_BOUGHT_SUBJECTS_FAIL,
} from "../../constants/admin";

const initialState = {
  bought_subjects: [],
  subjects: [],
  pending: false,
  error: "",
};

export const admin_subjects = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SUBJECTS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload,
        pending: false,
      };
    case GET_SUBJECTS_FAIL:
      return {
        subjects: [],
        pending: false,
        error: action,
      };
    case CREATE_SUBJECT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case CREATE_SUBJECT_FAIL:
      return {
        subjects: [],
        pending: false,
        error: action,
      };
    case GET_BOUGHT_SUBJECTS_PENDING:
      return {
        ...state,
        bought_subjects: [],
        pending: true
      }
    case GET_BOUGHT_SUBJECTS_SUCCESS:
      return {
        ...state,
        bought_subjects: action.payload,
        pending: false
      }
    case GET_BOUGHT_SUBJECTS_FAIL:
      return {
        ...state,
        bought_subjects: [],
        pending: false,
        error: action.payload
      }
      default: {
        return state;
      }
    }
}