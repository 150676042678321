export const find_by_id = (arr: any, id: number) => arr.find((element: any) => element.id === id)

export const shortify_text = (t: string, ml: number = 30) => t.length > ml ? t.slice(0, ml) + "..." : t;

export const shortify_end_text = (t: string, ml: number = 30) =>
{
  const len = t.length
  return len > ml ? "..." + t.slice(len - ml, len) : t
}

export const is_json_string = (str: string | number) =>
{
  if (typeof str === "number") return false
  try { JSON.parse(str) }
  catch { return false }
  return true;
}

export const prepare_match_variant_points = (answ: string | null) =>
{
  if (!answ) return []
  if (!answ.length) return []
  
  const splitted = answ.indexOf(",") === 0 ? answ.slice(1).split(",") : answ.split(",")
  return splitted.map((el: string) =>
  {
    const sp = el.split("-")
    return ({ num: parseInt(sp[0]), answ: sp[1] })
  })
}

export const split_arr = (arr: any, chunks: number = 2) =>
{
  if (!arr.length) return []
  return arr.reduce((resArr: any, item: any, index: number) =>
  { 
    const chunkIndex = Math.floor(index/chunks)
    if(!resArr[chunkIndex]) { resArr[chunkIndex] = [] }
    resArr[chunkIndex].push(item)
    return resArr
  }, [])
}

export const link_check = (url: string | null) =>
{
  if (!url) return false
  const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(url)
}