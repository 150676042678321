export const GET_TASKS_PENDING = "GET_TASKS_PENDING";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_FAIL = "GET_TASKS_FAIL";

export const CREATE_TASK_PENDING = "CREATE_TASK_PENDING";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAIL = "CREATE_TASK_FAIL";

export const UPDATE_TASK_PENDING = "UPDATE_TASK_PENDING";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL";

export const DELETE_TASK_PENDING = "DELETE_TASK_PENDING";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";