import {
  GET_LESSONS_PENDING,
  GET_LESSONS_SUCCESS,
  GET_LESSONS_FAIL,
} from "./../../constants/admin";
import axios from 'axios';
import { requestConfig, serv } from "../../constants/config";

interface CurrentDataType {
  id: number;
  name: string;
}

export const get_lessons_action = (module_id: number, callback?: (lessons: any) => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_groups/lessons?id=${module_id}`;
  dispatch({ type: GET_LESSONS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) =>
    {
      callback && callback(res.data)
      dispatch({ type: GET_LESSONS_SUCCESS, payload: res.data })
    })
    .catch((err: any) =>
    {
      callback && callback([])
      dispatch({ type: GET_LESSONS_FAIL, payload: err })
    });
};

export const patch_lesson_action = (lesson: any, callback?: (lesson: any) => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/lessons/${lesson.id}`;
  const body = { lesson };
  axios.patch(url, body, requestConfig()).then((res: any) =>callback && callback(res.data));
};

export const post_lesson_action = (lesson_info: any, callback?: (lesson: any) => void) => (dispatch: any) =>
{
  
  const url = `${serv}/adminpanel/lessons`;
  const body = { lesson: lesson_info};
  axios
    .post(url, body, requestConfig())
    .then((res: any) => callback && callback(res.data)
);
};

export const delete_lesson_action = (lesson_id: number, module_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/lessons/${lesson_id}`;
  axios.delete(url, requestConfig()).then(() => dispatch(get_lessons_action(module_id)));
};

export const set_current_lesson = (element: CurrentDataType) => (dispatch: any) =>
{
  const { id, name } = element;
  dispatch({ type: "SELECT_CURRENT_LESSON", payload: { id, name } });
};

export const post_additional_materials = (position: number, title: string, lesson_url: string, lesson_id: number) =>
{
  const url = `${serv}/adminpanel/extra_materials`;
  const body = { extra_material: { position, title, url: lesson_url, lesson_id } }
  return axios.post(url, body, requestConfig())
}

export const patch_additional_materials = (position: number, title: string, lesson_url: string, lesson_id: number,  material_id: number ) =>
{
  const url = `${serv}/adminpanel/extra_materials/${material_id}`;
  const body = { extra_material: { position, title, url: lesson_url, lesson_id } }
  return axios.patch(url, body, requestConfig())
}

export const delete_additional_materials = ( material_id: number ) =>
{
  const url = `${serv}/adminpanel/extra_materials/${material_id}`;
  return axios.delete(url, requestConfig())
}