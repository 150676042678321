import {
  GET_TASKS_PENDING,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
} from "../../constants/admin";

const initialState = {
  balls: null,
  theme: null,
  tasks: [],
  is_it_lesson: false,
  searched_tasks: [],
  pending: false,
  error: "",
};

export const admin_tasks = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TASKS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        balls: action.payload.balls,
        theme: action.payload.theme,
        tasks: action.payload.exercises,
        is_it_lesson: action.is_lesson,
        pending: false,
      };
    case GET_TASKS_FAIL:
      return {
        balls: null,
        theme: null,
        tasks: [],
        is_it_lesson: action.is_lesson,
        pending: false,
        error: action,
      };
    case "SEARCH_TASKS_PENDING":
      return {
        ...state,
        searched_tasks: [],
        pending: true
      }
    case "SEARCH_TASKS_SUCCESS":
      return {
        ...state,
        searched_tasks: action.payload,
        pending: false
      }
    case "CLEAR_SEARCHED_TASK":
      return {
        ...state,
        searched_tasks: [],
        pending: false
      }
    default: {
      return state;
    }
    }
}