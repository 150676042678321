import { FC } from "react";
import { v4 as uuidv4 } from "uuid";
import "./styles.sass";
import { CuratorTableHeaderTypes } from "./types";

export const CuratorTableHeader: FC<CuratorTableHeaderTypes> = ({
  headersTitle,
  sortResults,
}) => (
  <thead className="curator-thead">
    <tr>
      <th className="thNameEL " onClick={() => sortResults("index", 1)}>
        #
      </th>
      <th
        className="thNameEL sticky-col first-col"
        onClick={() => sortResults("name", 1)}
      >
        ІМ’Я
      </th>
      {headersTitle.homeworks.map((header: any, id: number) => (
        <th
          key={uuidv4()}
          className="thNameEL"
          onClick={() => sortResults("homework", header.homework_id)}
        >
          <p>{header.name}</p>
          <p className="table_balls" >{header.balls}</p>
        </th>
      ))}
      {headersTitle.exams.map((header: any, id: number) => (
        <th
          key={uuidv4()}
          className="thNameEL"
          onClick={() => sortResults("test", header.homework_id)}
        >
          <p>{header.name}</p>
          <p className="table_balls" >{header.balls}</p>
        </th>
      ))}
    </tr>
  </thead>
);
