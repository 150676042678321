import "./styles.sass"
import { FC, useContext } from "react"
import { AdminTasksHeaderType } from "./types"
import { SimpleInput } from "../../atoms/SimpleInput"
import { Button } from "../../atoms/Button"
import { is_lesson } from "../../../tools/admin/common"
import { useDispatch } from "react-redux"
import { Context } from "../../../context"
import { patch_test_action } from "../../../redux/actions/admin"
import { MODULE_MODE } from "../../../constants/admin"

export const AdminTasksHeader: FC<AdminTasksHeaderType> = ({
  update_all,
  canSave,
  lesson,
  homework_theme,
  lesson_balls,
  is_it_lesson
}) => {
  const [context, setContext] = useContext(Context)
  const dispatch = useDispatch()

  const update_test_publish = () =>
  {
    if (is_lesson(lesson.name)) return
    dispatch<any>(patch_test_action({ ...lesson, aasm_state: "unpublished" }))
    setContext({ ...context, lesson: undefined, current_mode: MODULE_MODE })
  }

  return (
    <div className={`AdminTasksHeader ${ is_it_lesson ? "AdminTasksHeader_test" : "" }`}>
      <div className="AdminTasksHeaderInfoName">
        {is_lesson(lesson.name) ? homework_theme ?? "без теми" : lesson.name}
        {!is_lesson(lesson.name) && <>
        <div className="LessonInfoStatusText">{(lesson?.aasm_state ?? "unpublished") === "published" ? "Опублікуваний" : "Неопублікуваний"}</div>
        <div className="LessonInfoStatusActionButton">
          <Button
            title="закрити"
            mode={(lesson?.aasm_state ?? "unpublished") === "published" ? "btn_transparent" : "btn_gray"}
            uppercase
            onClick={update_test_publish}
            disabled={(lesson?.aasm_state ?? "unpublished") === "unpublished"}
          />
        </div>
        </>}
      </div>
      <div className="AdminTasksHeaderInfoActions">
        <div className="AdminTasksHeaderInfoText">Кількість балів</div>
        <SimpleInput value={lesson_balls} width={120} height={50} margin="0 20px" disabled />
        <Button
          title="зберегти"
          onClick={() => update_all()}
          mode={canSave ? "btn_black" : "btn_gray"}
          uppercase
          disabled={!canSave}
        />
      </div>
    </div>
  )
}