
import axios from 'axios';
import {
  GET_MODULES_PENDING,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAIL,
  CREATE_MODULE_PENDING,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_FAIL,
  UPDATE_MODULE_PENDING,
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_FAIL,
  DELETE_MODULE_PENDING,
  DELETE_MODULE_SUCCESS,
  DELETE_MODULE_FAIL,
  GET_BOUGHT_MODULES_SUCCESS,
} from "./../../constants/admin";
import { requestConfig, serv } from "../../constants/config";

interface CurrentDataType {
  id: number;
  name: string;
}

export const get_modules_action = (subject_id: number, callback?: (is_ok: boolean) => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/subjects/master_groups?id=${subject_id}`;
  dispatch({ type: GET_MODULES_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) =>
      {
        dispatch({ type: GET_MODULES_SUCCESS, payload: res.data })
        callback && callback(true)
      })
    .catch((err: any) =>
      {
        dispatch({ type: GET_MODULES_FAIL, payload: err })
        callback && callback(false)
      });
};

export const patch_module_action = (master_group: any, current_subject_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_groups/${master_group.id}`;
  const body = { master_group };
  dispatch({ type: UPDATE_MODULE_PENDING });
  axios
    .patch(url, body, requestConfig())
    .then((res: any) => dispatch({ type: UPDATE_MODULE_SUCCESS, payload: res.data }))
    .then((res: any) => dispatch(get_modules_action(current_subject_id)))
    .catch((err: any) => dispatch({ type: UPDATE_MODULE_FAIL, payload: err }));
};

export const post_module_action = (master_group: any, subject_id: number, callback?: (module_id?: number) => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_groups`;
  const body = { master_group };
  dispatch({ type: CREATE_MODULE_PENDING });
  axios
    .post(url, body, requestConfig())
    .then((res: any) =>
      {
        dispatch({ type: CREATE_MODULE_SUCCESS, payload: res.data })
        callback && callback(res?.data ? res.data.id : undefined)
      })
    .then(() => dispatch(get_modules_action(subject_id)))
    .catch((err: any) => dispatch({ type: CREATE_MODULE_FAIL, payload: err }));
};

export const delete_module_action = (master_group_id: any, current_subject_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_groups/${master_group_id}`;
  dispatch({ type: DELETE_MODULE_PENDING });
  axios
    .delete(url, requestConfig())
    .then((res: any) => dispatch({ type: DELETE_MODULE_SUCCESS, payload: res.data }))
    .then(() => dispatch(get_modules_action(current_subject_id)))
    .catch((err: any) => dispatch({ type: DELETE_MODULE_FAIL, payload: err }));
};

export const set_current_module = (element: CurrentDataType) => (dispatch: any) =>
{
  const { id, name } = element;
  dispatch({ type: "SELECT_CURRENT_MODULE", payload: { id, name } });
};

// `GET http://localhost:3000/api/v1/adminpanel/users/available_master_groups?subject_id=1&id=1`

export const get_bougth_modules_action = (subject_id: number, id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/users/available_master_groups?subject_id=${subject_id}&id=${id}`;
  // dispatch({ type: DELETE_MODULE_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_BOUGHT_MODULES_SUCCESS, payload: res.data }))
    // .catch((err: any) => dispatch({ type: DELETE_MODULE_FAIL, payload: err }));
}

export const get_all_bougth_modules_action = (subject_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_groups/with_students_groups?subject_id=${subject_id}`;
  // dispatch({ type: DELETE_MODULE_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_BOUGHT_MODULES_SUCCESS, payload: res.data }))
    // .catch((err: any) => dispatch({ type: DELETE_MODULE_FAIL, payload: err }));
}

interface BoughtModuleType
{
  students_group_id: number
  bought_master_group: {
    id: number
    package_id: number
    payment_system_paid: boolean
    master_group_id: number
  }
}

// interface BoughtPackageType
// {
//   bought_master_groups: BoughtModuleType[]
// }

const bought_module_body_example = {
      "bought_master_groups": [
      {
        "students_group_id":1,
        "bought_master_group": {
          "id": 2,
          "package_id":1,
          "payment_system_paid": false,
          "master_group_id":1
        }
      }, 
      {
        "students_group_id":2,
        "bought_master_group": {
          "id": 2,
          "package_id":2,
          "payment_system_paid": false,
          "master_group_id":2
        }
      }
    ]
}

// ʼPOST http://localhost:3000/api/v1/adminpanel/user_payments/add_to_master_group`

export const bought_modules_action = (user_payment_arr: BoughtModuleType[]) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/user_payments/add_to_master_groups`;
  const body = { user_payment: {bought_master_groups: user_payment_arr} };
  axios
    .post(url, body, requestConfig())
}

export const delete_modules_action = (user_payment_arr: BoughtModuleType[]) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/user_payments/remove_from_master_groups`;
  const body = { user_payment: {bought_master_groups: user_payment_arr} };
  axios
    .post(url, body, requestConfig())
}

export const get_module_groups_action = (module_id: number, callback?: (md_groups: any) => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/students_groups?master_group_id=${module_id}`;
  axios
    .get(url, requestConfig())
    .then((res: any) => { callback && callback(res.data); dispatch({ type: "GET_MODULE_GROUPS_SUCCESS", payload: res.data }) })
    // .catch((err: any) => dispatch({ type: CREATE_MODULE_FAIL, payload: err }));
}

export const add_module_groups_action = (group: any, callback?: () => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/students_groups`;
  const body = {students_group: group}
  //  {"students_group":{"telegram_link": "wsfwdaa aerdg", "master_group_id": 1, "curator_id"?: 1}}
  axios.post(url, body, requestConfig()).then(() => callback && callback())
}

export const delete_module_group_action = (group_id: any) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/students_groups/${group_id}`;
  axios.delete(url, requestConfig())
}

export const get_all_possible_groups_in_mastergroup = (mg_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/students_groups?master_group_id=${mg_id}`;
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: "GET_MODULE_GROUPS_SUCCESS", payload: res.data }))
}

export const get_all_curator_module_groups = (curator_id: number, callback?: (is_ok: boolean) => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/curators/involved_master_groups?id=${curator_id}`;
  axios
    .get(url, requestConfig())
    .then((res: any) => { callback && callback(true); dispatch({ type: "GET_CURATOR_MODULES_SUCCESS", payload: res.data }) })
}

export const get_setted_curator_module_groups = (mg_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/students_groups?master_group_id=${mg_id}`;
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: "GET_ALL_CURATOR_MODULE_GROUPS", payload: res.data }))
}

export const get_all_curator_modules_action = (mg_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/students_groups?master_group_id=${mg_id}`;
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: "GET_SETTED_CURATOR_MODULE_GROUPS", payload: res.data }))
}

// {"students_group":{"telegram_link": "wsfwdaa ", "master_group_id": 1, "curator_id": 1}}
interface CuratorGroupType
{
  id?: number
  telegram_link?: string
  master_group_id: number
  curator_id?: number | null
}

export const patch_curator_groups_action = (group: CuratorGroupType) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/students_groups/${group.id}`;
  const body = { students_group:  group }
  axios.patch(url, body, requestConfig())
}

export const delete_curator_groups = (group_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/students_groups/${group_id}`;
  axios.patch(url, requestConfig())
}