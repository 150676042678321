import { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { arrowBack } from "../../../assets/icons/common";
import { Context } from "../../../context";
import { get_curator_profile_action } from "../../../redux/actions/curator/profile";
import { CuratorStudentTableBody } from "../../molecules/CuratorStudentTableBody";
import { CuratorStudentTableHeader } from "../../molecules/CuratorStudentTableHeader";
import { CuratorTableBody } from "../../molecules/CuratorTableBody";
import { CuratorTableHeader } from "../../molecules/CuratorTableHeader";
import { ModalSlider } from "../../molecules/ModalSlider";
import { StudentLesson } from "../../organisms/StudentLesson";
import { CuratorCoursesType } from "./types";
import {
  clear_single_student,
  finish_work_check_action,
  get_curator_student_list,
  get_curator_work_list,
  set_single_student,
  set_user_task,
} from "../../../redux/actions/curator/groups";
import "./styles.sass";
import { SET_USER_TASK_PENDING } from "../../../redux/constants/curator";

export const CuratorCourses: FC<CuratorCoursesType> = () => {
  const dispatch = useDispatch();
  const [context, setContext] = useContext(Context);
  const { user_id, homework_id, aasm_state, involved_mgs } = context;
  const [work_photos, set_work_photos] = useState([]);
  const [have_groups, set_have_groups] = useState(false)

  const { 
    worklist, pending, pendingList, students, student,
    selected_student_id, homework, homeworkPending,
    student_group_id, work_name
  } = useSelector((st: any) => st.curator_groups);

  const { msgroup } = useSelector(
    (st: any) => st.curator_profile
  );

  const check_have_groups = () =>
  {
    if (!msgroup) return set_have_groups(false)
    if (!msgroup.length) return set_have_groups(false)
    const non_empty_count = msgroup.filter((el: any) => el.student_groups.length)
    if (!non_empty_count.length) return set_have_groups(false)
    return set_have_groups(true)
  }

  useEffect(check_have_groups, [msgroup])

  useEffect(() =>
  {
    dispatch<any>(get_curator_profile_action(user_id));
    return clearStudentData();
  }, []);

  useEffect(() =>
  {
    if (!student_group_id) return
    dispatch<any>(get_curator_work_list(student_group_id));
    dispatch<any>(get_curator_student_list(student_group_id, "index", 1));
  }, [student_group_id]);

  useEffect(() => { !student.length && showStudentTable(selected_student_id) }, [homework]);

  const showStudentTable = (data: number) =>
  {
    const studentData = students.filter((item: any) => item.id === data);
    dispatch<any>(set_single_student(studentData, data));
  };

  const get_student_id_from_list = (mode: "prev" | "next") =>
  {
    if (!selected_student_id) return
    if (selected_student_id < 0) return
    const current_st_index = [ ...students.map((std: any) => std.id) ].indexOf(selected_student_id)
    if (current_st_index < 0) return
    dispatch({ type: SET_USER_TASK_PENDING })
    if (mode === "prev" && current_st_index > 0) showStudentTable(students[current_st_index - 1].id)
    if (mode === "next" && current_st_index < students.length) showStudentTable(students[current_st_index + 1].id)
  }

  const clearStudentData = () => {
    if (!student_group_id) return
    dispatch<any>(clear_single_student())
    dispatch<any>(get_curator_student_list(student_group_id, "index", 1))
  };

  const selectHomework = (sh: any) =>
  {
    dispatch<any>( set_user_task( sh.id, sh.user_id, sh.work_name ))
    setContext({ ...context, homework_id: sh.id, aasm_state: sh.aasm_state })
  };

  const sortResults = (query: any, id: any) => { student_group_id && dispatch<any>(get_curator_student_list(student_group_id, query, id)) };

  const prepared_user_answers = (task: any) =>
  {
    const type = task.exercise_type
    const answer = task.user_answer
    if (!answer)
    {
      if (type === "ManualSolution") return []
      return ""
    }
    if (type === "ManualSolution") return task.user_answer.work_photo
    return task.user_answer
  }

  const curator_work_send = (comment: string) =>
  {
    dispatch<any>(finish_work_check_action(comment, homework_id, (status: number) =>
    {
      dispatch<any>(get_curator_student_list(student_group_id, "index", 1, (sts) =>
      {
        const studentData = sts.filter((item: any) => item.id === student[0].id);
        studentData.length && dispatch<any>(set_single_student(studentData, student[0].id));
      }))
      if (status !== 200) return setContext({ ...context, notification_data: { title: "Щось пішло не так", type: "warning" } })
      return setContext({ ...context, notification_data: { title: "Завдання перевірено", type: "success" } })
    }))
  }

  useEffect(() =>
  {
    if (!Object.keys(homework).length) return
    if (!homework.exercises.length) return
    setContext( (prev: any) => ({ ...prev, homework_answers: homework.exercises.map((task: any) => ({exercise_id: task.id, answer: prepared_user_answers(task)})) }))
  }, [homework])

  return (
    <div className="SCuratorCourses">
      { (involved_mgs.length === 0 || !have_groups) && "поки що куратор не прикріплений до жодної групи" }
      { !student_group_id && "Не знайдено жодного студента закріпленого за куратором" }
      {(!pending && !pendingList && involved_mgs.length > 0 && have_groups) && (
        <div className={`curatorTableDiv CuratorTableContainer ${student.length ? "curatorTableDivFix" : ""}`}>
          {!student.length ? (
            <>
              <table className="curatorTable">
                <CuratorTableHeader
                  headersTitle={worklist}
                  sortResults={sortResults}
                />
                <CuratorTableBody
                  selectHomework={selectHomework}
                  showStudent={showStudentTable}
                  headersTitle={worklist}
                  curatorTable={students}
                />
              </table>
            </>
          ) : (
            <>
              <div className="navigation-bar-main">
                <div className="navigation-bar">
                  <div className="aroowBlock" onClick={clearStudentData}>
                    {arrowBack}
                  </div>
                  <div className="arrow-back"></div>
                  <h1>{student[0].group_name}</h1>
                  <h1 className="navigation-bar-name">
                    {student[0].full_name}
                  </h1>
                </div>
                <div className="navigation-change-student">
                  <button
                    onClick={() => get_student_id_from_list("prev")}
                    className="navigation-control-one"
                  >
                    {arrowBack}
                  </button>
                  <button
                    onClick={() => get_student_id_from_list("next")}
                    className="navigation-control-two"
                  >
                    {arrowBack}
                  </button>
                </div>
              </div>
              <div className="CuratorTableContainer">
                <table className="curatorTable">
                  <CuratorStudentTableHeader headersTitle={worklist} />
                  <CuratorStudentTableBody
                    selectHomework={selectHomework}
                    showStudent={showStudentTable}
                    headersTitle={worklist}
                    curatorTable={student}
                  />
                </table>
              </div>
            </>
          )}
        </div>
      )}
      {homeworkPending && (
        <StudentLesson
          user_type={"curator"}
          lesson_info={homework}
          lesson_theme={homework.theme}
          lesson_name={work_name}
          set_work_photos={set_work_photos}
          curator_work_send={curator_work_send}
          is_checked={ aasm_state === "checked" }
          curator_comment={ homework?.curator_comment ? homework.curator_comment : "" }
        />
      )}
      {work_photos.length > 0 && <ModalSlider work_photos={work_photos} set_work_photos={set_work_photos} /> }
    </div>
  );
};
