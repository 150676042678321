// PROFILE
export const GET_CURATOR_PROFILE_PENDING = "GET_CURATOR_PROFILE_PENDING";
export const GET_CURATOR_PROFILE_SUCCESS = "GET_CURATOR_PROFILE_SUCCESS";
export const GET_CURATOR_PROFILE_FAIL = "GET_CURATOR_PROFILE_FAIL";

export const GET_CURATOR_MGROUPS_PENDING = "GET_CURATOR_MGROUPS_PENDING";
export const GET_CURATOR_MGROUPS_SUCCESS = "GET_CURATOR_MGROUPS_SUCCESS";
export const GET_CURATOR_MGROUPS_FAIL = "GET_CURATOR_MGROUPS_FAIL";

// Groups
export const GET_CURATOR_WORK_LIST_PENDING = "GET_CURATOR_WORK_LIST_PENDING";
export const GET_CURATOR_WORK_LIST_SUCCESS = "GET_CURATOR_WORK_LIST_SUCCESS";
export const GET_CURATOR_WORK_LIST_FAIL = "GET_CURATOR_WORK_LIST_FAIL";

export const GET_CURATOR_STUDENTS_LIST_PENDING =
  "GET_CURATOR_STUDENTS_LIST_PENDING";
export const GET_CURATOR_STUDENTS_LIST_SUCCESS =
  "GET_CURATOR_STUDENTS_LIST_SUCCESS";
export const GET_CURATOR_STUDENTS_LIST_FAIL = "GET_CURATOR_STUDENTS_LIST_FAIL";

//Helpers
export const SET_SINGLE_STUDENT = "SET_SINGLE_STUDENT";
export const CLEAR_STUDENT = "CLEAR_STUDENT";
export const SET_STUDENTS_ID = "SET_STUDENTS_ID";

export const SET_USER_TASK_PENDING = "SET_USER_TASK_PENDING";
export const SET_USER_TASK_SUCCESS = "SET_USER_TASK_SUCCESS";
export const SET_USER_TASK_FAIL = "SET_USER_TASK_FAIL";
