import { useContext} from "react";
import { useSelector } from "react-redux";
import { Context } from "../../../context";
import { prepareDate } from "../../../tools/admin/date";
import { shortify_text } from "../../../tools/common";
import "./styles.sass";

export const TeacherGroupInfoItem = () =>
{
  const [context] = useContext(Context)
  const { module, lesson } = context
  const { master_groups, users, pending } = useSelector((st: any) => st.teacher_mgps)

  const info_msg = () =>
  {
    if (!module) return "модуль не вибрано"
    if (!lesson) return "роботу не вибрано"
    if (pending) return "триває завантаження даних ..."
    if (!users.length) return "таких даних поки що немає"
  }

  return (
    <div className="TeacherGroupInfoItem">
      {(pending || !lesson || !module || users.length === 0 || master_groups.length === 0) && <p className="TeacherLessonInfoMainTop my10">{info_msg()}</p> }
      {!pending && module && lesson && users.length > 0 && users.map((user: any) => {
        return (
          <div className="TeacherGroupInfoItemContainer" key={user.id}>
            <div className="TeacherGroupInfoItemNumber">
              {user.id}
            </div>
            <div className="TeacherGroupInfoItemGroup">
              <div className="TeacherGroupInfoItemContent">{ module?.name ? module.name.slice(0, 6) : ""}</div>
            </div>
            <div className="TeacherGroupInfoItemName">
              <div className="TeacherGroupInfoItemContent">
                {shortify_text(user.full_name, 40)}
              </div>
            </div>
            <div className="TeacherGroupInfoItemVisiting">
              <div className="TeacherGroupInfoItemContent">
                {/* @ts-ignore */}
                {user.last_sign_in_at ? prepareDate(new Date(user.last_sign_in_at)) : "немає"}
              </div>
            </div>
            <div className="TeacherGroupInfoItemD">
              <div className="TeacherGroupInfoItemContent">{user.estimation} | {user.max_ball}</div>
            </div>
            <div className="TeacherGroupInfoItemPoints">
              <div className="TeacherGroupInfoItemContent">{user.total_ball}</div>
            </div>
            <div className="TeacherGroupInfoItemCurator">
              <div className="TeacherGroupInfoItemContent">{user.curator_name}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
