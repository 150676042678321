import { HTMLAttributes, ReactElement } from 'react'

type FocusEvents = 'focusin' | 'focusout'
type MouseEvents = 'click' | 'mousedown' | 'mouseup'
type TouchEvents = 'touchstart' | 'touchend'
export type Events = FocusEvent | MouseEvent | TouchEvent

export interface ClickAwayListenerPropsType
  extends HTMLAttributes<HTMLElement> {
  onClickAway: (event: Events) => void
  focusEvent?: FocusEvents
  mouseEvent?: MouseEvents
  touchEvent?: TouchEvents
  children: ReactElement<any>
}

export const eventTypeMapping = {
  click: 'onClick',
  focusin: 'onFocus',
  focusout: 'onFocus',
  mousedown: 'onMouseDown',
  mouseup: 'onMouseUp',
  touchstart: 'onTouchStart',
  touchend: 'onTouchEnd',
}

