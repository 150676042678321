import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeIcon } from "../../../assets/icons/common";
import { bought_modules_action, create_student_action, get_subjects_action } from "../../../redux/actions/admin";
import { email_validate } from "../../../tools/validators";
import { Button } from "../../atoms/Button";
import { SimpleInput } from "../../atoms/SimpleInput";
import "./styles.sass";
import { AddStudentModalType } from "./types";

export const AddStudentModal: FC<AddStudentModalType> = ({
  subjectsComponent, modulesComponent, close_modal, bought_packages, prepare_packages_to_bought, clear_data
}) =>
{
  const dispatch = useDispatch()
  const [email, set_email] = useState("")
  const [can_create, set_can_create] = useState(false)

  const can_bought_packages = () =>
  {
    if (bought_packages.length === 0) return false
    for (let i = 0; i < bought_packages.length; i++)
    {
      const p_item = bought_packages[i]
      if (
        p_item.module_id === undefined ||
        p_item.module_name === undefined ||
        p_item.package_id === undefined ||
        p_item.group_id === undefined ||
        p_item.group_name === undefined
      ) return false
    }
    return true
  }

  useEffect(() =>set_can_create(bought_packages.length === 0 ? email_validate(email) : ( email_validate(email) && can_bought_packages()) ), [email, bought_packages])
  useEffect(() =>
  {
    dispatch<any>(get_subjects_action())
    clear_data(true)
  }, [])

  const create_student = () =>
  {
    const student_data: {email: string, password: string} = { email, password: "password" }
    dispatch<any>(create_student_action(student_data, (id: number) =>
    {
      if (can_bought_packages() && id !== undefined && id !== null)
      {
        dispatch<any>(bought_modules_action(prepare_packages_to_bought(bought_packages, false, id)))
      }
      clear_data()
    }))
  }

  return (
    <div className="AddStudentModal">
      <div
        onClick={close_modal}
        className="AddStudentModalClose"
      >{ closeIcon }</div>
      <div className="AddStudentModalLabels">E-mail</div>
      <SimpleInput width={473} margin="0 0 20px 0" value={email} onChange={(e) => set_email(e.target.value)} />
      <div className="AddStudentModalLabels">
        <div>предмет</div>
        <div style={{ width: 295, display: "flex", justifyContent: "space-between" }}>
          <div>Майстер група</div>
          <div>куратор</div>
        </div>
      </div>
      <div className="AddStudentModalEducation">
        {subjectsComponent}
        {modulesComponent}
      </div>
      <div className="AddStudentModalActions">
        <Button
          title="зберегти"
          uppercase
          mode={can_create ? "btn_black" : "btn_gray"}
          onClick={() => can_create && create_student()}
          width={200}
          disabled={!can_create}
        />
      </div>
    </div>
  )
}