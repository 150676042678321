import { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Context } from "../../../context";
import {
  clear_single_student,
  set_students_id,
} from "../../../redux/actions/curator/groups";
import { get_curator_master_groups_action } from "../../../redux/actions/curator/profile";
import "./styles.sass";
import "../StudentHeader/styles.sass"
import { CuratorHeaderType } from "./types";

export const CuratorHeader: FC<CuratorHeaderType> = () => {
  const [context, setContext] = useContext(Context)
  const { involved_mgs } = context
  const dispatch = useDispatch();
  const { subject_name, msgroup, mspending } = useSelector(
    (st: any) => st.curator_profile
  );
  const [listValue, setListValue] = useState();
  const [groups, setGroups] = useState([]);
  const [groupValue, setGroupValue] = useState();

  useEffect(() => {
    dispatch<any>(get_curator_master_groups_action());
  }, []);

  const get_involved_mgs = () => msgroup.filter((item: any) => item.involved)

  const current_month_uk = () => new Date().toLocaleString('uk-UA', { month: 'long' })

  useEffect(() => {
    if (mspending) return
    const curr_month = current_month_uk()
    const involved_mgs = get_involved_mgs()
    setContext({ ...context, involved_mgs });
    if (!involved_mgs.length) return
    const current_month_mg = involved_mgs.find((el: any) => el.name.toLowerCase().includes(curr_month))
    if (current_month_mg)
    {
      if (!current_month_mg.student_groups.length) return
      dispatch<any>(set_students_id(current_month_mg.student_groups[0].id))
      setGroups(current_month_mg.student_groups)
      setGroupValue(current_month_mg.student_groups[0].id)
      setListValue(current_month_mg.id)
      return
    }
    if (!involved_mgs[0].student_groups.length) return
    involved_mgs.length && dispatch<any>(set_students_id(involved_mgs[0].student_groups[0].id));
    involved_mgs.length && setGroups(involved_mgs[0].student_groups);
    involved_mgs.length && setGroupValue(involved_mgs[0].student_groups[0].id);
    involved_mgs.length && setListValue(involved_mgs[0].id);
  }, [mspending]);

  const changeMastergroup = (data: any) => {
    const arr = msgroup.filter((item: any) => item.id === +data);

    setListValue(data);
    setGroups(arr[0].student_groups);
  };

  const setGroupId = (id: any) => {
    dispatch<any>(set_students_id(id));
    setGroupValue(id);
    dispatch<any>(clear_single_student());
  };

  return (
    <div className="StudentHeader">
      <div className="StudentHeaderTopBlock">
        <div className="StudentHeaderLeftItem">
          <div className="StudentHeaderLeftItemBlack" />
        </div>
        <div className={`TeacherHeaderSubject`}>{subject_name}</div>
        <div className="StudentHeaderNavigationContainer">
          <div className="CuratorHeaderSelectBlock">
            <select
              value={listValue}
              onChange={(e) => changeMastergroup(e.target.value)}
            >
              {!mspending &&
                involved_mgs &&
                involved_mgs.map((item: any) => (
                  <option key={uuidv4()} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <select value={groupValue} onChange={(e) => setGroupId(e.target.value)}>
              <option value="0">Оберіть групу</option>
              {!mspending &&
                groups &&
                groups.map((item: any) => (
                  <option key={uuidv4()} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="StudentHeaderRightItem">
          <div className="StudentHeaderRightItemBlack" />
        </div>
      </div>
      <div className="StudentHeaderBottomBlock">
        <div className="StudentHeaderBottomBlockBlack" />
      </div>
    </div>
  );
};




