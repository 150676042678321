import "./styles.sass";
import { v4 as uuidv4 } from "uuid";
import { FC, useContext, useEffect, useState, useRef } from "react";
import { StudentAdditionalMenuType } from "./types";
import { Context } from "../../../context";
import {
  STUDENT_LESSON_MODE,
  STUDENT_MODULE_MODE,
  STUDENT_TASK_MODE,
  TEACHER_LESSON_MODE,
  TEACHER_MODULE_MODE,
  TEACHER_TEST_MODE,
} from "../../../constants/admin";
import { useDispatch, useSelector } from "react-redux";
import { get_student_modules_action } from "../../../redux/actions/student";
import { get_student_exams_action, get_student_lessons_action } from "../../../redux/actions/student/lessons";
import { is_lesson } from "../../../tools/admin/common";
import { get_student_exam_tasks_action } from "../../../redux/actions/student/tasks";
import { Text } from "../../atoms/Text";
import {set_current_lesson} from "../../../redux/actions/admin";
import {arrowIcon} from "../../../assets/icons/header";

export const StudentAdditionalMenu: FC<StudentAdditionalMenuType> = ( ) => {
  const [active] = useState(false);
  const [isClicked, setIsActive] = useState(false);
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context);
  const [isModuleChosen, setIsModuleChosen] = useState(false);
  const moduleSelectRef = useRef<HTMLDivElement | null>(null)
  const lessonsSelectRef = useRef<HTMLDivElement | null>(null);
  const [isLessonSelectEnabled, setIsLessonSelectEnabled] = useState(false);
  const { subject, module, lesson, current_mode, user_type, is_lesson_changed } = context

  const is_user: boolean = user_type === "user"
  const is_teacher: boolean = user_type === "teacher"

  const user_id = useSelector((st: any) => st.user_info.id)
  const { student_modules, teacher_courses } = useSelector((st: any) => st)
  const { lessons, exams } = useSelector((st: any) => st.student_lessons)

  const view_modules = is_user ? student_modules.modules : teacher_courses.courses

  const sortArr = (arr: any) => arr.sort((a: any, b: any) => a.id - b.id)

  const prepare_items = (lessons: any, tests: any) =>
  {
    const test_1 = tests.length ? tests.find((el: any) => (el.name.toLowerCase() === "вступний тест" || el.name.toLowerCase() === "тест 1") ) : undefined
    const other_tests = (tests.length && test_1)  ? tests.filter((el: any) => el.id !== test_1.id) : []
    return  test_1 ? [ test_1, ...sortArr(lessons), ...other_tests ] : [...sortArr(lessons), ...sortArr(tests)]
  }

  const view_lessons = (module_id?: number) =>
  {
    if (is_user) return prepare_items(lessons, exams)
    if (is_teacher)
    {
      const need_module = teacher_courses.courses.find((c: any) => c.id === module_id)
      return prepare_items(need_module.lessons, need_module.master_group_exams)
    }
    return []
  }
  const select_modules = (module: any) =>
  {
    if (is_user)
    {
      if (current_mode === STUDENT_TASK_MODE && is_lesson_changed) return setContext({ ...context, lesson_pre_save_modal: true, stopped_action: { name: "MODULE", id: module.id } })
      dispatch<any>(get_student_lessons_action(module.id))
      dispatch<any>(get_student_exams_action(module.id))
      setContext({ ...context, current_mode: STUDENT_MODULE_MODE, module });
    }
    if (is_teacher)
    {
      setContext({ ...context, current_mode: TEACHER_MODULE_MODE, module });
    }
    setIsLessonSelectEnabled(true);
  };
  useEffect(() =>
  {
    if (is_teacher) return
    user_id && subject && dispatch<any>(get_student_modules_action(user_id, subject.id))
  }, [user_id, subject])


  const select_lesson = (lesson: any) =>
  {
    if (is_user)
    {
      if (current_mode === STUDENT_TASK_MODE && is_lesson_changed) return setContext({ ...context, lesson_pre_save_modal: true, stopped_action: { name: is_lesson(lesson.name) ? "LESSON" : "TEST", id: lesson.id } })
      if (!is_lesson(lesson.name)) dispatch<any>(get_student_exam_tasks_action({ name: lesson.name, exercises: lesson.exercises, user_master_group_exam_id: lesson.user_master_group_exam.id, ...lesson.user_master_group_exam }))
      setContext({ ...context, current_mode: is_lesson(lesson.name) ? STUDENT_LESSON_MODE : STUDENT_TASK_MODE, lesson });
    }
    if (is_teacher)
    {
      setContext({ ...context, current_mode: is_lesson(lesson.name) ? TEACHER_LESSON_MODE : TEACHER_TEST_MODE, lesson });
    }
  };

  const prepare_module_name = (name: string) =>
  {
    if (name.includes("МГ_")) return name.replace("МГ_", "МАЙСТЕР ГРУПА ")
    return name
  }
  const prepare_module_name_mobile = (name: string) =>
  {
    if (name.includes("МГ_")) return name.replace("МГ_", "")
    return name
  }
  const [isModuleSelectOpen, setIsModuleSelectOpen] = useState(false);
  const [isOpenLessons, setIsOpenLessons] = useState(false);
  const toggleSelectModules = () => {
    setIsModuleSelectOpen(!isModuleSelectOpen);
    setIsOpenLessons(false);
  };
  const toggleSelectLessons = () => {
    setIsOpenLessons(!isOpenLessons);
    setIsModuleSelectOpen(false);
  };

  const closeSelectModule = () => {
    setIsModuleSelectOpen(false);
  };
  const closeSelectLessons = () => {
    setIsOpenLessons(false);
  };
  const handleButtonClick = () => {
    // When you click the button, toggle the isClicked state
    setIsActive(true);
  };
  const handleClickOutside = (event:any) => {
    if (
        (moduleSelectRef.current && !moduleSelectRef.current.contains(event.target)) &&
        (lessonsSelectRef.current && !lessonsSelectRef.current.contains(event.target))
    ) {
      setIsModuleSelectOpen(false);
      setIsOpenLessons(false);
    }
  };
  useEffect(() => {
    // Attach the event listener to the document body
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  if ((is_user && student_modules.pending) || (is_teacher && teacher_courses.pending)) return <div className="StAddMenu"><Text title="Заванаження ..." /></div>
  return (
    <div className="StAddMenu">
      {view_modules.length > 0 ? view_modules.map((m: any) =>
          <div
              className={`StAddMenuFirstContainer ${
                  (module && m.id === module.id) && "ActiveModule"
              }`}
              key={uuidv4()}
          >
            <div
                className="StAddMenuFirstItem"
                onClick={() => select_modules(m)}
            >
              {prepare_module_name(m.name)}
            </div>
            {(module && m.id === module.id) && view_lessons(module.id).length > 0 && (
                <div className="StAddMenuSecondContainer">
                  {view_lessons(module.id).map((l: any) => (
                      <div
                          className={`StAddMenuSecondItem ${(l.aasm_state !== "published" && is_user) && "HiddenLesson"} ${
                              (lesson && ((l.id === lesson.id) && (is_lesson(l.name) === is_lesson(lesson.name)))) && "ActiveLesson"
                          }`}
                          onClick={() => select_lesson(l)}
                          key={uuidv4()}
                      >
                        {l.name}
                      </div>
                  ))}
                </div>
            )}
          </div>
      ) : <Text title="Модулів не знайдено" /> }
      <div className="StudentModuleSelect" ref={moduleSelectRef}>
        <span>МГ</span>
        <div className={`SelectedModule ${isModuleSelectOpen ? 'open' : ''}`} onClick={toggleSelectModules} >
          {module ? prepare_module_name_mobile(module.name) : 'Виберіть модуль'}
          <span className={`SelectedPlaceholder ${isClicked ? 'active' : ''}`}>Оберіть модуль</span>
          <div className={active ? "DigitalSelectIconReverse" : "DigitalSelectIcon"}>
            {arrowIcon}
          </div>
        </div>
        {isModuleSelectOpen && (
            <div className="ModuleOptions">
              {view_modules.length > 0 ? (
                  view_modules.map((m: any) => (
                      <div
                          className={`option ${module && m.id === module.id ? 'selected' : ''}`}
                          key={m.id}
                          onClick={() => {
                            select_modules(m);
                            handleButtonClick();
                            closeSelectModule();
                          }}
                      >
                        {prepare_module_name_mobile(m.name)}
                      </div>
                  ))
              ) : (
                  <div className="option">Модулів не знайдено</div>
              )}
            </div>
        )}
      </div>
      {view_modules.length > 0 ? view_modules.map((m: any) =>
          <div
              className={`StAddMenuFirstContainer ${
                  (module && m.id === module.id) && "ActiveModule"
              }`}
              key={uuidv4()}
          >
            <div
                className="StAddMenuFirstItemMobile"
                onClick={() => select_modules(m)}
            >
              {prepare_module_name(m.name)}
            </div>
          </div>
      ) : <Text title="Модулів не знайдено" /> }
      <div className={`StudentLessonSelect ${isLessonSelectEnabled ? 'enabled' : 'disabled'}`} ref={lessonsSelectRef}>
       <div className={`SelectedLesson ${isOpenLessons ? "open" : ''}`} onClick={toggleSelectLessons}>
          {lesson ? lesson.name : 'Оберіть урок'}
         <div className={ active ? "DigitalSelectIconReverse" : "DigitalSelectIcon" }>
           { arrowIcon }
         </div>
        </div>
        {isOpenLessons && module && view_lessons(module.id).length > 0 && (
            <div className="LessonsOptions">
              {view_lessons(module.id).map((l) => (
                  <div
                      className={`option ${
                          l.aasm_state !== 'published' && is_user ? 'hidden-lesson' : ''
                      } ${lesson && l.id === lesson.id && is_lesson(l.name) ? 'active-lesson' : ''}`}
                      key={l.id}
                      onClick={() => {
                        set_current_lesson(l);
                        closeSelectLessons();
                        select_lesson(l);
                      }}
                  >
                    {l.name}
                  </div>
              ))}
            </div>
        )}
      </div>
    </div>
  );
};
