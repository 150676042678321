import "./styles.sass";
import { FC } from "react";
import { useSelector } from "react-redux";
import { AdditionalMenuTypes } from "./types";
import { PURE_MODULES_MODE } from "../../../constants/admin";

export const AdditionalMenu: FC<AdditionalMenuTypes> = ({ module_click, lesson_or_test_click, current_module_id, current_mode }) =>
{
  const { admin_modules, admin_lessons, admin_tests } = useSelector((st: any) => st)

  const sortArr = (arr: any) => arr.sort((a: any, b: any) => a.id - b.id)
  const open_content_condition = (id: number) => current_mode !== PURE_MODULES_MODE && current_module_id === id && (admin_lessons.lessons.length > 0 || admin_tests.tests > 0)
  const prepare_items = () =>
  {
    const test_1 = admin_tests?.tests ? admin_tests.tests.find((el: any) => (el.name.toLowerCase() === "вступний тест" || el.name.toLowerCase() === "тест 1") ) : undefined
    const other_tests = (admin_tests?.tests && test_1)  ? admin_tests.tests.filter((el: any) => el.id !== test_1.id) : []
    return test_1 ? [ test_1, ...sortArr(admin_lessons.lessons), ...other_tests ] : [...sortArr(admin_lessons.lessons), ...sortArr(admin_tests.tests)]
  }

  return (
    <div className={`AdditionalMenu`}>
      <div className="ModulesContainer">
          {sortArr(admin_modules.modules).map((module: any) => (
            <div key={ module.id } className="ModuleItem">
              <div className="ModuleName" onClick={() => module_click(module)}>
                 <div className="ModuleNameText">{ module.name }</div>
              </div>
              {open_content_condition(module.id) && <div className="LessonContainer">
                {prepare_items().map((item: any) =>
                  <div
                    key={item.name + item.id}
                    className="LessonItem"
                    onClick={() => lesson_or_test_click(item)}
                  >{ item.name }</div>
                )}
              </div>}
            </div>
          ))}
      </div>
    </div>
  );
};
