import { GET_USER_INFO_PENDING, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAIL } from './../../constants/auth/admin';

const initialState = {
  id: undefined,
  full_name: undefined,
  role: undefined,
  email: undefined,
  created_at: undefined,
  updated_at: undefined,
  phone: undefined,
  gender: undefined,
  name: undefined,
  surname: undefined,
  time_zone: undefined,
  pending: false,
  error: undefined,
};

const check_undef = (obj: any, name: string) => obj?.name ? obj.name : undefined

export const user_info = (state = initialState, action: any) =>
{
  switch (action.type) {
    case GET_USER_INFO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_USER_INFO_SUCCESS:
      return {
        id: action.payload.id,
        full_name: action.payload.full_name,
        role: action.payload.role.toLowerCase(),
        email: check_undef(action.payload, "email"),
        created_at: check_undef(action.payload, "created_at"),
        updated_at: check_undef(action.payload, "updated_at"),
        phone: check_undef(action.payload, "phone"),
        gender: check_undef(action.payload, "gender"),
        name: check_undef(action.payload, "name"),
        surname: check_undef(action.payload, "surname"),
        time_zone: check_undef(action.payload, "time_zone"),
        pending: false,
        error: null,
      };
    case GET_USER_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default: return { ...state }
    }
  }