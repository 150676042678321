import { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Context } from "../../../context";
import { get_student_profile_action } from "../../../redux/actions/student/profile";
import { AvailableCourses } from "../../molecules/AvailableCourses";
import { ProfileRegistration } from "../../molecules/ProfileRegistration";
import { ProfileTop } from "../../molecules/ProfileTop";
import { StudentProfileType } from "./types";
import "./styles.sass";
import { get_teacher_profile_action } from "../../../redux/actions/teacher";

export const StudentProfile: FC<StudentProfileType> = ({ is_teacher }) => {
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const { user_id, mobile_menu, user_logo } = context
  const [selectedTab, setSelectedTab] = useState('registration'); // Initialize with the default tab
  const handleTabClick = (tab:any) => {
    setSelectedTab(tab);
  };

  useEffect(() =>
  {
    if (is_teacher) dispatch<any>(get_teacher_profile_action(user_id))
    if (!is_teacher) dispatch<any>(get_student_profile_action(user_id))
  }, [])

  useEffect(() =>
  {
    if (is_teacher) return
    const storage_logo = localStorage.getItem("user_logo")
    setContext((prev: any) => ({ ...prev, user_logo: storage_logo ?? "#шалена" }))
  }, [])

  return (
    <>
    <div className="StudentProfile StudentProfileMain_PC">
      <div className={`StudentProfileMain ${ is_teacher ? "ProfileTeacher" : "" }`}>
        <ProfileRegistration is_teacher={is_teacher} />
      </div>
      {!is_teacher && <div className="StudentProfileAdditional">
        <ProfileTop />
        <AvailableCourses />
      </div>}
    </div>
    <div className={`StudentProfile StudentProfileMain_MOBILE ${ mobile_menu ? "MENU_OPEN" : "MENU_CLOSE"}`}>
      <div className={`StudentProfileMain ${ is_teacher ? "ProfileTeacher" : "" }`}>
        {!is_teacher && <>
          <ProfileTop />
          <AvailableCourses />
        </>}
        <ProfileRegistration is_teacher={is_teacher} />
      </div>
    </div>
      <div className="MobileTabsMain">
        {/* Mobile Tabs */}
        <div className="MobileTabs">
          <div
              className={selectedTab === 'registration' ? 'MobileTabsButton active' : 'MobileTabsButton'}
              onClick={() => handleTabClick('registration')}
          >
            ПРОФІЛЬ
          </div>
          {!is_teacher && (
              <div
                  className={selectedTab === 'additional' ? 'MobileTabsButton active' : 'MobileTabsButton'}
                  onClick={() => handleTabClick('additional')}
              >
                ДОСТУПНІ МГ
              </div>
          )}
        </div>

        {/* Content Based on Selected Tab */}
        <div className="StudentProfileMobile">
          <div
              className={`StudentProfileMainMobile ${is_teacher ? 'ProfileTeacher' : ''}`}
          >
            {selectedTab === 'registration' && (
                <ProfileRegistration is_teacher={is_teacher} />
            )}
            {selectedTab === 'additional' && !is_teacher && (
                <>
                  <ProfileTop />
                  <AvailableCourses />
                </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
