import { FC } from "react"
import "./styles.sass"
import { TextFieldType } from "./types"

export const TextField: FC<TextFieldType> = ({ text, width, height }) =>
<div
  className="TextField"
  style={{
    width: width ?? "100%",
    height: height ?? "100%",
  }}
>
    { text }
</div>