import {
  GET_TEACHER_DB_PENDING,
  GET_TEACHER_DB_SUCCESS,
  GET_TEACHER_DB_FAIL,
} from "./../../constants/teacher";

const initialState = {
  exercises: [],
  pending: false,
  error: "",
};

export const teacher_db = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TEACHER_DB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TEACHER_DB_SUCCESS:
      return {
        ...state,
        exercises: action.payload,
        pending: false,
      };
    case GET_TEACHER_DB_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }
