import "./styles.sass";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context";
import {
  mainStudentLogoIcon,
  mobileStudentLogoIcon,
} from "../../../assets/icons/header";
import {
  student_menu_items,
  teacher_menu_items,
} from "../../../constants/general/menu";
import { MainMenuItemType } from "../../molecules/MainMenu/types";
import { StudentMenuType } from "./types";
import menu_decor from "../../../assets/images/student/menu_decor.png";
import { STUDENT_TASK_MODE } from "../../../constants/admin";
import { useDispatch } from "react-redux";
import { get_student_raiting_by_subject } from "../../../redux/actions/student/profile";
import { clear_storage } from "../../../Pages/UserEntry/exit";
import { useSelector } from "react-redux";
import bzz_1 from "../../../assets/images/logos/bzz_1.png";
import bzz_2 from "../../../assets/images/logos/bzz_2.png";
import bzz_3 from "../../../assets/images/logos/bzz_3.png";
import bzz_4 from "../../../assets/images/logos/bzz_4.png";
import bzz_5 from "../../../assets/images/logos/bzz_5.png";
import bzz_6 from "../../../assets/images/logos/bzz_6.png";

export const StudentMenu: FC<StudentMenuType> = ({ logo_click }) => {
  const navigate = useNavigate();

  const [context, setContext] = useContext(Context);
  const {
    mobile_menu,
    menu_item_id,
    current_mode,
    user_type,
    is_lesson_changed,
    user_full_name,
    subject,
    user_logo,
  } = context;
  const { full_name } = useSelector((st: any) => st.student_profile);
  const [subject_balls, set_subject_balls] = useState(0);

  useEffect(() => {
    setContext((prev: any) => ({ ...prev, user_full_name: full_name }));
  }, [full_name]);

  const is_user: boolean = user_type === "user";
  const is_curator: boolean = user_type === "curator";
  const is_teacher: boolean = user_type === "teacher";

  const menu_items = () => {
    if (is_user) return student_menu_items;
    if (is_teacher) return teacher_menu_items;
    return [];
  };

  const student_image = () => {
    const logo =
      user_logo.length > 0 ? user_logo : localStorage.getItem("user_logo");
    if (!logo) return bzz_1;
    if (logo === "") return bzz_1;
    if (logo === "#всезможу") return bzz_1;
    if (logo === "#душніла") return bzz_2;
    if (logo === "#кайфово") return bzz_3;
    if (logo === "#спатихочу") return bzz_4;
    if (logo === "#ботаю") return bzz_5;
    if (logo === "#упахалась") return bzz_6;
  };
  const student_tag = () => {
    const logo =
      user_logo.length > 0 ? user_logo : localStorage.getItem("user_logo");
    if (!logo) return "#всезможу";
    if (logo === "") return "#всезможу";
    if (logo === "#всезможу") return "#всезможу";
    if (logo === "#душніла") return "#душніла";
    if (logo === "#кайфово") return "#кайфово";
    if (logo === "#спатихочу") return "#спатихочу";
    if (logo === "#ботаю") return "#ботаю";
    if (logo === "#упахалась") return "#упахалась";
  };

  const change_menu_item = (item: MainMenuItemType) => {
    if (current_mode === STUDENT_TASK_MODE && is_user && is_lesson_changed)
      return setContext({
        ...context,
        lesson_pre_save_modal: true,
        stopped_action: { name: "MENU", id: item.id },
      });
    if (item.name === "вихід") {
      clear_storage();
      return navigate("/sign_in");
    }
    localStorage.setItem("menu_item", JSON.stringify(item.id));
    setContext({ ...context, menu_item_id: item.id });
  };

  const user_info_component = () => {
    const full = user_full_name && user_full_name.split(" ");
    if (is_user)
      return (
        user_full_name && (
          <div className="StudentMenuUser">
            <div>{full[0]}</div>
            <div>{full[1]}</div>
          </div>
        )
      );
    if (is_teacher)
      return (
        user_full_name && (
          <div className="TeacherMenuUser">
            <div className="TeacherMenuText">Кабінет вчителя</div>
            <div className="TeacherMenuName">{full[0]}</div>
          </div>
        )
      );
  };

  const dispatch = useDispatch();
  useEffect(() => {
    is_user &&
      subject &&
      dispatch<any>(
        get_student_raiting_by_subject(subject.id, (balls: number) => {
          set_subject_balls(balls);
        })
      );
  }, [subject]);

  return (
    <>
      <div className="StudentMenu StudentMenu_PC">
        <div className="StudentMenuLogo">
          <div className="StudentMenuLogoTopSide">
            <div className="StudentMenuLogoLeftSideCol"></div>
            <div className="StudentMenuLogoMainCol" onClick={logo_click}>
              <div className="StudentMenuLogoMainColLogo">
                {mainStudentLogoIcon}
              </div>
            </div>
            <div className="StudentMenuLogoRightSideCol">
              <div className="StudentMenuLogoRightSideColBlack" />
            </div>
          </div>
          <div className="StudentMenuLogoBottomSide"></div>
        </div>

        {is_user && (
          <>
            <div className="StudentMenuInfoBlock">
              <div className="StudentMenuInfoBalls">
                <div className="StudentMenuInfoBallsTopSide"></div>
                <div className="StudentMenuInfoBallsMain">
                  <div className="StudentMenuInfoBallsMainBalls">
                    {subject_balls}
                  </div>
                </div>
                <div className="StudentMenuInfoBallsBottomSide"></div>
              </div>
              <div className="StudentMenuInfoImage">
                <div className="StudentImage">
                  <img
                    style={{ marginRight: 60 }}
                    src={student_image()}
                    alt="student"
                  />
                </div>
              </div>
            </div>
            {user_info_component()}
          </>
        )}

        {is_teacher && user_info_component()}

        <div className="StudentMenuNavBlock">
          {menu_items().map((item: MainMenuItemType) => {
            const isActive = item.id === menu_item_id;
            return (
              <div
                className={
                  isActive
                    ? "StudentMenuNavBlockItem"
                    : "StudentMenuNavBlockItem bb"
                }
                key={item.id}
                onClick={() => change_menu_item(item)}
              >
                <div className="StudentMenuNavBlockItemTopSide" />
                <div className="StudentMenuNavBlockItemMainSideBlock">
                  <div
                    className={`StudentMenuNavBlockItemMainSideBlockItem ${
                      !isActive && "bb"
                    }`}
                  >
                    <div
                      className={`StudentMenuNavBlockItemIcon ${
                        isActive && "sb"
                      }`}
                    >
                      {item.icon}
                    </div>
                    <div
                      className={`StudentMenuNavBlockItemName ${
                        !isActive && "cw"
                      }`}
                    >
                      {item.name}
                    </div>
                  </div>
                </div>
                <div className="StudentMenuNavBlockItemBottomSide" />
              </div>
            );
          })}
        </div>
        <img src={menu_decor} alt="decor" className="StudentMenuDecor" />
      </div>
      <div className="StudentMenu_Mobile">
        <div className="StudentMenuLogoMOBILE">
          <div className="StudentMenuLogoTopSideMOBILE">
            <div className="StudentMenuLogoLeftSideColMOBILE"></div>
            <div className="StudentMenuLogoMainColMOBILE" onClick={logo_click}>
              <div className="StudentMenuLogoMainColLogoMOBILE">
                {mobileStudentLogoIcon}
              </div>
            </div>
            <div className="StudentMenuLogoRightSideColMOBILE">
              <div className="StudentMenuLogoRightSideColBlackMOBILE" />
            </div>
          </div>
          <div className="StudentMenuLogoBottomSideMOBILE"></div>
        </div>

        {is_user && (
          <>
            <div className="StudentMenuInfoBlockMOBILE">
              <div className="StudentMenuInfoBlockInfoMOBILE">
                <div className="StudentMenuInfoImageMOBILE">
                  <img src={student_image()} alt="student" />
                </div>
                <div className="StudentMenuInfoBlockNameMOBILE">
                  {user_info_component()}
                  <div className="StudentTag">{student_tag()}</div>
                </div>
              </div>
              <div className="StudentMenuInfoBallsMOBILE">
                <div className="StudentMenuInfoBallsTopSideMOBILE"></div>
                <div className="StudentMenuInfoBallsMainMOBILE">
                  <div className="StudentMenuInfoBallsMainBallsMOBILE">
                    {subject_balls}
                  </div>
                </div>
                <div className="StudentMenuInfoBallsBottomSideMOBILE"></div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="StudentMenu StudentMenu_MOBILE">
        <div className="StudentMenuNavBlock">
          {menu_items().map((item: MainMenuItemType) => {
            const isActive = item.id === menu_item_id;
            return (
              <div
                className={
                  isActive
                    ? "StudentMenuNavBlockItem"
                    : "StudentMenuNavBlockItem bb"
                }
                key={item.id}
                onClick={() => change_menu_item(item)}
              >
                <div className="StudentMenuNavBlockItemMainSideBlock">
                  <div
                    className={`StudentMenuNavBlockItemMainSideBlockItem ${
                      !isActive && "bb"
                    }`}
                  >
                    <div
                      className={`StudentMenuNavBlockItemIcon ${
                        isActive && "sb"
                      }`}
                    >
                      {item.icon}
                    </div>
                    <div
                      className={`StudentMenuNavBlockItemName ${
                        !isActive && "cw"
                      }`}
                    >
                      {item.name}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
