import axios from 'axios';
import {
  GET_STUDENT_SUBJECTS_PENDING,
  GET_STUDENT_SUBJECTS_SUCCESS,
  GET_STUDENT_SUBJECTS_FAIL,
} from "./../../constants/student";
import { requestConfig, serv } from "../../constants/config";

export const get_student_subjects_action = (user_id: number) => (dispatch: any) =>
{
  const url = `${serv}/studentpanel/subjects?user_id=${user_id}`;
  dispatch({ type: GET_STUDENT_SUBJECTS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_STUDENT_SUBJECTS_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_STUDENT_SUBJECTS_FAIL, payload: err }));
};
