import "./styles.sass";
import { FC } from "react";
import {
  hideInputText,
  showInputText,
} from "../../../assets/icons/digitalInput";
import { DigitalInputType } from "./types";

export const DigitalInput: FC<DigitalInputType> = ({
  title,
  icon,
  password,
  placeholder,
  name,
  onChange,
  value,
  error,
  withError,
  onIconClick,
  type,
  uppercase = true,
  disabled,
  margin,
  width,
}) => {
  return (
    <div
      className={`DigitalInputContainer ${
        withError && "DigitalInputWithError"
      }`}
    >
      {title && <div className="DigitalInputTitle">{title}</div>}
      <div
        className={
          disabled
            ? "DigitalInputMain DigitalInputMainDisabled"
            : "DigitalInputMain"
        }
      >
        <div className="DigitalInputMainInput">
          <input
            className={
              `InputForDigital 
              ${uppercase ? "InputForDigitalUpper" : ""}
              ${disabled ? "InputForDigitalDisabled" : ""}`
            }
            value={value}
            type={type}
            name={name ?? ""}
            placeholder={placeholder ?? ""}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        {icon && (
          <div className="DigitalInputIcon" onClick={onIconClick}>
            {icon}
          </div>
        )}
        {password && (
          <div className="DigitalInputIcon" onClick={onIconClick}>
            {type === "text" ? showInputText : hideInputText}
          </div>
        )}
      </div>
      {error && <div className={`DigitalInputError`}>{error}</div>}
    </div>
  );
};
