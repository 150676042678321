import { FC, useContext, useEffect } from "react";
import { TeacherType } from "./types";
import { Context } from "../../context";
import { TeacherCourses } from "../../Components/templates/TeacherCourses";
import { TEACHER_MODULE_MODE } from "../../constants/admin";
import { TeacherGroupInfo } from "../../Components/templates/TeacherGroupInfo";
import { AdminDB } from "../../Components/templates/AdminDB";
import { StudentProfile } from "../../Components/templates/StudentProfile";

export const Teacher: FC<TeacherType> = () => {
  const [context, setContext] = useContext(Context)
  const {menu_item_id} = context

  useEffect(() =>
  {
    setContext({
      ...context,
      current_mode: menu_item_id === 0 ? TEACHER_MODULE_MODE : undefined,
      lesson: undefined,
      module: undefined
    })
  } , [menu_item_id])

  useEffect(() =>
  {
    const menu_local = localStorage.getItem("menu_item")
    setContext({
      ...context,
      menu_item_id: menu_local ? JSON.parse(menu_local) : 0
    })
  }, [])

  return (
    <div className="Student">
      {menu_item_id === 0 && <TeacherCourses />}
      {menu_item_id === 1 && <TeacherGroupInfo />}
      {menu_item_id === 2 && <AdminDB is_teacher />}
      {menu_item_id === 3 && <StudentProfile is_teacher />}
    </div>
  );
};
