import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { mainStudentLogoIcon } from "../../../assets/icons/header";
import menu_decor from "../../../assets/images/student/menu_decor.png";
import { curator_menu_items } from "../../../constants/general/menu";
import { Context } from "../../../context";
import { clear_storage } from "../../../Pages/UserEntry/exit";
import { MainMenuItemType } from "../../molecules/MainMenu/types";
import { CuratorMenuType } from "./types";
import "./styles.sass";

export const CuratorMenu: FC<CuratorMenuType> = ({logo_click}) => {
  const navigate = useNavigate();
  const [context, setContext] = useContext(Context);
  const { menu_item_id } = context;
  const { user_full_name } = context;

  const change_menu_item = (item: any) => {
    if (item.name === "вихід") {
      clear_storage();
      return navigate("/sign_in");
    }
    localStorage.setItem("menu_item", JSON.stringify(item.id))
    setContext({ ...context, menu_item_id: item.id });
  };

  return (
    <div className="CuratorMenu">
      <div className="CuratorMenuLogo">
        <div className="CuratorMenuLogoTopSide">
          <div className="CuratorMenuLogoLeftSideCol"></div>
          <div className="CuratorMenuLogoMainCol" onClick={logo_click}>
            <div className="CuratorMenuLogoMainColLogo">
              {mainStudentLogoIcon}
            </div>
          </div>
          <div className="CuratorMenuLogoRightSideCol">
            <div className="CuratorMenuLogoRightSideColBlack" />
          </div>
        </div>
        <div className="CuratorMenuLogoBottomSide"></div>
      </div>

      <div className="CuratorMenuUser">
        <div>Кабінет куратора</div>
        <div className="CuratorMenuUserName">
          <p>{user_full_name}</p>
        </div>
      </div>

      <div className="CuratorMenuNavBlock">
        {curator_menu_items.map((item: MainMenuItemType) => {
          const isActive = item.id === menu_item_id;
          return (
            <div
              className={
                isActive
                  ? "CuratorMenuNavBlockItem"
                  : "CuratorMenuNavBlockItem bb"
              }
              key={item.id}
              onClick={() => change_menu_item(item)}
            >
              <div className="CuratorMenuNavBlockItemTopSide" />
              <div className="CuratorMenuNavBlockItemMainSideBlock">
                <div
                  className={`StudentMenuNavBlockItemMainSideBlockItem ${
                    !isActive && "bb"
                  }`}
                >
                  <div
                    className={`StudentMenuNavBlockItemIcon ${
                      isActive && "sb"
                    }`}
                  >
                    {item.icon}
                  </div>
                  <div
                    className={`StudentMenuNavBlockItemName ${
                      !isActive && "cw"
                    }`}
                  >
                    {item.name}
                  </div>
                </div>
              </div>
              <div className="CuratorMenuNavBlockItemBottomSide" />
            </div>
          );
        })}
      </div>
      <img src={menu_decor} alt="decor" className="CuratorMenuDecor" />
    </div>
  );
};
