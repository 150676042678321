import "./styles.sass";
import { FC, useRef, useState } from "react";
import { TaskTextareaType } from "./types";
import { is_json_string } from "../../../tools/common";
import { fillAddPhotoIcon, searchIcon } from "../../../assets/icons/common";

export const TaskTextarea: FC<TaskTextareaType> = ({
  name,
  name_ua,
  placeholder,
  value, small = false,
  width,
  onChange,
  disabled,
  mode = "text",
  task_id,
  photo_action
}) =>
{
  const [bigger_math, set_bigger_math] = useState(false)
  const fieldRef = useRef(null)

  const default_math_styles =
  {
    minWidth: width ? width - 20 : 400,
    maxWidth: width ? width - 20 : 400,
    minHeight: 100,
    border: "1px solid black",
  }

  const bigger_math_styles =
  {
    position: "fixed",
    top: "40%",
    left: "5vw",
    minWidth: "90vw",
    maxWidth: "90vw",
    border: "1px solid black",
    background: "white",
    zIndex: 1,
  }

  const default_math_item_styles =
  {
    minWidth: width ? width - 40 : 360,
    maxWidth: width ? width - 40 : 360,
    minHeight: 100,
  }

  const bigger_math_item_styles =
  {
    minWidth: "calc(90vw - 40px)",
    maxWidth: "calc(90vw - 40px)",
    minHeight: 100,
    overflowX: "auto",
  }

  const bigger_click = () =>
  {
    if (fieldRef?.current)
    {
      // @ts-ignore
      const elem = fieldRef.current.shadowRoot.querySelectorAll(".ML__content")[0]
      if (bigger_math)
      {
        elem.style = null
      }
      else
      {
        const sheet = new CSSStyleSheet
        sheet.replaceSync(
          ` ::-webkit-scrollbar {
              min-height: 7px;
              height: 7px;
              max-height: 7px;
              min-width: 4px;
              width: 4px;
              max-width: 4px;
            }
            ::-webkit-scrollbar-track {
              background: #f1f1f1
            }
            ::-webkit-scrollbar-thumb {
              background: #cacaca
            }
            ::-webkit-scrollbar-thumb:hover {
              background: black
            }
          `)
        // @ts-ignore
        fieldRef.current.shadowRoot.adoptedStyleSheets = [ sheet ] 
        elem.style.height = "80px"
        elem.style.overflowX = "scroll"
        elem.style.padding = "20px"
      }
    }
    set_bigger_math(!bigger_math)
  }

  return (
  <div className="TaskTextaeraContainer">
    <div className="TaskInformationName">
      { name_ua }
      { photo_action && <div>
        <label className="custom-file-upload">
          <input
            type="file"
            name={name}
            multiple
            onChange={photo_action}
          />
          {fillAddPhotoIcon}
        </label>
      </div> }
    </div>
    <div className="TaskTextareaItem">
      { mode === "text"
        ? <textarea
            placeholder={ placeholder }
            rows={ 6 }
            cols={ 72 }
            name={ name }
            className={ `TaskTextarea ${small && "TaskTextareaLess"}` }
            value={ value }
            onChange={ onChange }
            disabled={ disabled }
          />
        :
        // @ts-ignore
        <div className="TaskTextarea" style={ bigger_math ? bigger_math_styles : default_math_styles }>
        {/* @ts-ignore */}
          <math-field
            ref={ fieldRef }
            virtual-keyboard-mode="manual"
            math-mode-space = '\quad'
            style={ bigger_math ? bigger_math_item_styles : default_math_item_styles }
            className="TaskTextarea"
            data-name={ name }
            data-task_id={ task_id }
            data-type={ "field_item" }
            value={ is_json_string(value) ? JSON.parse(value) : value }
          />
          <div className={`MathBiggerIcon ${ bigger_math ? "MathBiggerIconCenter" : "" } `} onClick={ bigger_click } >
            { searchIcon }
          </div>
          </div >
      }
    </div>
  </div>
  )
}
