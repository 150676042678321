import { FC, useState } from "react";
import { info_arrow } from "../../../assets/icons/common";
import { v4 as uuidv4 } from "uuid";
import "./styles.sass";

export const TeacherGroupDropDown: FC<any> = ({ title, width, listWidth, map_array, onClick }) =>
{
  const [drop_down_expand, set_drop_down_expand] = useState(false);
  const expand_drop_down = () => set_drop_down_expand(!drop_down_expand)

  return (
    <div
      className="TeacherGroupDropDown"
      style={{
        width,
      }}
    >
      <div className="TeacherGroupDropDownMain" onClick={expand_drop_down}>
        <div className="TeacherGroupDropDownTitle">{title}</div>
        <div className="TeacherGroupDropDownExpand">
          {info_arrow}
        </div>
      </div>
      {drop_down_expand && (
        <div
          className="TeacherGroupDropDownList"
          style={{
            width: listWidth,
            minHeight: map_array.length * 23,
          }}
        >
          {map_array.map((group: any) => {
            return <h1 style={{ margin: "2px 0 2px 0", maxWidth: listWidth, overflow: "hidden" }} onClick={() => {onClick(group); expand_drop_down()}} key={uuidv4()} >{group.name}</h1>;
          })}
        </div>
      )}
    </div>
  );
};
