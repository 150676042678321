export const convert_url_to_embed = (url: string) =>
{
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}

export const convert_url_to_embed_custom = (url: string) =>
{
  const init = "https://www.youtube.com/embed/"
  const casual_id = convert_url_to_embed(url)
  if (casual_id) return init + casual_id
  const splitted = url.replace("?feature=share", "").split("/")
  return init + splitted[splitted.length -1]
}