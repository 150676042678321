// COURSES
export const GET_TEACHER_COURSES_PENDING = "GET_TEACHER_COURSES_PENDING"
export const GET_TEACHER_COURSES_SUCCESS = "GET_TEACHER_COURSES_SUCCESS"
export const GET_TEACHER_COURSES_FAIL = "GET_TEACHER_COURSES_FAIL"

// GROUP
export const GET_TEACHER_GROUP_MODULES_PENDING = "GET_TEACHER_GROUP_MODULES_PENDING"
export const GET_TEACHER_GROUP_MODULES_SUCCESS = "GET_TEACHER_GROUP_MODULES_SUCCESS"
export const GET_TEACHER_GROUP_MODULES_FAIL = "GET_TEACHER_GROUP_MODULES_FAIL"

export const GET_TEACHER_MG_USERS_PENDING = "GET_TEACHER_MG_USERS_PENDING"
export const GET_TEACHER_MG_USERS_SUCCESS = "GET_TEACHER_MG_USERS_SUCCESS"
export const GET_TEACHER_MG_USERS_FAIL = "GET_TEACHER_MG_USERS_FAIL"


// DB
export const GET_TEACHER_DB_PENDING = "GET_TEACHER_DB_PENDING"
export const GET_TEACHER_DB_SUCCESS = "GET_TEACHER_DB_SUCCESS"
export const GET_TEACHER_DB_FAIL = "GET_TEACHER_DB_FAIL"

// PROFILE
export const GET_TEACHER_PROFILE_PENDING = "GET_TEACHER_PROFILE_PENDING"
export const GET_TEACHER_PROFILE_SUCCESS = "GET_TEACHER_PROFILE_SUCCESS"
export const GET_TEACHER_PROFILE_FAIL = "GET_TEACHER_PROFILE_FAIL"
