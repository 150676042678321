import "./styles.sass";
import { FC } from "react";
import { lessonArrow_icon } from "../../../assets/icons/studentLessons";
import { TeacherHomeworkTopType } from "./types";

export const TeacherHomeworkTop: FC<TeacherHomeworkTopType> = ({ lesson_back_click, name, theme }) => {
  return (
    <div className="TeacherHomeworkTop">
      <div className="TeacherHomeworkTopTitle" onClick={lesson_back_click}>
        {lessonArrow_icon}
        <h1>
          <span className="TitleHomeworkAnnotation">ДЗ</span>
          <span className="TitleLessonNumber">{name}:</span>
          {theme}
        </h1>
      </div>
    </div>
  );
};
