import "./styles.sass"
import { FC } from "react"

export const AdminListItem: FC<any> = ({checkbox, text, group, width, height, onClick }) =>
  <div
    className="AdminListItem"
    style={{ width: width ?? "auto", height: height ?? 40}}
    onClick={onClick}
  >
    { checkbox } { text } { group }
  </div>