export const addZero = (num: number) =>
{
  let res = num.toString();
  return res.length === 1 ? "0" + res : res
};

export const prepareDate = (
  date: Date = new Date(),
  withHours: boolean = true,
  type = "normal"
) => {
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = addZero(date.getFullYear());
  if (type === "search") return `${year}/${month}/${day}`
  if (!withHours) return `${day}/${month}/${year}`;
  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());
  if (type === "split") return { date: `${day}/${month}/${year}`, time: `${hours}:${minutes}` };
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};