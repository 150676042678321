export const save_work_condition = (status: string, changed: boolean) =>
{
  switch (status)
  {
    case "created":
    case "started": return changed
    case "completed":
    case "checked": return false
  }
}

export const send_work_condition = (status: string, changed: boolean) =>
{
  switch (status)
  {
    case "created": return changed
    case "started": return true
    case "completed":
    case "checked": return false
  }
}

export const can_change_work = (status: string) =>
{
  switch (status)
  {
    case "created":
    case "started": return true
    case "completed":
    case "checked": return false
  }
}