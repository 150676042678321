import "./styles.sass";
import { FC, useState } from "react";
import { Button } from "../../atoms/Button";
import { lessonArrow_icon } from "../../../assets/icons/studentLessons";
import { save_work_condition, send_work_condition } from "../../../tools/student/work_actions";

export const LessonTop: FC<any> = ({ student_back_to_lesson, is_changed, lesson_status, lesson_theme, save_lesson, send_lesson, fail_case }) => {
  const [switchPrompt, setSwitchPrompt] = useState(false);

  const save_condition = save_work_condition(lesson_status, is_changed)
  const send_condition = send_work_condition(lesson_status, is_changed)

  return (
    <div className="LessonTopContainer">
      <div className="LessonTopMain">
        <div className="LessonTopMainTitle" onClick={student_back_to_lesson}>
          <div className="LessonTopArrow">{lessonArrow_icon}</div>
          {!fail_case && <h1>ДЗ</h1>}
          <p className={fail_case ? "ml10" : ""}>{ lesson_theme }</p>
        </div>
        {!fail_case && <div className="LessonTopMainButtons">
          <Button
            title="зберегти"
            uppercase
            rounded
            mode={save_condition ? "btn_transparent" : "btn_transparent_disabled"}
            onClick={ save_lesson }
            disabled={!save_condition}
          />
          <Button
            title="відправити"
            uppercase
            rounded
            mode={send_condition ? "btn_black" : "btn_black_disabled"}
            onClick={ send_lesson }
            disabled={!send_condition}
          />
        </div>}
      </div>
      {!fail_case && <div className="LessonTopRules">
        <h1 onClick={() => setSwitchPrompt(!switchPrompt)}>правила виконання ДЗ</h1>
      </div>}
      {switchPrompt && (
        <div className="LessonTopPrompt">
          <p>
            Дедлайну на виконання ДЗ немає, але після закінчення модуля ДЗ не
            перевіряється
          </p>
        </div>
      )}
    </div>
  );
};
