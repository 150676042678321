import { Fragment } from "react"
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "./styles.sass";

export const CuratorInvolveCourses = () => {
  const { msgroup, mspending } = useSelector((st: any) => st.curator_profile);

  if (mspending) return <h1>Завантаження</h1>

  return(
    <div className="CuratorInvolveCourses">
      {msgroup.map((cours: any) =>
        <div key={uuidv4()} className={`CuratorInvolveCoursesItem ${ cours.involved ? "mb10" : "" }`}>
          <div className={`CuratorInvolveCoursesItem_name ${ cours.involved ? "CuratorInvolveCoursesItem_active" : "" }`}>{ cours.name.replace("_", "-") }</div>
          {cours.involved &&
            <div className="CuratorInvolveCoursesItem_info_conatiner">
              {cours.student_groups.map((item: any) =>
              <div key={uuidv4()} className="CuratorInvolveCoursesItem_info_item">
                <div className="CuratorInvolveCoursesItem_group">{ item.name }</div>
                {item?.telegram_link
                  ? <a
                      className="CuratorInvolveCoursesItem_link"
                      target="_blank"
                      href={ item.telegram_link  }
                      rel="noreferrer"
                    >
                      { item.telegram_link }
                    </a>
                    : <div className="CuratorInvolveCoursesItem_link">no link</div>
                }

              </div>)}
            </div>
          }
          </div>
      )}
    </div>
  );
};
