import {
  GET_STUDENT_LESSONS_PENDING,
  GET_STUDENT_LESSONS_SUCCESS,
  GET_STUDENT_LESSONS_FAIL,
  GET_STUDENT_EXAMS_PENDING,
  GET_STUDENT_EXAMS_SUCCESS,
  GET_STUDENT_EXAMS_FAIL,
} from "./../../constants/student";

const initialState = {
  lessons: [],
  exams: [],
  pending: false,
  error: "",
};

export const student_lessons = (state = initialState, action: any) => {
  switch (action.type) {
    case "CLEAR_STUDENT_LESSONS":
      return {
        ...state,
        lessons: [],
        exams: [],
      };
    case GET_STUDENT_LESSONS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_LESSONS_SUCCESS:
      return {
        ...state,
        lessons: action.payload,
        pending: false,
      };
    case GET_STUDENT_LESSONS_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    case GET_STUDENT_EXAMS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_EXAMS_SUCCESS:
      return {
        ...state,
        exams: action.payload,
        pending: false,
      };
    case GET_STUDENT_EXAMS_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }