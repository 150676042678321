//@ts-nocheck
import "./styles.sass";
import {
  FC,
  FormEvent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button } from "../../Components/atoms/Button";
import authDecor from "../../assets/images/student/auth_decor.png";
import { DigitalInput } from "../../Components/atoms/DigitalInput";
import PDFViewer from "../../Components/atoms/PDFViewer";
import {
  entry_2bees_icon,
  entry_2bees_icon_mobile,
} from "../../assets/icons/entry";
import { UserEntryType } from "./types";
import {
  user_auth_button_title,
  user_auth_fields,
  user_auth_initials,
  user_entry_button_title,
  user_entry_fields,
  user_entry_initials,
  user_password_button_title,
  user_password_recovery_fields,
  user_password_recovery_initials,
  auth_user_variants,
} from "../../constants/auth";
import {
  email_validate,
  is_error,
  phone_validate,
} from "../../tools/validators";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  password_recovery_confirm,
  password_recovery_request_action,
  sign_in_action,
  update_user_data_action,
} from "../../redux/actions/admin";
import { DigitalSelect } from "../../Components/atoms/DigitalSelect";
import { Context } from "../../context";
import { AuthUserType } from "../Auth/types";

export const UserEntry: FC<UserEntryType> = ({ auth_form_type, is_admin }) => {
  const navigate = useNavigate();
  const pathname_info = useLocation();

  const [context, setContext] = useContext(Context);
  const [isChecked, setIsChecked] = useState(false);
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);
  const dispatch = useDispatch();

  const [finish_register_data, set_finish_register_data] = useState(undefined);

  const storage_user_type = () => localStorage.getItem("user_type");
  const get_user_type = () =>
    storage_user_type()
      ? JSON.parse(storage_user_type())
      : auth_user_variants[0];

  const [auth_user_type, set_auth_user_type] = useState<AuthUserType>(
    get_user_type()
  );

  const get_special_user_type = () =>
    storage_user_type() ? JSON.parse(storage_user_type()) : auth_user_type;

  const [is_rec_params, set_is_rec_params] = useState(false);

  const change_user_type = (variant: AuthUserType) => {
    localStorage.clear();
    localStorage.setItem("user_type", JSON.stringify(variant));
    set_auth_user_type(variant);
  };

  const current_entry_data = () => {
    if (auth_form_type === "password_recovery")
      return user_password_recovery_initials;
    if (auth_form_type === "auth") return user_auth_initials;
    if (auth_form_type === "entry") return user_entry_initials;
  };

  const current_button_title = () => {
    if (auth_form_type === "password_recovery")
      return user_password_button_title;
    if (auth_form_type === "auth") return user_auth_button_title;
    if (auth_form_type === "entry") return user_entry_button_title;
  };

  const current_user_fields = () => {
    if (auth_form_type === "password_recovery")
      return user_password_recovery_fields;
    if (auth_form_type === "auth") return user_auth_fields;
    if (auth_form_type === "entry") return user_entry_fields;
  };
  const [input_fields, set_input_fields] = useState<UserEntryTypes[] | any>(
    current_user_fields()
  );

  const [entry_data, set_entry_data] = useState<
    { [key: string]: string } | any
  >(current_entry_data());
  const [error_field, set_error_field] = useState<{
    name: string;
    message: string;
  }>({ name: "", message: "" });
  const [isValid, setIsValid] = useState<boolean | any>(false);
  const [get_error, set_get_error] = useState<boolean | any>(false);

  const validate_password_recovery_fields = (custom_data) => {
    const data = custom_data ?? entry_data;

    if (!is_rec_params) {
      if (!email_validate(data.email)) {
        set_error_field({
          name: "email",
          message: "email is not correct",
        });
        return false;
      }
    }

    if (is_rec_params) {
      if (data.password !== data.password_confirm) return false;
      const check_fields = ["password", "password_confirm"];
      for (let i = 0; i < check_fields.length; i++) {
        {
          if (data[check_fields[i]].length < 5) {
            set_error_field({
              name: check_fields[i],
              message: "too short",
            });
            return false;
          }
        }
      }
      set_error_field({
        name: "",
        message: "",
      });
    }
    return true;
  };

  const validate_recover_fields = (custom_data) => {
    const data = custom_data ?? entry_data;
    if (!email_validate(data.email)) {
      set_error_field({
        name: "email",
        message: "*unexpected character",
      });
      return false;
    }
    if (data.password.length < 7) {
      set_error_field({
        name: "password",
        message: "*should be more 7 symbols",
      });
      return false;
    }
    set_error_field({ name: "", message: "" });
    return true;
  };

  const check_register_fields = (custom_data) => {
    const data = custom_data ?? entry_data;
    const checked_fields: string[] = [
      "name",
      "surname",
      "password",
      "password_confirm",
    ];
    for (let i = 0; i < checked_fields.length; i++) {
      if (data[checked_fields[i]].length < 2) {
        set_error_field({
          name: checked_fields[i],
          message: "*should be more 2 symbols",
        });
        return false;
      }
    }
    const long_fields = ["password", "password_confirm"];
    for (let i = 0; i < long_fields.length; i++) {
      if (data[long_fields[i]].length < 7) {
        set_error_field({
          name: long_fields[i],
          message: "*should be at least 8 symbols",
        });
        return false;
      }
    }
    if (data.password !== data.password_confirm) {
      set_error_field({
        name: "password_confirm",
        message: "*passwords not the same",
      });
      return false;
    }
    set_error_field({ name: "", message: "" });
    return true;
  };

  const current_validate_function = (custom_data) => {
    if (auth_form_type === "password_recovery")
      return validate_password_recovery_fields(custom_data);
    if (auth_form_type === "auth") return check_register_fields(custom_data);
    if (auth_form_type === "entry") return validate_recover_fields(custom_data);
  };

  useEffect(() => {
    setIsValid(current_validate_function());
    set_get_error(is_error(entry_data, Object.keys(entry_data)));
  }, [entry_data]);

  useEffect(() => {
    set_entry_data(current_entry_data());
    set_input_fields(current_user_fields());
  }, [auth_form_type]);

  const input_event = (event: FormEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    const register: any = { ...entry_data };
    register[name] = value;
    set_entry_data(register);
  };

  const chnge_type = (name: string) => {
    set_input_fields(
      input_fields.map((field: UserEntryTypes) =>
        field.name === name && field.password
          ? { ...field, type: field.type === "password" ? "text" : "password" }
          : { ...field }
      )
    );
  };

  const send_data = (saved_data) => {
    if (
      pathname_info.pathname === "/finish_registration" &&
      finish_register_data
    ) {
      dispatch<any>(
        update_user_data_action(finish_register_data, saved_data ?? entry_data)
      );
      navigate("/sign_in");
    }
    if (auth_form_type === "entry") {
      dispatch<any>(
        sign_in_action(
          saved_data ?? entry_data,
          is_admin
            ? "admin"
            : saved_data
            ? get_special_user_type().en_name
            : auth_user_type.en_name,
          (status: number) => {
            if (status === 200) {
              navigate("/");
              const tm = setTimeout(() => {
                window.location.reload();
                clearTimeout(tm);
              }, 500);
            }
            if (status === 401)
              return setContext({
                ...context,
                notification_data: {
                  title: "Некорректний логін чи пароль",
                  type: "warning",
                },
              });
          }
        )
      );
    }
  };

  const redirect = (link: string) => {
    navigate(link);
  };

  useEffect(() => {
    if (pathname_info.pathname === "/password_recovery") {
      set_is_rec_params(pathname_info.search !== "");
      if (pathname_info.search !== "") {
        const user_data: { email: string } = {};
        const splitted = pathname_info.search.split("&");
        user_data.reset_password_token = splitted[0]
          .slice(1)
          .replace("reset_password_token=", "");
        user_data.user_type = splitted[1].replace("user_type=", "");
        user_data.email = splitted[2].replace("email=", "");
        set_entry_data({ ...entry_data, email: user_data.email });
        set_finish_register_data(user_data);
      }
    }
    if (
      pathname_info.pathname === "/finish_registration" &&
      !finish_register_data &&
      pathname_info.search !== ""
    ) {
      const user_data: {
        reset_password_token: string;
        user_type: string;
        email: string;
      } = {};
      const splitted = pathname_info.search.split("&");
      user_data.reset_password_token = splitted[0]
        .slice(1)
        .replace("reset_password_token=", "");
      user_data.user_type = splitted[1].replace("user_type=", "");
      user_data.email = splitted[2].replace("email=", "");
      set_entry_data({ ...entry_data, email: user_data.email });
      set_finish_register_data(user_data);
      // navigate("/finish_registration")
    }
  }, [pathname_info.pathname]);

  const current_title = () => {
    if (auth_form_type === "password_recovery") return "відновлення паролю";
    if (auth_form_type === "auth") return "завершіть реєстрацію";
    if (auth_form_type === "entry") return "вхід";
  };

  const initialize_user_type = () => {
    if (!storage_user_type()) {
      localStorage.setItem("user_type", JSON.stringify(auth_user_variants[0]));
      set_auth_user_type(auth_user_variants[0]);
    }
    set_auth_user_type(JSON.parse(storage_user_type()));
  };

  const set_browser_saved_data = () => {
    const elements = document.getElementsByClassName("InputForDigital");
    if (!elements.length) return {};
    const data = {};
    [...elements].forEach((el: any) => (data[el.name] = el.value));
    return data;
  };

  const click_listener = (event: any) => {
    if (event.keyCode !== 13) return;
    const data = set_browser_saved_data();
    const final_data = Object.values(data).length ? data : entry_data;
    if (current_validate_function(final_data)) send_data(final_data);
  };

  useEffect(initialize_user_type, []);

  useEffect(() => {
    window.addEventListener("keydown", click_listener);
    return () => {
      window.removeEventListener("keydown", click_listener);
    };
  }, []);

  const rec_fields = is_rec_params
    ? input_fields.filter(
        (el: any) => el.type === "password" || el.type === "text"
      )
    : [input_fields[0]];

  const send_changed_password = () => {
    password_recovery_confirm(
      entry_data.password,
      finish_register_data.reset_password_token,
      finish_register_data.user_type,
      (is_ok) => {
        setContext({
          ...context,
          notification_data: {
            title: is_ok ? "Пароль змінено" : "щось пішло не так",
            type: is_ok ? "success" : "warning",
          },
        });
        const tm = setTimeout(() => {
          navigate("/sign_in");
          clearTimeout(tm);
        }, 1200);
      }
    );
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const send_email_for_reset_pass = () => {
    password_recovery_request_action(entry_data.email, (is_ok) => {
      setContext({
        ...context,
        notification_data: {
          title: is_ok ? "Перевірте пошту" : "щось пішло не так",
          type: is_ok ? "success" : "warning",
        },
      });
    });
  };
  return (
    <div className="UserEntry">
      {pathname_info.pathname !== "/" && localStorage.getItem("token") && (
        <div onClick={() => navigate("/")} className="ComeBackElement">
          <div>Ви є в системі та можете повернутись назад клікнувши сюди</div>
        </div>
      )}
      <div className="UserEntryTop">
        <h2>Навчальна платформа</h2>
        {entry_2bees_icon}
      </div>

      <h1 className="UserEntryGeneralTitle">{current_title()}</h1>
      {auth_form_type === "password_recovery" && (
        <div className="PasswordRecoveryMain" style={{ margin: "30px 0 0 0" }}>
          <div className="PasswordRecoveryMainInputs">
            {rec_fields.map((inp: UserEntryTypes) => (
              <Fragment key={inp.id}>
                <DigitalInput
                  error={
                    error_field.name === inp.name && get_error
                      ? error_field.message
                      : undefined
                  }
                  title={inp.title}
                  password={inp.password}
                  name={inp.name}
                  type={inp.type}
                  onChange={input_event}
                  onIconClick={() => chnge_type(inp.name)}
                  withError
                  // @ts-ignore
                  value={entry_data[inp.name]}
                  uppercase={false}
                  disabled={is_rec_params ? inp.name === "email" : inp.disabled}
                />
                {!is_rec_params && (
                  <Button
                    title="надіслати код"
                    mode={isValid ? "btn_black" : "btn_gray"}
                    size="btn_normal"
                    width={100}
                    disabled={!isValid}
                    onClick={send_email_for_reset_pass}
                    uppercase
                    rounded
                    margin="20px 0 15px 0"
                  />
                )}
              </Fragment>
            ))}
            <h1
              className="UserEntryForgetPasswordTitle"
              onClick={() => {
                redirect("/sign_in");
              }}
            >
              Повернутись до входу
            </h1>
          </div>
        </div>
      )}
      {auth_form_type === "auth" && (
        <div className="UserAuthMain">
          <div className="UserAuthMainInputs">
            {input_fields.map((inp: UserEntryTypes) => (
              <Fragment key={inp.id}>
                <DigitalInput
                  error={
                    error_field.name === inp.name && get_error
                      ? error_field.message
                      : undefined
                  }
                  title={inp.title}
                  password={inp.password}
                  name={inp.name}
                  type={inp.type}
                  onChange={input_event}
                  onIconClick={() => chnge_type(inp.name)}
                  withError
                  // @ts-ignore
                  value={entry_data[inp.name]}
                  uppercase={false}
                  disabled={inp.disabled}
                  width="340px"
                />
              </Fragment>
            ))}
            <label className="terms-checkbox">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                z
              />
              <div className="checkbox-label">
                <p>Я погоджуюсь з </p>
                <a className="terms-link" href="/terms.pdf" target="_blank">
                  Публічною офертою
                </a>
              </div>
            </label>
          </div>
        </div>
      )}
      {auth_form_type === "entry" && (
        <div className="UserEntryMain">
          <div className="UserEntryMainInputs">
            {!is_admin && (
              <DigitalSelect
                value={auth_user_type}
                options={auth_user_variants}
                setValue={change_user_type}
                borderRadius={20}
                width="95%"
                margin="0 0 20px 0"
              />
            )}
            {input_fields.map((inp: UserEntryTypes) => (
              <Fragment key={inp.id}>
                <DigitalInput
                  error={
                    error_field.name === inp.name && get_error
                      ? error_field.message
                      : undefined
                  }
                  title={inp.title}
                  password={inp.password}
                  name={inp.name}
                  type={inp.type}
                  onChange={input_event}
                  onIconClick={() => chnge_type(inp.name)}
                  withError
                  // @ts-ignore
                  value={entry_data[inp.name]}
                  uppercase={false}
                  disabled={inp.disabled}
                />
              </Fragment>
            ))}
            <label className="terms-checkbox">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                z
              />
              <div className="checkbox-label">
                <p>Я погоджуюсь з </p>
                <a className="terms-link" href="/terms.pdf" target="_blank">
                  Публічною офертою
                </a>
              </div>
            </label>
            <h1
              className="UserEntryForgetPasswordTitle"
              onClick={() => {
                redirect("/password_recovery");
              }}
            >
              Не пам’ятаю пароль
            </h1>
          </div>
        </div>
      )}
      <div className="UserEntryButtonConatiner">
        {auth_form_type === "password_recovery" && is_rec_params && (
          <Button
            title={"ЗМІНИТИ ПАРОЛЬ"}
            mode={isValid ? "btn_black" : "btn_gray"}
            size="btn_normal"
            disabled={!isValid}
            onClick={send_changed_password}
            uppercase
            rounded
            margin="40px 0 0 0"
          />
        )}
        {auth_form_type !== "password_recovery" && (
          <Button
            title={current_button_title()}
            mode={isValid && isChecked ? "btn_black" : "btn_gray"}
            size="btn_normal"
            disabled={!isValid && !isChecked}
            onClick={() => send_data()}
            uppercase
            rounded
            margin="40px 0 0 0"
          />
        )}
        {pathname_info.pathname === "/finish_registration" && (
          <Button
            title={"я вже маю профіль"}
            mode={"btn_transparent"}
            size="btn_normal"
            onClick={() => navigate("/sign_in")}
            uppercase
            rounded
            margin="40px 0 0 0"
          />
        )}
      </div>
      <div
        className="UserEntryBottom"
        style={{
          backgroundImage: `url(${authDecor})`,
          backgroundPositionY: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );
};
