import axios from 'axios';
import {
  GET_STUDENT_PROFILE_PENDING,
  GET_STUDENT_PROFILE_SUCCESS,
  GET_STUDENT_PROFILE_FAIL,
} from "./../../constants/student";
import { requestConfig, serv } from "../../constants/config";

export const get_student_profile_action = (user_id: number) => (dispatch: any) =>
{
  const url = `${serv}/studentpanel/users/${user_id}`;
  dispatch({ type: GET_STUDENT_PROFILE_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_STUDENT_PROFILE_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_STUDENT_PROFILE_FAIL, payload: err }));
};

export const patch_student_profile_action = (user_id: number, data: { [key: string] : string }, callback?: (is_ok: boolean) => void) => (dispatch: any) =>
{
  const url = `${serv}/studentpanel/users/${user_id}`;
  const body = { user: data }
  axios
    .patch(url, body, requestConfig())
    .then(() =>
    {
      dispatch(get_student_profile_action(user_id))
      callback && callback(true)
    }).
    catch(() => { callback && callback(false) } )
};

export const get_student_raiting_by_subject = (subject_id: number, callback: (balls: number) => void) => (dispatch: any) =>
{
  const url = `${serv}/studentpanel/users/subject_rating?subject_id=${subject_id}`;
  axios
  .get(url, requestConfig())
  .then((res: any) => callback(res?.data?.subject_rating ? res.data.subject_rating : 0))
}
