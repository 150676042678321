export const apply_math_styles = () =>
{
  const english = /^[A-Za-z0-9]*$/
  const tm = setTimeout(() =>
  {
    const fields = document.getElementsByTagName("math-field")
    if (!fields.length) return
    Array.from(fields).forEach((el: any) =>
    {
      const decr_elements = el.shadowRoot.querySelectorAll("span")
      Array.from(decr_elements).forEach((s: any) =>
      {
        if (s.childElementCount) return
        if (english.test(s.innerText)) return
        s.style = "font-family: 'TT Firs Neue'; font-style: normal; font-weight: 500; color: rgb(0,0,0)"
      })
    })
    clearTimeout(tm)
  }, 100)
}