import "./styles.sass"
import { FC, useContext } from "react"
import { AdminStudentsType } from "./types"
import { StudentTableBody } from "../../molecules/StudentTableBody"

export const AdminStudents: FC<AdminStudentsType> = () =>
{
  return (
    <div className="AdminCoursesContainer">
      <div className="AdminCourses">
        <StudentTableBody />
      </div>
    </div>
  )
}