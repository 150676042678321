import "./styles.sass";
import React, { FC, useContext, useEffect, useState } from "react";
import { Input } from "../../atoms/Input";
import { Button } from "../../atoms/Button";
import { useSelector } from "react-redux";
import { SimpleInput } from "../../atoms/SimpleInput";
import { Context } from "../../../context";
import { useDispatch } from "react-redux";
import { generate_module } from "../../../tools/admin/education_initials";
import { ADD_MODULE_MODE, PURE_MODULES_MODE } from "../../../constants/admin";
import { get_curators_action, get_module_groups_action, patch_module_action, post_module_action, add_module_groups_action, patch_curator_groups_action, delete_curator_groups, delete_module_group_action } from "../../../redux/actions/admin";
import { DateInput } from "../../molecules/DateInput";
import { DatePosition } from "../../molecules/DateInput/types";
import { ModuleGroupType, ModuleInfoItemType } from "./types";
import { Select } from "../../atoms/Select";
import { addIcon, deleteIcon, plusIcon } from "../../../assets/icons/common";
import { ClickAwayListener } from "../../../tools/ClickAway";

interface GroupListsType
{
  [key: string]: number[]
}

export const AdminModuleInfo: FC = () =>
{
  
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const [showCalendar, setShowCalendar] = useState({ start_date: false, finish_date: false });
  const [isModuleChanged, setIsModuleChanged] = useState(false);
  const [isGroupsChanged, setIsGroupsChanged] = useState(false);
  
  const { curators } = useSelector((st: any) => st.admin_users)
  const { modules, module_groups } = useSelector((st: any) => st.admin_modules)
  const { current_mode, module, subject } = context

  const addMode = current_mode === ADD_MODULE_MODE

  const [current_groups, set_current_groups] = useState(addMode ? [] : module_groups)
  const [module_init, set_module_init] = useState(module)
  const [groups_list, set_groups_list] = useState<GroupListsType>({
    added: [], changed: [], deleted: []
  })


  const add_group = () =>
  {
    const new_id = current_groups.length ? Math.max(...current_groups.map((el: any) => el.id)) + 1 : 1
    set_current_groups([ ...current_groups, { id: new_id, telegram_link: "" } ])
    set_groups_list({ ...groups_list, added: [ ...groups_list.added, new_id ] })
  }

  const delete_group = (group_id: number) =>
  {
    set_current_groups(current_groups.filter((gr: any) => gr.id !== group_id))
    set_groups_list({ ...groups_list, deleted: [ ...groups_list.deleted, group_id ] })
  }

  const change_module_item = (name: string, value: string) =>
  {
    // if (name === "groups") groupsInput(value)
    const new_module = { ...module_init }
    new_module[name] = value
    set_module_init(new_module)
  }

  const change_module_term = (type: "start_date" | "finish_date", date: Date) =>
  {
    const new_module = { ...module_init }
    new_module[type] = date.toISOString()
    set_module_init(new_module)
  }

  const add_group_func = (arr: any, iterator: number = 0) =>
  {
    const element = arr[iterator]
    const group_obj = current_groups.filter((gr: any) => gr.id === element)[0]
    group_obj.master_group_id =  module.id
    delete group_obj.id
    delete group_obj.curator_name
    if (!group_obj.curator_id) group_obj.curator_id = null
    dispatch<any>(add_module_groups_action(group_obj, () =>
    {
      if (iterator + 1 === arr.length) return
      add_group_func(arr, iterator + 1)
    }))
  }

  const patch_group_fucn = (el: number) =>
  {
    const group_obj = current_groups.filter((gr: any) => gr.id === el)[0]
    group_obj.master_group_id = module.id
    delete group_obj.curator_name
    if (!group_obj.curator_id) group_obj.curator_id = null
    dispatch<any>(patch_curator_groups_action(group_obj))
  }

  const save_module = () =>
  {
    if (isModuleChanged)
    {
      !addMode && dispatch<any>(patch_module_action(module_init, subject.id))
      addMode && dispatch<any>(post_module_action(module_init, subject.id, (id: number | undefined) =>
      {
        if (id && current_groups.length) current_groups.forEach((gr: any) =>  dispatch<any>(add_module_groups_action({ master_group_id: id, curator_id: gr?.curator_id ? gr.curator_id : null, telegram_link: gr.telegram_link, id: gr.id})))
      }))
      setIsModuleChanged(false)
    }
    setContext({ ...context, current_mode: PURE_MODULES_MODE, module: null })
    if (!isGroupsChanged || addMode) return
    setIsGroupsChanged(false)
    if (groups_list.changed.length > 0 && groups_list.deleted.length > 0)
    {
      const pure_changed = []
      for (let id of groups_list.changed)
      {
        if (groups_list.deleted.indexOf(id) === -1) pure_changed.push(id)
      }
      if (pure_changed.length > 0) pure_changed.forEach(patch_group_fucn)
    } else if (groups_list.changed.length > 0) groups_list.changed.forEach(patch_group_fucn)
    if (groups_list.added.length > 0 && groups_list.deleted.length > 0)
    {
      const pure_added = []
      for (let id of groups_list.added)
      {
        if (groups_list.deleted.indexOf(id) === -1) pure_added.push(id)
      }
      if (pure_added.length > 0) add_group_func(pure_added)
    } else if (groups_list.added.length > 0) add_group_func(groups_list.added)
    if (groups_list.deleted.length > 0)
    {
      groups_list.deleted.forEach((el: number) => dispatch<any>(delete_module_group_action(el)))
    }
  }

  const select_module_term = (type: DatePosition, date: Date) =>
  {
    setShowCalendar({ start_date: false, finish_date: false });
    change_module_term(type, date);
    setIsModuleChanged(!addMode ?? module[type] !== module_init[type]);
  }

  const check_groups_change = () =>
  {
    if (module_groups.length !== current_groups.length) return setIsGroupsChanged(true)
    for (let i = 0; i < current_groups.length; i++)
    {
      const checked_items = [ "telegram_link", "curator_name", "name" ]
      for (let a = 0; a < checked_items.length; a++)
      {
        if (!module_groups[i]?.[checked_items[a]] && current_groups[i]?.[checked_items[a]]) return setIsGroupsChanged(true)
        if (module_groups[i]?.[checked_items[a]] && !current_groups[i]?.[checked_items[a]]) return setIsGroupsChanged(true)
        if (module_groups[i][checked_items[a]] != current_groups[i][checked_items[a]]) return setIsGroupsChanged(true)
      }
    }
    return setIsGroupsChanged(false)
  }

  const check_module_change = () =>
  {
    if (current_mode === ADD_MODULE_MODE) return setIsModuleChanged(true);
    setIsModuleChanged(
      module_init.name.trim() !== module.name ||
      module_init.start_date !== module.start_date ||
      module_init.finish_date !== module.finish_date
    );
  };

  const check_global = () =>
  {
    check_module_change()
    check_groups_change()
  }

  // check changes
  useEffect(check_global, [module_init, current_groups])

  useEffect(() => { set_module_init(module)}, [module])

  useEffect(() => { set_current_groups([]); set_groups_list({added: [], changed: [], deleted: []}) }, [])
  useEffect(() =>
  {
    dispatch<any>(get_curators_action(subject.id))
    !addMode && dispatch<any>(get_module_groups_action(module.id))
    addMode && set_module_init(generate_module(subject.id, modules.length))
    
  }, [addMode, module]);

  useEffect(() =>
  {
    set_current_groups(addMode ? [] : module_groups)
  }, [module_groups]);

  const date_input_props =
  {
    showCalendar,
    setShowCalendar,
    module_init,
    select_module_term,
  };

  const select_group_info = (group_id: number, type: "curator_name" | "telegram_link" | "name", value: string, curator_id?: number | null) =>
  {
    set_current_groups(current_groups.map((g: any) => g.id === group_id ? ({...g, [type]: value, curator_id: curator_id !== undefined ? curator_id : g.curator_id }) : ({...g})))
    if (groups_list.added.indexOf(group_id) === -1 && groups_list.changed.indexOf(group_id) === -1) set_groups_list({ ...groups_list, changed: groups_list.added.filter((c: number) => c !== group_id).length === 0 ? [ ...groups_list.changed, group_id ] : [ ...groups_list.changed ] })
  }


  const ModuleInfoItem = ({ title, placeholder, item, value }: ModuleInfoItemType) =>
  {
    const is_group = title === "Групи"
    return (
    <div className="ModuleSelectContainer">
      <div className="ModuleActionName">{ title }</div>
      <div className="ModuleActionSelector">
        <Input
          value={module_init !== null && module_init[item] !== null ? item === "groups" ? value : module_init[item] : ""}
          placeholder={placeholder}
          height={40}
          width={ is_group ? 72 : 90}
          onChange={(e) => is_group ? {} : change_module_item(item, e.target.value)}
          type="number"
          disabled={is_group}
        />
        { is_group && <div className="ModuleActionSelectorIcon" onClick={add_group} >{ addIcon }</div> }
      </div>
    </div>
    )
  }

  return (
    <div className="AdminModuleInfo">
      <div className="Moduleselect_subject">
        <div className="ModuleSelectContainer">
          <div className="ModuleActionName">Назва</div>
          <div className="ModuleActionSelector">
            <SimpleInput
              placeholder="MG name"
              value={ module_init?.name ?? "НОВА МГ" }
              onChange={ (e: any) => change_module_item("name", e.target.value) }
              height={40}
              font={{ fontSize: 15 }}
            />
          </div>
        </div>
        <ClickAwayListener onClickAway={() => showCalendar.start_date && setShowCalendar({ ...showCalendar, start_date: false })}>
        <div className="ModuleSelectContainer">
          <div className="ModuleActionName">Початок</div>
          <div
            className="ModuleActionSelector"
            onClick={() => { setShowCalendar({ start_date: true, finish_date: false }) }}
          >
            <DateInput {...{ ...date_input_props, type: "start_date" }} />
          </div>
        </div>
        </ClickAwayListener>
      </div>
      <div className="Moduleselect_subject">
        {addMode
          ? <ModuleInfoItem title="Уроки" placeholder="?" item="lessons_count" />
          : <ModuleInfoItem title="Групи" value={current_groups.length} placeholder="0" item="groups" />}
        <ClickAwayListener onClickAway={() => showCalendar.finish_date && setShowCalendar({ ...showCalendar, finish_date: false })}>
        <div className="ModuleSelectContainer">
          <div className="ModuleActionName">Закінчення</div>
          <div
            className="ModuleActionSelector"
            onClick={() => { setShowCalendar({ start_date: false, finish_date: true }) }}
          >
            <DateInput {...{ ...date_input_props, type: "finish_date" }} />
          </div>
        </div>
        </ClickAwayListener>
      </div>

      {addMode && (
        <div className="Moduleselect_subject">
          <ModuleInfoItem title="Групи" placeholder="?" item="groups" />
          <ModuleInfoItem title="Тести" placeholder="?" item="exams_count" />
        </div>
      )}

      {current_groups.length > 0 && current_groups.sort((a: any, b: any) => a.id - b.id).map((el: any) =>
        <div className="ModuleInputContainer" key={ el.id }>
          <Input
            placeholder={"ТГ Група ..."}
            onChange={ (event) => select_group_info(el.id, "name", event.target.value) }
            value={el.name}
            height={35}
            width={19}
            margin="5px 13px 0 10px"
          />
          <Input
            placeholder={ el.telegram_link === "" ? "https://t.me/telegrafdesign" : el.telegram_link }
            onChange={ (event) => select_group_info(el.id, "telegram_link", event.target.value) }
            height={35}
            width={48}
            margin="5px 0 0 0"
          />
          <Select
            value={ el?.curator_name ?? "без куратора .." }
            setValue={ (val) => select_group_info(el.id, "curator_name", val.name, val.id) }
            options={ curators.length > 0 ? [ ...curators.map((el: any) => ({ id: el.id, name: (el.name && el.surname) ? el.name + " " + el.surname : el.name })), { id: null, name: "без куратора" }] : [{ id: null, name: "без куратора" }]}
            defaultBorder
            width={120}
            margin="0 0 0 30px"
          />
          <div className="ModuleActionDelete" onClick={() => delete_group(el.id)}>{ deleteIcon }</div>
        </div>
      )}
      <Button
        title="зберегти"
        onClick={ save_module }
        uppercase
        width={50}
        margin="15px 0 0 30px"
        mode={ isModuleChanged || isGroupsChanged ? "btn_black" : "btn_gray" }
        disabled={ !isModuleChanged && !isGroupsChanged }
      />
    </div>
  );
};
