import {
  GET_SUBJECTS_PENDING,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  CREATE_SUBJECT_PENDING,
  CREATE_SUBJECT_SUCCESS,
  CREATE_SUBJECT_FAIL,
  GET_BOUGHT_SUBJECTS_PENDING,
  GET_BOUGHT_SUBJECTS_SUCCESS,
  GET_BOUGHT_SUBJECTS_FAIL,
} from "../../constants/admin";
import axios from 'axios';
import { requestConfig, serv } from "../../constants/config";

export const get_subjects_action = () => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/subjects`;
  dispatch({ type: GET_SUBJECTS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_SUBJECTS_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_SUBJECTS_FAIL, payload: err }));
};

export const post_subject_action = (name: string) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/subjects`;
  const body = { subject: { name } };
  dispatch({ type: CREATE_SUBJECT_PENDING });
  axios
    .post(url, body, requestConfig())
    .then((res: any) => dispatch({ type: CREATE_SUBJECT_SUCCESS, payload: res.data }))
    .then(() => dispatch(get_subjects_action()))
    .catch((err: any) => dispatch({ type: CREATE_SUBJECT_FAIL, payload: err }));
};

// `GET http://localhost:3000/api/v1/adminpanel/users/bought_subjects?user_id=1`

export const get_bought_subjects_action = (user_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/users/bought_subjects?id=${user_id}`;
  dispatch({ type: GET_BOUGHT_SUBJECTS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_BOUGHT_SUBJECTS_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_BOUGHT_SUBJECTS_FAIL, payload: err }));
}