import "./styles.sass";
import { FC } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { prepareDate } from "../../../tools/admin/date";
import { calendarIcon } from "../../../assets/icons/common";
import { DateInputType } from "./types";
import { SimpleInput } from "../../atoms/SimpleInput";

export const DateInput: FC<DateInputType> = ({
  type,
  showCalendar,
  module_init,
  select_module_term,
}) =>
        <div
          className="DateInput"
        >
            <SimpleInput
              icon={calendarIcon}
              width={100000}
              //@ts-ignore
              placeholder={prepareDate(new Date(module_init?.[type] ?? new Date()), false)}
              disabled
            />
            <ReactDatePicker
              customInput={<input style={{ display: "none" }} />}
              selected={new Date(module_init?.[type] ?? new Date())}
              onChange={(date: Date) => select_module_term(type, date)}
              open={showCalendar[type]}
              showTimeSelect
            />
        </div>
