export const closeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4099 11.9999L17.7099 7.70994C17.8982 7.52164 18.004 7.26624 18.004 6.99994C18.004 6.73364 17.8982 6.47825 17.7099 6.28994C17.5216 6.10164 17.2662 5.99585 16.9999 5.99585C16.7336 5.99585 16.4782 6.10164 16.2899 6.28994L11.9999 10.5899L7.70994 6.28994C7.52164 6.10164 7.26624 5.99585 6.99994 5.99585C6.73364 5.99585 6.47824 6.10164 6.28994 6.28994C6.10164 6.47825 5.99585 6.73364 5.99585 6.99994C5.99585 7.26624 6.10164 7.52164 6.28994 7.70994L10.5899 11.9999L6.28994 16.2899C6.19621 16.3829 6.12182 16.4935 6.07105 16.6154C6.02028 16.7372 5.99414 16.8679 5.99414 16.9999C5.99414 17.132 6.02028 17.2627 6.07105 17.3845C6.12182 17.5064 6.19621 17.617 6.28994 17.7099C6.3829 17.8037 6.4935 17.8781 6.61536 17.9288C6.73722 17.9796 6.86793 18.0057 6.99994 18.0057C7.13195 18.0057 7.26266 17.9796 7.38452 17.9288C7.50638 17.8781 7.61698 17.8037 7.70994 17.7099L11.9999 13.4099L16.2899 17.7099C16.3829 17.8037 16.4935 17.8781 16.6154 17.9288C16.7372 17.9796 16.8679 18.0057 16.9999 18.0057C17.132 18.0057 17.2627 17.9796 17.3845 17.9288C17.5064 17.8781 17.617 17.8037 17.7099 17.7099C17.8037 17.617 17.8781 17.5064 17.9288 17.3845C17.9796 17.2627 18.0057 17.132 18.0057 16.9999C18.0057 16.8679 17.9796 16.7372 17.9288 16.6154C17.8781 16.4935 17.8037 16.3829 17.7099 16.2899L13.4099 11.9999Z"
      fill="black"
    />
  </svg>
);

export const calendarIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 11H9V13H7V11ZM7 15H9V17H7V15ZM11 11H13V13H11V11ZM11 15H13V17H11V15ZM15 11H17V13H15V11ZM15 15H17V17H15V15Z"
      fill="black"
    />
    <path
      d="M5 22H19C20.103 22 21 21.103 21 20V6C21 4.897 20.103 4 19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V20C3 21.103 3.897 22 5 22ZM19 8L19.001 20H5V8H19Z"
      fill="black"
    />
  </svg>
);

export const searchIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99961 2.5C11.3976 2.49994 12.7678 2.89061 13.9555 3.62792C15.1432 4.36523 16.1012 5.41983 16.7214 6.6727C17.3416 7.92558 17.5993 9.32686 17.4654 10.7184C17.3315 12.11 16.8113 13.4364 15.9636 14.548L20.7066 19.293C20.886 19.473 20.9901 19.7144 20.9978 19.9684C21.0056 20.2223 20.9164 20.4697 20.7484 20.6603C20.5803 20.8508 20.3461 20.9703 20.0931 20.9944C19.8402 21.0185 19.5876 20.9454 19.3866 20.79L19.2926 20.707L14.5476 15.964C13.6006 16.6861 12.4953 17.1723 11.323 17.3824C10.1508 17.5925 8.94543 17.5204 7.80661 17.1721C6.66778 16.8238 5.62826 16.2094 4.77406 15.3795C3.91986 14.5497 3.27555 13.5285 2.89443 12.4002C2.51332 11.2719 2.40636 10.0691 2.5824 8.89131C2.75844 7.7135 3.21243 6.59454 3.9068 5.62703C4.60117 4.65951 5.51595 3.87126 6.57545 3.32749C7.63495 2.78372 8.80871 2.50006 9.99961 2.5ZM9.99961 4.5C8.54091 4.5 7.14197 5.07946 6.11052 6.11091C5.07907 7.14236 4.49961 8.54131 4.49961 10C4.49961 11.4587 5.07907 12.8576 6.11052 13.8891C7.14197 14.9205 8.54091 15.5 9.99961 15.5C11.4583 15.5 12.8572 14.9205 13.8887 13.8891C14.9201 12.8576 15.4996 11.4587 15.4996 10C15.4996 8.54131 14.9201 7.14236 13.8887 6.11091C12.8572 5.07946 11.4583 4.5 9.99961 4.5Z"
      fill="black"
    />
  </svg>
);

export const fillSelectIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 5C7.24 5 5 7.24 5 10C5 12.76 7.24 15 10 15C12.76 15 15 12.76 15 10C15 7.24 12.76 5 10 5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
      fill="black"
    />
  </svg>
);

export const emptySelectIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
      fill="black"
    />
  </svg>
);

export const successSelectIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99967 13.6666C3.31767 13.6666 0.333008 10.682 0.333008 6.99998C0.333008 3.31798 3.31767 0.333313 6.99967 0.333313C10.6817 0.333313 13.6663 3.31798 13.6663 6.99998C13.6663 10.682 10.6817 13.6666 6.99967 13.6666ZM6.99967 12.3333C8.41416 12.3333 9.77072 11.7714 10.7709 10.7712C11.7711 9.77102 12.333 8.41447 12.333 6.99998C12.333 5.58549 11.7711 4.22894 10.7709 3.22874C9.77072 2.22855 8.41416 1.66665 6.99967 1.66665C5.58519 1.66665 4.22863 2.22855 3.22844 3.22874C2.22824 4.22894 1.66634 5.58549 1.66634 6.99998C1.66634 8.41447 2.22824 9.77102 3.22844 10.7712C4.22863 11.7714 5.58519 12.3333 6.99967 12.3333ZM6.99967 10.3333C6.11562 10.3333 5.26777 9.98212 4.64265 9.357C4.01753 8.73188 3.66634 7.88403 3.66634 6.99998C3.66634 6.11592 4.01753 5.26808 4.64265 4.64296C5.26777 4.01784 6.11562 3.66665 6.99967 3.66665C7.88373 3.66665 8.73158 4.01784 9.3567 4.64296C9.98182 5.26808 10.333 6.11592 10.333 6.99998C10.333 7.88403 9.98182 8.73188 9.3567 9.357C8.73158 9.98212 7.88373 10.3333 6.99967 10.3333Z"
      fill="#14AE5C"
    />
  </svg>
);

export const successEmptyIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 9.15527e-05C2.98667 9.15527e-05 0 2.98676 0 6.66676C0 10.3468 2.98667 13.3334 6.66667 13.3334C10.3467 13.3334 13.3333 10.3468 13.3333 6.66676C13.3333 2.98676 10.3467 9.15527e-05 6.66667 9.15527e-05ZM6.66667 12.0001C3.72 12.0001 1.33333 9.61342 1.33333 6.66676C1.33333 3.72009 3.72 1.33342 6.66667 1.33342C9.61333 1.33342 12 3.72009 12 6.66676C12 9.61342 9.61333 12.0001 6.66667 12.0001Z"
      fill="#14AE5C"
    />
  </svg>

)

export const errorSelectIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99967 13.6666C3.31767 13.6666 0.333008 10.682 0.333008 6.99998C0.333008 3.31798 3.31767 0.333313 6.99967 0.333313C10.6817 0.333313 13.6663 3.31798 13.6663 6.99998C13.6663 10.682 10.6817 13.6666 6.99967 13.6666ZM6.99967 12.3333C8.41416 12.3333 9.77072 11.7714 10.7709 10.7712C11.7711 9.77102 12.333 8.41447 12.333 6.99998C12.333 5.58549 11.7711 4.22894 10.7709 3.22874C9.77072 2.22855 8.41416 1.66665 6.99967 1.66665C5.58519 1.66665 4.22863 2.22855 3.22844 3.22874C2.22824 4.22894 1.66634 5.58549 1.66634 6.99998C1.66634 8.41447 2.22824 9.77102 3.22844 10.7712C4.22863 11.7714 5.58519 12.3333 6.99967 12.3333ZM6.99967 10.3333C6.11562 10.3333 5.26777 9.98212 4.64265 9.357C4.01753 8.73188 3.66634 7.88403 3.66634 6.99998C3.66634 6.11592 4.01753 5.26808 4.64265 4.64296C5.26777 4.01784 6.11562 3.66665 6.99967 3.66665C7.88373 3.66665 8.73158 4.01784 9.3567 4.64296C9.98182 5.26808 10.333 6.11592 10.333 6.99998C10.333 7.88403 9.98182 8.73188 9.3567 9.357C8.73158 9.98212 7.88373 10.3333 6.99967 10.3333Z"
      fill="#FF1300"
    />
  </svg>
);

export const addIcon = (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 4.5C9.69891 4.5 9.88968 4.57902 10.0303 4.71967C10.171 4.86032 10.25 5.05109 10.25 5.25V8.25H13.25C13.4489 8.25 13.6397 8.32902 13.7803 8.46967C13.921 8.61032 14 8.80109 14 9C14 9.19891 13.921 9.38968 13.7803 9.53033C13.6397 9.67098 13.4489 9.75 13.25 9.75H10.25V12.75C10.25 12.9489 10.171 13.1397 10.0303 13.2803C9.88968 13.421 9.69891 13.5 9.5 13.5C9.30109 13.5 9.11032 13.421 8.96967 13.2803C8.82902 13.1397 8.75 12.9489 8.75 12.75V9.75H5.75C5.55109 9.75 5.36032 9.67098 5.21967 9.53033C5.07902 9.38968 5 9.19891 5 9C5 8.80109 5.07902 8.61032 5.21967 8.46967C5.36032 8.32902 5.55109 8.25 5.75 8.25H8.75V5.25C8.75 5.05109 8.82902 4.86032 8.96967 4.71967C9.11032 4.57902 9.30109 4.5 9.5 4.5Z"
      fill="black"
    />
    <path
      d="M0.5 9C0.5 7.8181 0.732792 6.64778 1.18508 5.55585C1.63738 4.46392 2.30031 3.47177 3.13604 2.63604C3.97177 1.80031 4.96392 1.13738 6.05585 0.685084C7.14778 0.232792 8.3181 0 9.5 0C10.6819 0 11.8522 0.232792 12.9442 0.685084C14.0361 1.13738 15.0282 1.80031 15.864 2.63604C16.6997 3.47177 17.3626 4.46392 17.8149 5.55585C18.2672 6.64778 18.5 7.8181 18.5 9C18.5 11.3869 17.5518 13.6761 15.864 15.364C14.1761 17.0518 11.8869 18 9.5 18C7.11305 18 4.82387 17.0518 3.13604 15.364C1.44821 13.6761 0.5 11.3869 0.5 9ZM9.5 1.5C7.51088 1.5 5.60322 2.29018 4.1967 3.6967C2.79018 5.10322 2 7.01088 2 9C2 10.9891 2.79018 12.8968 4.1967 14.3033C5.60322 15.7098 7.51088 16.5 9.5 16.5C11.4891 16.5 13.3968 15.7098 14.8033 14.3033C16.2098 12.8968 17 10.9891 17 9C17 7.01088 16.2098 5.10322 14.8033 3.6967C13.3968 2.29018 11.4891 1.5 9.5 1.5Z"
      fill="black"
    />
  </svg>
);

export const deleteIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z"
      fill="black"
    />
  </svg>
);

export const plusIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.98 8.92V6.94H6.92V0.639998H9.08V6.94H15.02V8.92H9.08V15.22H6.92V8.92H0.98Z"
      fill="black"
    />
  </svg>
);

export const telegram_icon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5525 0.99691C18.3117 1.01443 18.0754 1.07091 17.8527 1.16416H17.8497C17.636 1.24891 16.6197 1.67641 15.0747 2.32441L9.53822 4.65616C5.56547 6.32866 1.66022 7.97566 1.66022 7.97566L1.70672 7.95766C1.70672 7.95766 1.43747 8.04616 1.15622 8.23891C0.982463 8.34948 0.832947 8.49414 0.716717 8.66416C0.578717 8.86666 0.467717 9.17641 0.508967 9.49666C0.576467 10.0382 0.927467 10.3629 1.17947 10.5422C1.43447 10.7237 1.67747 10.8084 1.67747 10.8084H1.68347L5.34572 12.0422C5.50997 12.5694 6.46172 15.6984 6.69047 16.4192C6.82547 16.8497 6.95672 17.1189 7.12097 17.3244C7.20047 17.4294 7.29347 17.5172 7.40522 17.5877C7.46331 17.6215 7.52526 17.6481 7.58972 17.6672L7.55222 17.6582C7.56347 17.6612 7.57247 17.6702 7.58072 17.6732C7.61072 17.6814 7.63097 17.6844 7.66922 17.6904C8.24897 17.8659 8.71472 17.5059 8.71472 17.5059L8.74097 17.4849L10.9032 15.5162L14.5272 18.2964L14.6097 18.3317C15.365 18.6632 16.13 18.4787 16.5342 18.1532C16.9415 17.8254 17.0997 17.4062 17.0997 17.4062L17.126 17.3387L19.9265 2.99191C20.006 2.63791 20.0262 2.30641 19.9385 1.98466C19.848 1.65903 19.639 1.37897 19.3527 1.19941C19.1123 1.05325 18.8335 0.982697 18.5525 0.99691ZM18.4767 2.53441C18.4737 2.58166 18.4827 2.57641 18.4617 2.66716V2.67541L15.6875 16.8729C15.6755 16.8932 15.6552 16.9374 15.5997 16.9817C15.5412 17.0282 15.4947 17.0574 15.251 16.9607L10.8185 13.5624L8.14097 16.0029L8.70347 12.4104L15.9455 5.66041C16.244 5.38291 16.1442 5.32441 16.1442 5.32441C16.1652 4.98391 15.6935 5.22466 15.6935 5.22466L6.56147 10.8819L6.55847 10.8669L2.18147 9.39316V9.39016L2.17022 9.38791C2.17789 9.38536 2.1854 9.38236 2.19272 9.37891L2.21672 9.36691L2.23997 9.35866C2.23997 9.35866 6.14822 7.71166 10.121 6.03916C12.11 5.20141 14.114 4.35766 15.6552 3.70666C16.5693 3.32191 17.4843 2.93941 18.4002 2.55916C18.4617 2.53516 18.4325 2.53516 18.4767 2.53516V2.53441Z"
      fill="#24221A"
    />
  </svg>
);

export const paperclip_icon = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85234 11.7422C8.86988 11.7646 8.89229 11.7827 8.91788 11.7952C8.94346 11.8077 8.97154 11.8141 9 11.8141C9.02846 11.8141 9.05654 11.8077 9.08212 11.7952C9.10771 11.7827 9.13012 11.7646 9.14766 11.7422L11.7727 8.42109C11.8687 8.29922 11.782 8.11875 11.625 8.11875H9.88828V0.1875C9.88828 0.084375 9.80391 0 9.70078 0H8.29453C8.19141 0 8.10703 0.084375 8.10703 0.1875V8.11641H6.375C6.21797 8.11641 6.13125 8.29688 6.22734 8.41875L8.85234 11.7422ZM17.5781 10.9219H16.1719C16.0688 10.9219 15.9844 11.0063 15.9844 11.1094V14.7188H2.01562V11.1094C2.01562 11.0063 1.93125 10.9219 1.82812 10.9219H0.421875C0.31875 10.9219 0.234375 11.0063 0.234375 11.1094V15.75C0.234375 16.1648 0.569531 16.5 0.984375 16.5H17.0156C17.4305 16.5 17.7656 16.1648 17.7656 15.75V11.1094C17.7656 11.0063 17.6812 10.9219 17.5781 10.9219Z"
      fill="#24221A"
    />
  </svg>
);

export const download_icon = (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4531 12.5962C11.4178 12.5613 11.3702 12.5418 11.3207 12.5418C11.2711 12.5418 11.2235 12.5613 11.1882 12.5962L8.4648 15.3197C7.20386 16.5806 5.07573 16.7142 3.68355 15.3197C2.28901 13.9251 2.42261 11.7994 3.68355 10.5384L6.40698 7.81499C6.47964 7.74233 6.47964 7.6228 6.40698 7.55015L5.47417 6.61733C5.43893 6.58244 5.39134 6.56287 5.34175 6.56287C5.29216 6.56287 5.24457 6.58244 5.20933 6.61733L2.48589 9.34077C0.503076 11.3236 0.503076 14.5322 2.48589 16.5126C4.4687 18.4931 7.6773 18.4955 9.65776 16.5126L12.3812 13.7892C12.4539 13.7166 12.4539 13.597 12.3812 13.5244L11.4531 12.5962ZM17.514 1.48687C15.5312 -0.495947 12.3226 -0.495947 10.3421 1.48687L7.61636 4.2103C7.58146 4.24554 7.56189 4.29313 7.56189 4.34272C7.56189 4.39232 7.58146 4.43991 7.61636 4.47515L8.54683 5.40562C8.61948 5.47827 8.73901 5.47827 8.81167 5.40562L11.5351 2.68218C12.796 1.42124 14.9242 1.28765 16.3164 2.68218C17.7109 4.07671 17.5773 6.20249 16.3164 7.46343L13.5929 10.1869C13.558 10.2221 13.5385 10.2697 13.5385 10.3193C13.5385 10.3689 13.558 10.4165 13.5929 10.4517L14.5257 11.3845C14.5984 11.4572 14.7179 11.4572 14.7906 11.3845L17.514 8.66109C19.4945 6.67827 19.4945 3.46968 17.514 1.48687ZM12.2992 5.72671C12.2639 5.69181 12.2163 5.67224 12.1667 5.67224C12.1172 5.67224 12.0696 5.69181 12.0343 5.72671L6.72573 11.033C6.69084 11.0682 6.67126 11.1158 6.67126 11.1654C6.67126 11.215 6.69084 11.2626 6.72573 11.2978L7.65386 12.2259C7.72651 12.2986 7.84605 12.2986 7.9187 12.2259L13.225 6.91968C13.2976 6.84702 13.2976 6.72749 13.225 6.65483L12.2992 5.72671Z"
      fill="#24221A"
    />
  </svg>
);

export const arrowBack = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
      fill="black"
    />
  </svg>
);

export const triangle_icon = (
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.05475 9L0.93673 0.750001L15.1728 0.75L8.05475 9Z"
      fill="black"
    />
  </svg>
);

export const info_arrow = (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.364 0.293031C13.1764 0.10556 12.9221 0.000244141 12.657 0.000244141C12.3918 0.000244141 12.1375 0.10556 11.95 0.293031L6.99995 5.24303L2.04995 0.293031C1.86135 0.110873 1.60875 0.0100779 1.34655 0.0123563C1.08435 0.0146347 0.83354 0.119804 0.648132 0.305212C0.462725 0.49062 0.357556 0.741432 0.355278 1.00363C0.353 1.26583 0.453794 1.51843 0.635952 1.70703L6.29295 7.36403C6.48048 7.5515 6.73479 7.65682 6.99995 7.65682C7.26512 7.65682 7.51942 7.5515 7.70695 7.36403L13.364 1.70703C13.5514 1.5195 13.6567 1.26519 13.6567 1.00003C13.6567 0.734866 13.5514 0.480558 13.364 0.293031Z"
      fill="black"
    />
  </svg>
);

export const fillAddPhotoIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16C10 16.7 10.13 17.37 10.35 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 0.9 0.9 0 2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V10.35C17.37 10.13 16.7 10 16 10V2H2V16H10ZM10.96 9.29L8.21 12.83L6.25 10.47L3.5 14H10.35C10.75 12.88 11.47 11.91 12.4 11.21L10.96 9.29ZM17 15V12H15V15H12V17H15V20H17V17H20V15H17Z"
      fill="black"
    />
  </svg>
)

export const emptyAddPhotoIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16C10 16.7 10.13 17.37 10.35 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 0.9 0.9 0 2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V10.35C17.37 10.13 16.7 10 16 10V2H2V16H10ZM10.96 9.29L8.21 12.83L6.25 10.47L3.5 14H10.35C10.75 12.88 11.47 11.91 12.4 11.21L10.96 9.29ZM17 15V12H15V15H12V17H15V20H17V17H20V15H17Z"
      fill="#E7E7E7"
    />
  </svg>

)