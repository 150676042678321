import { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Context } from "../../../context";
import { patch_curator_profile_action } from "../../../redux/actions/curator/profile";
import { phone_validate } from "../../../tools/validators";
import { Button } from "../../atoms/Button";
import { DigitalInput } from "../../atoms/DigitalInput";
import "./styles.sass";
import { ProfileRegistrationType } from "./types";

export const CuratorProfileRegistration: FC<ProfileRegistrationType> = ({
  user_id,
}) => {
  const [context, setContext] = useContext(Context)
  const dispatch = useDispatch();
  const {
    email,
    gender,
    id,
    name,
    phone,
    rolle,
    subject_id,
    subject_name,
    surname,
    telegram,
    pending,
  } = useSelector((st: any) => st.curator_profile);

  const user_data = {
    email,
    gender,
    id,
    name,
    phone,
    rolle,
    subject_id,
    subject_name,
    surname,
    telegram,
    pending,
    password: "",
  };
  const [user_initials, set_user_initials] = useState(user_data);
  const [is_changed, set_is_changed] = useState(false);

  const change_initials = (target: any) => {
    !is_changed && set_is_changed(true);
    const { name, value } = target;
    set_user_initials({ ...user_initials, [name]: value });
  };

  const check_sending_data = (data: any) =>
  {
    if (data?.phone) if (!phone_validate(data.phone))
    {
      setContext({ ...context, notification_data: { title: "Невіоний формат. Приклад: +380 000 000 000", type: "warning"}})
      return false
    }
    if (data?.name) if (data.name.length < 1)
    {
      setContext({ ...context, notification_data: { title: "Закоротке ім'я", type: "warning"}})
      return false
    }
    if (data?.surname) if (data.surname.length < 1)
    {
      setContext({ ...context, notification_data: { title: "Закоротке прізвище", type: "warning"}})
      return false
    }
    if (data?.password) if (data.password.length < 7)
    {
      setContext({ ...context, notification_data: { title: "Закороткий пароль", type: "warning"}})
      return false
    }
    return true
  }

  const callback_handler = (is_ok: boolean) =>
  {
    setContext({
      ...context,
      notification_data:
      {
        title: is_ok ? "Дані змінено успішно" : "Щось пішло не так",
        type: is_ok ? "success" : "warning"
      }
    })
  }

  const save_initials = () => {
    let curator = { ...user_initials };

    for (let key in curator) {
      // @ts-ignore
      !curator[key] && delete curator[key];
    }
    if (check_sending_data(curator)) dispatch<any>(patch_curator_profile_action(user_id, curator, callback_handler))
    set_is_changed(false);
  };

  useEffect(() => {
    set_user_initials(user_data);
    set_is_changed(false);
  }, [pending]);

  return pending ? (
    <h1>Завантаження</h1>
  ) : (
    <div className="ProfileRegistration">
      <DigitalInput
        title="Ім’я*"
        name="name"
        uppercase={false}
        value={user_initials.name}
        onChange={(e: any) => change_initials(e.target)}
        margin="24px 0 0 0"
      />
      <DigitalInput
        title="Прізвище*"
        name="surname"
        uppercase={false}
        value={user_initials.surname}
        onChange={(e: any) => change_initials(e.target)}
        margin="24px 0 0 0"
      />
      <DigitalInput
        title="E-mail*"
        name="email"
        uppercase={false}
        value={user_initials.email}
        disabled
        margin="24px 0 0 0"
      />
      <DigitalInput
        title="Телефон*"
        name="phone"
        uppercase={false}
        value={user_initials.phone}
        onChange={(e: any) => change_initials(e.target)}
        margin="24px 0 0 0"
      />
      <DigitalInput
        title="Пароль*"
        name="password"
        uppercase={false}
        value={user_initials.password}
        onChange={(e: any) => change_initials(e.target)}
        password
        margin="32px 0 0 0"
      />
      <h1 className="ProfileRegistrationChangePassword">Змінити пароль</h1>
      <Button
        rounded
        title="зберегти зміни"
        mode={is_changed ? "btn_black" : "btn_gray"}
        disabled={!is_changed}
        onClick={save_initials}
        uppercase
      />
    </div>
  );
};
