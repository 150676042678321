import {
  GET_MODULES_PENDING,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAIL,
  GET_BOUGHT_MODULES_SUCCESS,
} from "../../constants/admin";

const initialState = {
  curator_modules: [],
  curator_module_groups: [],
  curator_setted_module_groups: [],
  bought_modules: [],
  module_groups: [],
  modules: [],
  pending: false,
  error: "",
};

export const admin_modules = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MODULES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload,
        pending: false,
      };
    case GET_BOUGHT_MODULES_SUCCESS:
      return {
        ...state,
        bought_modules: action.payload,
        pending: false,
      };
    case "GET_MODULE_GROUPS_SUCCESS":
      return {
        ...state,
        module_groups: action.payload,
        pending: false,
      };

    case "GET_ALL_CURATOR_MODULE_GROUPS":
      return {
        ...state,
        curator_module_groups: action.payload,
        pending: false,
      };
    case "GET_SETTED_CURATOR_MODULE_GROUPS":
      return {
        ...state,
        curator_setted_module_groups: action.payload,
        pending: false,
      };

    case "GET_CURATOR_MODULES_SUCCESS":
      return {
        ...state,
        curator_modules: action.payload,
        pending: false,
      };
    case GET_MODULES_FAIL:
      return {
        modules: [],
        pending: false,
        error: action.payload,
      };
      default: {
        return state;
      }
    }
}