import "./styles.sass";
import { FC } from "react";
import { Button } from "../../atoms/Button";
import { send_work_condition } from "../../../tools/student/work_actions";

export const TestTop: FC<any> = ({ lesson_status, is_changed, lesson_name, send_lesson }) =>
{
  const send_condition = send_work_condition(lesson_status, is_changed)
  return (
    <div className="TestTopContainer">
      <div className="TestTopMainTitle">
        {lesson_name}
      </div>
      <div className="TestTopMainButtons">
        <Button
          title="відправити"
          uppercase
          rounded
          mode={send_condition ? "btn_black" : "btn_black_disabled"}
          onClick={ send_lesson }
          disabled={!send_condition}
        />
      </div>
    </div>
  );
};
