import "./styles.sass";
import { FC, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { MainHeaderTypes } from "./types";
import { Context } from "../../../context";
import { Button } from "../../atoms/Button";
import { ADD_CURATOR_MODE, ADD_LESSON_MODE, ADD_MODULE_MODE, ADD_TEACHER_MODE, ADD_TEST_MODE, CURATOR_MODE, LESSON_MODE, MODULE_MODE, PURE_MODULES_MODE, PURE_USERS_MODE, SUBJECT_MODE, TASKS_MODE, TEACHER_MODE, TEST_MODE } from "../../../constants/admin";
import { Select } from "../../atoms/Select";
import { signOutIcon } from "../../../assets/icons/header";
import { useDispatch } from "react-redux";
import { get_lessons_action, get_modules_action, get_subjects_action, patch_lesson_action, patch_test_action, post_task_action, search_student_action } from "../../../redux/actions/admin";
import { generate_task } from "../../../tools/admin/education_initials";
import { StudentTableHeader } from "../StudentTableHeader";
import { useNavigate } from "react-router-dom";
import { is_lesson } from "../../../tools/admin/common";
import { clear_storage } from "../../../Pages/UserEntry/exit";
import useEventListener from "../../../utils/useEventListener";

export const MainHeader: FC<MainHeaderTypes> = () => {
  const navigate = useNavigate()
  const [context, setContext] = useContext(Context);
  const { user_type, menu_item_id, current_mode, subject, module, search_task_modal, add_student_modal, add_subject_modal, student_search_data, lesson, new_year_modal } = context;
  const { subjects } = useSelector((st: any) => st.admin_subjects)
  const { tasks } = useSelector((st: any) => st.admin_tasks)
  const { teachers } = useSelector((st: any) => st.admin_users)
  const admin_education_tab = user_type === "admin" && menu_item_id === 0
  const admin_students_tab = menu_item_id === 1
  const admin_users_tab = menu_item_id === 2
  const admin_db_tab = menu_item_id === 3

  // temprary !!!
  const dispatch = useDispatch()
  const subject_chose = (subject: any) => {
    const { id, name } = subject
    dispatch<any>(get_modules_action(id))
    localStorage.setItem("subject_item", JSON.stringify({ id, name }))
    localStorage.removeItem("module_item")
    localStorage.removeItem("lesson_item")
    localStorage.removeItem("test_item")
    setContext({
      ...context,
      subject: { id, name },
      module: null,
      lesson: null,
      test: null,
      current_mode: PURE_MODULES_MODE,
      show_additional_menu: true
    })
  }

  const add_task = () => {
    const mode = (lesson.name.toLowerCase().includes("урок") || lesson.name.toLowerCase().includes("день")) ? "lesson" : "test"
    dispatch<any>(post_task_action(lesson.id, generate_task(tasks.length, subject.id), mode))
    const tm = setTimeout(() =>
    {
      const container = document.getElementsByClassName("AdminTasksInfo")[0]
      container.scrollIntoView(false)
      clearTimeout(tm)
    }, 500)
  }

  const add_from_bd = () => {
    setContext({ ...context, search_task_modal: true })
  }

  const addStudent = () =>
  {
    setContext({...context, add_student_modal: true})
  }

  const add_task_to_db = () =>
  {
    setContext({ ...context, add_db_task_modal: true })
  }

  const search_student = () =>
  {
    dispatch<any>(search_student_action(student_search_data))
  }

  const update_lesson_publish = () => {
    const patch_func = is_lesson(lesson.name) ? patch_lesson_action : patch_test_action
    dispatch<any>(patch_func({ ...lesson, aasm_state: "published" }, () => dispatch<any>(get_lessons_action(module.id))))
    setContext({ ...context, lesson: undefined, current_mode: MODULE_MODE })
  }

  const new_year_click = () => setContext({ ...context, new_year_modal: true })

  const is_august = () => new Date().getMonth() === 7

  const sign_out = () =>
  {
    clear_storage()
    navigate("sign_in")
  }

  const click_listener = (event: any) =>
  {
    event.preventDefault()
    if (event.keyCode === 13) search_student()
  }

  useEventListener('keyup', click_listener)

  useEffect(() => { if (admin_db_tab && !subject) setContext({ ...context, subject: subjects && subjects.length > 0 && subjects[0] })}, [subjects, admin_db_tab])
  useEffect(() => { if (admin_db_tab && subjects.length === 0) dispatch<any>(get_subjects_action())} , [subjects, admin_db_tab])
  return (
    <div className={`MainHeader ${(search_task_modal || add_student_modal || add_subject_modal || new_year_modal) ? "MainHeaderLess" : ""}`}>
      <div className={`HeaderInfo ${ admin_students_tab ? "HeaderInfo_Students" : "" }`}>
        {admin_education_tab && (
          <>
            {current_mode === SUBJECT_MODE && (
              <>
                <div className="HeaderAdminEducationName">Всі предмети</div>
                <Button
                  mode="btn_black"
                  title="+ предмет"
                  margin="0 0 0 20px"
                  width={20}
                  onClick={() => setContext({ ...context, add_subject_modal: true })}
                />
                <Button
                  mode={ is_august() ? "btn_black" : "btn_gray"}
                  title="новий рік"
                  margin="0 0 0 20px"
                  width={ 20 }
                  onClick={ new_year_click }
                  disabled={ !is_august() }
                  uppercase
                />
              </>
            )}
            {current_mode === PURE_MODULES_MODE && (
              <>
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: SUBJECT_MODE })}
                >
                  {subject.name}
                </div>
                <div className="HeaderButtonContainer">
                  <Button
                    mode="btn_black"
                    title="+ майстер група"
                    margin="0 0 0 20px"
                    width={100}
                    onClick={() => setContext({ ...context, current_mode: ADD_MODULE_MODE })}
                  />
                </div>
              </>
            )}
            {current_mode === ADD_MODULE_MODE && (
              <>
                <div className="HeaderAdminEducationName">
                  {subject.name}
                </div>
                <div className="Splitter" />
                <div className="HeaderAdminEducationName">НОВА МГ</div>
              </>
            )}
            {current_mode === MODULE_MODE && (
              <>
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: PURE_MODULES_MODE })}
                >
                  {subject.name}
                </div>
                <div className="Splitter" />
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: PURE_MODULES_MODE })}
                >
                  {module.name}
                </div>
                <Button
                  mode="btn_black"
                  title="+ Урок"
                  onClick={() => setContext({ ...context, current_mode: ADD_LESSON_MODE })}
                  margin="0 0 0 20px"
                  width={10}
                  uppercase
                />
                <Button
                  mode="btn_black"
                  title="+ тест"
                  onClick={() => setContext({ ...context, current_mode: ADD_TEST_MODE })}
                  margin="0 0 0 20px"
                  width={10}
                  uppercase
                />
              </>
            )}
            {(current_mode === LESSON_MODE || current_mode === ADD_LESSON_MODE || current_mode === TEST_MODE || current_mode === ADD_TEST_MODE) && (
              <>
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: SUBJECT_MODE })}
                >
                  {subject.name}
                </div>
                <div className="Splitter" />
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: PURE_MODULES_MODE })}
                >
                  {module.name}
                </div>
                {(current_mode === LESSON_MODE || current_mode === TEST_MODE) && <>
                  <div className="Splitter" />
                  <div
                    className="HeaderAdminEducationName"
                    onClick={() => setContext({ ...context, current_mode: MODULE_MODE })}
                  >
                    {lesson?.name ?? ""}
                  </div>
                </>}
                {(current_mode === ADD_LESSON_MODE || current_mode === ADD_TEST_MODE) ?
                  <div
                    className="HeaderAdminEducationName"
                    onClick={() => setContext({ ...context, current_mode: MODULE_MODE })}
                  >
                    {current_mode === ADD_LESSON_MODE && "Новий урок"}
                    {current_mode === ADD_TEST_MODE && "Новий тест"}
                  </div>
                  :
                  <Button
                    mode={lesson && (lesson.aasm_state === "unpublished" ? "btn_black" : "btn_gray")}
                    title={"опублікувати"}
                    margin="0 0 0 20px"
                    width={16}
                    uppercase
                    onClick={update_lesson_publish}
                    disabled={!lesson || (lesson && lesson.aasm_state === "published")}
                  />
                }
              </>)}
            {current_mode === TASKS_MODE && (
              <>
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: SUBJECT_MODE })}
                >
                  {subject.name}
                </div>
                <div className="Splitter" />
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: MODULE_MODE })}
                >
                  {module.name}
                </div>
                <div className="Splitter" />
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: is_lesson(lesson.name) ? LESSON_MODE : MODULE_MODE })}
                >
                  {lesson.name}
                </div>
                <div className="Splitter" />
                <div
                  className="HeaderAdminEducationName"
                  onClick={() => setContext({ ...context, current_mode: LESSON_MODE })}
                >
                  ДЗ
                </div>
                <Button
                  mode="btn_black"
                  title="+ нове"
                  margin="0 0 0 20px"
                  width={14}
                  onClick={add_task}
                  uppercase
                />
                <Button
                  mode="btn_black"
                  title="+ з БД"
                  margin="0 0 0 20px"
                  width={14}
                  onClick={add_from_bd}
                  uppercase
                />
                {!is_lesson(lesson?.name ? lesson.name : "урок") &&
                  <Button
                    mode={lesson && (lesson.aasm_state === "unpublished" ? "btn_black" : "btn_gray")}
                    title={"опублікувати"}
                    margin="0 0 0 20px"
                    width={16}
                    uppercase
                    onClick={update_lesson_publish}
                    disabled={!lesson || (lesson && lesson.aasm_state === "published")}
                  />
                }
              </>
            )}
          </>
        )}
        {admin_students_tab && (
          <>
            <StudentTableHeader />
            <Button
              title="оновити"
              mode="btn_black"
              width={15}
              margin="0 40px"
              onClick={search_student}
            />
            <Button
              title="+ студент"
              mode="btn_black"
              width={15}
              onClick={addStudent}
            />
          </>
        )}
        {admin_users_tab && (
          <>
          <div className="HeaderAdminEducationName">Вчителі</div>

          {(current_mode === TEACHER_MODE && teachers.length === 0) && (
              <Button
                mode={
                  teachers.length >= 1 ? "btn_gray" : "btn_black"
                }
                title="+ вчитель"
                margin="0 0 0 20px"
                width={14}
                disabled={teachers.length >= 1}
                onClick={() => setContext({ ...context, current_mode: ADD_TEACHER_MODE })}
                uppercase
              />
            )}
          {(current_mode === CURATOR_MODE || (current_mode === TEACHER_MODE && teachers.length >= 1))&& (
            <>
              <div className="Splitter" />
              <div className="HeaderAdminEducationName">Куратори</div>
              <Button
                mode="btn_black"
                title="+ куратор"
                margin="0 0 0 20px"
                onClick={() => setContext({ ...context, current_mode: ADD_CURATOR_MODE })}
                width={14}
                uppercase
              />
            </>
          )}
        </>
        )}
        {admin_db_tab &&
          <>
            <div className="HeaderAdminEducationName">Предмет</div>
            {subjects.length > 0 && subject && (
              <Select
                value={subject}
                options={subjects}
                setValue={subject_chose}
                width={250}
              />
            )}
            <Button
              title="+ Завдання"
              mode="btn_black"
              uppercase
              width={20}
              margin="0 0 0 20px"
              onClick={add_task_to_db}
            />
          </>
        }
      </div>
      <div className="HeaderActions">
        {admin_education_tab && (
          <>
            {subjects.length > 0 && subject && current_mode !== SUBJECT_MODE && (
              <Select
                value={subject}
                options={subjects}
                setValue={subject_chose}
                width={250}
              />
            )}
          </>
        )}
        <div className="mx10 pointer" onClick={sign_out}>
          {signOutIcon}
        </div>
      </div>
    </div>
  );
};
