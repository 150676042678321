import { ChangeEvent, FC, useState, useContext } from "react"
import { Button } from "../../Components/atoms/Button"
import { DigitalInput } from "../../Components/atoms/DigitalInput"
import { TextField } from "../../Components/atoms/TextField"
import { Context } from "../../context"
import "../../tools/clipboard"
import "./styles.sass"

export const FormulaCopy: FC = () =>
{
  const [context, setContext] = useContext(Context)
  const [initial_value, set_initial_value] = useState<string>("")
  const [result_value, set_result_value] = useState<string>("")

  const gen_notif = (title: string, type: "success" | "warning") =>
  {
    setContext((prev: any) => ({
      ...prev,
      notification_data: { title, type }
    }))
  }

  const set_value = (event: ChangeEvent<HTMLInputElement>) =>
  {
    set_initial_value(event.target.value)
  }

  const clear_values = () =>
  {
    set_result_value("")
    set_initial_value("")
    gen_notif("очищено", "success")
  }

  const convert_value = () =>
  {
    try {
      set_result_value(initial_value.replace(/$/g, "").replace(/%/g, "\\% ").replace(/ /g, "\\quad "))
      gen_notif("сконвертовано", "success")
    } catch { gen_notif("конвертування не успішне", "warning") }
  }

  const copy_value = async () =>
  {
    try {
      //@ts-ignore
      window.Clipboard.copy(result_value);
      gen_notif("скопійовано", "success")
    } catch { gen_notif("щось пішло не так", "warning") }
  }

  return (
    <div className="FormulaCopyContainer">
      <DigitalInput
        title="Поле для скопійованого тексту"
        placeholder="Вставте скопійований текст сюди"
        value={ initial_value }
        uppercase={ false }
        width="100%"
        margin="0 0 40px 0"
        onChange={ set_value }
      />
      <TextField
        text={ result_value }
        height="auto"
        width="calc(100% - 60px)"
      />
      <div className="FormulaCopyActions">
        <Button
          title="очистити"
          mode="btn_transparent"
          width={ 30 }
          onClick={ clear_values }
        />
        <Button
          title="конвертувати"
          mode="btn_black"
          width={ 30 }
          onClick={ convert_value }
          />
        <Button
          title="скопіювати"
          mode="btn_black"
          width={ 30 }
          onClick={ copy_value }
        />
      </div>
    </div>
  )
}
