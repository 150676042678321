import "./styles.sass";
import { download_icon, paperclip_icon } from "../../../assets/icons/common";
import { Button } from "../../atoms/Button";
import { prepareDate } from "../../../tools/admin/date";
import { useContext } from "react";
import { Context } from "../../../context";
import { TEACHER_TASK_MODE } from "../../../constants/admin";
import { convert_url_to_embed_custom } from "../../../tools/youtube";

export const TeacherLessonInfo = () =>
{
  const [context, setContext] = useContext(Context)
  const { lesson } = context

  // @ts-ignore
  const { date, time } = prepareDate(new Date(lesson?.created_at ? lesson.created_at : new Date()), true, "split")

  const additional_materials = () =>
  {
    const materials = lesson.additional_materials
    if (!materials) return []
    if (!materials.length) return []
    return materials
  }

  return (
    <div className="TeacherLessonInfo">
      <div className="TeacherLessonInfoMain">
        <div className="TeacherLessonInfoMainTop">
          <h1>{lesson?.theme ? lesson.theme : "Без теми"}</h1>
          <p>{lesson?.description ? lesson.description : "Без опису"}</p>
          <div className="TeacherLessonInfoMainHashtags">
            {lesson?.hashtags ? lesson.hashtags : ""}
          </div>
        </div>
        <div className="TeacherLessonInfoMainMiddle">
          <h2 className="TeacherLessonInfoMainTime">
            Початок
            <span className="TeacherLessonTime">
              {date} о {time}
            </span>
          </h2>
          <div className="TeacherLessonInfoMainLinks">
            <div className="MainLinksDownload">
              <div className="MainLinksDownloadIcon">{download_icon}</div>
              <div className="MainLinksDownloadLink">
                <a href={lesson?.lesson_url ? lesson.lesson_url : "https://www.youtube.com/watch?v=NjDMzkRbSZI"} target="_blank">
                  посилання на online трансляцію
                </a>
              </div>
            </div>
            <div className="MainLinksTranslation">
              {additional_materials().length === 0 ? "Немає додаткових матеріалів" :
              <>
                <div className="MainLinksTranslationIcon">{paperclip_icon}</div>
                <div className="MainLinksTranslationLink">
                  {additional_materials().map((link: string, index: number) =>
                    <a href={link} target="_blank" key={index}>
                      матеріали для завантаження
                    </a>
                  )}
                </div>
              </>
              }
            </div>
          </div>
        </div>
        <div className="TeacherLessonInfoMainBottom">
          <Button
            title="Переглянути ДЗ"
            uppercase
            onClick={() => setContext({ ...context, current_mode: TEACHER_TASK_MODE, homework_id: lesson.homework_id })}
            mode={"btn_black"}
            size="btn_normal"
            width={80}
            rounded
          />
        </div>
      </div>
      { lesson.lesson_url && <div className="TeacherLessonInfoPlayer">
        <iframe
          className="ModuleInfoPlayer"
          width="505"
          height="308"
          src={ convert_url_to_embed_custom(lesson.lesson_url) }
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>}
    </div>
  );
};
