import "./styles.sass";
import { FC } from "react";
import { AdminUsersMenuGroupType, AdminUsersMenuTypes } from "./types";

export const AdminUsersMenu: FC<any> = ({
  subject,
  subjects,
  curators,
  curator,
  select_subject,
  select_curator,
}) => {
  return (
    <div className={`AdditionalMenu`}>
      <div className="ModulesContainer">
        {subjects.length > 0 &&
          subjects.map((subj: AdminUsersMenuGroupType) => (
            <div key={subj.id} className="ModuleItem">
              <div className={`ModuleName ${subj.id === subject?.id && "CurrentModuleName"}`} onClick={() => select_subject(subj)}>
                <div className="ModuleNameText">{ subj.name }</div>
              </div>
              {subj.id === subject?.id && subject.id && curators.length > 0 && (
                <div className="LessonContainer">
                  {curators.map((cur: any) => (
                      <div
                        key={cur.id}
                        className={`LessonItem ${cur.id === curator?.id &&  "SelectedLessonItem"}`}
                        onClick={() => select_curator(cur)}
                      >
                        {cur?.name ? cur.name : `kurator ${cur.id}`}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
