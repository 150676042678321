import {
  GET_STUDENT_SUBJECTS_PENDING,
  GET_STUDENT_SUBJECTS_SUCCESS,
  GET_STUDENT_SUBJECTS_FAIL,
} from "./../../constants/student";

const initialState = {
  subjects: [],
  pending: false,
  error: "",
};

export const student_subjects = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STUDENT_SUBJECTS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload,
        pending: false,
      };
    case GET_STUDENT_SUBJECTS_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }