import { TeacherGroupInfoItem } from "../../molecules/TeacherGroupInfoItem";
import { TeacherGroupSchedule } from "../../molecules/TeacherGroupSchedule";
import "./styles.sass";

export const TeacherGroupInfo = () =>
{
  return (
    <div className="TeacherGroupInfo">
      <div className="TeacherGroupInfoHeader">
        <TeacherGroupSchedule />
      </div>
      <div className="TeacherGroupInfoItem">
        <TeacherGroupInfoItem />
      </div>
    </div>
  );
};
