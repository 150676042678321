//@ts-nocheck
import { FC } from "react";
import { v4 as uuidv4 } from "uuid";
import "./styles.sass";
import { CuratorStudentTableBodyTypes } from "./types";

export const CuratorStudentTableBody: FC<CuratorStudentTableBodyTypes> = ({
  curatorTable,
  headersTitle,
  selectHomework,
}) => {
  const findSmth = (item: any, homework: any) => {
    const newArray = item.filter(
      (item: any) => item.work_name === homework.name
    );

    return newArray[0];
  };

  return (
    <tbody className="curatorTbody">
      {curatorTable.map((item: any) => (
        <tr key={uuidv4()}>
          {headersTitle.homeworks.map((lessonItem: any) => {
            const data = findSmth(item.user_homeworks, lessonItem);

            return !data ? (
              <td key={uuidv4()}></td>
            ) : (
              <td
                className={
                  data.aasm_state === "completed"
                    ? "thBody-event completed"
                    : "thBody-event checked"
                }
                onClick={() => selectHomework(data)}
                key={uuidv4()}
              >
                {data.estimation}
              </td>
            );
          })}
          {headersTitle.exams.map((test: any) => {
            const data = findSmth(item.user_exams, test);
            return !data ? (
              <td key={uuidv4()}></td>
            ) : (
              <td
                className={
                  data.aasm_state === "completed"
                    ? "thBody-event completed"
                    : "thBody-event checked"
                }
                onClick={() => selectHomework(data)}
                key={uuidv4()}
              >
                {data.estimation}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};
