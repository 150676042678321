import { FC } from "react"
import { Button } from "../../Components/atoms/Button"
import "./styles.sass"

export const Page404: FC<{navigate_home: () => void}> = ({ navigate_home }) =>
<div className="Page404">
  <Button
    title="go back"
    mode="btn_black"
    onClick={ navigate_home }
    width={ 50 }
    margin="0 0 30px 0"
    uppercase
  />
</div>