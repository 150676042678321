import axios from "axios";
import { requestConfig, serv } from "../../constants/config";
import {
  GET_CURATOR_MGROUPS_FAIL,
  GET_CURATOR_MGROUPS_PENDING,
  GET_CURATOR_MGROUPS_SUCCESS,
  GET_CURATOR_PROFILE_FAIL,
  GET_CURATOR_PROFILE_PENDING,
  GET_CURATOR_PROFILE_SUCCESS,
} from "./../../constants/curator";

export const get_curator_profile_action =
  (user_id: number) => (dispatch: any) => {
    const url = `${serv}/curatorpanel/curators/${user_id}`;
    dispatch({ type: GET_CURATOR_PROFILE_PENDING });
    axios
      .get(url, requestConfig())
      .then((res: any) =>
        dispatch({ type: GET_CURATOR_PROFILE_SUCCESS, payload: res.data })
      )
      .catch((err: any) =>
        dispatch({ type: GET_CURATOR_PROFILE_FAIL, payload: err })
      );
  };

export const patch_curator_profile_action =
  (user_id: number, data: { [key: string]: string }, callback?: (is_ok: boolean) => void) => (dispatch: any) => {
    const url = `${serv}/curatorpanel/curators/${user_id}`;
    const body = { curator: data };
    axios
      .patch(url, body, requestConfig())
      .then(() =>
      {
        get_curator_profile_action(user_id)
        callback && callback(true)
      })
      .catch(() => callback && callback(false));
  };

export const get_curator_master_groups_action = () => (dispatch: any) => {
  const url = `${serv}/curatorpanel/master_groups`;
  dispatch({ type: GET_CURATOR_MGROUPS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res) =>
      dispatch({
        type: GET_CURATOR_MGROUPS_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err: any) =>
      dispatch({ type: GET_CURATOR_MGROUPS_FAIL, payload: err })
    );
};
