import "./styles.sass";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { lessonDownload_icon } from "../../../assets/icons/studentLessons";
import { LessonInput } from "../../atoms/LessonInput";
import { LessonRadio } from "../../atoms/LessonRadio";
import { Context } from "../../../context";
import { taskEnTypes } from "../../organisms/AdminTasksInfo/types";
import { TaskMarkPoints } from "../../atoms/TaskMarkPoints";
import { Button } from "../../atoms/Button";
import { closeIcon } from "../../../assets/icons/common";
import { useDispatch } from "react-redux";
import { cahnge_exercise_estimate_action, set_user_task } from "../../../redux/actions/curator/groups";
import { prepare_text_to_math } from "../../../tools/latex/field";
import { is_json_string, shortify_end_text } from "../../../tools/common";
import { UserPhotosArea } from "../../atoms/UserPhotosArea";
import { alphabetic_list_ua } from "../../../constants/general/common";
import { apply_math_styles } from "../../../tools/latex/field_fresh";
import { is_lesson } from "../../../tools/admin/common";
import MathRenderer from '../../atoms/KatexOutput/KatexOutput';
export const LessonTasks: FC<any> = ({ task, homework_status, set_work_photos, is_checked, lesson_name }) => {
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const { homework_answers, user_type, homework_id } = context
  const [equations, setEquations] = useState([]);
  const [hint_modal, set_hint_modal] = useState<boolean>(false)
  const [solution_modal, set_solution_modal] = useState<boolean>(false)
  const [change_clicked, set_change_clicked] = useState(false)

  const is_homework_created = homework_status === "created"
  const is_homework_saved = homework_status === "started"
  const is_homework_sended = homework_status === "completed"
  const is_homework_checked = homework_status === "checked"

  const is_student = user_type === "user"
  const is_curator = user_type === "curator"

  const user_answer = task?.user_answer ? task.user_answer : ""

  const prepare_task_number = (id: number, number: number) => {
    apply_math_styles()
    if (!number) return id + 1
    const new_num = number.toString();
    if (new_num.length > 1) {
      return new_num;
    } else {
      const prepared_num = "0" + new_num;
      return prepared_num;
    }
  };

  const prepare_task_variant = (variant: string) => {
    const cuted_variant = variant.split("@@@");
    const prepared_variant = {
      variant_num: cuted_variant[0],
      variant_text: cuted_variant.splice(1, cuted_variant.length - 1).join(" "),
    };
    return prepared_variant;
  };

  const set_exercise_answer = (task_id: number, task_type: taskEnTypes, target: any) =>
  {
    let answer: any;
    const { name, value } = target
    const prev_filter = homework_answers.filter((p: any) => p.exercise_id === task_id)
    const is_ready = prev_filter.length > 0
    // value = ""
    switch (task_type)
    {
      case "MatchPair":
      {
        if (value.trim().length > 1) break
        if (is_ready)
        {
          const answ_arr = prev_filter[0].answer.trim().split(",")
          const index_of_answ = answ_arr.indexOf(answ_arr.find((e: any) => e.includes(name)))
          if (value.trim().length === 0)
          {
            const new_answ_arr = []
            for (let i = 0; i < answ_arr.length; i++)
            {
              if (i !== index_of_answ && answ_arr[i].trim().length > 0) new_answ_arr.push(answ_arr[i])
            }
            answer = new_answ_arr
          }
          if (value.trim().length > 0 && index_of_answ !== -1)
          {
            answ_arr[index_of_answ] = value.trim().length ? `${name}-${value.toUpperCase()}` : ""
            answer = answ_arr
          }
          if (index_of_answ === -1)
          {
            answer = [ ...answ_arr, `${name}-${value.toUpperCase()}` ]
          }
        }
        else
        {
          answer = [ `${name}-${value.toUpperCase()}` ]
        }
        const str_to_replace = ",,"
        const reg = new RegExp(str_to_replace, 'g')
        setContext({
          ...context,
          homework_answers: homework_answers.length > 0
            ? homework_answers.map((h: any) => h.exercise_id === task_id
              ? ({ ...h, answer: answer.length > 0 ? answer.join(",").replace(reg, ",") : "" })
              : ({ ...h }))
            : [{ exercise_id: task_id, answer: answer.length > 0 ? answer.join(",").replace(reg, ",") : "" }] })
        break;
      }
      case "ChooseAnswer":
        answer = target
        setContext({
          ...context,
          homework_answers: 
          is_ready
            ? homework_answers.length > 0
              ? homework_answers.map((h: any) => h.exercise_id === task_id
                ? ({ ...h, answer })
                : ({ ...h }))
              : [{ exercise_id: task.id, answer }]
            : [ ...homework_answers, { exercise_id: task.id, answer } ]
        })
        break;
      case "ShortAnswer":
        answer = value
        setContext({
          ...context,
          homework_answers: 
          is_ready
            ? homework_answers.length > 0
              ? homework_answers.map((h: any) => h.exercise_id === task_id
                ? ({ ...h, answer })
                : ({ ...h }))
              : [{ exercise_id: task_id, answer }]
            : [ ...homework_answers, { exercise_id: task_id, answer } ]
        })
        break;
      case "ManualSolution":
        answer = target.files;
        setContext({
          ...context,
          homework_answers: 
          is_ready
            ? homework_answers.length > 0
              ? homework_answers.map((h: any) => h.exercise_id === task_id
                ? ({ ...h, answer: [ ...h.answer, ...answer ] })
                : ({ ...h }))
              : [{ exercise_id: task_id, answer }]
            : [ ...homework_answers, { exercise_id: task_id, answer } ]
        })
        break;
      default:
        answer = "";
        break;
    }
  }

  const match_pair_input_value = (num: number) =>
  {
    if (homework_answers.length > 0)
    {
      const filtered = homework_answers.filter((h: any) => h.exercise_id === task.id)
      if (filtered.length === 0) return ""
      const items = filtered[0].answer.trim().split(",")
      if (items.length === 1)
      {
        const item = items[0].split("-")
        if (item[0] == num) return item[1]
      }
      if (items.length > 1)
      {
        //@ts-ignore
        const need_item = items.filter((i: string) => i.split("-")[0] == num)
        if (need_item.length > 0) return need_item[0].split("-")[1]
      }
    }
    return ""
  }
 
  const choose_answer_input_value = () =>
  {
    if (is_homework_checked) return user_answer
    if (homework_answers.length > 0)
    {
      const filtered = homework_answers.filter((h: any) => h.exercise_id === task.id)
      if (filtered.length > 0) return filtered[0].answer
      if (filtered.length === 0 && is_homework_created) return user_answer
    }
    return ""
  } 
 
  const manual_solution_value = () =>
  {
    if (is_homework_sended || is_homework_checked) return user_answer?.work_photo ? user_answer.work_photo : []
    if (homework_answers.length > 0)
    {
      const filtered = homework_answers.filter((h: any) => h.exercise_id === task.id)
      if (filtered.length > 0) return filtered[0].answer
      if (filtered.length === 0 && is_homework_created) return user_answer
    }
    return []
  }

  const remove_file = (task_id: number, index: number) =>
  {
    setContext({ ...context, homework_answers: homework_answers.map((answ: any)=> answ.exercise_id === task_id ? ({ ...answ, answer: [...answ.answer].filter((a: any, i: number) => i !== index )}) : ({ ...answ }))})
  }

  const prepare_file_name = (file: string) =>
  {
    const splitted_url = file.split("/")
    const str_to_replace = "%20"
    const reg = new RegExp(str_to_replace, 'g')
    const prepared = splitted_url[splitted_url.length -1].replace(reg, " ")
    return prepared.length > 60 ? shortify_end_text(prepared) : prepared
  }

  const [mark, set_mark] = useState(task.estimation)

  const setTaskMark = () =>
  {
    if (!task.user_answer_id) return setContext((prev: any) => ({ ...prev, notification_data: { title: "Неможливо змінити оціну якщо відповідь відсутня", type: "warning" }}))
    set_mark(mark)
    dispatch<any>(cahnge_exercise_estimate_action(task.user_answer_id, mark, homework_id, (status: number) =>
    {
      if (status !== 200) return setContext({ ...context, notification_data: { title: "Оцінку не змінено, щось пішло не так", type: "warning" } })
      dispatch<any>( set_user_task( homework_id ))
      return setContext({ ...context, notification_data: { title: "Оцінку змінено", type: "success" } })
    }))
  }
  const can_change_mark = (task.estimation !== mark)

  const change_estimate_component =
    <div className="LessonTaskOneMarkConteiner">
      <div className="TaskInformationText">Оцінити</div>
      <TaskMarkPoints
        taskMark={mark}
        setTaskMark={set_mark}
        with_zero
        disabled={ is_checked }
      />
      <Button
        title="зберегти"
        uppercase
        rounded
        width={15}
        margin="0 0 0 25px"
        mode={ can_change_mark ? "btn_black" : "btn_gray" }
        onClick={setTaskMark}
        disabled={ !can_change_mark || is_checked }
      />
    </div>

  const Task_hint_element = () =>
  <>
    <div className="LessonTaskOneHelp" onClick={() => set_hint_modal(!hint_modal)}>
      <h1>підказка</h1>
    </div>
    {hint_modal && (task.hint_photos.length > 0
        ? <UserPhotosArea photos={ task.hint_photos } />
        : <div className="LessonTaskOnePrompt">
            <p>{task.hint}</p>
          </div>)
    }
  </>

  const Task_right_solution_element = () =>
  <div className="LessonTasksCommonPart">
    <div className="LessonTaskOneHelp" onClick={() => set_solution_modal(!solution_modal)}>
      <h1>рішення</h1>
    </div>
    {solution_modal && task.right_solution_photos.length > 0 &&
      <UserPhotosArea photos={ task.right_solution_photos } />
    }
    {solution_modal && task.right_solution.trim().length > 0 &&
      <div className="LessonTaskOnePrompt">
        <p><MathRenderer mathExpression= {prepare_text_to_math(task.right_solution)} /></p>
      </div>
    }
  </div>

  const is_first_photo_points = task.possible_answer_fr_photos.length > 0
  const is_second_photo_points = task.possible_answer_sr_photos.length > 0

  const get_answer_result = (id: number = 0) =>
  {
    if (is_homework_created || is_homework_saved) return "pending"
    const answer_result = task.answer_result
    if (!answer_result.length) return "pending"
    if (answer_result[id].is_correct)
    { return "success" } else
    { return "error" }
  }

  const prepare_user_answer = (answer: any) =>
  {
    if (isNaN(answer)) return is_json_string(answer) ? JSON.parse(answer) : answer
    return answer.toString()
  }

  const get_rest_answer_result = (initial_user_answer: string | number) =>
  {
    if (is_homework_created || is_homework_saved) return "pending"
    const reg = /[\\" ]/g

    const prepared_user_answer = prepare_user_answer(initial_user_answer).replace(reg, "")
    const answer_result = task.answer_result

    if (!answer_result.length) return "pending"

    const prepared_correct_answer = prepare_user_answer(answer_result[0].correct_answer).replace(reg, "")
    const prepared_saved_user_answer = prepare_user_answer(answer_result[0].user_answer).replace(reg, "")

    const ok_condition = prepared_correct_answer === prepared_user_answer
    const so_condition = (prepared_correct_answer === prepared_user_answer) && !answer_result[0].is_correct
    const err_condition = (prepared_saved_user_answer === prepared_user_answer) && !answer_result[0].is_correct

    if (so_condition) return "success_pending"
    if (ok_condition) return "success"
    if (err_condition) return "error"

    return "pending"
  }

  useEffect(() => {
    const withMath = window.screen.width - 100
    const withToString = withMath.toString();
    const elements = document.getElementsByTagName('math');
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;
      element.style.width = withToString + '!important';
    }
  }, []);

  const show_right_solution = () =>
  {
    if (is_curator) return true
    if (is_homework_checked || is_homework_sended) return true
    return false
  }
  const estimation_element = () => is_curator ? `${task.estimation} / ${task.max_shore}` : task.max_shore
  return (
    <div className="LessonTasks" key={task.id}>
      <div className="LessonTasksCommonPart">
        <h1>
          Завдання {prepare_task_number(task.id, task.position)} | {estimation_element()}
        </h1>
        <h2><MathRenderer mathExpression={prepare_text_to_math(task.task)} /></h2>
        { task.task_photos.length > 0 && <UserPhotosArea photos={ task.task_photos } /> }
      </div>
      {!is_curator && task.hint.length > 0 && <Task_hint_element />}
      {show_right_solution() && <Task_right_solution_element /> }
      {task.exercise_type === "ManualSolution" && (
        <div className="LessonTaskOneContainer">
          {is_student && <div className="LessonTaskOneDownloadResult">
            {manual_solution_value().length > 0 && [...manual_solution_value()].map((f: any, index: number) =>
              <div className="LessonTaskOneFile" key={index}>
                {f?.name ? f.name : prepare_file_name(f.url)}
                { !(is_homework_sended || is_homework_checked) && <div className="LessonTaskOneFileIcon" onClick={() => remove_file(task.id, index)}>{closeIcon}</div>}
              </div>
            )}
            {!(is_homework_sended || is_homework_checked) &&
              <label className="custom-file-upload">
                <input
                  type="file"
                  multiple
                  onChange={(event) => set_exercise_answer(task.id, task.exercise_type, event.target)}
                  disabled={ is_homework_checked || is_homework_sended || !is_student }
                />
                {lessonDownload_icon}
                <a className="LessonTaskKeepFileText">розгорнута відповідь прикріпи сюди</a>
              </label>
            }
          </div>}
          {is_curator && manual_solution_value().length > 0 && <div className="LessonTaskOneDownloadResult" onClick={() => set_work_photos([...manual_solution_value()])}>
            {[...manual_solution_value()].map((f: { id: number, url: string }) =>
              <div className="LessonTaskOneFile" key={f.id}>
                {prepare_file_name(f.url)}
              </div>
            )}
          </div>}
          {is_curator && change_estimate_component}
        </div>
      )}
      {task.exercise_type === "MatchPair" && (
        <div className="LessonTaskTwoContainer">
          <div className="LessonTaskTwoObjective">
            <div className="LessonTaskTwoObjectiveQuestion">
              {!is_first_photo_points && task.possible_answer[0].first_row.length > 0 && task.possible_answer[0].first_row.map((item: string) => {
                const { variant_num, variant_text } = prepare_task_variant(item);
                return (
                  <div className="LessonTaskTwoObjectiveQuestionItem">
                    <h1>{variant_num}</h1>
                    <MathRenderer mathExpression= { prepare_text_to_math(variant_text) } />
                  </div>
                );
              })}
              {is_first_photo_points && task.possible_answer_fr_photos.map((item: { id: number, url: string }, id: number) => {
                return (
                  <div className="LessonTaskTwoObjectiveQuestionItem" key={ id }>
                    <h1>{ id + 1 }</h1>
                    <UserPhotosArea photos={ [ item ] } />
                  </div>
                );
              })}
            </div>
            <div className="LessonTaskTwoObjectiveAnswer">
              {!is_second_photo_points && task.possible_answer[0].second_row.length > 0 && task.possible_answer[0].second_row.map((item: string) => {
                const { variant_num, variant_text } = prepare_task_variant(item);
                return (
                  <div className="LessonTaskTwoObjectiveAnswerItem">
                    <h1>{variant_num}</h1>
                    <MathRenderer mathExpression= { prepare_text_to_math(variant_text) } />
                  </div>
                );
              })}
              {is_second_photo_points && task.possible_answer_sr_photos.map((item: { id: number, url: string }, id: number) => {
                return (
                  <div className="LessonTaskTwoObjectiveQuestionItem" key={ id }>
                    <h1>{ alphabetic_list_ua[id] }</h1>
                    <UserPhotosArea photos={ [ item ] } />
                  </div>
                );
              })}
            </div>
          </div>
          <p className="LessonTaskTwoDescription">
            Впиши біля цифр відповідні букви
          </p>
          <div className="LessonTaskTwoInputsContainer">
            {(is_first_photo_points ? task.possible_answer_fr_photos : task.possible_answer[0].first_row).map((_: any, index: number) => (
              <LessonInput
                title={index + 1 + " -"}
                name={index + 1}
                estimate={ get_answer_result(index) }
                placeholder={match_pair_input_value(index + 1)}
                value={is_homework_created ? match_pair_input_value(index + 1) : ""}
                onChange={(event: any) => set_exercise_answer(task.id, task.exercise_type, event.target)}
                disabled={((is_homework_checked || is_homework_sended) || is_curator)}
              />
            ))}
          </div>
        </div>
      )}
      {task.exercise_type === "ChooseAnswer" && (
        <div className="LessonTaskThreeContainer">
          <div className="LessonTaskThreeContainerRadiosContainer">
            {!is_first_photo_points && task.possible_answer[0].first_row.map((item: string, index: number) => (
              <Fragment key={index}>
                <LessonRadio
                  title={item.split("@@@")[1]}
                  estimate={ get_rest_answer_result(item.split("@@@")[1]) }
                  onClick={(item: string) => set_exercise_answer(task.id, task.exercise_type, item)}
                  value={choose_answer_input_value()}
                  disabled={((is_homework_checked || is_homework_sended) || is_curator)}
                />
              </Fragment>
            ))}
            {is_first_photo_points && task.possible_answer_fr_photos.map((item: { id: number, url: string }, index: number) => (
              <div className="LessonTestTaskItemContainer" key={index}>
                <LessonRadio
                  hidden_title={ item.id.toString() }
                  estimate={ get_rest_answer_result(item.id.toString()) }
                  onClick={(item: string) => set_exercise_answer(task.id, task.exercise_type, item)}
                  value={ choose_answer_input_value() }
                  disabled={((is_homework_checked || is_homework_sended) || is_curator)}
                />
                <UserPhotosArea
                  photos={ [ item ] }
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {task.exercise_type === "ShortAnswer" && (
        <div className="LessonTaskFourthContainer">
          <p className="LessonTaskFourthDescription">
            Впиши в текстове поле відповідь
          </p>
          <LessonInput
            width={200}
            textAlign="left"
            estimate={ get_answer_result() }
            placeholder={choose_answer_input_value()}
            value={choose_answer_input_value()}
            onChange={(e: any) => set_exercise_answer(task.id, task.exercise_type, e.target)}
            disabled={((is_homework_checked || is_homework_sended) || is_curator)}
          />
          {is_curator && is_lesson(lesson_name ?? "урок") && (change_clicked ? change_estimate_component : <div onClick={() => set_change_clicked(true)}>Виправити оцінку</div> )}
        </div>
      )}
    </div>
  );
};
