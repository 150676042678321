import "./styles.sass";
import { FC } from "react";
import { ButtonTypes } from "./types";

export const Button: FC<ButtonTypes> = ({
  title,
  mode = "btn_gray",
  type = "button",
  size = "normal",
  margin = "0",
  uppercase = false,
  onClick,
  width,
  disabled,
  rounded,
}) => {
  return (
    <button
      className={"Button " + mode + " " + size}
      style={{
        width: (width ?? 100) + "%",
        borderRadius: rounded ? "28px" : "5px",
      }}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      <div className="ButtonText">{ uppercase ? title.toUpperCase() : title }</div>
    </button>
  );
};
