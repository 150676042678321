import { FC, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { patch_student_profile_action } from "../../../redux/actions/student/profile";
import { patch_teacher_profile_action } from "../../../redux/actions/teacher";
import { Button } from "../../atoms/Button";
import { DigitalInput } from "../../atoms/DigitalInput";
import { ProfileRegistrationType } from "./types";
import { Context } from "../../../context";
import "./styles.sass";
import { phone_validate } from "../../../tools/validators";

export const ProfileRegistration: FC<ProfileRegistrationType> = ({ is_teacher }) => {
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const { user_id } = context
  const { email, phone, name, full_name, time_zone, telegram, pending } = useSelector((st: any) => st[is_teacher ? "teacher_profile" : "student_profile"])
  const split_name = () => full_name ? full_name.split(" ") : ["", ""]

  const user_data = { email, phone, name: is_teacher ? name : split_name()[0], surname: split_name()[1], time_zone, password: "", telegram }
  const [user_initials, set_user_initials] = useState(user_data)
  const [is_changed, set_is_changed] = useState(false)

  const change_initials = (target: any) =>
  {
    !is_changed && set_is_changed(true)
    const { name, value } = target
    set_user_initials({ ...user_initials, [name]: value })
  }

  const clear_data = (data: any) =>
  {
    const keys = Object.keys(data)
    const data_copy = { ...data }
    const reg = (repl: string) =>
    {
      if (!repl) return ""
      if (repl.length) return ""
      return new RegExp(repl, 'g')
    }
    for (let key of keys)
    {
      if (key === "phone") if (data_copy[key]) data_copy[key] = data_copy[key].replace(reg(" "), "").replace(reg("+"), "")
      if (!data_copy[key]) delete data_copy[key]
      if (data_copy[key]) if (!data_copy[key].trim().length) delete data_copy[key]
    }
    return data_copy
  }

  const check_sending_data = (data: any) =>
  {
    if (data?.phone) if (!phone_validate(data.phone))
    {
      setContext({ ...context, notification_data: { title: "Невіоний формат. Приклад: +380 000 000 000", type: "warning"}})
      return false
    }
    if (data?.name) if (data.name.length < 1)
    {
      setContext({ ...context, notification_data: { title: "Закоротке ім'я", type: "warning"}})
      return false
    }
    if (data?.surname) if (data.surname.length < 1)
    {
      setContext({ ...context, notification_data: { title: "Закоротке прізвище", type: "warning"}})
      return false
    }
    if (data?.password) if (data.password.length < 7)
    {
      setContext({ ...context, notification_data: { title: "Закороткий пароль", type: "warning"}})
      return false
    }
    return true
  }

  const callback_handler = (is_ok: boolean) =>
  {
    setContext({
      ...context,
      notification_data:
      {
        title: is_ok ? "Дані змінено" : "Дані не змінено. Щось пішло не так",
        type: is_ok ? "success" : "warning"
      }
    })
  }

  const save_initials = () =>
  {
    const initials: any = { ...user_initials }
    const clear = { ...clear_data(initials), surname: initials.surname ?? ""}
    if (check_sending_data(clear))
    {
      if (is_teacher) dispatch<any>(patch_teacher_profile_action(user_id, clear, callback_handler))
      if (!is_teacher) dispatch<any>(patch_student_profile_action(user_id, clear, callback_handler))
      set_is_changed(false)
    }
  }

  useEffect(() => { set_user_initials(user_data); set_is_changed(false) }, [pending])

  return pending ? (<h1>Завантаження</h1>) : (
    <div className="ProfileRegistration">
      { !is_teacher && <h1 className="ProfileRegistrationTitle BzzOnLeft">Яка ти сьогодні бджілка?</h1>}
      <DigitalInput uppercase={false} title="Ім’я*" name="name" value={user_initials.name} onChange={(e: any) => change_initials(e.target)} margin="24px 0 0 0" />
      <DigitalInput uppercase={false} title={is_teacher ? "ТГ нік*" : "Прізвище*"} name={is_teacher ? "telegram" : "surname"} value={user_initials[is_teacher ? "telegram" : "surname"]} onChange={(e: any) => change_initials(e.target)} margin="24px 0 0 0" />
      <DigitalInput uppercase={false} title="E-mail*" name="email" value={user_initials.email} disabled margin="24px 0 0 0" />
      <DigitalInput uppercase={false} title="Телефон*" type="number" name="phone" value={user_initials.phone} onChange={(e: any) => change_initials(e.target)} margin="24px 0 0 0" />
      <DigitalInput uppercase={false} title="Пароль*" name="password" value={user_initials.password} onChange={(e: any) => change_initials(e.target)} password margin="32px 0 0 0" />
      <Button
        title="зберегти зміни"
        mode={ is_changed ? "btn_black" : "btn_gray" }
        disabled={!is_changed}
        onClick={save_initials}
        margin="40px 0 0 0"
        rounded
        uppercase
      />
    </div>
  );
};
