import {
  GET_TEACHER_COURSES_PENDING,
  GET_TEACHER_COURSES_SUCCESS,
  GET_TEACHER_COURSES_FAIL,
} from "./../../constants/teacher";

const initialState = {
  courses: [],
  pending: false,
  error: "",
};

export const teacher_courses = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TEACHER_COURSES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TEACHER_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        pending: false,
      };
    case GET_TEACHER_COURSES_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }
