import "./styles.sass";
import { FC, useContext, useState } from "react";
import { Input } from "../../atoms/Input";
import { Button } from "../../atoms/Button";
import { closeIcon } from "../../../assets/icons/common";
import { Context } from "../../../context";

export const AddSubjectModal: FC<any> = ({ create_new_subject }) => {
  const [context, setContext] = useContext(Context)
  const [name, set_name] = useState("")

  const isNormal = name.trim().length > 0

  return (
    <div className={`AddSubjectModal`}>
      <div className="AddSubjectModalHeader">
        <div className="AddSubjectModalName">введіть назву предмету</div>
        <div
          className="AddSubjectModalClose"
          onClick={() => setContext({ ...context, add_subject_modal: false })}
        >
          {closeIcon}
        </div>
      </div>
      <div className="AddSubjectModalBody">
        <Input
          value={name}
          height={40}
          placeholder="зарубіжна література"
          onChange={(e) => set_name(e.target.value)}
          borderWidth
        />
        <Button
          title="зберегти"
          mode={isNormal ? "btn_black" : "btn_gray"}
          size="btn_small"
          width={50}
          margin="0 0 5px 20px"
          uppercase
          onClick={() => create_new_subject(name.trim())}
          disabled={!isNormal}
        />
      </div>
    </div>
  );
};
