export const GET_LESSONS_PENDING = "GET_LESSONS_PENDING";
export const GET_LESSONS_SUCCESS = "GET_LESSONS_SUCCESS";
export const GET_LESSONS_FAIL = "GET_LESSONS_FAIL";

export const CREATE_LESSON_PENDING = "CREATE_LESSON_PENDING";
export const CREATE_LESSON_SUCCESS = "CREATE_LESSON_SUCCESS";
export const CREATE_LESSON_FAIL = "CREATE_LESSON_FAIL";

export const UPDATE_LESSON_PENDING = "UPDATE_LESSON_PENDING";
export const UPDATE_LESSON_SUCCESS = "UPDATE_LESSON_SUCCESS";
export const UPDATE_LESSON_FAIL = "UPDATE_LESSON_FAIL";

export const DELETE_LESSON_PENDING = "DELETE_LESSON_PENDING";
export const DELETE_LESSON_SUCCESS = "DELETE_LESSON_SUCCESS";
export const DELETE_LESSON_FAIL = "DELETE_LESSON_FAIL";