import "./styles.sass";
import { FC } from "react";
import { fillSelectIcon, emptySelectIcon, successSelectIcon, successEmptyIcon, errorSelectIcon } from "../../../assets/icons/common"
import { prepare_text_to_math } from "../../../tools/latex/field";
import MathRenderer from '../../atoms/KatexOutput/KatexOutput';

export const LessonRadio: FC<any> = ({
  hidden_title,
  title,
  onClick,
  value,
  disabled,
  estimate = "pending" // "pending" | "success" | "error"
}) =>
  {
    const get_icon = () =>
    {
      if (estimate === "pending")
      {
        if (
          (hidden_title ?? title) == value ||
          (hidden_title ?? title).replace(/\\/g, "").replace(/"/g, "") == value.replace(/\\/g, "").replace(/"/g, "")
          )
        { return fillSelectIcon } else
        { return emptySelectIcon }
      }
      if (estimate === "success") return successSelectIcon
      if (estimate === "success_pending") return successEmptyIcon
      if (estimate === "error") return errorSelectIcon
    }
    return (
      <div
        className="LessonRadioContainer"
        onClick={() => !disabled && onClick(hidden_title ?? title)}
      >
        { get_icon() }
        <h1 className={`LessonRadioText LessonRadioText_${ estimate }`}><MathRenderer mathExpression={ prepare_text_to_math(hidden_title ? "" : title) } /></h1>
      </div>
    )
  }
