import "./styles.sass";
import React, { FC } from "react";
import { Select } from "../../atoms/Select";
import { SimpleInput } from "../../atoms/SimpleInput";
import { searchIcon } from "../../../assets/icons/common";
import { DigitalInput } from "../../atoms/DigitalInput";
import { DigitalSelect } from "../../atoms/DigitalSelect";

export const AdminDbFilters: FC<any> = ({
  addHashtag,
  setNewHash,
  newHash,
  filterTypes,
  is_teacher,
  filterOrder,
  toggleFilterTypes,
  toggleFilterOrder,
  searchId,
  setSearchId,
}) => {
  return (
    <div className={`AdminDbFilters ${is_teacher && "DbFiltersTop"}`}>
      <div className="HeaderAdminEducationName nomargin">Тип</div>
      {is_teacher
        ? <DigitalSelect
            value={filterTypes.filter((el: any) => el.active)[0]}
            options={filterTypes}
            setValue={toggleFilterTypes}
            margin="0 20px 0 0"
            borderRadius={20}
          />
        : <SimpleInput
            placeholder="введи хештег для пошуку уроку"
            margin="0 20px 0 0"
            width={500}
            height={40}
            icon={searchIcon}
            onIconClick={addHashtag}
            value={newHash}
            onChange={(event: any) => setNewHash(event.target.value)}
          />
      }
      <div className="HeaderAdminEducationName">ID</div>
      {is_teacher
        ? <DigitalInput
            placeholder="24000"
            value={searchId}
            onChange={(event: any) => setSearchId(event.target.value)}
            margin="0"
          />
        : <SimpleInput
            placeholder="25000"
            roundedBorder
            width={150}
            height={40}
            value={searchId}
            onChange={(event: any) => setSearchId(event.target.value)}
          />
      }
      {is_teacher
        ? <DigitalInput
            placeholder="введи хештег для пошуку уроку"
            margin="0 20px 0 50px"
            width="500px"
            icon={searchIcon}
            onIconClick={addHashtag}
            value={newHash}
            onChange={(event: any) => setNewHash(event.target.value)}
          />
        : <Select
            value={filterTypes.filter((el: any) => el.active)[0]}
            options={filterTypes}
            setValue={toggleFilterTypes}
            width={250}
            height={40}
            margin="0 30px 0 30px"
          />
      }
      {!is_teacher && <>
      <div className="HeaderAdminEducationName">Сортувати</div>
        <Select
          value={filterOrder.filter((el: any) => el.active)[0]}
          options={filterOrder}
          setValue={toggleFilterOrder}
          width={250}
          height={40}
          margin="0 0 0 10px"
      /></>}
    </div>
  );
};
