export const GET_MODULES_PENDING = "GET_MODULES_PENDING";
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_MODULES_FAIL = "GET_MODULES_FAIL";

export const CREATE_MODULE_PENDING = "CREATE_MODULE_PENDING";
export const CREATE_MODULE_SUCCESS = "CREATE_MODULE_SUCCESS";
export const CREATE_MODULE_FAIL = "CREATE_MODULE_FAIL";

export const UPDATE_MODULE_PENDING = "UPDATE_MODULE_PENDING";
export const UPDATE_MODULE_SUCCESS = "UPDATE_MODULE_SUCCESS";
export const UPDATE_MODULE_FAIL = "UPDATE_MODULE_FAIL";

export const DELETE_MODULE_PENDING = "DELETE_MODULE_PENDING";
export const DELETE_MODULE_SUCCESS = "DELETE_MODULE_SUCCESS";
export const DELETE_MODULE_FAIL = "DELETE_MODULE_FAIL";

export const GET_BOUGHT_MODULES_PENDING = "GET_BOUGHT_MODULES_PENDING";
export const GET_BOUGHT_MODULES_SUCCESS = "GET_BOUGHT_MODULES_SUCCESS";
export const GET_BOUGHT_MODULES_FAIL = "GET_BOUGHT_MODULES_FAIL";