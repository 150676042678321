import "./styles.sass";
import { FC, FormEvent, Fragment, useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../../atoms/Button";
import { SimpleInput } from "../../atoms/SimpleInput";
import { delete_additional_materials, get_lessons_action, get_lesson_homework_action, patch_additional_materials, patch_lesson_action, patch_test_action, post_additional_materials, post_lesson_action, post_test_action } from "../../../redux/actions/admin";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ADD_LESSON_MODE, ADD_TEST_MODE, LESSON_MODE, MODULE_MODE, TASKS_MODE, TEST_MODE } from "../../../constants/admin";
import { Context } from "../../../context";
import { prepareDate } from "../../../tools/admin/date";
import { addIcon, calendarIcon, deleteIcon } from "../../../assets/icons/common";
import { generate_lesson } from "../../../tools/admin/education_initials";
import { AdminLessonInfoTypes } from "./types";
import { ClickAwayListener } from "../../../tools/ClickAway";

export const AdminLessonInfo: FC<AdminLessonInfoTypes> = ({ isTest, lesson_or_test_click }) => {
  const [context, setContext] = useContext(Context)
  const { current_mode, module, lesson } = context

  const [lesson_init, set_lesson_init] = useState(lesson)
  const [isChanged, setIsChanged] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);

  const { admin_lessons } = useSelector((st: any) => st)
  const addMode = current_mode === ADD_LESSON_MODE || current_mode === ADD_TEST_MODE
  const testMode = current_mode === TEST_MODE || current_mode === ADD_TEST_MODE
  const lessonMode = current_mode === LESSON_MODE || current_mode === ADD_LESSON_MODE

  const dispatch = useDispatch()

  let name_placeholder;
  if (current_mode === ADD_TEST_MODE) name_placeholder = "ТЕСТ _";
  if (current_mode === LESSON_MODE || current_mode === ADD_LESSON_MODE) name_placeholder = "УРОК _";

  const lesson_name = lesson?.name ?? lesson_init?.name ?? ""

  const update_lesson_publish = () => {
    const patch_func = isTest(lesson.name) ? patch_test_action : patch_lesson_action
    dispatch<any>(patch_func({ ...lesson, aasm_state: "unpublished" }, () => dispatch<any>(get_lessons_action(module.id))))
    setContext({ ...context, lesson: undefined, current_mode: MODULE_MODE })
  }

  const change_lesson_item = (event: FormEvent) => {
    const { name, value } = event.target as HTMLInputElement
    const new_lesson: any = { ...lesson_init }
    new_lesson[name] = value
    set_lesson_init(new_lesson)
  }

  const sort_by_postion = (arr: AdditionalMaterialType[]) => arr.sort((a: AdditionalMaterialType, b: AdditionalMaterialType) => a.position - b.position)

  const prepare_lesson_extra_materials_to_view = (lesson_obj?: any) =>
  {
    const lesson_info = lesson_obj ?? lesson_init
    if (!lesson_info.extra_materials) return []
    return sort_by_postion(lesson_info.extra_materials)
  }

  interface AdditionalMaterialType
  {
    id: number
    title: string
    url: string
    lesson_id: string
    position: number
  }

  const get_max_positon = (arr: AdditionalMaterialType[]) => Math.max(...arr.map((el: AdditionalMaterialType) => el.position))


  const change_lesson_extra_materials = (mode: "add" | "delete" | "change", id?: number, item: "title" | "url" = "title", value: string = "") =>
  {
    const lesson_copy = { ...lesson_init }
    const materials = lesson_copy.extra_materials
    let result_materials: string[];
    switch (mode)
    {
      case "add":
        const new_item = { id: materials ? materials.length : 0, url: "url", title: "title", position: materials ? materials.length ? get_max_positon(materials) + 1 : 1 : 1 }
        result_materials = materials ? [ ...materials, new_item ] : [ new_item ];
      break;
      case "delete": result_materials = materials.filter((m: AdditionalMaterialType) => m.id !== id)
      break;
      case "change": result_materials = materials.map((m: AdditionalMaterialType) => m.id === id ? ({ ...m, [item]: value }) : ({ ...m }))
      break;
    }
    lesson_copy.extra_materials = result_materials
    set_lesson_init(lesson_copy)
  }

  const change_lesson_date = (date: Date) =>
  {
    set_lesson_init({ ...lesson_init, [isTest(lesson_name) ? "date_start" : "lesson_start"]: date.toISOString() })
  }

  const save_extra_materials = (lesson_id: number) =>
  {
    if (!lesson_id) return
    const old_materials: any = lesson?.extra_materials ? [ ...lesson.extra_materials ] : []
    const new_materials: any = lesson_init?.extra_materials ? [ ...lesson_init.extra_materials ] : []
    
    if (!old_materials || !new_materials) return
    const added: AdditionalMaterialType[] = []
    const changed: AdditionalMaterialType[] = []
    const deleted: AdditionalMaterialType[] = []
    new_materials.forEach((m: AdditionalMaterialType) => [ ...old_materials.map((el: AdditionalMaterialType) => el.id)].indexOf(m.id) === -1 && added.push(m) )
    old_materials.forEach((m: AdditionalMaterialType) => [ ...new_materials.map((el: AdditionalMaterialType) => el.id)].indexOf(m.id) === -1 && deleted.push(m) )
    for (let a = 0; a < old_materials.length; a++)
    {
      for (let b = 0; b < new_materials.length; b++)
      {
        if (old_materials[a].id === new_materials[b].id)
        {
          if ((old_materials[a].title !== new_materials[b].title) ||
              (old_materials[a].url !== new_materials[b].url))
          { changed.push( new_materials[b] ) }
        }
      }
    }
    const AddPromises = added.map((m: AdditionalMaterialType) => post_additional_materials(m.position, m.title, m.url, lesson_id) )
    const ChangePromises = changed.map((m: AdditionalMaterialType) => patch_additional_materials(m.position, m.title, m.url, lesson_id, m.id) )
    const DeletePromises = deleted.map((m: AdditionalMaterialType) => delete_additional_materials(m.id) )
    const FinalPromise = Promise.all([ ...AddPromises, ChangePromises, DeletePromises ])
    FinalPromise.then(() =>
    {
      dispatch<any>(get_lessons_action(module.id, (lessons) =>
      {
        const fail_get_lesson = () => setContext((prev: any) => ({ ...prev, notification_data: { title: "щось пішло не так", type: "warning" } }))
        if (!lessons.length) return fail_get_lesson() 
        const new_lesson = lessons.find((l: any) => l.id === lesson_id)
        if (!new_lesson) return fail_get_lesson()
        lesson_or_test_click(new_lesson)
        setContext((prev: any) => ({ ...prev, notification_data: { title: "урок збережено", type: "success" } }))
      }))
      if (added.length || changed.length || deleted.length) setContext((prev: any) => ({ ...prev, notification_data: { title: "матеріали збережено", type: "success" } }))
    })
  }

  const change_lesson_callback = (lesson: any) =>
  {
    if (!lesson) return setContext((prev: any) => ({ ...prev, notification_data: { title: "щось пішло не так", type: "warning" } }))
    save_extra_materials(lesson.id)
  }

  const save_lesson = () =>
  {
    const lesson_copy = { ...lesson_init }
    const prepared_masterials = lesson_copy.extra_materials ? lesson_copy.extra_materials.join(" ").trim() : [""]
    lesson_copy.extra_materials = prepared_masterials
    isTest(lesson_name) && addMode && dispatch<any>(post_test_action(lesson_copy))
    !isTest(lesson_name) && addMode && dispatch<any>(post_lesson_action(lesson_copy, change_lesson_callback))
    isTest(lesson_name) && !addMode && dispatch<any>(patch_test_action(lesson_copy))
    !isTest(lesson_name) && !addMode && dispatch<any>(patch_lesson_action(lesson_copy, change_lesson_callback))
    setIsChanged(false)
    // setContext({ ...context, lesson: null, current_mode: MODULE_MODE })
  }

  const getPreparedDate = () => prepareDate(
    lesson_init[isTest(lesson_init.name) ? "date_start" : "lesson_start"]
      ? new Date(lesson_init[isTest(lesson_init.name) ? "date_start" : "lesson_start"])
      : new Date(), true, "split"
  );

  const open_homework = () => {
    setContext({ ...context, current_mode: TASKS_MODE })
    dispatch<any>(get_lesson_homework_action(lesson.id));
  }


  const check_extra_materials_change = () =>
  {
    const old_materials: any = lesson?.extra_materials ? [ ...lesson.extra_materials ] : []
    const new_materials: any = lesson_init?.extra_materials ? [ ...lesson_init.extra_materials ] : []

    if (old_materials.length !== new_materials.length) return true
    for (let i = 0; i < old_materials.length; i++)
    {
      if (old_materials[i].title !== new_materials[i].title) return true
      if (old_materials[i].url !== new_materials[i].url) return true
    }
    return false
  }

  const check_lesson_change = () => {
    if (current_mode === ADD_LESSON_MODE || current_mode === ADD_TEST_MODE) return setIsChanged(true);
    if (check_extra_materials_change()) return setIsChanged(true)
    const check_fields = [
      "name",
      "lesson_url",
      "theme",
      isTest(lesson_init.name) ? "date_start" : "lesson_start",
      "description",
      "hashtags",
    ];
    for (let i = 0; i < check_fields.length; i++)
    {
      if (lesson_init?.[check_fields[i]])
      {
        if (lesson[check_fields[i]] !== lesson_init[check_fields[i]].trim())
        {
          setIsChanged(true);
          break;
        }
      }
      setIsChanged(false);
    }
  }

  // check changes
  useEffect(check_lesson_change, [lesson_init])

  useEffect(() =>
  {
    if (!lesson) return
    const lesson_copy = { ...lesson }
    lesson_copy.extra_materials = prepare_lesson_extra_materials_to_view(lesson_copy)
    set_lesson_init(lesson_copy)
  }, [lesson])

  useEffect(() => {
    addMode && set_lesson_init(generate_lesson(testMode, admin_lessons.lessons.length, module.id))
  }, [addMode, testMode, lesson, module.id, admin_lessons.lessons.length]);

  return (
    <div className="LessonMainDataBlock">
      {lesson_init && <div className="LessonInformationContainer">
        <div className="LessonsInformationContainerRow">
          <div className="LessonInformationName">
            {lessonMode && "Урок"}
            {testMode && "Тест"}
          </div>
          <div className="LessonInformationBody">
            <div className="LessonInfoNameInput">
              <SimpleInput
                name="name"
                placeholder={name_placeholder}
                value={lesson_init.name}
                height={40}
                onChange={change_lesson_item}
              />
            </div>
            <div className="LessonInfoStatusText">{(lesson?.aasm_state ?? "unpublished") === "published" ? "Опублікуваний" : "Неопублікуваний" }</div>
            <div className="LessonInfoStatusActionButton">
              <Button
                title="закрити"
                mode={(lesson?.aasm_state ?? "unpublished") === "published" ? "btn_transparent" : "btn_gray"}
                uppercase
                onClick={update_lesson_publish}
                disabled={(lesson?.aasm_state ?? "unpublished") === "unpublished"}
              />
            </div>
          </div>
        </div>
        <div className="LessonsInformationContainerRow">
          <div className="LessonInformationName">Назва</div>
          <div className="LessonInformationBody">
            <SimpleInput
              name="theme"
              placeholder="Обчислення. Елементарні дії з числами"
              value={lesson_init.theme}
              height={50}
              onChange={change_lesson_item}
            />
          </div>
        </div>
        <ClickAwayListener onClickAway={() => setShowDateModal(false)}>
        <div
          className="LessonsInformationContainerRow"
          onClick={() => setShowDateModal(true)}
        >
          <div className="LessonInformationName">Дата</div>
          <div className="LessonInformationBody">
            <SimpleInput
              name="feefewe"
              icon={calendarIcon}
              width={350}
              height={40}
              //@ts-ignore
              placeholder={getPreparedDate()?.date}
              disabled
            />
            <div className="LessonInfoTimeText">Час</div>
            <SimpleInput
              name="feefewe"
              width={160}
              height={40}
              //@ts-ignore
              placeholder={getPreparedDate()?.time}
              disabled
            />
              <ReactDatePicker
                customInput={<input style={{ display: "none" }} />}
                selected={new Date(lesson_init[isTest(lesson_init.name) ? "date_start" : "lesson_start"] ?? new Date())}
                onChange={(date: Date) => change_lesson_date(date)}
                open={showDateModal}
                showTimeSelect
              />
          </div>
        </div>
        </ClickAwayListener>
        <div className="LessonsInformationContainerRow LessonDescription">
          <div className="LessonInformationName">Опис</div>
          <div className="LessonInformationBody">
            <textarea
              name="description"
              placeholder="На цьому уроці ми почнемо знайомитись із базовими знаннями математики. Охоплюємо арифметичні дії, модулі, дроби та багато інших базових штук."
              value={lesson_init.description ?? ""}
              rows={9}
              cols={72}
              max-cols={72}
              className="LessonTextarea"
              onChange={change_lesson_item}
            />
          </div>
        </div>
        <div className="LessonsInformationContainerRow LessonMaterials">
          <div className="LessonInformationName">
            Матеріали
            <div className="AdditionalMaterialsAdd" onClick={() => change_lesson_extra_materials("add")}>{addIcon}</div>
          </div>
          <div className="LessonInformationBody">
            <div className="LessonInformationBodyMaterials">
              {prepare_lesson_extra_materials_to_view().map((m: AdditionalMaterialType, index: number) =>
              (
                <div className="LessonMaterialItem" key={index}>
                  <SimpleInput
                    value={m.title}
                    placeholder="additional materials name"
                    height={40}
                    onChange={(e: any) => change_lesson_extra_materials("change", m.id, "title", e.target.value)}
                  />
                  <SimpleInput
                    value={m.url}
                    placeholder="additional materials link"
                    height={40}
                    onChange={(e: any) => change_lesson_extra_materials("change", m.id, "url", e.target.value)}
                  />
                  <div className="AdditionalMaterialsDelete" onClick={() => change_lesson_extra_materials("delete", m.id)}>{deleteIcon}</div>
                </div>       
              ))}
            </div>
          </div>
        </div>
        <div className="LessonsInformationContainerRow LessonMaterials">
          <div className="LessonInformationName">Урок</div>
          <div className="LessonInformationBody">
            <textarea
              name="lesson_url"
              placeholder="https://www.youtube.com/watch?v=bGONaHKS8i0&ab_channel=%D0%9F%D0%B0%D0%BB%D0%B0%D1%94"
              value={lesson_init.lesson_url ?? ""}
              rows={2}
              cols={72}
              className="LessonTextarea"
              onChange={change_lesson_item}
            />
          </div>
        </div>
        <div className="LessonsInformationContainerRow LessonMaterials">
          <div className="LessonInformationName">Хештеги</div>
          <div className="LessonInformationBody">
            <textarea
              name="hashtags"
              placeholder="#тригонометрія #шось там #шосьтут"
              value={lesson_init.hashtags ?? ""}
              rows={2}
              cols={72}
              className="LessonTextarea"
              onChange={change_lesson_item}
            />
          </div>
        </div>
      </div>}
      <div className="LessonActionsContainer">
        <Button
          title="зберегти"
          mode={addMode ? "btn_black" : isChanged ? "btn_black" : "btn_gray"}
          onClick={save_lesson}
          uppercase
          width={70}
          margin="0 30px 0 0"
          disabled={addMode ? false : !isChanged}
        />
        {!addMode && (
          <Button
            title="Домашнє завдання"
            mode="btn_transparent"
            uppercase
            onClick={open_homework}
          />
        )}
      </div>
    </div>
  );
};
