// @ts-nocheck
import { is_json_string, split_arr } from "../common"

const get_separator = () =>
{
  let separator;
  const scr_width = window.screen.width

  switch (true)
  {
    case scr_width <= 1050: separator = 1; break;
    case 1050 < scr_width && scr_width < 1100: separator = 3; break;
    case 1100 < scr_width && scr_width < 1200: separator = 5; break;
    case 1200 < scr_width && scr_width < 1300: separator = 5; break;
    case 1300 < scr_width && scr_width < 1400: separator = 8; break;
    case 1400 < scr_width && scr_width < 1500: separator = 8; break;
    case 1500 < scr_width && scr_width < 1600: separator = 10; break;
    case scr_width >= 1600: separator = 11; break;
    default: separator = 9; break;
  }

  return separator
}

export const prepare_text_to_math = (item_text: string) =>
{
  if (!item_text.length) return item_text
  const prepared_item = is_json_string(item_text) ? JSON.parse(item_text) : item_text
  const splitted = prepared_item.split()

  const style =
  {

    "pointer-events": "none",
    "border": "none",
    "margin-right": "16px",
    "font-size": "16px"
  }

  return splitted.toString()
}