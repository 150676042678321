import { useContext } from "react";
import { telegram_icon } from "../../../assets/icons/common";
import { Context } from "../../../context";
import { prepareDate } from "../../../tools/admin/date";
import { v4 as uuidv4 } from "uuid";
import "./styles.sass";

export const TeacherModuleInfo = () =>
{
  const [context] = useContext(Context)
  const { module } = context

  const start_date: any = module?.start_date ? prepareDate(new Date(module.start_date), false) : "дату початку ще не задано"
  const finish_date: any = module?.finish_date ? prepareDate(new Date(module.finish_date), false) : "дату закінчення ще не задано"

  return (
    <div className="TeacherModuleInfo">
      <div className="TeacherModuleInfoMain">
        <div className="TeacherModuleInfoMainLeft">
          <h1>початок</h1>
          <h1>закінчення</h1>
          <h1>групи</h1>
        </div>
        <div className="TeacherModuleInfoMainRight">
          <h1>{start_date}</h1>
          <h1>{finish_date}</h1>
          <div className="TeacherModuleInfoMainRightLinks">
            {module.groups.length === 0 && <h1>немає</h1>}
            {module.groups.length > 0 && module.groups.map((gr: any) =>
              <div className="TeacherModuleInfoTelegramLink" key={uuidv4()}>
                <div className="TeacherModuleInfoTelegramLinkGroup">
                  <h1>{gr?.name ? gr.name : "без назви"}</h1>
                  <a className="TelegramGroupLink">
                    <div className="TelegramIcon">{telegram_icon}</div>
                    <a href={gr?.telegram_link ? gr.telegram_link : "https://google.com"} target="_blank" className="TelegramGroupLinkText">{gr?.telegram_link ? "група в телеграмі" : "(посилання відсутнє)"}</a>
                  </a>
                </div>
                <div className="TeacherModuleInfoTelegramLinkCurator">
                  <h1>Куратор: {module?.curator_name ? module.curator_name : "(Імʼя не вказано)"}</h1>
                  <a className="TelegramLink">{module?.curator_email ? module.curator_email : "(Емейл не вказано)"}</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="TeacherModuleInfoRight"></div>
    </div>
  );
};
