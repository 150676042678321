import React, {
  cloneElement,
  FC,
  MutableRefObject,
  ReactElement,
  RefCallback,
  useEffect,
  useRef,
} from 'react'

import { ClickAwayListenerPropsType, Events, eventTypeMapping } from './types'

export const ClickAwayListener: FC<ClickAwayListenerPropsType> = ({
  children,
  onClickAway,
  focusEvent = 'focusin',
  mouseEvent = 'click',
  touchEvent = 'touchend',
}) => {
  const node = useRef<HTMLElement | null>(null)
  const bubbledEventTarget = useRef<EventTarget | null>(null)
  const mountedRef = useRef(false)

  useEffect(() => {
    setTimeout(() => {
      mountedRef.current = true
    }, 0)

    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleBubbledEvents =
    (type: string) =>
    (event: Events): void => {
      bubbledEventTarget.current = event.target

      const handler = children?.props[type]

      if (handler) {
        handler(event)
      }
    }

  const handleChildRef = (childRef: HTMLElement) => {
    node.current = childRef

    let { ref } = children as typeof children & {
      ref: RefCallback<HTMLElement> | MutableRefObject<HTMLElement>
    }

    if (typeof ref === 'function') {
      ref(childRef)
    } else if (ref) {
      ref.current = childRef
    }
  }

  useEffect(() => {
    const handleEvents = (event: Events): void => {
      if (!mountedRef.current) return

      if (
        (node.current && node.current.contains(event.target as Node)) ||
        bubbledEventTarget.current === event.target ||
        !document.contains(event.target as Node)
      ) {
        return
      }

      onClickAway(event)
    }

    document.addEventListener(mouseEvent, handleEvents)
    document.addEventListener(touchEvent, handleEvents)
    document.addEventListener(focusEvent, handleEvents)

    return () => {
      document.removeEventListener(mouseEvent, handleEvents)
      document.removeEventListener(touchEvent, handleEvents)
      document.removeEventListener(focusEvent, handleEvents)
    }
  }, [focusEvent, mouseEvent, onClickAway, touchEvent])

  const mappedMouseEvent = eventTypeMapping[mouseEvent]
  const mappedTouchEvent = eventTypeMapping[touchEvent]
  const mappedFocusEvent = eventTypeMapping[focusEvent]

  return React.Children.only(
    cloneElement(children as ReactElement<any>, {
      ref: handleChildRef,
      [mappedFocusEvent]: handleBubbledEvents(mappedFocusEvent),
      [mappedMouseEvent]: handleBubbledEvents(mappedMouseEvent),
      [mappedTouchEvent]: handleBubbledEvents(mappedTouchEvent),
    }),
  )
}

ClickAwayListener.displayName = 'ClickAwayListener'

