import "./App.sass";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CuratorHeader } from "./Components/atoms/CuratorHeader";
import { CuratorMenu } from "./Components/atoms/CuratorMenu";
import { Notification } from "./Components/atoms/Notification";
import { StudentHeader } from "./Components/atoms/StudentHeader";
import { StudentMenu } from "./Components/atoms/StudentMenu";
import { MainHeader } from "./Components/molecules/MainHeader";
import { MainMenu } from "./Components/molecules/MainMenu";
import { STUDENT_TASK_MODE } from "./constants/admin";
import {
  AdminContextType,
  Context,
  CuratorContextType,
  GeneralContextItems,
  get_initial_context,
  StudentContextType,
} from "./context";
import { Admin } from "./Pages/Admin";
import { Curator } from "./Pages/Curator";
import { Student } from "./Pages/Student";
import { Teacher } from "./Pages/Teacher";
import { UserEntry } from "./Pages/UserEntry";
import { get_user_info_action } from "./redux/actions/auth";
import { get_teacher_profile_action } from "./redux/actions/teacher";
import { clear_storage } from "./Pages/UserEntry/exit"
import { MathfieldElement } from "mathlive"
import { FormulaCopy } from "./Pages/FormulaCopy";
import { Page404 } from "./Pages/Page404";

new MathfieldElement()

export const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location_info = useLocation();
  const [isContextPrepared, setIsContextPrepared] = useState(false);
  const [context, setContext] = useState<AdminContextType | StudentContextType | CuratorContextType>(
    GeneralContextItems
  );

  // @ts-ignore
  const { auth, current_mode, is_lesson_changed } = context;
  const { id, role, full_name } = useSelector((st: any) => st.user_info);

  const currrent_path = location_info.pathname;

  const is_admin = currrent_path.includes("admin");
  const is_auth_page =
    currrent_path === "/finish_registration" ||
    currrent_path === "/password_recovery" ||
    currrent_path === "/sign_in";

  useEffect(() => {
    if (currrent_path === "/copy") return
    // if (!is_auth_page && currrent_path !== "/" && currrent_path !== "/admin/sign_in") return navigate("/sign_in")
    // if (currrent_path !== "/") return clear_storage()
    const token = localStorage.getItem("token");
    if (is_auth_page) return;
    if (token === null)
      return navigate(is_admin ? "/admin/sign_in" : "/sign_in");
    // if (id && role && role === "user") dispatch<any>(get_student_info_action(id))
    // else
    dispatch<any>(get_user_info_action());

    if (role) {
      setContext({
        ...get_initial_context(role),
        auth: token !== null,
        user_type: role,
        // menu_item_id: 0,
        user_full_name: full_name,
        user_id: id,
      });
      if (role === "teacher") dispatch<any>(get_teacher_profile_action(id));
      setIsContextPrepared(true);
    }
  }, [currrent_path, role]);



  const reset_menu_item = (item?: number) => { setContext((prev: any) => ({ ...prev, menu_item_id: item })) }
  const logo_click = () =>
  {
    if (role === "user" && current_mode === STUDENT_TASK_MODE && is_lesson_changed) return setContext({ ...context, lesson_pre_save_modal: true, stopped_action: { name: "GENERAL" } })
    reset_menu_item();
    const tm = setTimeout(() => { reset_menu_item(0); clearTimeout(tm) }, 100)
    localStorage.setItem("menu_item", "0")
  }

  const navigate_home = () => navigate("/")

  const AdminStructure = (
    <>
      <MainHeader />
      <MainMenu logo_click={logo_click} />
      <Admin />
    </>
  );

  const StudentStructure = (
    <>
      <StudentHeader logo_click={logo_click} />
      <StudentMenu logo_click={logo_click} />
      <Student />
    </>
  );

  const CuratorStructure = (
    <>
      <CuratorHeader />
      <CuratorMenu logo_click={logo_click} />
      <Curator />
    </>
  );

  const TeacherStructure = (
    <>
      <StudentHeader />
      <StudentMenu logo_click={logo_click} />
      <Teacher />
    </>
  );

  return (
    <Context.Provider value={[context, setContext]}>
      <Routes>
        <Route
          path="/"
          element={
            auth &&
            isContextPrepared && (
              <>
                {role === "admin" && AdminStructure}
                {role === "user" && StudentStructure}
                {role === "teacher" && TeacherStructure}
                {role === "curator" && CuratorStructure}{" "}
              </>
            )
          }
        />
        <Route
          path="/finish_registration"
          element={<UserEntry auth_form_type="auth" />}
        />
        <Route
          path="/admin/sign_in"
          element={<UserEntry auth_form_type="entry" is_admin />}
        />
        <Route path="/sign_in" element={<UserEntry auth_form_type="entry" />} />
        <Route
          path="/password_recovery"
          element={<UserEntry auth_form_type="password_recovery" />}
        />
        <Route
          path="/copy"
          element={<FormulaCopy />}
        />
        <Route
          path="*"
          element={<Page404 navigate_home={ navigate_home } />}
        />
      </Routes>
      <Notification />
    </Context.Provider>
  );
};
