import { courses_icon, db_icon, exit_icon, groups_icon, settings_icon, students_icon, users_icon } from "../../assets/icons/menu";
import { MainMenuItemType } from "../../Components/molecules/MainMenu/types";

export const admin_menu_items: MainMenuItemType[] = [
  {
    id: 0,
    name: "курси",
    icon: courses_icon,
  },
  {
    id: 1,
    name: "студенти",
    icon: students_icon,
  },
  {
    id: 2,
    name: "користувачі",
    icon: users_icon,
  },
  {
    id: 3,
    name: "БД",
    icon: db_icon,
  },
];

export const student_menu_items: MainMenuItemType[] = [
  {
    id: 0,
    name: "курси",
    icon: courses_icon,
  },
  {
    id: 1,
    name: "профіль",
    icon: settings_icon,
  },
  {
    id: 2,
    name: "вихід",
    icon: exit_icon,
  },
];

export const teacher_menu_items: MainMenuItemType[] = [
  {
    id: 0,
    name: "курси",
    icon: courses_icon,
  },
  {
    id: 1,
    name: "група",
    icon: students_icon,
  },
  {
    id: 2,
    name: "БД",
    icon: db_icon,
  },
  {
    id: 3,
    name: "профіль",
    icon: users_icon,
  },
  {
    id: 4,
    name: "вихід",
    icon: db_icon,
  },
];

export const curator_menu_items: MainMenuItemType[] = [
  {
    id: 0,
    name: "групи",
    icon: groups_icon,
  },
  {
    id: 1,
    name: "профіль",
    icon: settings_icon,
  },
  {
    id: 2,
    name: "вихід",
    icon: exit_icon,
  },
];