import axios from 'axios';
import {
  GET_STUDENT_LESSONS_PENDING,
  GET_STUDENT_LESSONS_SUCCESS,
  GET_STUDENT_LESSONS_FAIL,
  GET_STUDENT_EXAMS_PENDING,
  GET_STUDENT_EXAMS_SUCCESS,
  GET_STUDENT_EXAMS_FAIL,
} from "./../../constants/student";
import { requestConfig, serv } from "../../constants/config";

export const get_student_lessons_action = (master_group_id: number, callback?: (lessons: any) => void) => (dispatch: any) =>
{
  const url = `${serv}/studentpanel/lessons?master_group_id=${master_group_id}`;
  dispatch({ type: GET_STUDENT_LESSONS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => { dispatch({ type: GET_STUDENT_LESSONS_SUCCESS, payload: res.data }); callback && callback(res.data) })
    .catch((err: any) => dispatch({ type: GET_STUDENT_LESSONS_FAIL, payload: err }));
};

export const get_student_exams_action = (master_group_id: number, callback?: (lessons: any) => void) => (dispatch: any) =>
{
  const url = `${serv}/studentpanel/master_group_exams?master_group_id=${master_group_id}`;
  dispatch({ type: GET_STUDENT_EXAMS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => { dispatch({ type: GET_STUDENT_EXAMS_SUCCESS, payload: res.data }); callback && callback(res.data) })
    .catch((err: any) => dispatch({ type: GET_STUDENT_EXAMS_FAIL, payload: err }));
};

export const clear_student_lessons_action = () => (dispatch: any) =>
{
  dispatch({ type: "CLEAR_STUDENT_LESSONS" });
};
