import "./styles.sass"
import { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { AdminCoursesType } from "./types";
import { Context } from "../../../context";
import { ADD_LESSON_MODE, ADD_MODULE_MODE, ADD_TEST_MODE, LESSON_MODE, MODULE_MODE, PURE_MODULES_MODE, SUBJECT_MODE, TASKS_MODE } from "../../../constants/admin";
import { AdminSubjectItems } from "../../atoms/AdminSubjectItems";
import { AdditionalMenu } from "../../molecules/AdditionalMenu";
import { useDispatch } from "react-redux";
import { delete_lesson_action, delete_module_action, delete_test_action, get_lessons_action, get_modules_action, get_lesson_homework_action, get_tests_action, get_test_homework_action, post_subject_action } from "../../../redux/actions/admin";
import { AdminModuleInfo } from "../../organisms/AdminModuleInfo";
import { AdminLessonInfo } from "../../organisms/AdminLessonInfo";
import { AdminTasksInfo } from "../../organisms/AdminTasksInfo";
import { ModalHoc } from "../../../hoc/Modal";
import { AddTaskModal } from "../../molecules/AddTaskModal";
import { AddSubjectModal } from "../../molecules/AddSubjectModal";
import { NewYearModal } from "../../molecules/NewYearModal";
import { is_lesson } from "../../../tools/admin/common";

export const AdminCourses: FC<AdminCoursesType> = () => {
  const [context, setContext] = useContext(Context)
  const dispatch = useDispatch()
  const { search_task_modal, show_additional_menu, current_mode, subject, module, lesson, add_subject_modal, new_year_modal } = context
  const { admin_subjects, admin_modules, admin_tasks } = useSelector((st: any) => st)

  const isTest = (name: string) => !is_lesson(name)

  const subject_click = (id: number, name: string) => {
    dispatch<any>(get_modules_action(id))
    localStorage.setItem("subject_item", JSON.stringify({ id, name }))
    localStorage.removeItem("module_item")
    localStorage.removeItem("lesson_item")
    localStorage.removeItem("test_item")
    setContext({
      ...context,
      subject: { id, name },
      module: null,
      lesson: null,
      test: null,
      current_mode: PURE_MODULES_MODE,
      show_additional_menu: true
    })
  }

  const module_click = (selected_module: any) => {
    localStorage.removeItem("lesson_item")
    localStorage.removeItem("task_item")
    const isSame = module && module?.id && module.id === selected_module.id
    !isSame &&
      dispatch<any>(get_lessons_action(selected_module.id))
    dispatch<any>(get_tests_action(selected_module.id))
    localStorage.setItem("module_item", JSON.stringify(selected_module))
    setContext({
      ...context,
      module: isSame ? null : selected_module,
      lesson: null,
      task: null,
      current_mode: isSame ? PURE_MODULES_MODE : MODULE_MODE
    })
  }



  const lesson_or_test_click = (item: any) => {
    const type = isTest(item.name) ? "test" : "lesson"
    localStorage.setItem(`${type}_item`, JSON.stringify(item))
    if (!isTest(item.name))
    {
      setContext({ ...context, lesson: item, current_mode: LESSON_MODE })
      dispatch<any>(get_lesson_homework_action(item.id));
    } else
    {
      setContext({ ...context, current_mode: TASKS_MODE, lesson: item })
      dispatch<any>(get_test_homework_action(item.id))
    }
  }

  const delete_module = () => {
    dispatch<any>((delete_module_action(module.id, subject.id)))
    setContext({ ...context, current_mode: PURE_MODULES_MODE, module: null })
  }

  const delete_lesson = () => {
    current_mode === LESSON_MODE && dispatch<any>(delete_lesson_action(lesson.id, module.id));
    admin_tasks.is_it_lesson && dispatch<any>(delete_test_action(lesson.id, module.id));
    setContext({ ...context, current_mode: MODULE_MODE, lesson: null, test: null })
  }

  const coursesDeleteAction = () => {
    current_mode === MODULE_MODE && delete_module();
    (current_mode === LESSON_MODE || admin_tasks.is_it_lesson) && delete_lesson();
  };

  const create_new_subject = (name: string) => {
    dispatch<any>(post_subject_action(name.toUpperCase()));
    setContext({ ...context, add_subject_modal: false });
  };

  const additional_menu_show_condition = show_additional_menu && admin_modules.modules.length > 0 && current_mode !== SUBJECT_MODE && current_mode !== TASKS_MODE
  const subjects_show_condition = current_mode === SUBJECT_MODE
  const module_show_condition = (current_mode === MODULE_MODE && module !== null) || current_mode === ADD_MODULE_MODE
  const lesson_show_condition = current_mode === LESSON_MODE || current_mode === ADD_LESSON_MODE || current_mode === ADD_TEST_MODE
  const tasks_show_condition = current_mode === TASKS_MODE

  const delete_show_condition = current_mode === MODULE_MODE || current_mode === LESSON_MODE

  const additional_menu_props = { module_click, lesson_or_test_click, current_module_id: module?.id ?? null, current_mode }
  const subject_items_props = { admin_subjects, onClick: subject_click }

  return (
    <div className="AdminCoursesContainer">
      <div className="AdminCourses">
        {additional_menu_show_condition && <AdditionalMenu {...additional_menu_props} />}
        <div className="AdminCabinet">
          {subjects_show_condition && <AdminSubjectItems {...subject_items_props} />}
          {module_show_condition && <AdminModuleInfo />}
          {lesson_show_condition && <AdminLessonInfo isTest={isTest} lesson_or_test_click={lesson_or_test_click} />}
          {tasks_show_condition && <AdminTasksInfo delete_lesson={delete_lesson} />}
        </div>
      </div>
      {search_task_modal && <ModalHoc children={<AddTaskModal />} />}
      {delete_show_condition &&
        <div className="CoursesDeleteButton" onClick={coursesDeleteAction}>
          видалити
        </div>
      }
      {add_subject_modal && <ModalHoc children={<AddSubjectModal create_new_subject={create_new_subject} />} />}
      {new_year_modal && <ModalHoc children={<NewYearModal />} />}
    </div>
  )
}