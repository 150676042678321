export const GET_TESTS_PENDING = "GET_TESTS_PENDING";
export const GET_TESTS_SUCCESS = "GET_TESTS_SUCCESS";
export const GET_TESTS_FAIL = "GET_TESTS_FAIL";

export const CREATE_TEST_PENDING = "CREATE_TEST_PENDING";
export const CREATE_TEST_SUCCESS = "CREATE_TEST_SUCCESS";
export const CREATE_TEST_FAIL = "CREATE_TEST_FAIL";

export const UPDATE_TEST_PENDING = "UPDATE_TEST_PENDING";
export const UPDATE_TEST_SUCCESS = "UPDATE_TEST_SUCCESS";
export const UPDATE_TEST_FAIL = "UPDATE_TEST_FAIL";

export const DELETE_TEST_PENDING = "DELETE_TEST_PENDING";
export const DELETE_TEST_SUCCESS = "DELETE_TEST_SUCCESS";
export const DELETE_TEST_FAIL = "DELETE_TEST_FAIL";