import axios from 'axios';
import {
  GET_STUDENT_TASKS_PENDING,
  GET_STUDENT_TASKS_SUCCESS,
  GET_STUDENT_TASKS_FAIL,
} from "./../../constants/student";
import { requestConfig, serv } from "../../constants/config";

export const get_student_tasks_action = (homework_id: number, callback?: () => void) => (dispatch: any) =>
{
  if (!homework_id) return;
  const url = `${serv}/studentpanel/homeworks/${homework_id}`;
  dispatch({ type: GET_STUDENT_TASKS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => { dispatch({ type: GET_STUDENT_TASKS_SUCCESS, payload: res.data }); callback && callback() })
    .catch((err: any) => dispatch({ type: GET_STUDENT_TASKS_FAIL, payload: err }));
};

export const get_student_exam_tasks_action = (data: any, callback?: () => void) => (dispatch: any) =>
{
  dispatch({ type: GET_STUDENT_TASKS_SUCCESS, payload: data});
};

export const save_homevork_action = (user_id: number, user_homework_id: number, answers: { answer: string, exercise_id: number }[]) =>
{
  const url = `${serv}/studentpanel/homeworks/save_answers`
  const body = { user_answers: { user_id, user_homework_id, answers } }
  return axios.post(url, body, requestConfig())
}
export const send_homevork_action = (user_id: number, user_homework_id: number, answers: { answer: string, exercise_id: number }[]) =>
{
  const url = `${serv}/studentpanel/homeworks/send_to_inspection`
  const body = { user_answers: { user_id, user_homework_id, answers } }
  return axios.post(url, body, requestConfig())
}
export const send_exam_action = (user_id: number, user_master_group_exam_id: number, answers: { answer: string, exercise_id: number }[]) =>
{
  const url = `${serv}/studentpanel/master_group_exams/finish_exam`
  const body = { user_answers: { user_id, user_master_group_exam_id, answers } }
  return axios.post(url, body, requestConfig())
}
