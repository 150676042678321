import React, { useEffect, useRef } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

interface MathRendererProps {
    mathExpression: string;
}
const katexOptions = {
        displayMode: false,
        throwOnError: true,
        strict: 'ignore',

};

function MathRenderer({ mathExpression }: MathRendererProps) {
    const mathContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        if (mathContainerRef.current) {
            katex.render(mathExpression, mathContainerRef.current,katexOptions);
        }
    }, [mathExpression]);

    return <div ref={mathContainerRef} />;
}

export default MathRenderer;