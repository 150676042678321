// MODULES
export const GET_STUDENT_MODULES_PENDING = "GET_STUDENT_MODULES_PENDING";
export const GET_STUDENT_MODULES_SUCCESS = "GET_STUDENT_MODULES_SUCCESS";
export const GET_STUDENT_MODULES_FAIL = "GET_STUDENT_MODULES_FAIL";

// LESSONS
export const GET_STUDENT_LESSONS_PENDING = "GET_STUDENT_LESSONS_PENDING";
export const GET_STUDENT_LESSONS_SUCCESS = "GET_STUDENT_LESSONS_SUCCESS";
export const GET_STUDENT_LESSONS_FAIL = "GET_STUDENT_LESSONS_FAIL";

// LESSONS
export const GET_STUDENT_EXAMS_PENDING = "GET_STUDENT_EXAMS_PENDING";
export const GET_STUDENT_EXAMS_SUCCESS = "GET_STUDENT_EXAMS_SUCCESS";
export const GET_STUDENT_EXAMS_FAIL = "GET_STUDENT_EXAMS_FAIL";

// TASKS
export const GET_STUDENT_TASKS_PENDING = "GET_STUDENT_TASKS_PENDING";
export const GET_STUDENT_TASKS_SUCCESS = "GET_STUDENT_TASKS_SUCCESS";
export const GET_STUDENT_TASKS_FAIL = "GET_STUDENT_TASKS_FAIL";

// SUBJECTS
export const GET_STUDENT_SUBJECTS_PENDING = "GET_STUDENT_SUBJECTS_PENDING";
export const GET_STUDENT_SUBJECTS_SUCCESS = "GET_STUDENT_SUBJECTS_SUCCESS";
export const GET_STUDENT_SUBJECTS_FAIL = "GET_STUDENT_SUBJECTS_FAIL";

// PROFILE
export const GET_STUDENT_PROFILE_PENDING = "GET_STUDENT_PROFILE_PENDING";
export const GET_STUDENT_PROFILE_SUCCESS = "GET_STUDENT_PROFILE_SUCCESS";
export const GET_STUDENT_PROFILE_FAIL = "GET_STUDENT_PROFILE_FAIL";

// PHOTOS
export const POST_PHOTO_PENDING = "POST_PHOTOS_PENDING"
export const POST_PHOTO_SUCCESS = "POST_PHOTOS_SUCCESS"
export const POST_PHOTO_FAIL = "POST_PHOTOS_FAIL"
