import "./styles.sass";
import { FC } from "react";
import { TeacherTasksType } from "./types";
import { TeacherLessonItem } from "../../molecules/TeacherLessonItem";
import { TaskMarkPoints } from "../../atoms/TaskMarkPoints";
import { emptySelectIcon, fillSelectIcon } from "../../../assets/icons/common";
import { prepare_number } from "../../../tools/correct_number";
import { prepare_text_to_math } from "../../../tools/latex/field";

export const TeacherTasks: FC<TeacherTasksType> = ({
  exercise,
  index,
  db_mode,
}) => {
  const exercise_type = exercise.exercise_type;

  const translate_exercise_type = () =>
  {
    if (exercise_type === "ShortAnswer") return "коротка відповідь"
    if (exercise_type === "ChooseAnswer") return "Тест"
    if (exercise_type === "MatchPair") return "відповідність"
    if (exercise_type === "ManualSolution") return "розгорнута відповідь"
  }

  const prepare_points = () => {
    if (exercise_type !== "Test") return;
    const initial_answers = exercise.possible_answer[0].first_row;
    const right_answer = exercise.right_solution;
    const answers = initial_answers.map((item: string) => item.split(" ")[1]);
    const correct_answer_position = answers.indexOf(right_answer);
    return (
      <div className="PossibleAnswerItemContainer">
        {initial_answers.map((item: string, index: number) => (
          <div className="PossibleAnswerItem">
            <div>
              {correct_answer_position === index
                ? fillSelectIcon
                : emptySelectIcon}
            </div>
            <div className="PossibleAnswerText">{item}</div>
          </div>
        ))}
      </div>
    );
  };

  const prepared_task_number = () => prepare_number(index + 1);

  const reg = (repl: string) => new RegExp(repl, 'g')

  const prepare_task_variants = (type: "first_row" | "second_row") => {
    if (exercise_type !== "MatchPair") return;
    const initial_variant = exercise.possible_answer[0][type];
    const numbering_items: string[] = [];
    const value_items: string[] = [];
    for (let i = 0; i < initial_variant.length; i++) {
      const split_item = initial_variant[i].split(" ");
      numbering_items.push(split_item[0]);
      value_items.push(split_item.splice(1).join(" "));
    }
    return (
      <div className="PossibleAnswerItemContainer">
        {initial_variant.map((_: string, index: number) => (
          <div className="PossibleAnswerItem">
            <div>{numbering_items[index]}</div>
            <div className="PossibleAnswerText">{value_items[index].replace(reg("@"), "")}</div>
          </div>
        ))}
      </div>
    );
  };

  const exercise_title = !db_mode && (
    <h1 className="TeacherTasksTaskOneTitle">
      Завдання {prepared_task_number()} | {exercise.max_shore}
    </h1>
  );

  const exercise_info = (
    <div className="TeacherTasksTaskOneTop">
      <div className="TaskInformationTextBlock">
        <TeacherLessonItem
          title="ID"
          rounded
          height={25}
          width={100}
          text={exercise.id.toString()}
          vertical_centered
          horizontal_centered
        />
        <TeacherLessonItem
          title="Тип"
          titleWidth={40}
          rounded
          height={25}
          width={165}
          text={translate_exercise_type()}
          vertical_centered
          uppercase
        />
      </div>
      <div className="TaskInformationTextBlock">
        <div className="TaskInformationText">Бали</div>
        <TaskMarkPoints
          taskMark={exercise.max_shore}
          setTaskMark={() => {}}
          disabled
        />
      </div>
    </div>
  );

  return (
    <div className="TeacherTasks">
      <div className="TeacherTasksTaskOne">
        {exercise_title}
        {exercise_info}
        <div className="TeacherTasksTaskOneMain">
          <div className="TeacherTasksTaskOneMainColumn">
            <TeacherLessonItem
              title="Завдання"
              text={prepare_text_to_math(exercise.task)}
              height={100}
            />
            <TeacherLessonItem
              title="Підказка"
              text={exercise.hint}
              height={100}
            />
            {exercise_type === "MatchPair" && (
              <TeacherLessonItem
                title="Відповідь"
                text={prepare_text_to_math(exercise.correct_answer)}
                height={44}
                vertical_centered
              />
            )}
            <TeacherLessonItem
              title="Хештеги"
              text={exercise.hashtag}
              height={100}
              textColor="#C4BEAB"
            />
          </div>
          <div className="TeacherTasksTaskOneMainColumn">
            {(exercise_type === "ShortAnswer" ||
              exercise_type === "ManualSolution") && (
              <>
                <TeacherLessonItem
                  title="Рішення"
                  text={prepare_text_to_math(exercise.right_solution)}
                  height={100}
                />
                <TeacherLessonItem
                  title="Відповідь"
                  text={exercise.correct_answer}
                  height={25}
                  vertical_centered
                />
              </>
            )}
            {exercise_type === "MatchPair" && (
              <>
                <TeacherLessonItem
                  title="Пункти 1, 2..."
                  text={prepare_task_variants("first_row")}
                  height={125}
                />
                <TeacherLessonItem
                  title="Пункти A, B..."
                  text={prepare_task_variants("second_row")}
                  height={225}
                />
              </>
            )}
            {exercise_type === "Test" && (
              <TeacherLessonItem
                title="Пункти"
                text={prepare_points()}
                height={170}
              />
            )}
          </div>
        </div>
        <div className="TeacherTasksTaskOneMainBorder"></div>
      </div>
    </div>
  );
};
