import "./styles.sass";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { addIcon, deleteIcon, emptySelectIcon, fillSelectIcon, fillAddPhotoIcon, emptyAddPhotoIcon } from "../../../assets/icons/common";
import { SimpleInput } from "../../atoms/SimpleInput";
import { TaskVariantPointsType } from "./types";
import { alphabetic_list_ua } from "../../../constants/general/common";
import { is_json_string } from "../../../tools/common";
import { TaskPhotosArea } from "../../atoms/TaskPhotosArea";

export const TaskVariantPoints: FC<TaskVariantPointsType> = ({
  point_items_data,
  photo_items_data,
  add_point,
  select_point,
  change_point_text,
  delete_point,
  name,
  task_id = -1,
  correct_answer,
  disabled = false,
  mode = "text",
  variant = "single",
  variant_items = [],
  change_point_variant = () => {},
  add_photo_point,
  delete_photo_point
}) =>
{
  const [ general_mode, set_general_mode ] = useState<"text" | "photos">("text")

  const is_photos = () =>
  {
    if (photo_items_data instanceof Array)
    {
      if (photo_items_data.length > 0) return true
    }
    return false
  }

  useEffect(() =>
  {
    set_general_mode(is_photos() ? "photos" : "text")
  }, [point_items_data])

  const toggle_general_mode = () => {
    // toggle_points_mode(name, task_id)
    set_general_mode((prev: "text" | "photos") => prev === "text" ? "photos" : "text" )
  }

  const find_variant = (num: number) => variant_items.find((el: any) => parseInt(el.num) === num)
  const additional_item = (id: number) =>
  {
    if (variant === "single") return id + 1
    const it_len = variant_items.length
    return <div style={{ display: "flex" }}>
      <div className="AddBarText mr10">{ id + 1 }</div>
      <SimpleInput
        width={40}
        onChange={ (event: any) => change_point_variant({ num: id + 1, answ: event.target.value.toUpperCase() }, task_id) }
        value={ it_len ? find_variant(id + 1) ? find_variant(id + 1).answ : "" : "" }
      />
    </div> 
  }

  const compare_items = (first: string, second: string) =>
  {
    if (second === "") return false;
    const exp = /[\\\\quad"\{\}\,\.\@\[\] ]/g;
    const first_p = first.replace(exp, "");
    const second_p = second.replace(exp, "");
    if (first_p === second_p) return true;
    return false;
  }

  return (
    <div className="TestPointsContainer">
      <div className="TestPointsAddBar">
        <div className="AddBarText">
          <div>Пункти</div>
          <div className="PointsVariant" onClick={toggle_general_mode}>
            <div className={ `AddBarTextItem ${ general_mode === "photos" ? "AddBarTextItem_empty" : "" }`}>{name === "first_row" ? "123" : "абв"}</div>
                {general_mode === "photos" ? fillAddPhotoIcon : emptyAddPhotoIcon}
            </div>
          <div
            className="AddBarIcon"
            onClick={() => (disabled || general_mode === "photos") ? {} : add_point(name, task_id)}
          >
            { general_mode === "text"
              ? addIcon
              : <label className="custom-file-upload">
                  <input
                    type="file"
                    name={ name }
                    multiple
                    onChange={ (event: any) => add_photo_point(name, event.target.files, task_id) }
                  />
                  { addIcon }
                </label>
            }
          </div>
        </div>
      </div>
      <div className="TestPointsBody">
        {point_items_data && general_mode === "text" && point_items_data.length > 0 && point_items_data.map((item: string, id: number) =>
        {
          return (
          <div className="PointItemContainer" key={id}>
            {select_point && <div
              className="PointItemSelect"
              onClick={() => disabled ? {} : select_point(item, task_id)}
            >
              {compare_items(item, correct_answer ?? "") ? fillSelectIcon : emptySelectIcon}
            </div>}
            {!select_point && <div className="PointItemNumber AddBarText">{ name === "first_row" ? additional_item(id)  : alphabetic_list_ua[id] }</div>}
            <div className="PointItemInput">
              { mode === "text"
                ? <SimpleInput
                    onChange={(e: ChangeEvent<HTMLInputElement>) => change_point_text(e.target.value, id, name, task_id)}
                    value={typeof item !== "object" ? item : ""}
                    margin="0 10px"
                    placeholder="write item text"
                    disabled={disabled}
                  />
                : // @ts-ignore
                  <math-field
                    virtual-keyboard-mode="manual"
                    math-mode-space = '\quad'
                    style={{ minWidth: 500, minHeight: 25, border: "1px solid gray", padding: "0 5px 0 10px" }}
                    data-name={ name }
                    data-id={ name === "first_row" ? id+task_id : id }
                    data-point_id={ id }
                    data-task_id={ task_id }
                    data-type={ "point_item" }
                    value={ is_json_string(typeof item !== "object" ? item : "") ? JSON.parse(typeof item !== "object" ? item : "") : item }
                  />
              }
            </div>
            <div
              className="PointItemDelete"
              onClick={() => disabled ? {} : delete_point(id, name, task_id )}
            >
              {deleteIcon}
            </div>
          </div>
          )
        })}
        {  general_mode === "photos" && is_photos() && photo_items_data.map((item: { id: number, url: File | string }, id: number) =>
          {
            return (
              <div className="PointPhotoItemContainer" key={id}>
                {select_point && <div
                  className="PointItemSelect"
                  onClick={() => disabled ? {} : select_point(item.id, task_id)}
                >
                  {/* @ts-ignore */}
                  {(correct_answer && item.id == correct_answer) ? fillSelectIcon : emptySelectIcon}
                </div>}
                {!select_point && <div className="PointItemNumber AddBarText">{ name === "first_row" ? additional_item(id)  : alphabetic_list_ua[id] }</div>}
                <TaskPhotosArea
                  photos={ [ item ] }
                  name={ name === "first_row" ? "possible_answer_fr_photos" : "possible_answer_sr_photos" }
                  delete_photos={(name, id) => delete_photo_point(name, id, task_id)}
                />
              </div>
            )
          }
        )}
      </div>
    </div>
  )
}
