import {
  CLEAR_STUDENT,
  GET_CURATOR_STUDENTS_LIST_FAIL,
  GET_CURATOR_STUDENTS_LIST_PENDING,
  GET_CURATOR_STUDENTS_LIST_SUCCESS,
  GET_CURATOR_WORK_LIST_FAIL,
  GET_CURATOR_WORK_LIST_PENDING,
  GET_CURATOR_WORK_LIST_SUCCESS,
  SET_SINGLE_STUDENT,
  SET_STUDENTS_ID,
  SET_USER_TASK_FAIL,
  SET_USER_TASK_PENDING,
  SET_USER_TASK_SUCCESS,
} from "../../constants/curator";

const initialState = {
  worklist: {},
  students: [],
  student: [],
  pending: true,
  pendingList: true,
  selected_student_id: null,
  homeworkPending: false,
  homework: {},
  student_group_id: null,
  work_name: "",
};

export const curator_groups = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CURATOR_WORK_LIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_CURATOR_WORK_LIST_SUCCESS:
      return {
        ...state,
        worklist: { ...action.payload },
        pending: false,
      };
    case GET_CURATOR_WORK_LIST_FAIL:
      return {
        ...state,
        pending: false,
      };
    case GET_CURATOR_STUDENTS_LIST_PENDING:
      return {
        ...state,
        pendingList: true,
      };
    case GET_CURATOR_STUDENTS_LIST_SUCCESS:
      return {
        ...state,
        students: [...action.payload],
        pendingList: false,
      };
    case GET_CURATOR_STUDENTS_LIST_FAIL:
      return {
        ...state,
        pendingList: false,
      };

    case SET_SINGLE_STUDENT:
      return {
        ...state,
        student: action.payload.data,
        selected_student_id: action.payload.selected_student_id,
      };

    case CLEAR_STUDENT:
      return {
        ...state,
        student: [],
        selected_student_id: null,
        homework: {},
        students: [],
        homeworkPending: false,
      };

    case SET_USER_TASK_PENDING:
      return {
        ...state,
        homeworkPending: false,
      };
    case SET_USER_TASK_SUCCESS:
      return {
        ...state,
        selected_student_id: action.payload.id ? action.payload.id : state.selected_student_id,
        homework: action.payload.homework,
        homeworkPending: true,
        work_name: action.payload.work_name ? action.payload.work_name : state.work_name,
      };
    case SET_USER_TASK_FAIL:
      return {
        ...state,
        homeworkPending: true,
      };

    case SET_STUDENTS_ID:
      return {
        ...state,
        student_group_id: action.payload,
      };

    default:
      return { ...state };
  }
};
