import {
  GET_TESTS_PENDING,
  GET_TESTS_SUCCESS,
  GET_TESTS_FAIL,
} from "../../constants/admin";

const initialState = {
  tests: [],
  pending: false,
  error: "",
};

export const admin_tests = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TESTS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TESTS_SUCCESS:
      return {
        ...state,
        tests: action.payload,
        pending: false,
      };
    case GET_TESTS_FAIL:
      return {
        ...state,
        tests: [],
        pending: false,
        error: action,
      };
    default: {
      return state;
    }
    }
}