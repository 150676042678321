import axios from 'axios';
import {
  GET_STUDENT_MODULES_PENDING,
  GET_STUDENT_MODULES_SUCCESS,
  GET_STUDENT_MODULES_FAIL,
} from "./../../constants/student";
import { requestConfig, serv } from "../../constants/config";

export const get_student_modules_action = (user_id: number, subject_id: number, callback?: (m: any) => void) => (dispatch: any) =>
{
  const url = `${serv}/studentpanel/master_groups?user_id=${user_id}&subject_id=${subject_id}`;
  dispatch({ type: GET_STUDENT_MODULES_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) =>
    {
      dispatch({ type: GET_STUDENT_MODULES_SUCCESS, payload: res.data })
      callback && callback(res?.data ? res.data : [])
    })
    .catch((err: any) =>
    {
      dispatch({ type: GET_STUDENT_MODULES_FAIL, payload: err })
      callback && callback([])
    });
};
