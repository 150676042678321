import {
  GET_STUDENT_MODULES_PENDING,
  GET_STUDENT_MODULES_SUCCESS,
  GET_STUDENT_MODULES_FAIL,
} from "./../../constants/student";

const initialState = {
  modules: [],
  pending: false,
  error: "",
};

export const student_modules = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STUDENT_MODULES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload,
        pending: false,
      };
    case GET_STUDENT_MODULES_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }