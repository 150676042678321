export const email_validate = (email: string) => /\S+@\S+\.\S+/.test(email)
export const is_error = (entry_data: any, fields_array: string[]) => {
  for (let i = 0; i < fields_array.length; i++) {
  if (entry_data[fields_array[i]].length < 1) return false
}
return true;
};

export const phone_validate = (p: any) =>
{
  const regex = /^[\+]?[(]?[0-9]{3}[) ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
  return regex.test(p)
}
