export const GET_SUBJECTS_PENDING = "GET_SUBJECTS_PENDING";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";
export const GET_SUBJECTS_FAIL = "GET_SUBJECTS_FAIL";

export const CREATE_SUBJECT_PENDING = "CREATE_SUBJECT_PENDING";
export const CREATE_SUBJECT_SUCCESS = "CREATE_SUBJECT_SUCCESS";
export const CREATE_SUBJECT_FAIL = "CREATE_SUBJECT_FAIL";

export const GET_BOUGHT_SUBJECTS_PENDING = "GET_BOUGHT_SUBJECTS_PENDING";
export const GET_BOUGHT_SUBJECTS_SUCCESS = "GET_BOUGHT_SUBJECTS_SUCCESS";
export const GET_BOUGHT_SUBJECTS_FAIL = "GET_BOUGHT_SUBJECTS_FAIL";
