import axios from 'axios';
import {
  GET_STUDENTS_PENDING,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
} from "./../../constants/admin";
import { requestConfig, serv } from "../../constants/config";

export const get_students_action = () => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/users`;
  dispatch({ type: GET_STUDENTS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_STUDENTS_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_STUDENTS_FAIL, payload: err }));
};