import { FC } from "react";
import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { v4 as uuidv4 } from "uuid";
import { arrowBack } from "../../../assets/icons/common";
import "./style.sass";

export const ModalSlider: FC<any> = ({ work_photos, set_work_photos }) => {
  const { student } = useSelector((st: any) => st.curator_groups);

  return (
    <div className="modal-main">
      <div className="modal-wrapper">
        <div onClick={() => set_work_photos([])} className="aroowBlock">
          {arrowBack}
        </div>
        {student[0] && (
          <h1 className="modal-user-name">{student[0].full_name}</h1>
        )}
      </div>
      <Carousel dynamicHeight emulateTouch swipeable showThumbs showArrows>
        {work_photos.map((file: {id: number, url: string}) =>
          <div key={uuidv4()}>
            <img src={file.url} />
          </div>
        )}
      </Carousel>
    </div>
  );
};
