import { FC, useContext } from "react"
import { useSelector } from "react-redux"
import { closeIcon } from "../../../assets/icons/common"
import { Context } from "../../../context"
import { generate_db_task } from "../../../tools/admin/education_initials"
import { AdminTasksInfo } from "../../organisms/AdminTasksInfo"
import "./styles.sass"

export const AddDbTaskModal: FC<any> = () =>
{
  const [context, setContext] = useContext(Context)
  const { tasks } = useSelector((st: any) => st.admin_tasks)
  return (
    <div className="AddDbTaskModal">
      <div className="AddDbTaskModalClose">
        <div onClick={() => setContext({...context, add_db_task_modal: false})}>{closeIcon}</div>
      </div>
      <div className="AddDbTaskModalContainer">
        <AdminTasksInfo taskExternal taskExternalBody={generate_db_task(tasks.length)} />
      </div>
    </div> 
  )
}