import { FC } from "react";
import { v4 as uuidv4 } from "uuid";
import "./styles.sass";
import { CuratorStudentTableHeaderTypes } from "./types";

export const CuratorStudentTableHeader: FC<CuratorStudentTableHeaderTypes> = ({
  headersTitle,
}) => (
  <thead className="curator-thead">
    <tr>
      {headersTitle.homeworks.map((header: any, id: number) => (
        <th key={uuidv4()} className="thNameEL">
          <p>{header.name}</p>
          <p className="table_balls" >{header.balls}</p>
        </th>
      ))}
      {headersTitle.exams.map((header: any, id: number) => (
        <th key={uuidv4()} className="thNameEL">
          <p>{header.name}</p>
          <p className="table_balls" >{header.balls}</p>
        </th>
      ))}
    </tr>
  </thead>
);
