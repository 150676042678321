import "./styles.sass";
import { FC } from "react";
import { EmptyCheckboxIcon, FullCheckboxIcon } from "../../../assets/icons/chackbox";
import { CheckBoxType } from "./types";

export const CheckBox: FC<CheckBoxType> = ({ selected, onClick, width, height }) => 
  <div
    className={`CheckBox`}
    onClick={onClick}
    style={{ width: width ?? 40, height: height ?? 40 }}
  >
    { selected ? FullCheckboxIcon : EmptyCheckboxIcon }
  </div>
