import "./styles.sass";
import { FC, useContext } from "react";
import { mainLogoIcon } from "../../../assets/icons/header";
import { admin_menu_items } from "../../../constants/general/menu";
import { MainMenuItemType, MainMenuTypes } from "./types";
import { Context } from "../../../context";
import { SUBJECT_MODE } from "../../../constants/admin";

export const MainMenu: FC<MainMenuTypes> = ({logo_click}) => {
  const [context, setContext] = useContext(Context);
  const { menu_item_id } = context
  const toggle = (id: number) => {
    setContext({ ...context, current_mode: SUBJECT_MODE, menu_item_id: id })
    localStorage.setItem("menu_item", JSON.stringify(id));
  }

  return (
    <div className={`MainMenu`}>
      <div className="MenuLabelContainer" onClick={logo_click}>
        {mainLogoIcon}
      </div>
      <div className="MenuItemsContainer">
        {admin_menu_items.map((item: MainMenuItemType) => (
          <div
            key={item.id}
            className={item.id === menu_item_id ? "MenuItem MenuItemActive" : "MenuItem"}
            onClick={() => toggle(item.id)}
          >
            <div className="MenuItemIcon">{item.icon}</div>
            <div className="MenuItemName">{item.name.toUpperCase()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
