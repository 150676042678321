import axios from "axios";
import { is_lesson } from "../../../tools/admin/common";
import { requestConfig, serv } from "../../constants/config";
import {
  CLEAR_STUDENT,
  GET_CURATOR_STUDENTS_LIST_FAIL,
  GET_CURATOR_STUDENTS_LIST_PENDING,
  GET_CURATOR_STUDENTS_LIST_SUCCESS,
  GET_CURATOR_WORK_LIST_FAIL,
  GET_CURATOR_WORK_LIST_PENDING,
  GET_CURATOR_WORK_LIST_SUCCESS,
  SET_SINGLE_STUDENT,
  SET_STUDENTS_ID,
  SET_USER_TASK_FAIL,
  SET_USER_TASK_PENDING,
  SET_USER_TASK_SUCCESS,
} from "../../constants/curator";

export const get_curator_work_list = (group_id: number) => (dispatch: any) => {
  const url = `${serv}/curatorpanel/students_groups/${group_id}/work_list`;
  dispatch({ type: GET_CURATOR_WORK_LIST_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) =>
      dispatch({ type: GET_CURATOR_WORK_LIST_SUCCESS, payload: res.data })
    )
    .catch((err: any) =>
      dispatch({ type: GET_CURATOR_WORK_LIST_FAIL, payload: err })
    );
};

export const get_curator_student_list =
  (group_id: number, query: any, id: any, callback?: (sts: any) => void) => (dispatch: any) => {
    let url;
    if (query === "index") {
      url = `${serv}/curatorpanel/students_groups/${group_id}/students_list`;
    } else if (query === "name") {
      url = `${serv}/curatorpanel/students_groups/${group_id}/students_list?name=true`;
    } else if (query === "homework") {
      url = `${serv}/curatorpanel/students_groups/${group_id}/students_list?homework_id=${id}`;
    } else {
      url = `${serv}/curatorpanel/students_groups/${group_id}/students_list?master_group_exam_id=${id}`;
    }

    dispatch({ type: GET_CURATOR_STUDENTS_LIST_PENDING });
    axios
      .get(url, requestConfig())
      .then((res: any) =>
        {
          dispatch({ type: GET_CURATOR_STUDENTS_LIST_SUCCESS, payload: res.data })
          callback && callback(res.data)
        }
      )
      .catch((err: any) =>
        {
          dispatch({ type: GET_CURATOR_STUDENTS_LIST_FAIL, payload: err })
          callback && callback([])
        }
      );
  };

export const set_single_student = (data: any, selected_student_id: any) => {
  return { type: SET_SINGLE_STUDENT, payload: { data, selected_student_id } };
};

export const set_user_task =
  (data: any, user_id?: number, work_name?: string) => (dispatch: any) => {
    const url = `${serv}/curatorpanel/${is_lesson(work_name ?? "урок") ? "user_homeworks" : "user_master_group_exams"}/${data}`;
    dispatch({ type: SET_USER_TASK_PENDING });
    axios
      .get(url, requestConfig())
      .then((res: any) => {
        return dispatch({
          type: SET_USER_TASK_SUCCESS,
          payload: { homework: res.data, id: user_id, work_name: work_name },
        });
      })
      .catch((err: any) =>
        dispatch({ type: SET_USER_TASK_FAIL, payload: err })
      );
  };

export const clear_single_student = () => {
  return { type: CLEAR_STUDENT };
};

export const set_students_id = (id: any) => {
  return { type: SET_STUDENTS_ID, payload: id };
};

export const cahnge_exercise_estimate_action = (
  user_answer_id: number,
  estimation: number,
  user_hw_id: number,
  callback?: (status: number) => void ) => (dispatch: any) =>
{
  const url = `${serv}/curatorpanel/user_homeworks/${user_hw_id}/estimate`
  const body = { estimate: { user_answer_id, estimation } }
  axios
    .post(url, body, requestConfig())
    .then((res: any) => callback && callback(res.status))
    .catch((err: any) => callback && callback(err.response.status))
}

export const finish_work_check_action = (
  curator_comment: string,
  user_hw_id: number,
  callback?: (status: number) => void ) => (dispatch: any) =>
{
  const url = `${serv}/curatorpanel/user_homeworks/${user_hw_id}/check`
  const body = { user_homework: { curator_comment } }
  axios
    .post(url, body, requestConfig())
    .then((res: any) => callback && callback(res.status))
    .catch((err: any) => callback && callback(err.response.status))
}
