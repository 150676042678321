import axios from 'axios';
import {
  GET_TESTS_PENDING,
  GET_TESTS_SUCCESS,
  GET_TESTS_FAIL,
} from "./../../constants/admin";
import { requestConfig, serv } from "../../constants/config";

interface CurrentDataType {
  id: number;
  name: string;
}

export const get_tests_action = (module_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_groups/exams?id=${module_id}`;
  dispatch({ type: GET_TESTS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_TESTS_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_TESTS_FAIL, payload: err }));
};

export const patch_test_action = (task: any, callback?: () => void) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_group_exams/${task.id}`;
  const body = { master_group_exam: task };
  axios.patch(url, body, requestConfig())
    .then(() =>
      {
        callback && callback()
        dispatch(get_tests_action(task.master_group_id))
      });
};

export const post_test_action = (test_info: any) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_group_exams`;
  const body = { master_group_exam: test_info};
  axios.post(url, body, requestConfig()).then(() => dispatch(get_tests_action(test_info.master_group_id)));
};

export const delete_test_action = (test_id: number, module_id: number) => (dispatch: any) =>
{
  const url = `${serv}/adminpanel/master_group_exams/${test_id}`;
  axios.delete(url, requestConfig()).then(() => dispatch(get_tests_action(module_id)));
};

export const set_current_test = (element: CurrentDataType) => (dispatch: any) =>
{
  const { id, name } = element;
  dispatch({ type: "SELECT_CURRENT_LESSON", payload: { id, name } });
};