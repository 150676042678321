export const showInputText = (
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.855 6.46771C21.5954 2.61005 17.1221 0 12 0C6.87791 0 2.40332 2.61187 0.144977 6.46807C0.0496614 6.63305 0 6.81532 0 7.00018C0 7.18504 0.0496614 7.36732 0.144977 7.53229C2.40457 11.3899 6.87791 14 12 14C17.1221 14 21.5967 11.3881 23.855 7.53193C23.9503 7.36695 24 7.18468 24 6.99982C24 6.81496 23.9503 6.63269 23.855 6.46771ZM12 12.25C10.8133 12.25 9.65327 11.9421 8.66657 11.3652C7.67987 10.7883 6.91084 9.9684 6.45671 9.00909C6.00259 8.04978 5.88377 6.99418 6.11528 5.97578C6.34679 4.95738 6.91823 4.02191 7.75735 3.28769C8.59647 2.55346 9.66557 2.05345 10.8295 1.85088C11.9933 1.6483 13.1997 1.75227 14.2961 2.14963C15.3925 2.54699 16.3295 3.2199 16.9888 4.08326C17.6481 4.94661 18 5.96165 18 7C18.0004 7.68953 17.8455 8.37237 17.5441 9.00949C17.2427 9.6466 16.8007 10.2255 16.2435 10.7131C15.6863 11.2006 15.0247 11.5873 14.2966 11.8511C13.5684 12.1148 12.788 12.2503 12 12.25ZM12 3.5C11.643 3.50437 11.2882 3.55084 10.9454 3.63818C11.228 3.9742 11.3636 4.38772 11.3277 4.80373C11.2917 5.21974 11.0865 5.6107 10.7494 5.9057C10.4122 6.20071 9.96541 6.38022 9.48997 6.41169C9.01453 6.44316 8.54194 6.32451 8.15791 6.07724C7.93923 6.78219 7.9787 7.5294 8.27078 8.2137C8.56285 8.89799 9.09282 9.48492 9.78608 9.89186C10.4793 10.2988 11.301 10.5053 12.1354 10.4822C12.9698 10.4591 13.7749 10.2077 14.4375 9.76329C15.1 9.31887 15.5866 8.70385 15.8287 8.00479C16.0708 7.30573 16.0563 6.55783 15.7872 5.86635C15.5181 5.17488 15.008 4.57466 14.3286 4.15017C13.6492 3.72567 12.8348 3.49828 12 3.5Z"
      fill="black"
    />
  </svg>
);
export const hideInputText = (
  <svg
    width="22"
    height="9"
    viewBox="0 0 22 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1C1 1 4.5 5 11 5C17.5 5 21 1 21 1M3 2.645L1 5M21 5L19.004 2.648M7.914 4.68L7 7.5M14.063 4.688L15 7.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
