import "./styles.sass";
import { FC, useRef, useState } from "react";
import { SelectItemType, select_subjectType, SelectTypes } from "./types";
import { arrowIcon } from "../../../assets/icons/header";
import { ClickAwayListener } from "../../../tools/ClickAway";
import { getMaxItemLength } from "../../../tools/max_length";

export const Select: FC<SelectTypes> = ({
  setValue,
  value,
  options,
  margin,
  defaultBorder,
  width,
  height,
  mode = "normal",
  disabled = false
}) => {
  const [active, setActive] = useState(false);
  const selectRef = useRef(null);

  const current: any = selectRef?.current !== null ? selectRef.current : {};
  const maxLength = getMaxItemLength(options);

  const close = () => setActive(false);

  const select = (element: SelectItemType | select_subjectType) => {
    setValue(element);
    close();
  };

  const sizeStyle: string = mode === "small" ? "Select SelectSmall" : "Select"
  const title = value?.name ?? value

  return (
    <ClickAwayListener onClickAway={close}>
      <div
        ref={selectRef}
        className={
          active
            ? `${sizeStyle} SelectActive`
            : sizeStyle
        }
        onClick={disabled ? () => { } : () => setActive(!active)}
        style={{
          maxWidth: width ?? "auto",
          width: width ?? maxLength * 12,
          height: height ?? "35px,",
          margin: margin ?? 0,
          borderRadius: defaultBorder ? 0 : "auto",
        }}
      >
        <div className="SelectMain">{title.toUpperCase()}</div>
        <div className={active ? "SelectIconReverse" : "SelectIcon"}>
          {arrowIcon}
        </div>
        {active && options.length > 0 && (
          <div
            className="SelectOptions"
            style={{
              top: current["offsetTop"] + current["offsetHeight"] - 2,
              left: current["offsetLeft"],
              width: current["offsetWidth"] - 2,
              borderRadius: defaultBorder ? 0 : "auto",
            }}
          >
            {options.map((el: any, id: number) => {
              return (
              <div
                key={el?.id ? el.id : id}
                className="SelectOption"
                onClick={() => select(el)}
              >
                {el?.name ? el.name.toUpperCase() : `no name ${el.id}`}
              </div>
            )})}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
