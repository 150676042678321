import { FC } from "react";
import { EducationScreenType } from "./types";

export const EducationScreen: FC<EducationScreenType> = ({ children, state, item }) => {
  const pending_condition = state.pending
  const ok_condition = state[item].length > 0
  const empty_condition = !state.pending && state.error.length === 0 && state[item].length === 0
  const error_condition = state.error.length > 0
  return (
    <>
      {pending_condition && <h2>{item} завантажуэться</h2>}
      {empty_condition && <h2>No {item} found</h2>}
      {error_condition && <h2>Error during get {item} : `${state.error}`</h2>}
      {ok_condition && children}
    </>
  )
}