import {
  GET_TEACHER_PROFILE_PENDING,
  GET_TEACHER_PROFILE_SUCCESS,
  GET_TEACHER_PROFILE_FAIL,
} from "./../../constants/teacher";

const initialState = {
  email: undefined,
  name: undefined,
  surname: undefined,
  phone: undefined,
  telegram: undefined,
  subject_id: undefined,
  subject_name: undefined,
  next_lesson_start: undefined,
  pending: false,
  error: "",
};

export const teacher_profile = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TEACHER_PROFILE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TEACHER_PROFILE_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        name: action.payload.name,
        surname: action.payload.surname,
        phone: action.payload.phone,
        telegram: action.payload.telegram,
        subject_id: action.payload.subject_id,
        subject_name: action.payload.subject_name,
        next_lesson_start: action.payload.next_lesson_start,
        pending: false,
      };
    case GET_TEACHER_PROFILE_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }
