import { GET_USER_INFO_PENDING, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAIL } from './../../constants/auth/admin';
import axios from 'axios';
import { domen, requestConfig, serv } from '../../constants/config';
import { clear_storage } from '../../../Pages/UserEntry/exit';

export const get_user_info_action = () => (dispatch: any) =>
{
  dispatch({type: GET_USER_INFO_PENDING})
  axios
    .get(`${serv}/members/current_info`, requestConfig())
    .then(res => dispatch({type: GET_USER_INFO_SUCCESS, payload: res.data}))
    .catch(err =>
    {
      dispatch({type: GET_USER_INFO_FAIL, payload: err})
      clear_storage()
      window.location.href = `${domen}/sign_in`
    })
}

export const get_student_info_action = (id: number) => (dispatch: any) =>
{
  dispatch({type: GET_USER_INFO_PENDING})
  axios
    .get(`${serv}/studentpanel/users/${id}`, requestConfig())
    .then(res => dispatch({type: GET_USER_INFO_SUCCESS, payload: res.data}))
    .catch(err => dispatch({type: GET_USER_INFO_FAIL, payload: err}))
}