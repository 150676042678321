import "./styles.sass";
import { FC } from "react";
import { emptySelectIcon, fillSelectIcon } from "../../../assets/icons/common";
import { TaskMarkPointsType } from "./types";

export const TaskMarkPoints: FC<TaskMarkPointsType> = ({ taskMark, setTaskMark, with_zero, disabled = false }) =>
  <div className="MarkSelector">
    {Array.from(Array(7).keys()).splice(with_zero ? 0 : 1).map((point: number, id: number) =>
      <div
        className="MarkSelectorItem"
        key={id}
        onClick={() => disabled ? {} : setTaskMark(point)}
      >
        {taskMark === point ? fillSelectIcon : emptySelectIcon}
        <div className="MarkSelectorNum">{point}</div>
      </div>
    )}
  </div>
