import "./styles.sass";
import { FC, useContext, useState } from "react";
import { Button } from "../../atoms/Button";
import { closeIcon } from "../../../assets/icons/common";
import { Context } from "../../../context";
import { useDispatch } from "react-redux";
import { get_subjects_action, start_new_year_action } from "../../../redux/actions/admin";

export const NewYearModal: FC<any> = () =>
{
  const [context, setContext] = useContext(Context)
  const [accept_clicked, set_accept_clicked] = useState(false)
  const dispatch = useDispatch()

  const message = !accept_clicked
    ? "Ви впевнені? Після цієї дії зітруться всі дані окрім Бази Знань. Відновлення неможливе."
    : "Точно? :)"

  const button_title = !accept_clicked
    ? "стерти все"
    : "точно"

  const leave_click = () =>
  {
    if (accept_clicked) set_accept_clicked(false)
    setContext({ ...context, new_year_modal: false})
  }

  const accept_click = () =>
  {
    if (!accept_clicked) return set_accept_clicked(true)
    dispatch<any>(start_new_year_action((status: number) =>
    {
      if (status === 422)
      {
        leave_click()
        setContext((prev: any) => ({ ...prev, notification_data: { title: "Видалення можливе тільки в Серпні", type: "warning"} }))
      }
      if (status === 200)
      {
        dispatch<any>(get_subjects_action())
        leave_click()
        setContext((prev: any) => ({ ...prev, notification_data: { title: "Видалення успішне", type: "success" }}))
        console.warn("dropped DB")
      }
      if (status !== 200 && status !== 422) setContext({ ...context, notification_data: { title: "Відміна дії, щось пішло не так", type: "warning" }})
    }))
  }

  return (
    <div className={`AddSubjectModal NewYearModal`}>
      <div className="AddSubjectModalHeader NewYearModalName">
        <div className="AddSubjectModalName">{ message }</div>
        <div
          className="AddSubjectModalClose"
          onClick={ leave_click }
        >
          { closeIcon }
        </div>
      </div>
      <div className="AddSubjectModalBody">
        <Button
          title="ні, повернутись назад"
          mode="btn_transparent"
          width={ 50 }
          margin="0 10px 5px 0"
          uppercase
          onClick={ leave_click }
        />
        <Button
          title={ button_title }
          mode="btn_black"
          width={ 50 }
          margin="0 0 5px 10px"
          uppercase
          onClick={ accept_click }
        />
      </div>
    </div>
  );
};
