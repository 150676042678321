import { AuthInitialTypes, AuthTypes, AuthUserType } from "../../Pages/Auth/types";

export const authInputs: AuthTypes[] = [
  {
    id: 0,
    label: "Введи адресу пошти чи телефон",
    name: "email",
    pass: false,
    error: "",
  },
  {
    id: 1,
    label: "Твій пароль",
    name: "password",
    pass: true,
    error: "",
  },
];

export const registerInputs: AuthTypes[] = [
  {
    id: 0,
    label: "Ім’я *",
    name: "firstName",
    pass: false,
    error: "",
  },
  {
    id: 1,
    label: "Прізвище*",
    name: "lastName",
    pass: false,
    error: "",
  },
  {
    id: 2,
    label: "E-mail*",
    name: "email",
    pass: false,
    error: "",
  },
  {
    id: 3,
    label: "Телефон*",
    name: "phoneNumber",
    pass: false,
    error: "",
  },
  {
    id: 4,
    label: "Пароль*",
    name: "password",
    pass: true,
    error: "",
  },
  {
    id: 5,
    label: "Підтвердження паролю*",
    name: "passwordConfirmation",
    pass: true,
    error: "",
  },
];

export const initialLoginValues: AuthInitialTypes = {
  email: "",
  password: "",
};

export const initialRegisterValues: AuthInitialTypes = {
  firstName: "",
  lastName: "",
  phoneNumber: "+380",
  email: "",
  password: "",
  passwordConfirmation: "",
};

export const user_auth_fields = [
  {
    id: 0,
    title: "E-mail*",
    name: "email",
    type: "text",
    password: false,
    disabled: true,
  },
  {
    id: 2,
    title: "Ім'я*",
    name: "name",
    type: "text",
    password: false,
    disabled: false,
  },
  {
    id: 3,
    title: "Прізвище*",
    name: "surname",
    type: "text",
    password: false,
    disabled: false,
  },
  {
    id: 4,
    title: "Пароль*",
    name: "password",
    type: "password",
    password: true,
    disabled: false,
  },
  {
    id: 5,
    title: "Підтвердження паролю*",
    name: "password_confirm",
    type: "password",
    password: true,
    disabled: false,
  },
];

export const user_password_recovery_fields = [
  {
    id: 0,
    title: "E-mail*",
    name: "email",
    type: "text",
    password: false,
    disabled: false,
  },
  // {
  //   id: 1,
  //   title: "Код з листа*",
  //   name: "code",
  //   type: "text",
  //   password: false,
  //   disabled: false,
  // },
  {
    id: 1,
    title: "Пароль*",
    name: "password",
    type: "password",
    password: true,
    disabled: false,
  },
  {
    id: 2,
    title: "Підтвердження паролю*",
    name: "password_confirm",
    type: "password",
    password: true,
    disabled: false,
  },
];

export const user_entry_fields = [
  {
    id: 0,
    title: "E-mail*",
    name: "email",
    type: "text",
    password: false,
    disabled: false,
  },
  {
    id: 1,
    title: "Пароль",
    name: "password",
    type: "password",
    password: true,
    disabled: false,
  },
];

export const user_auth_initials = {
  email: "",
  phone: "",
  name: "",
  surname: "",
  password: "",
  password_confirm: "",
};

export const user_password_recovery_initials = {
  email: "",
  code: "",
  password: "",
  password_confirm: "",
};

export const user_entry_initials = {
  email: "",
  password: "",
};

export const user_password_button_title = "Змінити пароль";

export const user_auth_button_title = "Створити профіль";

export const user_entry_button_title = "Ввійти";

export const auth_user_variants: AuthUserType[] = [
  { id: 0, name: "студент", en_name: "user" },
  { id: 1, name: "викладач", en_name: "teacher" },
  { id: 1, name: "куратор", en_name: "curator" }
]
