import { FC, FormEvent, useContext, useEffect, useReducer, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { deleteIcon, searchIcon } from "../../../assets/icons/common"
import { STUDENT_SEARCH_MODE } from "../../../constants/admin"
import { Context } from "../../../context"
import "./styles.sass"
import { StudentSearchType } from "./types"
import useEventListener from "../../../utils/useEventListener"

export const StudentSearch: FC<StudentSearchType> = ({ is_teacher }) =>
{
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const searchRef = useRef(null)
  const current: any = searchRef?.current !== null ? searchRef.current : {};

  const user_id = useSelector((st: any) => st.user_info.id)
  const { modules } = useSelector((st: any) => st.student_modules)
  const { courses } = useSelector((st: any) => st.teacher_courses)

  const [variant_modules, set_variant_modules] = useState([])

  const [hashtags, set_hashtags] = useState<any>([])
  const [hashtags_list, set_hashtags_list] = useState<any>([])
  const [search_value, set_search_value] = useState("")
  const [search_results, set_search_results] = useState<any>([])
  const [saved_hashtags, set_saved_hashtags] = useState<any>([])
  const [show_hashtags, set_show_hashtags] = useState<boolean>(false)

  const chech_search = (event: FormEvent) =>
  {
    const {value} = event.target as HTMLInputElement
    set_search_value(value)
    !show_hashtags && set_show_hashtags(true)
  }

  const start_search = () =>
  { 
    if (!saved_hashtags.length) return
    const materials: any[] = []
    for (let a = 0; a < hashtags.length; a++)
    {
      for (let b = 0; b < saved_hashtags.length; b++)
      {
        if (hashtags[a].hashtag === saved_hashtags[b].hashtag)
        {
          materials.push(hashtags_list.find((el: any) => el.id === hashtags[a].id))
        }
      }
    }
    setContext((prev: any) => ({ ...prev, search_materials: materials, current_mode: STUDENT_SEARCH_MODE }))
  }

  const select_hashtag = (hash: any) =>
  {
    !saved_hashtags.includes(hash) && set_saved_hashtags([ ...saved_hashtags, hash ])
    set_show_hashtags(false)
    set_search_results([])
    start_search()
    set_search_value("")
  }

  const delete_hashtag = (hash_id: number) => { set_saved_hashtags(saved_hashtags.filter((_: string, id: number) => id !== hash_id)) }


  const prepare_hashtags = (modules_info: any) =>
  {
    if (!modules_info.length) return
    const moudules_copy = [ ...modules_info ]
    const hashtags_data: any = []
    const all_hashtags: any = []
    moudules_copy.forEach((m: any) =>
    {
      if (m.lessons.length)
      {
        m.lessons.forEach((l: any) =>
        {
          if (l.hashtags)
          {
            const splitted_h = l.hashtags.split(" ")
            hashtags_data.push({id: l.id,
            mg_name: m.name,
            mg_id: m.id,
            l_name: l.name,
            theme: l.theme ?? "without theme",
            hashtags: splitted_h})
            all_hashtags.push(...splitted_h.map((h: string) => ({ hashtag: h, id: l.id})))
          }
        })
      }
    })
    set_hashtags_list(hashtags_data)
    set_hashtags(all_hashtags)
  }

  const sortArr = (arr: any) => arr.sort((a: any, b: any) => b.characters.length - a.characters.length)

  const live_search = (str: string = "") =>
  {
    if (!variant_modules.length) return []
    if (!hashtags.length || !hashtags_list.length) return []
    const reg = (repl: string) => new RegExp(repl, 'g')
    const find_same = hashtags.filter((h: { id: number, hashtag: string }) => h.hashtag.includes(str.replace(reg("#"), "").trim()))
    if (find_same.length) return find_same.map((el: any) => ({ ...el, characters: str.replace(reg("#"), "").trim()}) )

    const find_match = hashtags.map((h: { id: number, hashtag: string }) =>
    {
      const splitted = str.replace(reg(" "), "").replace(reg("#"), "").split("")
      for (let ch of splitted) if (h.hashtag.includes(ch)) return { ...h, characters: splitted.filter((s: string) =>  h.hashtag.includes(s)) }
    })

    const clear = find_match.filter((t: any) => !!t)
    if (clear.length) return sortArr(clear)
    return []
  }
  
  const light_str = (str: string, chr: [string]) => str.split("").map((el: string, id: number) => <div style={{ color: chr.includes(el) ? "red" : "grey" }} key={id}>{ el }</div>)
  
  const show_hashtags_condition = search_value.length > 0 && show_hashtags

  useEffect(() =>
  {
    const variant = is_teacher ? courses : modules
    if (!variant.length) return
    prepare_hashtags(variant)
    set_variant_modules(variant)
  }, [modules, courses])
  useEffect(() => { hashtags.length > 0 && search_value.trim().length && set_search_results(live_search(search_value)) }, [search_value])

  const click_listener = (event: any) =>
  {
    event.preventDefault()
    if (event.keyCode === 13) start_search()
  }

  useEventListener('keyup', click_listener)

  return (
    <div className="StudentSearch">
      <div className="StudentSearchHashtagsContainer">
        {saved_hashtags.length > 0 && saved_hashtags.map((hash: any, id: number) => (
          <div className="StudentSearchHashtagBlock" key={id}>
            <div className="StudentSearchHashtag">
              {hash.hashtag.split("#").length > 0 ? hash.hashtag : ("#" + hash.hashtag)}
            </div>
            <div
              className="StudentSearchHashtagDelete"
              onClick={() => delete_hashtag(id)}
            >{deleteIcon}</div>
          </div>
        ))}
      </div>
      <div ref={searchRef} className="StudentSearchInputContainer">
      <input
        className="StudentSearchInput"
        placeholder="введи хештег для пошуку уроку"
        onChange={chech_search}
        value={search_value}
      />
      <div
        className={`StudentSearchInputIcon ${false && "SearchDisabledIcon"}`}
        onClick={start_search}
      >
        {searchIcon}
      </div>
      </div>
      {show_hashtags_condition &&
      <div
        className="StudentSearchHashtagsList"
        style={{
          top: current["offsetTop"] + current["offsetHeight"] - 2,
          left: current["offsetLeft"],
          width: current["offsetWidth"] - 2,
        }}
      >
        {search_results.length > 0
        ? search_results.map((hash: any) => (
          <div
            key={hash.id}
            className="StudentSearchHashtagItem"
            style={{
              top: current["offsetTop"] + current["offsetHeight"] - 2,
              left: current["offsetLeft"],
              width: current["offsetWidth"] - 2,
            }}
            onClick={() => select_hashtag(hash)}
          >{light_str(hash.hashtag, hash.characters)}</div>
        ))
        : <div>Хештеги не знайдено</div>
        }
      </div>}
    </div>
  )
}