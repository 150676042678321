import "./styles.sass";
import { LessonTop } from "../../molecules/LessonTop";
import { LessonTasks } from "../../molecules/LessonTasks";
import { FC, useState } from "react";
import { TestTop } from "../../molecules/TestTop";
import { Button } from "../../atoms/Button";
import { LessonInput } from "../../atoms/LessonInput";
import { is_lesson } from "../../../tools/admin/common";

export const StudentLesson: FC<any> = ({ curator_comment, student_back_to_lesson, is_changed, lesson_info, lesson_name, lesson_theme, save_lesson, send_lesson, user_type, set_work_photos, curator_work_send, is_checked }) =>
{
  const [curator_msg, set_curator_msg] = useState<string>("")
  if (!lesson_info) return <div className="StudentLessonContainer">
    <LessonTop lesson_theme="Помилка зчитування інформації про ДЗ" student_back_to_lesson={student_back_to_lesson} fail_case /> 
  </div>
  const is_curator = user_type === "curator"

  const curator_msg_placeholder = curator_comment ?? `повідомлення від куратора, МАЛАДЄЦ!`


  const top_props =
  {
    is_changed,
    send_lesson,
    lesson_status: lesson_info.aasm_state,
  }


  const show_top = lesson_name.toLowerCase().includes("урок") || lesson_name.toLowerCase().includes("день")

  const tasks_order_by_id = (arr: any) => arr.sort((a: any, b: any) => a.position - b.position )

  const tasks = (lesson_info.exercises && lesson_info.exercises.length > 0) ? tasks_order_by_id(lesson_info.exercises) : []

  return (
    <div className={`${is_curator ? "Curator" : "Student"}LessonContainer`}>
      {(show_top && !is_curator) && <LessonTop { ...{ ...top_props, lesson_theme, save_lesson, student_back_to_lesson } } />}
      {(!show_top && !is_curator) && <TestTop { ...{ ...top_props, lesson_name } } />}

      <div className="StudentLessonTasks">
        {tasks.map((task: any) => <LessonTasks task={task} lesson_name={lesson_name} homework_status={lesson_info.aasm_state} key={task.id} set_work_photos={set_work_photos} is_checked={is_checked} /> )}
        
        {!is_curator &&  <h1> { ( lesson_info.aasm_state === "checked" || lesson_info.aasm_state === "completed" ) ? "" :  "Передивись ще раз завдання і надсилай куратору на перевірку кнопкою зверху" } </h1>}
        
        {is_curator && is_lesson(lesson_name) && <>
          <div className="LessonCommentContainer">
            <div className="LessonCommentaTitle">коментар по дЗ</div>
            <div className="LessonTaskTwoInputsContainer">
              <LessonInput
                placeholder={curator_msg_placeholder}
                value={curator_msg}
                width={600}
                margin="20px 0 0 0"
                onChange={(event: any) => set_curator_msg(event.target.value)}
                // disabled={is_checked}
              />
          </div>
          </div>
          <Button
            onClick={() => curator_work_send(curator_msg)}
            title="перевірено"
            uppercase
            rounded
            // mode={ is_checked ? "btn_gray" : "btn_black"}
            mode={"btn_black"}
            width={15}
            margin="30px 0 50px 0"
            // disabled={is_checked}
          />
        </>}

      </div>
    </div>
  );
};
