import { FC, useContext } from "react";
import { StudentPreSaveModaltype } from "./types";
import "./styles.sass";
import { closeIcon } from "../../../assets/icons/common";
import { Button } from "../../atoms/Button";
import { Context } from "../../../context";
import { is_lesson } from "../../../tools/admin/common";

export const leave_lesson_msg: string = "Зберегти ДЗ, щоб повернутись до нього пізніше?"
export const leave_exam_msg: string = "Відправити відповіді? В разі виходу тест буде завершено."
export const send_not_ended_lesson_msg: string = "Ви відповіли не на всі запитання. Бажаєте завершити роботу?"
export const send_not_ended_exam_msg: string = "Ви відповіли не на всі запитання. Бажаєте завершити тест?"

export const leave_lesson_buttons_msg: string[] = ["Зберегти", "перероблю"]
export const leave_exam_buttons_msg: string[] = ["Відправити", "дороблю"]
export const send_not_ended_work_buttons_msg: string[] = ["Завершити", "дороблю"]

export const StudentPreSaveModal: FC<StudentPreSaveModaltype> = ({ select_tasks_leave_answer, clear_homework_answers }) =>
{
  const [context, setContext] = useContext(Context)
  const { stopped_action, homework_answers, lesson } = context

  const is_sending = stopped_action.name === "SEND_LESSON" || stopped_action.name === "SEND_TEST"

  const no_answers = clear_homework_answers().length === 0

  const not_all_answers = clear_homework_answers().length < homework_answers.length

  const set_title_msg = () =>
  {
    if (is_sending)
    {
      if (stopped_action.name === "SEND_LESSON")
      {
        if (no_answers) return "Ви не відповіли на жодне питання. В такому разі ДЗ отримає оцінку 0. Відправити?"
        if (not_all_answers) return "Ви відповіли не на всі запитання. Бажаєте завершити роботу?"
      }
      if (stopped_action.name === "SEND_TEST")
      {
        if (no_answers) return "Ви не відповіли на жодне питання. В такому разі Тест отримає оцінку 0. Відправити?"
        if (not_all_answers) return "Ви відповіли не на всі запитання. Бажаєте завершити тест?"
      }
    }
    if (is_lesson(lesson.name)) return "Зберегти ДЗ, щоб повернутись до нього пізніше?"
    if (!is_lesson(lesson.name)) return "Відправити відповіді? В разі виходу тест буде завершено."
  }

  const set_buttons_options = () =>
  {
    if (is_sending)
    {
      if (not_all_answers) return ["Завершити", "дороблю"]
    }
    if (is_lesson(lesson.name)) return ["Зберегти", "перероблю"]
    if (!is_lesson(lesson.name)) return ["Завершити", "дороблю"]
    return ["Завершити", "буду доробляти"]
  }

  return (
    <div className="StudentPreSaveModal">
      <div className="StudentPreSaveModalCancel" onClick={() => { select_tasks_leave_answer(false) }}>
        { closeIcon }
      </div>
      <div className="StudentPreSaveModalText">
        {set_title_msg()}
      </div>
      <div className="StudentPreSaveModalActions">
        <Button
          title={set_buttons_options()[0]}
          mode="btn_black"
          uppercase
          rounded
          margin="0 30px 0 0"
          width={80}
          onClick={() => { select_tasks_leave_answer(true) }}
        />
        <Button
          title={set_buttons_options()[1]}
          mode="btn_transparent"
          uppercase
          rounded
          margin="0 0 0 10px"
          width={100}
          onClick={() => { select_tasks_leave_answer(false) }}
        />
      </div>
    </div>
  )
}