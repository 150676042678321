import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { triangle_icon } from "../../../assets/icons/common";
import { Context } from "../../../context";
import { get_teacher_master_groups_action, get_teacher_mg_users_action } from "../../../redux/actions/teacher";
import { is_lesson } from "../../../tools/admin/common";
import { TeacherGroupDropDown } from "../../atoms/TeacherGroupDropDown";
import "./styles.sass";

export const TeacherGroupSchedule = () =>
{
  const [context, setContext] = useContext(Context)
  const { module, lesson } = context
  const { master_groups } = useSelector((st: any) => st.teacher_mgps)
  const [sort_by, set_sort_by] = useState< "total_ball" | "last_sign_in_at" | "name" | "" >("")



  const dispatch = useDispatch()

  const select_item = (type: "module" | "lesson", item: any) =>
  {
    setContext({ ...context, [type]: item, lesson: type === "module" ? undefined : item ?? lesson })
  }

  const prepare_select_list = (type: "module" | "lesson") =>
  {
    if (type === "module")
    {
      return master_groups.length ? master_groups.map((mg: any) => ({ ...mg, name: mg.name.slice(0, 6)})) : []
    }
    if (type === "lesson")
    {
      if (!master_groups.length || !module) return []
      const lssns = module.homeworks.length ? module.homeworks.map((l: any) => ({ ...l, name: "урок" + (l.sequence_number)}) ) : []
      const tsts = module.master_group_exams.length ? module.master_group_exams.map((t: any) => ({ ...t, name: t.name.replace(" ", "")}) ) : []
      return [ ...lssns, ...tsts ]
    }
  }

  useEffect(() =>
  {
    if (!module || !lesson) return
    const queries =
    {
      work_id: lesson.id,
      work_type: is_lesson(lesson.name) ? "Homework" : "MasterGroupExam",
      total_ball: sort_by.includes("total_ball"),
      last_sign_in_at: sort_by.includes("last_sign_in_at"),
      name: sort_by.includes("name")
    }
    //@ts-ignore
    dispatch<any>(get_teacher_mg_users_action(module.id, queries))
  }, [module, lesson, sort_by])

  useEffect(() => { dispatch<any>(get_teacher_master_groups_action()) }, [])


  return (
    <div className="TeacherGroupSchedule">
      <div className="TeacherGroupScheduleNumber">#</div>
      <div className="TeacherGroupScheduleGroup">
        <TeacherGroupDropDown
          title={module?.name ? module.name : "модуль"}
          width="100%"
          listWidth="70%"
          map_array={prepare_select_list("module")}
          onClick={(item: any) => select_item("module", item)}
        />
      </div>
      <div className="TeacherGroupScheduleName" onClick={() => set_sort_by("name")}>
        <div></div>
        <div className="TeacherGroupScheduleTitle">ІМ’Я</div>
        <div className="TeacherGroupScheduleChangeOrder">
          {triangle_icon}
        </div>
      </div>
      <div className="TeacherGroupScheduleVisiting" onClick={() => set_sort_by("last_sign_in_at")}>
        <div></div>
        <div className="TeacherGroupScheduleTitle">ВІДВІДУВАННЯ</div>
        <div className="TeacherGroupScheduleChangeOrder">
          {triangle_icon}
        </div>
      </div>
      <div className="TeacherGroupScheduleD">
        <TeacherGroupDropDown
          title={ lesson ? lesson.name : "робота"}
          width="100%"
          listWidth="90%"
          map_array={prepare_select_list("lesson")}
          onClick={(item: any) => select_item("lesson", item)}
        />
      </div>
      <div className="TeacherGroupSchedulePoints" onClick={() => set_sort_by("total_ball")}>
        <div></div>
        <div className="TeacherGroupScheduleTitle">заг. бали</div>
        <div className="TeacherGroupScheduleChangeOrder">
          {triangle_icon}
        </div>
      </div>
      <div className="TeacherGroupScheduleCurator">
        <div></div>
        <div className="TeacherGroupScheduleTitle">куратор</div>
        <div className="TeacherGroupScheduleChangeOrder">
          {triangle_icon}
        </div>
      </div>
    </div>
  );
};
