import axios from 'axios'
import {
  GET_TEACHER_DB_PENDING,
  GET_TEACHER_DB_SUCCESS,
  GET_TEACHER_DB_FAIL,
} from "./../../constants/teacher";
import { requestConfig, serv } from "../../constants/config";
import { prepare_query_to_search } from '../../../tools/api';

// teacherpanel/exercises/search?subject_id=1&exercise_type=MatchPair&hashtag=theory

export const get_teacher_db_action = (subject_id: number, queries: any) => (dispatch: any) =>
{
  const url = `${serv}/teacherpanel/exercises/search?subject_id=${subject_id}&${prepare_query_to_search(queries)}`;
  dispatch({ type: GET_TEACHER_DB_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_TEACHER_DB_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_TEACHER_DB_FAIL, payload: err }));
};
