import {
  GET_STUDENT_PROFILE_PENDING,
  GET_STUDENT_PROFILE_SUCCESS,
  GET_STUDENT_PROFILE_FAIL,
} from "./../../constants/student";

const initialState = {
  id: null,
  email: null,
  phone: null,
  gender: null,
  full_name: null,
  time_zone: null,
  pending: false,
  error: "",
};

export const student_profile = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STUDENT_PROFILE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        phone: action.payload.phone,
        gender: action.payload.gender,
        full_name: action.payload.full_name,
        time_zone: action.payload.time_zone,
        pending: false,
      };
    case GET_STUDENT_PROFILE_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }