import {
  GET_TEACHER_GROUP_MODULES_PENDING,
  GET_TEACHER_GROUP_MODULES_SUCCESS,
  GET_TEACHER_GROUP_MODULES_FAIL,
  GET_TEACHER_MG_USERS_PENDING,
  GET_TEACHER_MG_USERS_SUCCESS,
  GET_TEACHER_MG_USERS_FAIL,
} from "./../../constants/teacher";

const initialState = {
  master_groups: [],
  users: [],
  pending: false,
  error: "",
};

export const teacher_mgps = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TEACHER_GROUP_MODULES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TEACHER_GROUP_MODULES_SUCCESS:
      return {
        ...state,
        master_groups: action.payload,
        pending: false,
      };
    case GET_TEACHER_GROUP_MODULES_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    case GET_TEACHER_MG_USERS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_TEACHER_MG_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        pending: false,
      };
    case GET_TEACHER_MG_USERS_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default: return { ...state }
    }
  }