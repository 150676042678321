import "./styles.sass";
import { FC, useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Input } from "../../atoms/Input";
import { calendarIcon, searchIcon } from "../../../assets/icons/common";
import { prepareDate } from "../../../tools/admin/date";
import { Context } from "../../../context";
import { Button } from "../../atoms/Button";
import { ClickAwayListener } from "../../../tools/ClickAway";

export const StudentTableHeader: FC<any> = () => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [context, setContext] = useContext(Context)

  const [studentSearchData, setStudentSearchData] = useState<any>({
    created_at: undefined,
    phone: undefined,
    email: undefined,
  });
  const changeStudentSearch = (event: any) => {
    const { name, value } = event.target;
    setStudentSearchData((prev: any) => ({ ...prev, [name]: value }));
  };

  const selectSearchDate = (created_at: Date | undefined) => {
    setStudentSearchData((prev: any) => ({ ...prev, created_at }));
  };

  const clear_calendar = () =>
  {
    selectSearchDate(undefined)
    setShowCalendar(false)
  }

  const prepare_search_date = () =>
  {
    if (studentSearchData.created_at)
    {
      return prepareDate(studentSearchData.created_at, false)
    }
    return "_ _/_ _/_ _"
  }

  useEffect(() => setContext({ ...context, student_search_data: { ...studentSearchData }}), [studentSearchData])

  return (
      <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
    <div className={`StudentTableHeader`}>
      <div className="StudentTableId StudentTableItem">#</div>
      <div className="StudentTableRegister StudentTableItem">
        <div className="StudentTableItemName">РЕЄСТРАЦІЯ</div>
        <Input
          name="register"
          width={85}
          icon={calendarIcon}
          onClick={() => setShowCalendar(true)}
          // @ts-ignore
          value={prepare_search_date()}
          height={30}
          disabled
        />
        <ReactDatePicker
          // @ts-ignore
          selected={studentSearchData.created_at}
          onChange={(created_at: Date) => selectSearchDate(created_at)}
          open={showCalendar}
          children={<div className="RecatCalendarClearChildren">
            <Button
              title="очистити"
              mode="btn_transparent"
              size="btn_small"
              width={100}
              onClick={clear_calendar}
              uppercase
            />
          </div>}
        />
      </div>
      <div className="StudentTablePhone StudentTableItem">
        <div className="StudentTableItemName">ТЕЛЕФОН</div>
        <Input
          placeholder="(___)-___ -__-__"
          name="phone"
          width={85}
          // icon={searchIcon}
          height={30}
          value={studentSearchData.phone}
          onChange={changeStudentSearch}
        />
      </div>
      <div className="StudentTableEmail StudentTableItem">
        <div className="StudentTableItemName">EMAIL</div>
        <Input
          placeholder="введи email"
          name="email"
          width={85}
          // icon={searchIcon}
          height={30}
          value={studentSearchData.email}
          onChange={changeStudentSearch}
        />
      </div>

    </div>
      </ClickAwayListener>
  );
};
