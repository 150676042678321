import { user_info } from "./auth"
import { admin_users, admin_students, admin_tasks, admin_tests, admin_modules, admin_lessons, admin_subjects } from './admin';
import { student_subjects, student_modules, student_lessons, student_tasks, student_profile } from './student';
import { teacher_courses, teacher_profile, teacher_mgps, teacher_db } from "./teacher"
import { curator_groups, curator_profile } from "./curator";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  user_info,
  admin_subjects,
  admin_modules,
  admin_lessons,
  admin_tests,
  admin_tasks,
  admin_students,
  admin_users,
  student_subjects,
  student_modules,
  student_lessons,
  student_tasks,
  student_profile,
  teacher_courses,
  teacher_profile,
  teacher_db,
  teacher_mgps,
  curator_profile,
  curator_groups,
});

export default rootReducer;
