import "./styles.sass";
import { FC } from "react";
import { AdminSubjectItemTypes } from "./types";
import { SubjectType } from "../../templates/AdminCourses/types";
import { EducationScreen } from "../../../hoc/EducationScreen";

export const AdminSubjectItems: FC<AdminSubjectItemTypes> = ({ admin_subjects, onClick }) =>
  <EducationScreen state={admin_subjects} item="subjects">
    <div className="AdminSubjectsContainer">
      {admin_subjects.subjects.map((subject: SubjectType) =>
        <div
          className={`AdminSubjectItems`}
          onClick={() => onClick(subject.id, subject.name)}
          key={subject.id}
        >
          <div className="AdminSubjectItemName">{ subject.name.toUpperCase() }</div>
        </div>
      )}
    </div>
  </EducationScreen>
