import axios from 'axios';
import {
  POST_PHOTO_PENDING,
  POST_PHOTO_SUCCESS,
  POST_PHOTO_FAIL,
} from "./../../constants/student";
import { requestConfig, serv } from "../../constants/config";

interface PostExercisePhotoParamsType
{
  user_id: number
  exercise_id: number
  user_homework_id: number
  work_photo: File
}

export const post_exercise_photo_action = (body: PostExercisePhotoParamsType) =>
{
  const url = `${serv}/studentpanel/user_answers/load_file`;
  return axios.post(url, body, requestConfig("photo"))
};

export const delete_exercise_photo_action = (work_photo_id: number) =>
{
  const url = `${serv}/studentpanel/user_answers/delete_file?work_photo_id=${work_photo_id}`;
  return axios.delete(url, requestConfig())

};
