export const lessonArrow_icon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
      fill="black"
    />
  </svg>
);

export const lessonDownload_icon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.375 4.44614H8.10703V12.375C8.10703 12.4782 8.19141 12.5625 8.29453 12.5625H9.70078C9.80391 12.5625 9.88828 12.4782 9.88828 12.375V4.44614H11.625C11.782 4.44614 11.8687 4.26567 11.7727 4.14379L9.14766 0.820354C9.13012 0.797944 9.10771 0.77982 9.08212 0.767355C9.05654 0.754891 9.02846 0.748413 9 0.748413C8.97154 0.748413 8.94346 0.754891 8.91788 0.767355C8.89229 0.77982 8.86988 0.797944 8.85234 0.820354L6.22734 4.14145C6.13125 4.26567 6.21797 4.44614 6.375 4.44614ZM17.5781 11.6719H16.1719C16.0688 11.6719 15.9844 11.7563 15.9844 11.8594V15.4688H2.01562V11.8594C2.01562 11.7563 1.93125 11.6719 1.82812 11.6719H0.421875C0.31875 11.6719 0.234375 11.7563 0.234375 11.8594V16.5C0.234375 16.9149 0.569531 17.25 0.984375 17.25H17.0156C17.4305 17.25 17.7656 16.9149 17.7656 16.5V11.8594C17.7656 11.7563 17.6812 11.6719 17.5781 11.6719Z"
      fill="#24221A"
    />
  </svg>
);

export const telegram_icon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5525 0.99691C18.3117 1.01443 18.0754 1.07091 17.8527 1.16416H17.8497C17.636 1.24891 16.6197 1.67641 15.0747 2.32441L9.53822 4.65616C5.56547 6.32866 1.66022 7.97566 1.66022 7.97566L1.70672 7.95766C1.70672 7.95766 1.43747 8.04616 1.15622 8.23891C0.982463 8.34948 0.832947 8.49414 0.716717 8.66416C0.578717 8.86666 0.467717 9.17641 0.508967 9.49666C0.576467 10.0382 0.927467 10.3629 1.17947 10.5422C1.43447 10.7237 1.67747 10.8084 1.67747 10.8084H1.68347L5.34572 12.0422C5.50997 12.5694 6.46172 15.6984 6.69047 16.4192C6.82547 16.8497 6.95672 17.1189 7.12097 17.3244C7.20047 17.4294 7.29347 17.5172 7.40522 17.5877C7.46331 17.6215 7.52526 17.6481 7.58972 17.6672L7.55222 17.6582C7.56347 17.6612 7.57247 17.6702 7.58072 17.6732C7.61072 17.6814 7.63097 17.6844 7.66922 17.6904C8.24897 17.8659 8.71472 17.5059 8.71472 17.5059L8.74097 17.4849L10.9032 15.5162L14.5272 18.2964L14.6097 18.3317C15.365 18.6632 16.13 18.4787 16.5342 18.1532C16.9415 17.8254 17.0997 17.4062 17.0997 17.4062L17.126 17.3387L19.9265 2.99191C20.006 2.63791 20.0262 2.30641 19.9385 1.98466C19.848 1.65903 19.639 1.37897 19.3527 1.19941C19.1123 1.05325 18.8335 0.982697 18.5525 0.99691ZM18.4767 2.53441C18.4737 2.58166 18.4827 2.57641 18.4617 2.66716V2.67541L15.6875 16.8729C15.6755 16.8932 15.6552 16.9374 15.5997 16.9817C15.5412 17.0282 15.4947 17.0574 15.251 16.9607L10.8185 13.5624L8.14097 16.0029L8.70347 12.4104L15.9455 5.66041C16.244 5.38291 16.1442 5.32441 16.1442 5.32441C16.1652 4.98391 15.6935 5.22466 15.6935 5.22466L6.56147 10.8819L6.55847 10.8669L2.18147 9.39316V9.39016L2.17022 9.38791C2.17789 9.38536 2.1854 9.38236 2.19272 9.37891L2.21672 9.36691L2.23997 9.35866C2.23997 9.35866 6.14822 7.71166 10.121 6.03916C12.11 5.20141 14.114 4.35766 15.6552 3.70666C16.5693 3.32191 17.4843 2.93941 18.4002 2.55916C18.4617 2.53516 18.4325 2.53516 18.4767 2.53516V2.53441Z"
      fill="#24221A"
    />
  </svg>
);

