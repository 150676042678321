import { ProfileBee } from "../../atoms/ProfileBee";
import "./styles.sass";

export const ProfileTop = () => {
  return (
    <div className="ProfileTop">
      <h1 className="ProfileRegistrationTitle BzzOnTop">Яка ти сьогодні бджілка?</h1>
      <ProfileBee />
    </div>
  );
};
