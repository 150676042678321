import axios from 'axios';
import {
  GET_TEACHER_GROUP_MODULES_PENDING,
  GET_TEACHER_GROUP_MODULES_SUCCESS,
  GET_TEACHER_GROUP_MODULES_FAIL,
  GET_TEACHER_MG_USERS_PENDING,
  GET_TEACHER_MG_USERS_SUCCESS,
  GET_TEACHER_MG_USERS_FAIL,
} from "./../../constants/teacher";
import { requestConfig, serv } from "../../constants/config";
import { prepare_query_to_search } from '../../../tools/api';

// teacherpanel/master_groups/master_groups_work_list

export const get_teacher_master_groups_action = () => (dispatch: any) =>
{
  const url = `${serv}/teacherpanel/master_groups/master_groups_work_list`;
  dispatch({ type: GET_TEACHER_GROUP_MODULES_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_TEACHER_GROUP_MODULES_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_TEACHER_GROUP_MODULES_FAIL, payload: err }));
};

// teacherpanel/master_groups/1/users?work_id=1&work_type=(Homework||MasterGroupExam)&total_ball=true || last_sign_in_at = true || name = true
interface MgUsersQueryType
{
  work_id: number
  work_type: "Homework" | "MasterGroupExam"
  total_ball?: boolean
  last_sign_in_at?: boolean
  name?: boolean
}

export const get_teacher_mg_users_action = (module_id: number, queries: MgUsersQueryType) => (dispatch: any) =>
{
  const url = `${serv}/teacherpanel/master_groups/${module_id}/users?${prepare_query_to_search(queries)}`;
  dispatch({ type: GET_TEACHER_MG_USERS_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_TEACHER_MG_USERS_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_TEACHER_MG_USERS_FAIL, payload: err }));
};
