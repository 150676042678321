import axios from 'axios';
import {
  GET_TEACHER_PROFILE_PENDING,
  GET_TEACHER_PROFILE_SUCCESS,
  GET_TEACHER_PROFILE_FAIL
} from "./../../constants/teacher";
import { requestConfig, serv } from "../../constants/config";

export const get_teacher_profile_action = (teacher_id: number) => (dispatch: any) =>
{
  const url = `${serv}/teacherpanel/teachers/${teacher_id}`;
  dispatch({ type: GET_TEACHER_PROFILE_PENDING });
  axios
    .get(url, requestConfig())
    .then((res: any) => dispatch({ type: GET_TEACHER_PROFILE_SUCCESS, payload: res.data }))
    .catch((err: any) => dispatch({ type: GET_TEACHER_PROFILE_FAIL, payload: err }));
};

export const patch_teacher_profile_action = (teacher_id: number, updated_info: { [key: string] : string }, callback?: (is_ok: boolean) => void) => (dispatch: any) =>
{
  const url = `${serv}/teacherpanel/teachers/${teacher_id}`;
  const body = { teacher: updated_info }
  axios
    .patch(url, body, requestConfig())
    .then(() =>
    {
      dispatch(get_teacher_profile_action(teacher_id))
      callback && callback(true)
    }).
    catch(() => { callback && callback(false) } )
};