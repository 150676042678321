import "./styles.sass"
import { FC, MouseEvent, useContext, useEffect, useState } from "react"
import { StudentTableBodyType } from "./types"
import { useSelector } from "react-redux"
import { prepareDate } from "../../../tools/admin/date"
import { CheckBox } from "../../atoms/CheckBox"
import { AdminListItem } from "../../atoms/AdminListItem"
import { Select } from "../../atoms/Select"
import { useDispatch } from "react-redux"
import { bought_modules_action, create_student_action, delete_modules_action, get_all_bougth_modules_action, get_bought_subjects_action, get_bougth_modules_action, get_modules_action, get_module_groups_action, get_students_action, get_subjects_action, send_link_action, delete_user_action, resend_user_link_action } from "../../../redux/actions/admin"
import { Button } from "../../atoms/Button"
import { Context } from "../../../context"
import { ModalHoc } from "../../../hoc/Modal"
import { AddStudentModal } from "../../organisms/AddStudentModal"

export const StudentTableBody: FC<StudentTableBodyType> = () =>
{
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const { add_student_modal, subject } = context;
  const { students, pending, error } = useSelector((st: any) => st.admin_students)
  const { subjects, bought_subjects } = useSelector((st: any) => st.admin_subjects)
  const { bought_modules } = useSelector((st: any) => st.admin_modules)

  const [selected_student, set_selected_student] = useState<any>(undefined)
  const [selected_subjects, set_selected_subjects] = useState<number[]>([])
  const [bought_packages, set_bought_packages] = useState<any>([])

  const [show_subjects, set_show_subjects] = useState(false)
  const [show_modules, set_show_modules] = useState(false)
  
  const [offset_top, setOffset_top] = useState(0)

  const [student_email, set_student_email] = useState("")

  const select_student = (event: MouseEvent, student: any) =>
  {
    set_student_email(student.email)
    if (selected_student && selected_student.id === student.id) return set_selected_student(undefined)
    dispatch<any>(get_subjects_action())
    dispatch<any>(get_bought_subjects_action(student.id))
    set_selected_student(student)
    set_bought_packages([])
    set_selected_subjects([])
    set_show_modules(false)
    set_show_subjects(true)
    const { offsetTop } = event.target as HTMLDivElement;
    setOffset_top(offsetTop)
  }

  const is_bought_subject = (id: number) =>
  {
    if (bought_subjects.length === 0) return false
    const bought = bought_subjects.filter((s: any) => s.id === id)
    return bought.length !== 0
  }

  const is_selected_subject = (id: number) =>
  {
    if (selected_subjects.length === 0) return false
    const selected = selected_subjects.filter((s: any) => s.id === id)
    return selected.length !== 0
  }

  const select_subject = (subject: any) =>
  {
    set_show_subjects(true)
    set_bought_packages([])
    set_show_modules(true)
    !add_student_modal && dispatch<any>(get_bougth_modules_action(subject.id, selected_student.id))
    add_student_modal && dispatch<any>(get_all_bougth_modules_action(subject.id))
    if (!add_student_modal && is_bought_subject(subject.id)) return
    set_selected_subjects(is_selected_subject(subject.id) ? [] : [subject])
  }

  const set_new_package = (module: any, mode: "module" | "group", group?: any) =>
  {
    let module_id: number = module.id, module_name: string = module.name, package_id: number = module.package_id, group_id: number, group_name: string;
    if (!group && mode === "module")
    {
      const such_packages = bought_packages.filter((p: any) => (p.module_id === module_id && p.module_name === module_name))
      const isExist = such_packages.length > 0
      // delete package if exists
      if (isExist)
      {
        set_bought_packages(bought_packages.filter((p: any) => (p.module_id !== module_id && p.module_name !== module_name)))
      }
      // add package if not exists
      if (!isExist)
      {
        set_bought_packages([...bought_packages, { module_id, module_name, package_id, group_id: undefined, group_name: undefined}])
      }
    }
    if (group && mode === "group")
    {
      group_id = group.id;
      group_name = group.name
      set_bought_packages(bought_packages.map((p: any) => (p.module_id === module_id && p.module_name === module_name) ? ({...p, group_id, group_name}) : ({...p})))
    }
  }

  const show_subjects_condition = (bought_subjects.length > 0 || selected_subjects.length > 0) && selected_student && show_modules && bought_modules.length > 0 && !add_student_modal

  const external_styles = { position: "absolute", top: offset_top }


  const get_bought_modules_and_groups = (set: boolean) =>
  {
    const temp_bought_modules = []
    for (let m = 0; m < bought_modules.length; m++)
    {
      const module_item = bought_modules[m]
      if (module_item.bought && module_item.students_groups.length > 0)
      {
        for (let i = 0; i < module_item.students_groups.length; i++)
        {
          const group_item = module_item.students_groups[i]
          if (group_item["user_in_group?"])
          {
            temp_bought_modules.push({
              module_id: module_item.id,
              module_name: module_item.name,
              package_id: module_item.package_id,
              group_id: group_item.id,
              group_name: group_item.name
            })
          }
        }
      }
    }
    if (set) set_bought_packages(temp_bought_modules)
    if (!set) return temp_bought_modules
  }

  const set_selected_group = (module: any) =>
  {
    if (bought_packages.length === 0) return undefined
    const all_groups = module.students_groups
    for (let a = 0; a < all_groups.length; a++)
    {
      for (let b = 0; b < bought_packages.length; b++)
      {
        if (all_groups[a].id === bought_packages[b].group_id && all_groups[a].name === bought_packages[b].group_name) return all_groups[a]
      }
    }
    return undefined
  }

  const prepare_packages_to_bought = (packages: any, is_new: boolean = false, student_id?: number) =>
  {
    const prepared_new_packages: any = []
    packages.forEach((p: any) =>
    {prepared_new_packages.push({
      students_group_id: p.group_id,
      change_group: is_new,
      bought_master_group: {
        user_id: student_id ?? selected_student.id,
        package_id: p.package_id,
        payment_system_paid: false,
        master_group_id: p.module_id
      }
    })})
    return prepared_new_packages
  }

  const clear_data = (is_modal?: boolean) =>
  {
    set_bought_packages([])
    set_selected_subjects([])
    set_selected_student(undefined)
    set_show_modules(false)
    set_show_subjects(false)
    if (is_modal) return
    setContext({ ...context, add_student_modal: false })
  }

  const confirm_payment = () =>
  {

    const initial_bought_packages: any = get_bought_modules_and_groups(false)
    const thereAreOldPackages = initial_bought_packages.length > 0
    const new_packages = thereAreOldPackages
      ? bought_packages.filter((el: any) => ![ ...initial_bought_packages.map((p: any) => p.package_id) ].includes(el.package_id))
      : [...bought_packages]
    const changed_packages =
      (new_packages.length ? bought_packages.filter((b: any) => ![ ...new_packages.map((n: any) => n.package_id)].includes(b.package_id)) : bought_packages)
      .filter((el: any) => ![ ...initial_bought_packages.map((p: any) => p.group_id) ].includes(el.group_id))

    const deleted_packages: any = []
    
    if (thereAreOldPackages)
    {
      for (let i = 0; i < initial_bought_packages.length; i++)
      {
        const searched_package = bought_packages.filter((p: any) => p.package_id === initial_bought_packages[i].package_id)
        const isPackageExists = searched_package.length > 0
        if (!isPackageExists) deleted_packages.push(initial_bought_packages[i])
      }
    }


    if (changed_packages.length > 0) dispatch<any>(bought_modules_action(prepare_packages_to_bought(changed_packages, true)))
    if (new_packages.length > 0) dispatch<any>(bought_modules_action(prepare_packages_to_bought(new_packages, false)))
    if (deleted_packages.length > 0) dispatch<any>(delete_modules_action(prepare_packages_to_bought(deleted_packages, false)))

    clear_data()
  }

  const can_make_payment = () =>
  {
    const old_packages: any = get_bought_modules_and_groups(false)
    if (bought_packages.length === old_packages.length)
    {
      for (let a = 0; a < bought_packages.length; a++)
      {
        for (let b = 0; b < bought_packages.length; b++)
        {
          const same_mg = bought_packages[a].module_id === old_packages[b].module_id
          if (!same_mg) continue
          if (bought_packages[a].group_id !== old_packages[b].group_id) return true
        }
      }
      return false
    }
    for (let i = 0; i < bought_packages.length; i++)
    {
      const p_item = bought_packages[i]
      if (
        p_item.module_id === undefined ||
        p_item.module_name === undefined ||
        p_item.package_id === undefined ||
        p_item.group_id === undefined ||
        p_item.group_name === undefined
        ) return false
    }
    return true
  }

  const close_modal = () =>
  {
    set_bought_packages([])
    set_selected_subjects([])
    set_show_modules(false)
    set_show_subjects(false)
    setContext({...context, add_student_modal: false})
  }

  const delete_student = () =>
  {
    dispatch<any>(delete_user_action(selected_student.id, "user", (status) =>
    {
      const notif_title = status === 200
      ? "Видалення успішне"
      : status === 404
      ? "Ця сутність має дані"
      : "Помилка видалення"
      setContext({
        ...context,
        notification_data: { title: notif_title, type: status === 200 ? "success" : "warning" }
      })
    }))
  }

  const send_link = () =>
  {
    clear_data()
    dispatch<any>(resend_user_link_action(student_email, (is_ok) =>
    {
      setContext((prev: any) => ({ ...prev, notification_data: {
        title: is_ok ? "Повторне запрошення відправлено" : "Щось пішло не так",
        type: is_ok ? "success" : "warning" } }))
        dispatch<any>(get_students_action())
    }))
  }

  const clear_states = () =>
  {
    set_bought_packages([])
    set_selected_subjects([])
    set_show_modules(false)
    set_show_subjects(false)
    set_selected_student(undefined)
  }

  useEffect(() =>
  {
    if (add_student_modal)
    {
      subject && students.length > 0 && dispatch<any>(get_bougth_modules_action(subject.id, students[0].id))
      clear_states()
    }
  }, [add_student_modal])

  useEffect(() => { clear_states() }, [students])

  useEffect(() =>
  {
    !add_student_modal && get_bought_modules_and_groups(true)
  }, [bought_modules, selected_student])

  useEffect(() =>
  {
    !add_student_modal && set_selected_subjects(bought_subjects)
  }, [bought_subjects, selected_student])

  useEffect(() =>
  {
    dispatch<any>(get_students_action())
  }, [])

  const subjectsComponent =
    <div
    // @ts-ignore
      style={add_student_modal ? {} : { ...external_styles , left: 910 }}
      className="TableSabjectsContainer"
    >
      {!add_student_modal && <div className="TableListContainerName">Subjects</div>}
      <div className="TableSabjectsListContainer">
        {subjects.length > 0 && subjects.map((subject: any) =>
        {
          return(
          <AdminListItem
            onClick={() => select_subject(subject)}
            checkbox={<CheckBox selected={add_student_modal ? is_selected_subject(subject.id) : (is_bought_subject(subject.id) || is_selected_subject(subject.id))} />}
            text={<div>{subject.name}</div>}
            width={160}
          />)
        }
        )}
      </div>
    </div>

  const invitationButton =
    <div
      // @ts-ignore
      style={{ ...external_styles, left: 1082 }}
    >
      <Button title="Відправити лінк" uppercase onClick={send_link} size="btn_small" mode="btn_transparent" />
    </div>

  const modulesComponent =
  <div
    // @ts-ignore
    style={add_student_modal ? {} : { ...external_styles, left: 1082 }}
    className="TableModulesContainer"
  >
    {!add_student_modal && <div className="TableListContainerName">Modules</div>}
    <div className="TableModulesListContainer">
      {show_subjects && bought_modules.map((module: any) =>
        {
          
          const package_in_bought = bought_packages.filter((p: any) => p.module_id === module.id)
          const isSelected = package_in_bought.length > 0
          const selectedGroup = set_selected_group(module) ?? {id: 0, name: "група"}
          const item_click = (mode: "module" | "group" = "module", group?: any) => set_new_package(module, mode, group)
          return (
          <AdminListItem
            checkbox={<CheckBox selected={isSelected} onClick={() => item_click()} />}
            text={<div onClick={() => item_click()} className="TableModulesListName" >{module.name}</div>}
            width={290}
            group={(isSelected && module.students_groups.length > 0) ? <Select
              value={ selectedGroup }
              options={module.students_groups}
              setValue={(gr) => item_click("group", gr)}
              mode="small"
              width={"90px"}
              margin="0 0 0 15px"
            /> : module?.students_groups?.length > 0 ? "" : <div style={{marginLeft: 50}}>empty</div>}
          />)
        })}
    </div>
  </div>


  const modal_props =
  {
    subjectsComponent,
    modulesComponent,
    close_modal,
    bought_packages,
    prepare_packages_to_bought,
    clear_data
  }

  if (pending) return <div className="StudentTableBody"><div className="StudentTableBodyEmpty">Завантаження ...</div></div>
  if (error !== "") return <div className="StudentTableBody"><div className="StudentTableBodyEmpty">Помилка при завантаженні</div></div>
  if (students.length === 0) return <div className="StudentTableBody"><div className="StudentTableBodyEmpty">Студентів не знайдено</div></div>

  return (
    <div className="StudentTableBody">
      {students.map((st: any) => (
          <div
            className={st.id === selected_student?.id ? "TableBodyRow TableBodyRowselected" : "TableBodyRow"}
            key={st.id}
            onClick={(event: MouseEvent<HTMLDivElement>) => select_student(event, st)}
          >
            <div className="TableBodyIdItem TableBodyItem">{st.id}</div>
            <div className="TableBodyRegisterItem TableBodyItem">
              {/*@ts-ignore */}
              {prepareDate(new Date(st?.created_at ? st.created_at : new Date()))}
            </div>
            <div className="TableBodyPhoneItem TableBodyItem">
              {st?.phone ? st.phone : "empty"}
            </div>
            <div className="TableBodyEmailItem TableBodyItem">{st.email ?? "empty"}</div>
          </div>
      ))}
      {selected_student && show_subjects && !add_student_modal && subjectsComponent}
      {selected_student && show_subjects && !add_student_modal && !show_subjects_condition && invitationButton}
      {show_subjects_condition && modulesComponent}
      {show_subjects_condition &&
        <div
          // @ts-ignore
          style={{ ... external_styles, left: 1384 }}
          onClick={() => can_make_payment() && confirm_payment()}
        >
          <Button
            title="save"
            mode={can_make_payment() ? "btn_black" : "btn_gray"}
            size="btn_small"
            width={200}
            uppercase
            disabled={!can_make_payment()}
          />
        </div>
      }
      {add_student_modal && <ModalHoc children={<AddStudentModal {...modal_props}  />} />}
      {selected_student&& <div className="CoursesDeleteButton" onClick={delete_student}>
        видалити
      </div>}
    </div>
  )
}