import "./styles.sass";
import { FC } from "react";
import { TeacherLessonItemTypes } from "./types";

export const TeacherLessonItem: FC<TeacherLessonItemTypes> = ({
  text,
  title,
  rounded,
  height,
  width,
  vertical_centered,
  horizontal_centered,
  titleWidth,
  textColor,
  uppercase,
}) => {

  const prepared_title = () =>
  {
    if (!title) return ""
    if (title.split("Пункти").length > 1) {
      const splited_title = title.split(" ");
      const first_content = splited_title[0];
      const second_content = splited_title.splice(1).join(" ");
      return (
        <>
          <div>{first_content}</div>
          <div>{second_content}</div>
        </>
      );
    } else return title;
  };

  return (
    <div className="TeacherLessonItem">
      {title && <h1
        className="TeacherLessonItemTitle"
        style={{
          width: titleWidth ?? 128,
        }}
      >
        {prepared_title()}
      </h1>}
      <div
        className="TeacherLessonItemMain"
      >
        <div className={`TeacherLessonItemText ${title === "Тип" ? "TeacherLessonItemTextType" : "" }`}>{ text }</div>
      </div>
    </div>
  );
};
