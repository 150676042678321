import "./styles.sass";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { delete_user_action, get_all_curator_modules_action, get_all_curator_module_groups, get_all_possible_groups_in_mastergroup, get_curators_action, get_subjects_action, get_teachers_action, patch_curator_groups_action, send_link_action, add_module_groups_action } from "../../../redux/actions/admin";
import { useSelector } from "react-redux";
import { Context } from "../../../context";
import { AdminUsersMenu } from "../../molecules/AdminUsersMenu";
import { ADD_CURATOR_MODE, ADD_TEACHER_MODE, CURATOR_MODE, PURE_USERS_MODE, TEACHER_MODE } from "../../../constants/admin";
import { SimpleInput } from "../../atoms/SimpleInput";
import { Field } from "formik";
import { Button } from "../../atoms/Button";
import { email_validate } from "../../../tools/validators";
import { AdminListItem } from "../../atoms/AdminListItem";
import { CheckBox } from "../../atoms/CheckBox";
import { Select } from "../../atoms/Select";
import { get_all_bougth_modules_action, get_bougth_modules_action, get_modules_action, get_module_groups_action } from "../../../redux/actions/admin"


export const AdminUsers: FC<any> = () => {
  const dispatch = useDispatch()
  const { subjects } = useSelector((st: any) => st.admin_subjects)
  const { teachers, curators } = useSelector((st: any) => st.admin_users)
  const { bought_modules, module_groups, curator_modules,  curator_module_groups, curator_setted_module_groups } = useSelector((st: any) => st.admin_modules)
  const [context, setContext] = useContext(Context)
  const { subject, curator, teacher, current_mode, module } = context

  const [show_groups, set_show_groups] = useState(false)
  const [email_field, set_email_field] = useState("")
  const [email_valid, set_email_valid] = useState(false)
  const [selected_groups, set_selected_groups] = useState<any>([])


  useEffect(() => dispatch<any>(get_subjects_action()), [])

  const select_subject = (subj: any) =>
  {
    const isSame = ((subject?.id === subj.id) && !curator)
    !isSame && dispatch<any>(get_teachers_action(subj.id))
    !isSame && dispatch<any>(get_curators_action(subj.id))
    setContext((prev: any) => ({ ...prev, subject: isSame ? undefined : subj, teacher: undefined, curator: undefined, current_mode: isSame ? PURE_USERS_MODE : TEACHER_MODE }))
  }


  const select_curator = (cur: any) =>
  {
    // dispatch<any>(get_all_bougth_modules_action(subject.id))
    dispatch<any>(get_all_curator_module_groups(cur.id)) // not work
    const isSame = curator?.id === cur.id
    !isSame && setContext((prev: any) => ({ ...prev, curator: cur, current_mode: isSame ? TEACHER_MODE : CURATOR_MODE}))
  }

  const select_module = (module: any) =>
  {
    dispatch<any>(get_module_groups_action(module.id))
    setContext({ ...context, module })
    set_show_groups(true)
  }


  const select_group = (group: any) =>
  {
    const isExist = selected_groups.filter((gr: any) => gr.id === group.id).length > 0
    if (isExist) set_selected_groups(selected_groups.filter((gr: any) => gr.id !== group.id))
    if (!isExist) set_selected_groups([...selected_groups, group])
  }

  const get_checked_groups = (set: boolean = false, groups?: any) =>
  {
    const use_groups = groups ?? module_groups
    if (use_groups.length === 0) return []
    const checked_groups = use_groups.filter((gr: any) => gr.curator_id === (curator?.id ? curator.id : ""))
    set && set_selected_groups(checked_groups)
    return checked_groups
  }
  
  useEffect(() =>
  {
    let need_teacher;
    if (teachers.length > 0 && subjects.length > 0) need_teacher = teachers.filter((t: any) => t.subject_id === subject.id)
    setContext({ ...context, teacher: need_teacher?.length > 0 ? need_teacher[0] : undefined})
  }, [teachers])

  useEffect(() => setContext({ ...context, current_mode: PURE_USERS_MODE, subject: undefined}), [])

  const menu_props =
  {
    curators,
    curator,
    subjects,
    subject,
    select_subject,
    select_curator,
  }

  const input_reffer = current_mode === TEACHER_MODE ? teacher : curator

  const send_link = () =>
  {
    const isAddTeacher = current_mode === ADD_TEACHER_MODE
    dispatch<any>(send_link_action(email_field, isAddTeacher ? "teacher" : "curator", subject.id, (msg, user) =>
    {
      isAddTeacher && dispatch<any>(get_teachers_action(subject.id))
      !isAddTeacher && dispatch<any>(get_curators_action(subject.id))
      setContext({
        ...context,
        [isAddTeacher ? "teachers" : "curators"]: [],
        notificatiion_data: { title: msg, type: "success" }
      })
      set_email_field("")
      set_email_valid(false)
      user && !isAddTeacher && select_curator(user)
      user && isAddTeacher && select_subject(user)
    }))
  }

  const delete_user = () =>
  {
    const isTeacher = current_mode === TEACHER_MODE
    const user_id = isTeacher ? teacher.id : curator.id
    const user_type = isTeacher ? "teacher" : "curator"
    dispatch<any>(delete_user_action(user_id, user_type, (status) =>
    {
      isTeacher && dispatch<any>(get_teachers_action(subject.id))
      !isTeacher && dispatch<any>(get_curators_action(subject.id))
      const notif_title = status === 200
        ? "Видалення успішне"
        : status === 404
        ? "Ця сутність має дані"
        : "Помилка видалення"
      setContext({
        ...context,
        [isTeacher ? "teachers" : "curators"]: [],
        current_mode: PURE_USERS_MODE,
        notification_data: { title: notif_title, type: status === 200 ? "success" : "warning" }
      })
      set_email_field("")
      set_email_valid(false)
    }))
  }

  const update_groups = (just_check: boolean = true) =>
  {
    const current_groups = [...selected_groups]
    const old_groups = get_checked_groups()
    const new_groups = []
    const deleted_groups = []
    // if group exist in old but not in current --- it's deleted group
    if (old_groups.length === 0)
    {
      // use currant groups as new
    }
    if (old_groups.length > 0)
    {
      for (let c = 0; c < current_groups.length; c++)
      {
        const current_id = current_groups[c].id
        if (current_id)
        {
          const isExistInOld = old_groups.filter((gr: any) => gr.id === current_id)
          if (isExistInOld.length === 0) new_groups.push(current_id)
        }
      }
      for (let c = 0; c < old_groups.length; c++)
      {
        const ol_id = old_groups[c].id
        if (ol_id)
        {
          const isExistInCurrent = current_groups.filter((gr: any) => gr.id === ol_id)
          if (isExistInCurrent.length === 0) deleted_groups.push(ol_id)
        }
      }
    }
    if (just_check)
    {
      if (deleted_groups.length === 0 && new_groups.length === 0) return false
      else return true
    }
    if (!just_check) {
      if (new_groups.length > 0)
      {
        new_groups.forEach((id: number) =>
        {
          dispatch<any>(patch_curator_groups_action({id, master_group_id: module.id, curator_id: curator.id}))
        })
      }
      if (deleted_groups.length > 0)
      {
        deleted_groups.forEach((id: number) =>
        {
          dispatch<any>(patch_curator_groups_action({id, master_group_id: module.id, curator_id: null}))
        })
      }
      set_selected_groups([])
      set_show_groups(false)
      setContext({ ...context, module: undefined, curator: undefined, current_mode: TEACHER_MODE})
    };
    
    // if group exist in current but not in old --- it's new group
  }

  const add_curator_group = () =>
  {
    dispatch<any>(add_module_groups_action({master_group_id: module.id, curator_id: curator.id}))
    dispatch<any>(get_all_curator_module_groups(curator.id, () =>
    {
      const current_module = curator_modules.find((el: any) => el.id === module.id)
      select_module(current_module)
      !current_module && set_show_groups(false)
      dispatch<any>(get_module_groups_action(current_module ? current_module.id : module.id, (md_groups) =>
      {
        get_checked_groups(true, md_groups)
        select_curator(curator)
      }))  
    }))
  }

  useEffect(() => { set_email_valid(email_validate(email_field)) }, [email_field])

  // set selected groups for curator
  useEffect(() =>
  {
    get_checked_groups(true)
  }, [module_groups])

  const user_input_fields = [
    {
      id: 0,
      name: "Пошта",
      value: input_reffer?.email ?? "no set",
    },
    {
      id: 1,
      name: "Ім’я",
      value: input_reffer?.name ?? "no set"
    },
    // {
    //   id: 2,
    //   name: "Пароль",
    //   value: input_reffer.email,
    // },
    {
      id: 3,
      name: "Телефон",
      value: input_reffer?.phone ?? "no set",
    },
    {
      id: 4,
      name: "ТГ",
      value: input_reffer?.telegram ?? "no set",
    },
  ]

  const modulesComponent = () =>
  <div className="TableModulesContainer">
    <div className="TableListContainerNameCurator">МАЙСТЕР - ГРУПИ</div>
    <div className="TableModulesListContainer">
      {curator_modules.length > 0 && curator_modules.map((module: any) =>
        {
          const on_click = () => select_module(module)
          return (
          <AdminListItem
            checkbox={<CheckBox selected={module["involved?"]} onClick={on_click} />}
            text={<div onClick={on_click} className="TableModulesListName" >{module.name}</div>}
            width={290}
          />)
        })}
    </div>
  </div>

  const set_curator_name = (group: any) =>
  {
    if (!group?.curator_name && !group?.curator_id) return "empty"
    if (group?.curator_name)
    {
      if (group.curator_name.trim() !== "") return group.curator_name
      if (group?.curator_id)
      {
        return `curator ${group.curator_id}`
      } else return "curator"
    }
    if (group?.curator_id)
    {
      return `curator ${group.curator_id}`
    } else return "curator"
  }

  const groupsComponent =
  <div className="TableModulesContainer">
    <div className="TableListContainerName TableListContainerNamecurator"><div style={{margin: "0 0 0 20px"}}>ГРУПА</div> <Button title="+ Group" size="btn_small" mode="btn_black" onClick={add_curator_group} width={30}  /></div>
    <div className="TableModulesListContainer">
      {module_groups.length > 0 ? module_groups.map((group: any) =>
        {
          const isExist = selected_groups.filter((gr: any) => gr.id === group.id).length > 0
          const on_click = () => select_group(group)
          return (
          <AdminListItem
            checkbox={<CheckBox selected={isExist} onClick={on_click} />}
            text={<div onClick={on_click} className="TableModulesListName" >{group.name}</div>}
            width={290}
            group={<div>{set_curator_name(group)}</div>}
          />)
        }) : "no groups found"}
    </div>
  </div>

  return (
    <div className="AdminCoursesContainer">
      <AdminUsersMenu { ...menu_props }  />
      {current_mode !== PURE_USERS_MODE &&
        <div className="AdminUsersCabinet">
          <div className="AdminUserInputsContainer">
            {((current_mode === TEACHER_MODE && teachers.length > 0) || (current_mode === CURATOR_MODE && curators.length > 0)) && user_input_fields.map ((user: any) =>
            <div key={user.id} className="AdminUserInputBlock">
              <div className="AdminUserInputName">{user.name}</div>
              <SimpleInput value={user.value} disabled/>
            </div>
            )}
            {(current_mode === ADD_TEACHER_MODE || current_mode === ADD_CURATOR_MODE ) &&
              <div className="AdminUserInvitationBlock">
                <div className="AdminUserInputName">Email</div>
                <SimpleInput value={email_field} onChange={(e) => set_email_field(e.target.value.trim())} margin="0 40px 0 0" />
                <Button title="відправити лінк" uppercase onClick={send_link} mode={email_valid ? "btn_transparent" : "btn_gray"} disabled={!email_valid} />
              </div>
            }
          </div>
          {current_mode === CURATOR_MODE &&
            <div className="AdminUsersCuratorInfoBlock">
              {modulesComponent()}
              {show_groups && groupsComponent}
            </div>
          }
          {current_mode === CURATOR_MODE &&
            <Button
              title="save"
              mode={update_groups() ? "btn_black" : "btn_gray"}
              margin="30px 0 0 20px"
              disabled={!update_groups()}
              uppercase
              width={30}
              onClick={() => update_groups(false)}
            />
          }
        </div>
      }
      {current_mode !== PURE_USERS_MODE && <div className="CoursesDeleteButton" onClick={delete_user}>
        видалити
      </div>}
    </div>
  );
};
