import { FC } from "react"
import { closeIcon } from "../../../assets/icons/common"
import "./styles.sass"
import { TaskPhotosAreaType, TaskPhotoType } from "./types"

export const TaskPhotosArea: FC<TaskPhotosAreaType> = ({ photos, name, delete_photos }) =>
{
  return (
    <div className="TaskPhotosAreaContainer">
      <div className="TaskPhotosArea">
        {photos.map(( el: TaskPhotoType, index: number ) =>
        {
          return (
            <div className="TaskPhotosItemContainer">
              { delete_photos &&
                <div
                  className="TaskPhotosDeleteItem"
                  onClick={() => delete_photos(name, el.id)}
                >{ closeIcon }</div>
              }
              <img className="TaskPhotosItem" key={ index } src={ el.url instanceof File ? URL.createObjectURL(el.url) : el.url } />
            </div>
          )
        })}
      </div>
    </div>
  )
}