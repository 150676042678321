import "./styles.sass";
import React, { FC, useContext, useEffect, useState } from "react";
import { AdminDbFilters } from "../../organisms/AdminDbFilters";
import { Button } from "../../atoms/Button";
import { FilterSelectType, HashTagType } from "./types";
import { useDispatch } from "react-redux";
import { search_tasks_action } from "../../../redux/actions/admin";
import { useSelector } from "react-redux";
import { closeIcon } from "../../../assets/icons/common";
import { AdminTasksInfo } from "../../organisms/AdminTasksInfo";
import { Context } from "../../../context";
import { ModalHoc } from "../../../hoc/Modal";
import { AddDbTaskModal } from "../../molecules/AddDbTaskModal";
import { TeacherLesson } from "../../organisms/TeacherLesson";
import { get_teacher_db_action } from "../../../redux/actions/teacher/db";

export const AdminDB: FC<any> = ({is_teacher = false}: {is_teacher: boolean}) => {
  const [context, setContext] = useContext(Context)
  const { add_db_task_modal, subject } = context
  const { subjects, current_subject } = useSelector((st: any) => st.admin_subjects)
  const { searched_tasks, tasks, pending } = useSelector((st: any) => st.admin_tasks)
  const profile = useSelector((st: any) => st.teacher_profile)
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [hashtags, setHashtags] = useState<HashTagType[]>([]);
  const [newHash, setNewHash] = useState<string>("");
  const [searchId, setSearchId] = useState<string>("");
  const [ise_search_clicked, set_is_search_clicked] = useState(false)
  const [filterTypes, setFilterTypes] = useState<FilterSelectType[]>([
    { id: 0, name: "всі", en_name: "", active: true },
    { id: 1, name: "коротка відповідь", en_name: "ShortAnswer", active: false },
    { id: 2, name: "тест", en_name: "ChooseAnswer", active: false },
    { id: 3, name: "відповідність", en_name: "MatchPair", active: false },
    { id: 4, name: "розгорнута відповідь", en_name: "ManualSolution", active: false },
  ]);
  const [filterOrder, setFilterOrder] = useState<FilterSelectType[]>([
    { id: 0, name: "ID", en_name: "id", active: true },
    { id: 1, name: "дата", en_name: "created_at", active: false },
    { id: 2, name: "тип", en_name: "exercise_type", active: false },
  ]);

  console.group("state in AdminDB")

  console.groupEnd()

  const toggleFilterTypes = (elem: FilterSelectType) => {
    setFilterTypes(
      filterTypes.map((el: FilterSelectType) => ({
        ...el,
        active: el.id === elem.id,
      }))
    );
  };

  const toggleFilterOrder = (elem: FilterSelectType) => {
    setFilterOrder(
      filterOrder.map((el: FilterSelectType) => ({
        ...el,
        active: el.id === elem.id,
      }))
    );
  };

  const addHashtag = () => {
    if (newHash.trim() === "") return;
    const new_hashtags: HashTagType[] = [];
    let splitted_hashtags = newHash.trim().split(" ");
    splitted_hashtags.forEach((el: string, id: number) =>
      new_hashtags.push({
        id: hashtags.length + id,
        name: "#" + el,
      })
    );
    setHashtags([...hashtags, ...new_hashtags]);
    setNewHash("");
  };

  const deleteHashtag = (id: number) => {
    setHashtags(hashtags.filter((el: HashTagType) => el.id !== id));
  };

  const clearFilters = () => {
    setFilterTypes(
      filterTypes.map((el: FilterSelectType) => ({
        ...el,
        active: el.id === 0,
      }))
    );
    setFilterOrder(
      filterOrder.map((el: FilterSelectType) => ({
        ...el,
        active: el.id === 0,
      }))
    );
    setSearchId("");
    setHashtags([]);
    setNewHash("");
    set_is_search_clicked(false)
  };

  useEffect(() => {
    setIsChanged(
      !filterTypes[0].active ||
      !filterOrder[0].active ||
      searchId !== "" ||
      newHash !== "" ||
      hashtags.length > 0
    );
  }, [hashtags, filterTypes, filterOrder, newHash, searchId]);

  const dispatch = useDispatch()
  const startSearch = () =>
  {
    set_is_search_clicked(true)
    const task_type = filterTypes.filter((el: FilterSelectType) => el.active)[0].en_name
    const prepared_hsahtags: string = hashtags.map((el: HashTagType) => el.name.replace("#", "")).join("");
    if (is_teacher)
    {
      const search_data = {
        exercise_id: searchId === "" ? undefined : searchId,
        exercise_type: task_type === "" ? undefined : task_type,
        hashtag: prepared_hsahtags.length > 0 ? prepared_hsahtags : undefined,
      };
      dispatch<any>(get_teacher_db_action(profile.subject_id, search_data))
    }
    if (!is_teacher)
    {
      const search_data = {
        subject_id: subject && subject?.id ? subject.id : subjects[0].id,
        exercise_id: searchId === "" ? undefined : searchId,
        exercise_type: task_type === "" ? undefined : task_type,
        sort_by: filterOrder.filter((el: FilterSelectType) => el.active)[0].en_name,
        hashtag: prepared_hsahtags.length > 0 ? prepared_hsahtags : undefined,
      };
      dispatch<any>(search_tasks_action(search_data))
      setContext({ ...context, search_data })
    }
  };

  const pending_element = () =>
  {
    if (is_teacher) return <></>
    if (pending) return <h3>Триває пошук вправ...</h3>
    if (tasks.length === 0 && (ise_search_clicked && searched_tasks.length === 0)) return <h3>Таких вправ ще нема</h3>
  }

  useEffect(clearFilters, [subject])

  const filterProps = {
    addHashtag,
    setNewHash,
    newHash,
    is_teacher,
    filterTypes,
    filterOrder,
    toggleFilterTypes,
    toggleFilterOrder,
    searchId,
    setSearchId,
  };

  return (
    <div className={`AdminDB ${ is_teacher ? "AdminDB_Teacher" : "" }`}>
      <AdminDbFilters {...filterProps} />
      {hashtags.length > 0 && (
        <div className={`AdminDBHashtags ${ is_teacher ? "AdminDBHashtags_Teacher" : "" }`}>
          {hashtags.map((el: HashTagType) => (
            <div className="AdminDBHashtagItemContainer" key={el.id}>
              <div className="AdminDBHashtagItem">{el.name}</div>
              <div
                className="AdminDBHashtagRemove"
                onClick={() => deleteHashtag(el.id)}
              >
                {closeIcon}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={`AdminDBButtons ${ is_teacher ? "AdminDBButtons_Teacher" : "" }`}>
        <Button
          title="Застосувати"
          mode="btn_black"
          width={20}
          rounded={is_teacher}
          margin="0 30px 0 0"
          onClick={startSearch}
        />
        {isChanged && (
          <Button
            title="скинути"
            mode="btn_transparent"
            width={20}
            onClick={clearFilters}
          />
        )}
      </div>
      {(ise_search_clicked || searched_tasks.length > 0) && (is_teacher ? <TeacherLesson db_mode /> : <AdminTasksInfo taskExternal />)}
      {/* {(!ise_search_clicked && searched_tasks.length > 0) && <AdminTasksInfo taskExternal taskExternalBody={ searched_tasks[0] } /> } */}
      {pending_element()}
      {add_db_task_modal && <ModalHoc children={<AddDbTaskModal />} />}
    </div>
  );
};
