import "./styles.sass";
import { FC, useContext } from "react";
import { Context } from "../../../context";
import { is_lesson } from "../../../tools/admin/common";
import { TeacherHomeworkTop } from "../../atoms/TeacherHomeworkTop";
import { TeacherTestTop } from "../../atoms/TeacherTestTop";
import { TeacherLessonType } from "./types";
import { TeacherTasks } from "../TeacherTasks";
import { TEACHER_LESSON_MODE } from "../../../constants/admin";
import { useSelector } from "react-redux";

export const TeacherLesson: FC<TeacherLessonType> = ({ db_mode }) =>
{
  const [context, setContext] = useContext(Context)
  const { module, lesson, homework_id } = context

  const teacher_db = useSelector((st: any) => st.teacher_db)

  const need_work = lesson ? is_lesson(lesson.name) ? module["homeworks"].find((work: any) => work.lesson_id === homework_id) : lesson : {}
  const exercises = !db_mode ? need_work?.exercises ? need_work.exercises : [] : teacher_db.exercises
  
  const lesson_back_click = () => { setContext({ ...context, current_mode: TEACHER_LESSON_MODE }) }

  const top_component = !db_mode ? is_lesson(lesson.name) ? <TeacherHomeworkTop lesson_back_click={lesson_back_click} name={lesson.name} theme={lesson?.theme ? lesson.theme : "без теми"} /> : <TeacherTestTop /> : null

  return (
    <div className="TeacherLesson">
      { top_component }
      <div className="TeacherLessonMain">
        {exercises.length > 0 ? exercises.map((exercise: any, index: number) => (
          <TeacherTasks exercise={exercise} index={index} db_mode={db_mode} />
        )) : <div className="TeacherLessonEmpty">Завдань поки що немає</div> }
      </div>
    </div>
  );
};
