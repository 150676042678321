import { FC } from "react";
import "./styles.sass";
import { SimpleInputType } from "./types";

export const SimpleInput: FC<SimpleInputType> = ({
  name,
  value,
  placeholder,
  onChange,
  onClick,
  roundedBorder,
  onIconClick,
  width,
  type,
  margin,
  padding,
  height,
  font = {},
  icon,
  disabled = false,
}) => {
  return (
    <div
      className="SimpleInputContainer"
      style={{
        height: height ? `${height}px` : "100%",
        width: width ? `${width}px` : "calc(100% - 20px)",
        margin: margin ?? "0",
        borderRadius: roundedBorder ? 8 : 0,
      }}
    >
      <input
        style={{
          padding: padding ?? "10px",
          ...font,
        }}
        className="SimpleInput"
        name={name ?? "simple_input"}
        value={value ?? ""}
        onClick={onClick}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        type={type ?? "text"}
      />
      {icon && <div onClick={onIconClick} className="SimpleInputIcon">{ icon }</div>}
    </div>
  );
};
