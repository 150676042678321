import { ChangeEvent, FC, KeyboardEvent, useContext, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { closeIcon } from "../../../assets/icons/common"
import { Context } from "../../../context"
import { clear_searched_task_action, search_tasks_action } from "../../../redux/actions/admin"
import { Button } from "../../atoms/Button"
import { SimpleInput } from "../../atoms/SimpleInput"
import { AdminTasksInfo } from "../../organisms/AdminTasksInfo"
import "./styles.sass"

export const AddTaskModal: FC<any> = () => {
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const { searched_tasks } = useSelector((st: any) => st.admin_tasks)


  const [search_id, set_search_id] = useState("")

  const { subject } = context

  const start_search = () => {
    dispatch<any>(search_tasks_action({ subject_id: subject.id, exercise_id: +search_id}))
  }

  const close_search = () => {
    setContext({ ...context, search_task_modal: false })
    dispatch<any>(clear_searched_task_action())
  }

  const update_search_id = (value: string) =>
  {
    // @ts-ignore
    if (isNaN(value)) return
    set_search_id(value)
  }

  const isFinded = searched_tasks.length > 0

  const is_id_typed = search_id.trim().length !== 0

  return (
    <div className="AddTaskModal">
      <div className="AddTaskModalsearchContainer">
        <div className="TaskInformationId">ID</div>
        <SimpleInput
          name="id"
          placeholder="0"
          value={search_id}
          width={200}
          height={50}
          margin="0 60px 0 0"
          onChange={(e: ChangeEvent<HTMLInputElement>) => update_search_id(e.target.value)}
        />
        <Button
          title="знайти"
          mode={ is_id_typed ? "btn_black" : "btn_gray" }
          uppercase
          width={20}
          onClick={start_search}
          disabled={ !is_id_typed }
        />
        {!isFinded && <div className="TaskInformationInfo">введіть корректне ID для пошуку</div>}
        <div
          style={{ marginLeft: isFinded ? 560 : 60, cursor: "pointer" }}
          onClick={close_search}
        >{closeIcon}</div>
      </div>
      {isFinded && <AdminTasksInfo taskSearched={searched_tasks[0]} lock />}
    </div>
  )
}