import "./styles.sass";
import { FC, useContext, useEffect, useState } from "react"
import { AdminTypes } from "./types"
import { AdminCourses } from "../../Components/templates/AdminCourses";
import { Context, get_initial_context } from "../../context";
import { SUBJECT_MODE } from "../../constants/admin";
import { get_students_action, get_subjects_action } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";
import { AdminStudents } from "../../Components/templates/AdminStudents";
import { AdminUsers } from "../../Components/templates/AdminUsers";
import { AdminDB } from "../../Components/templates/AdminDB";
import { useSelector } from "react-redux";

export const Admin: FC<AdminTypes> = () => {
  const dispatch = useDispatch()
  const [context, setContext] = useContext(Context)
  const { auth, menu_item_id } = context;

  // set saved states
  useEffect(() => {
    const local_menu_item = localStorage.getItem("menu_item")
    const parsed_local_menu_item = local_menu_item !== null ? JSON.parse(local_menu_item) : 0
    const local_subject_item = localStorage.getItem("subject_item")
    setContext({
      ...context,
      current_mode: SUBJECT_MODE,
      menu_item_id: parsed_local_menu_item,
      subject: local_subject_item ? JSON.parse(local_subject_item) : null
    })
  }, [])

  // get initial data (subjects)
  useEffect(() => {
    if (menu_item_id === 0)
    {
      setContext({ ...context, current_mode: SUBJECT_MODE })
      if (auth) dispatch<any>(get_subjects_action())
    }
  }, [menu_item_id, auth])

  return (
    <div className="AdminPage">
      {menu_item_id === 0 && <AdminCourses />}
      {menu_item_id === 1 && <AdminStudents />}
      {menu_item_id === 2 && <AdminUsers />}
      {menu_item_id === 3 && <AdminDB />}
    </div>
  )
}