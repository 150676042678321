import {
  GET_CURATOR_MGROUPS_FAIL,
  GET_CURATOR_MGROUPS_PENDING,
  GET_CURATOR_MGROUPS_SUCCESS,
  GET_CURATOR_PROFILE_FAIL,
  GET_CURATOR_PROFILE_PENDING,
  GET_CURATOR_PROFILE_SUCCESS,
} from "./../../constants/curator";

const initialState = {
  email: "",
  gender: "",
  id: 0,
  name: "",
  phone: 0,
  rolle: "",
  subject_id: null,
  subject_name: null,
  surname: "",
  telegram: "",
  msgroup: null,
  pending: false,
  mspending: true,
  error: "",
  student_groups: [],
};

export const curator_profile = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CURATOR_PROFILE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_CURATOR_PROFILE_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        gender: action.payload.gender,
        id: action.payload.id,
        name: action.payload.name,
        phone: action.payload.phone,
        rolle: action.payload.rolle,
        subject_id: action.payload.subject_id,
        subject_name: action.payload.subject_name,
        surname: action.payload.surname,
        telegram: action.payload.telegram,
        pending: false,
      };
    case GET_CURATOR_PROFILE_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    case GET_CURATOR_MGROUPS_PENDING:
      return {
        ...state,
        mspending: true,
        error: action.payload,
      };

    case GET_CURATOR_MGROUPS_SUCCESS:
      return {
        ...state,
        msgroup: [...action.payload],

        mspending: false,
      };

    case GET_CURATOR_MGROUPS_FAIL:
      return {
        ...state,
        mspending: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};
