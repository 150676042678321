import "./styles.sass";
import { FC, useContext, useEffect, useState } from "react";
import { StudentCoursesType } from "./types";
import { StudentSearch } from "../../molecules/StudentSearch";
import { StudentAdditionalMenu } from "../../molecules/StudentAdditionalMenu";
import { StudentLesson } from "../../organisms/StudentLesson";
import { StudentModuleInfo } from "../../organisms/StudentModuleInfo";
import { StudentSearchScreen } from "../../molecules/StudentSearchScreen";
import { Context } from "../../../context";
import { STUDENT_COURSES_MODE, STUDENT_LESSON_MODE, STUDENT_MODULE_MODE, STUDENT_SEARCH_MODE, STUDENT_TASK_MODE, STUDENT_TEST_MODE } from "../../../constants/admin";
import { StudentLessonInfo } from "../../organisms/StudentLessonInfo";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { get_student_exam_tasks_action, get_student_tasks_action, save_homevork_action, send_exam_action, send_homevork_action } from "../../../redux/actions/student/tasks";
import { ModalHoc } from "../../../hoc/Modal";
import { StudentPreSaveModal } from "../../molecules/StudentPreSaveModal";
import { find_by_id, shortify_text } from "../../../tools/common";
import { get_student_exams_action, get_student_lessons_action } from "../../../redux/actions/student/lessons";
import { is_lesson } from "../../../tools/admin/common";
import { student_menu_items } from "../../../constants/general/menu";
import { useNavigate } from "react-router-dom";
import { delete_exercise_photo_action, post_exercise_photo_action } from "../../../redux/actions/student/photos";
import { clear_storage } from "../../../Pages/UserEntry/exit";

export const StudentCourses: FC<StudentCoursesType> = () =>
{
  const [context, setContext] = useContext(Context)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    current_mode,
    module,
    lesson,
    lesson_pre_save_modal,
    homework_answers,
    stopped_action,
    leave_lesson,
    user_type,
    is_lesson_changed,
    search_materials,
    menu_item_id
  } = context

  const user_id = useSelector((st: any) => st.user_info.id)
  const { modules } = useSelector((st: any) => st.student_modules)
  const { lessons } = useSelector((st: any) => st.student_lessons)
  const { lesson_info, pending } = useSelector((st: any) => st.student_tasks)
  const { subjects } = useSelector((st: any) => st.student_subjects)
  const [ estimation_data, set_estimation_data ] = useState({ current: 0, max: 0, status: "", comment: "" })

  const set_task_mode = () => { setContext({ ...context, current_mode: STUDENT_TASK_MODE }) }

  const clear_homework_answers = () => homework_answers.filter((answer: { exercise_id: number, answer: any}) =>  answer.answer.length > 0)

  const generate_photo_action_notif = (mode: "delete" | "add", name: string, is_ok: boolean) =>
  {
    setContext((prev: any) => ({
      ...prev,
      notification_data:
      {
        title: `${mode === "delete" ? "Видалення" : "Завантаження"} фото ${shortify_text(name)} ${is_ok ? "успішне" : "невдале"}`,
        type: is_ok ? "success" : "warning"
      }
    }))
  }

  let tm: any = null;
  const sleep = new Promise((resolve: any) => {
    if(!tm) tm = setTimeout(() => { resolve(); clearTimeout(tm) }, 3000)
    else resolve()
  });

  const send_photos = () => new Promise((resolve) =>
  {
    const photo_exercises: any = homework_answers.filter((a: any) => typeof a.answer !== "string")
    if (!photo_exercises.length) return
    const GlobalPromise = photo_exercises.map((photo_exercise: any, index: number) =>
    {
      const prepared_files = [ ...photo_exercise.answer ]
      const exercise_id: any = photo_exercise.exercise_id
      const new_photos: File[] = []
      const old_photos: { id: number, url: string }[] = []
      prepared_files.forEach((el: { id: number, url: string } | File) => el instanceof File ? new_photos.push(el) : old_photos.push(el))
      const exercise_initial_data = lesson_info.exercises.filter((el: any) => el.id === exercise_id)[0]
      const initial_photos = exercise_initial_data?.user_answer ? exercise_initial_data.user_answer.work_photo : []
      const intial_photo_ids = initial_photos.map((el: { id: number, url: string }) => el.id)
      const deleted_photos = intial_photo_ids.filter((el: number) => !old_photos.map((old_ph: { id: number, url: string }) => old_ph.id).includes(el) )

      const DeletePromises = deleted_photos.map((el: number) =>
      {
        const notif = (st: boolean) => generate_photo_action_notif("delete", find_by_id(initial_photos, el).url, st)
        return delete_exercise_photo_action(el)
          .then(() => notif(true) )
          .catch(() => notif(true) )
      })
      const AddPromises = new_photos.map((ph: File) =>
      {
        const notif = (st: any) => generate_photo_action_notif("add", ph.name, st)
        return post_exercise_photo_action({user_id, exercise_id, user_homework_id: lesson_info.user_homework_id, work_photo: ph})
          .then(() => notif(true) )
          .catch(() => notif(false) )
      })
      
      return Promise.race([...DeletePromises, ...AddPromises, sleep])
    })
    const smth = Promise.all(GlobalPromise)
    smth
      .then(() => resolve("ok"))
      .catch(() => resolve("ok"))
  })

  const save_photos_callback = (is_ok: boolean, mode: "save" | "send") =>
  {
    const is_it_lesson = is_lesson(lesson.name)
    const notification_data = mode === "save"
      ?
      {
        title: is_ok ? "ДЗ збережено. Зможеш продовжити коли буде зручно"
                     : "Щось пішло не такб відповіді не збережено, спробуй знову",
        type: is_ok ? "success" : "warning"
      }
      :
      {
        title: is_ok ? `${is_it_lesson ? "Дз" : "Тест"} відправлено на перевірку куратора. Чекай результат!`
                     : "Щось пішло не так. Спропуй пізніше",
        type: is_ok ? "success" : "warning"
      }
      if (is_it_lesson)
      {
        dispatch<any>(get_student_tasks_action(lesson.homework_id, () =>
        {
          setContext((prev_context: any) => ({
            ...prev_context,
            notification_data,
            is_lesson_changed: false
          }))
        }))
      } else
      {
        dispatch<any>(get_student_exams_action(module.id, (exams: any) =>
        {
          const need_lesson = exams.find((el: any) => el.id === lesson.id)
          setContext((prev_context: any) => ({
            ...prev_context,
            notification_data,
            is_lesson_changed: false
          }))
          dispatch<any>(get_student_exam_tasks_action({ name: need_lesson.name, exercises: need_lesson.exercises, user_master_group_exam_id: need_lesson.user_master_group_exam.id, ...need_lesson.user_master_group_exam }))
        }))
      }
  }

  const is_photo_exercise = homework_answers.filter((a: any) => typeof a.answer !== "string").length

  const save_lesson = async () =>
  {
    is_photo_exercise && await send_photos()
    const res = await save_homevork_action(
      user_id,
      lesson_info.user_homework_id,
      clear_homework_answers(),
    )
    save_photos_callback(res.status === 200, "save")
  }

  const not_all_answers = clear_homework_answers().length < homework_answers.length
  
  const send_lesson = async ({after_modal}: {after_modal: boolean | undefined}) =>
  {
    if (not_all_answers && !after_modal) return setContext((prev: any) => ({ ...prev, lesson_pre_save_modal: true, stopped_action: { name: is_lesson(lesson.name) ? "SEND_LESSON" : "SEND_TEST" } }))
    {
      is_photo_exercise && await send_photos()
      if (is_lesson(lesson.name))
      {
        const res = await send_homevork_action(
          user_id,
          lesson_info.user_homework_id,
          clear_homework_answers(),
        )
        dispatch<any>(get_student_tasks_action(lesson_info.user_homework_id))
        save_photos_callback(res.status === 200, "send")
      } else
      {
        const res = await send_exam_action(
          user_id,
          lesson_info.user_master_group_exam_id,
          clear_homework_answers(),
        )
        console.log("res", res);
        
        save_photos_callback(res.status === 200, "send")
      }
    }
  }
  const chech_homework_changes = () =>
  {
    if (current_mode !== STUDENT_TASK_MODE) return false
    if (!lesson_info) return false
    const exercises = lesson_info.exercises
    if (!exercises) return false
    if (!exercises.length) return false
    for (let i = 0; i < exercises.length; i++)
    {
      const exercise = exercises[i]
      const type = exercise.exercise_type
      const answer = exercise.user_answer
      const is_manual = type === "ManualSolution"
      const current_answer = is_manual ? homework_answers[i].answer : homework_answers[i].answer.trim()
      if (is_manual && (answer || current_answer.length))
      {
        if (!answer && current_answer.length) return true
        if (current_answer.length || answer.work_photo.length)
        {
          if (answer.work_photo.length !== current_answer.length) return true
          if (current_answer.find((el: any) => el instanceof File)) return true
        }
      } else
      {
        if (current_answer.length)
        {
          if (!lesson_info.user_master_group_exam_id && answer && answer.length!=null && answer.length !=0 && current_answer.length) return true
          if (!answer && current_answer.length) return true
          if (answer.trim().length !== current_answer.length) return true
        }
      }
    }
    return false 
  }

  const select_search_material = (item: any) =>
  { dispatch<any>(get_student_lessons_action(item.mg_id, (lssns) =>
    { dispatch<any>(get_student_tasks_action(lssns.find((el: any) => el.id === item.id).homework_id, () =>
      { setContext((prev: any) => ({
        ...prev,
        current_mode: STUDENT_TASK_MODE,
        module: modules.find((el: any) => el.name === item.mg_name),
        lesson: lssns.find((el: any) => el.id === item.id) }))
      }))
    }
  ))}


  useEffect(() => { lesson && dispatch<any>(get_student_tasks_action(lesson.homework_id)) }, [lesson])

  useEffect(() => { current_mode === STUDENT_TASK_MODE && setContext({ ...context, is_lesson_changed: chech_homework_changes() }) }, [homework_answers])

  const prepared_user_answers = (task: any) =>
  {
    const type = task.exercise_type
    const answer = task.user_answer
    if (!answer)
    {
      if (type === "ManualSolution") return []
      return ""
    }
    if (type === "ManualSolution") return answer.work_photo
    return answer
  }

  useEffect(() =>
  {
    if (!lesson_info) return
    set_estimation_data({
      current: lesson_info?.estimation ? lesson_info.estimation : 0,
      max: lesson_info?.exercises ? lesson_info.exercises.reduce((acc: number, ex: any) => acc + ex.max_shore, 0) : 0,
      status: lesson_info.aasm_state,
      comment: lesson_info?.curator_comment ? lesson_info.curator_comment : ""
    })
    if (!lesson_info?.exercises) return
    if (!lesson_info.exercises.length) return
    
    setContext( (prev: any) => ({ ...prev, homework_answers: lesson_info.exercises.map((task: any) => ({exercise_id: task.id, answer: prepared_user_answers(task)})) }))
  }, [lesson_info])

  useEffect(() => { !module && modules.length > 0 && setContext({ ...context, module: modules[0], current_mode: STUDENT_MODULE_MODE }) }, [modules])

  const select_tasks_leave_answer = (answer: boolean) => { setContext({ ...context, lesson_pre_save_modal: false, leave_lesson: answer }) }
  const reset_menu_item = (item?: number) => { setContext((prev: any) => ({ ...prev, menu_item_id: item })) }

  useEffect(() =>
  {
    if (lesson_pre_save_modal) return
    if (!leave_lesson) return
    if (!stopped_action) return
    switch (stopped_action.name)
    {
      case "LESSON":
      case "TEST":
        const saved_lesson = find_by_id(lessons, stopped_action.id)
        if (!saved_lesson) break
        save_lesson()
        return setContext({ ...context, current_mode: is_lesson(saved_lesson.name) ? STUDENT_LESSON_MODE : STUDENT_TEST_MODE, saved_lesson });
      case "SEND_LESSON": return send_lesson({after_modal: true})
      case "SEND_TEST": return send_lesson({after_modal: true})
      case "MODULE":
        const saved_module = find_by_id(modules, stopped_action.id)
        if (!saved_module) break
        dispatch<any>(get_student_lessons_action(saved_module.id)) 
        save_lesson()
        return setContext({ ...context, current_mode: STUDENT_MODULE_MODE, saved_module });
      case "MENU":
        const saved_menu_item = find_by_id(student_menu_items, stopped_action.id)
        if (!saved_menu_item) break
        if (saved_menu_item.name === "вихід") { clear_storage(); navigate("/sign_in") }
        setContext({...context, menu_item_id: saved_menu_item.id})
        return save_lesson()
      case "SUBJECT":
        const saved_subject = find_by_id(subjects, stopped_action.id)
        if (!saved_subject) break
        save_lesson()
        return setContext({ ...context, subject: saved_subject, lesson: null, module: null, current_mode: STUDENT_COURSES_MODE  })
      case "GENERAL":
        reset_menu_item();
        const tm = setTimeout(() => { reset_menu_item(0); clearTimeout(tm) }, 100)
        localStorage.setItem("menu_item", "0")
        return;
    }
  }, [lesson_pre_save_modal])

  const student_back_to_lesson = () => { setContext({ ...context, current_mode: STUDENT_LESSON_MODE }) }

  const student_lesson_info_props = { lesson, set_task_mode, estimation_data }
  const student_lesson_props =
  {
    student_back_to_lesson,
    is_changed: is_lesson_changed,
    lesson_info,
    lesson_name: lesson ? lesson.name : undefined,
    lesson_theme: lesson ? lesson.theme : undefined,
    save_lesson,
    send_lesson,
    user_type
  }
  const student_s_screen_props = { search_materials, select_search_material }
  const student_pr_save_modal_props = { select_tasks_leave_answer, clear_homework_answers }

  const show_module_condition = current_mode === STUDENT_MODULE_MODE && module
  const show_lesson_condition = current_mode === STUDENT_LESSON_MODE && lesson && lesson.aasm_state === "published"

  return (
    <>
      <StudentSearch />
      <div className="StudentContent">
        <StudentAdditionalMenu />
        { show_module_condition && <StudentModuleInfo module={ module } /> }
        { show_lesson_condition && <StudentLessonInfo { ...student_lesson_info_props } /> }
        { current_mode === STUDENT_TASK_MODE && !pending && <StudentLesson { ...student_lesson_props } /> }
        { current_mode === STUDENT_SEARCH_MODE &&  <StudentSearchScreen { ...student_s_screen_props } /> }
      </div>
      { lesson_pre_save_modal && <ModalHoc children={ <StudentPreSaveModal { ...student_pr_save_modal_props } /> } /> }
    </>
  );
};
