import { FC, useContext, useEffect, useState } from "react"
import { NotificationType } from "./types"
import { Context } from "../../../context"
import { notif_icon } from "../../../assets/icons/notification"
import "./styles.sass"

export const Notification: FC<NotificationType> = () =>
{
  const [show_notif, set_show_notif] = useState(false)
  const [context, setContext] = useContext(Context)
  const { notification_data } = context

  useEffect(() =>
  {
    if (notification_data.title !== "")
    {
      set_show_notif(true)
      const timeout = setTimeout(() =>
      {
        const second_timeout = setTimeout(() =>
        {
          setContext({ ...context, notification_data: { title: "", type: "info" } })
          clearTimeout(second_timeout)
        }, 500)
        set_show_notif(false)
        clearTimeout(timeout)
      }, 2000)
    }
  }, [notification_data])

  return (
    <div className={`Notification ${notification_data.title === "" && "NotificationHide"}`}>
      <div className={
        `NotificationElement
        ${show_notif && "NotificationElementVisible"}
        NotificationElement_${notification_data.type}`
      }>
        {/* @ts-ignore */}
        {notif_icon[notification_data.type]}
        <div className="NotificationElementText">{ notification_data.title }</div>
      </div>
    </div>
  )
}