export const paperclip_icon = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.85234 11.7422C8.86988 11.7646 8.89229 11.7827 8.91788 11.7952C8.94346 11.8077 8.97154 11.8141 9 11.8141C9.02846 11.8141 9.05654 11.8077 9.08212 11.7952C9.10771 11.7827 9.13012 11.7646 9.14766 11.7422L11.7727 8.42109C11.8687 8.29922 11.782 8.11875 11.625 8.11875H9.88828V0.1875C9.88828 0.084375 9.80391 0 9.70078 0H8.29453C8.19141 0 8.10703 0.084375 8.10703 0.1875V8.11641H6.375C6.21797 8.11641 6.13125 8.29688 6.22734 8.41875L8.85234 11.7422ZM17.5781 10.9219H16.1719C16.0688 10.9219 15.9844 11.0063 15.9844 11.1094V14.7188H2.01562V11.1094C2.01562 11.0063 1.93125 10.9219 1.82812 10.9219H0.421875C0.31875 10.9219 0.234375 11.0063 0.234375 11.1094V15.75C0.234375 16.1648 0.569531 16.5 0.984375 16.5H17.0156C17.4305 16.5 17.7656 16.1648 17.7656 15.75V11.1094C17.7656 11.0063 17.6812 10.9219 17.5781 10.9219Z"
      fill="#24221A"
    />
  </svg>
);

export const download_icon = (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4531 12.5962C11.4178 12.5613 11.3702 12.5418 11.3207 12.5418C11.2711 12.5418 11.2235 12.5613 11.1882 12.5962L8.4648 15.3197C7.20386 16.5806 5.07573 16.7142 3.68355 15.3197C2.28901 13.9251 2.42261 11.7994 3.68355 10.5384L6.40698 7.81499C6.47964 7.74233 6.47964 7.6228 6.40698 7.55015L5.47417 6.61733C5.43893 6.58244 5.39134 6.56287 5.34175 6.56287C5.29216 6.56287 5.24457 6.58244 5.20933 6.61733L2.48589 9.34077C0.503076 11.3236 0.503076 14.5322 2.48589 16.5126C4.4687 18.4931 7.6773 18.4955 9.65776 16.5126L12.3812 13.7892C12.4539 13.7166 12.4539 13.597 12.3812 13.5244L11.4531 12.5962ZM17.514 1.48687C15.5312 -0.495947 12.3226 -0.495947 10.3421 1.48687L7.61636 4.2103C7.58146 4.24554 7.56189 4.29313 7.56189 4.34272C7.56189 4.39232 7.58146 4.43991 7.61636 4.47515L8.54683 5.40562C8.61948 5.47827 8.73901 5.47827 8.81167 5.40562L11.5351 2.68218C12.796 1.42124 14.9242 1.28765 16.3164 2.68218C17.7109 4.07671 17.5773 6.20249 16.3164 7.46343L13.5929 10.1869C13.558 10.2221 13.5385 10.2697 13.5385 10.3193C13.5385 10.3689 13.558 10.4165 13.5929 10.4517L14.5257 11.3845C14.5984 11.4572 14.7179 11.4572 14.7906 11.3845L17.514 8.66109C19.4945 6.67827 19.4945 3.46968 17.514 1.48687ZM12.2992 5.72671C12.2639 5.69181 12.2163 5.67224 12.1667 5.67224C12.1172 5.67224 12.0696 5.69181 12.0343 5.72671L6.72573 11.033C6.69084 11.0682 6.67126 11.1158 6.67126 11.1654C6.67126 11.215 6.69084 11.2626 6.72573 11.2978L7.65386 12.2259C7.72651 12.2986 7.84605 12.2986 7.9187 12.2259L13.225 6.91968C13.2976 6.84702 13.2976 6.72749 13.225 6.65483L12.2992 5.72671Z"
      fill="#24221A"
    />
  </svg>
);
