export const SUBJECT_MODE = "SUBJECT_MODE";
export const PURE_MODULES_MODE = "PURE_MODULES_MODE";
export const MODULE_MODE = "MODULE_MODE";
export const LESSON_MODE = "LESSON_MODE";
export const TEST_MODE = "TEST_MODE";
export const TASKS_MODE = "TASKS_MODE";
export const ADD_MODULE_MODE = "ADD_MODULE_MODE";
export const ADD_LESSON_MODE = "ADD_LESSON_MODE";
export const ADD_TEST_MODE = "ADD_TEST_MODE";
export const PURE_USERS_MODE = "PURE_USERS_MODE";
export const TEACHER_MODE = "ADMIN_TEACHER_MODE";
export const ADD_TEACHER_MODE = "ADD_TEACHER_MODE";
export const ADD_CURATOR_MODE = "ADD_CURATOR_MODE";
export const CURATOR_MODE = "ADMIN_CURATOR_MODE";
export const USERS_MODE = "ADMIN_USERS_MODE";
export const DB_MODE = "ADMIN_DB_MODE";

// STUDENT
export const STUDENT_COURSES_MODE = "STUDENT_COURSES_MODE";
export const STUDENT_PROFILE_MODE = "STUDENT_PROFILE_MODE";
export const STUDENT_MODULE_MODE = "STUDENT_MODULE_MODE";
export const STUDENT_LESSON_MODE = "STUDENT_LESSON_MODE";
export const STUDENT_TEST_MODE = "STUDENT_TEST_MODE";
export const STUDENT_TASK_MODE = "STUDENT_TASK_MODE";
export const STUDENT_SEARCH_MODE = "STUDENT_SEARCH_MODE";

// TEACHER
export const TEACHER_MODULE_MODE = "TEACHER_MODULE_MODE";
export const TEACHER_LESSON_MODE = "TEACHER_LESSON_MODE";
export const TEACHER_TEST_MODE = "TEACHER_TEST_MODE";
export const TEACHER_TASK_MODE = "TEACHER_TASK_MODE";

