import "./styles.sass";
import { FC, useContext, useEffect } from "react";
import { StudentType } from "./types";
import { StudentProfile } from "../../Components/templates/StudentProfile";
import { StudentCourses } from "../../Components/templates/StudentCourses";
import { Context } from "../../context";
import { STUDENT_MODULE_MODE, STUDENT_PROFILE_MODE } from "../../constants/admin";

export const Student: FC<StudentType> = () => {
  const [context, setContext] = useContext(Context)
  const {menu_item_id, mobile_menu} = context

  useEffect(() => {
    setContext({...context, current_mode: menu_item_id === 0 ? STUDENT_MODULE_MODE : STUDENT_PROFILE_MODE })
  }, [menu_item_id])

  useEffect(() =>
  {
    const menu_local = localStorage.getItem("menu_item")
    setContext({
      ...context,
      menu_item_id: menu_local ? JSON.parse(menu_local) : 0
    })
  }, [])

  const Student = <>
      {menu_item_id === 0 && <StudentCourses />}
      {menu_item_id === 1 && <StudentProfile />}
  </>

  return (
    <>
      <div className={`Student Student_PC`}>{ Student }</div>
      <div className={`Studen Student_MOBILE St ${ mobile_menu ? "MENU_OPEN" : "MENU_CLOSE"}`}>
        { Student }
      </div>
    </>
  );
};
