import axios from 'axios';
import {
  GET_STUDENTS_PENDING,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
} from "./../../constants/admin";

const initialState = {
  students: [],
  pending: false,
  error: "",
};

export const admin_students = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STUDENTS_PENDING:
      return {
        ...state,
        pending: true
      }
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
        pending: false
      }
    case GET_STUDENTS_FAIL:
      return {
        ...state,
        pending: false,
        error: action.payload
      }
    default: return { ...state }
  }
}